<template>
  <div class="home" id="rootchart" :style="cssVars">
    <!-- :style="cssVars" -->
    <div class="title">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="概览" name="first">
          <div class="CH_Card profit_card autoArea">
            <div class="CH_Card_tit overfall">
              <span
                >财报分析
                {{ period }}
              </span>

              <div class="downloadBtns">
                <CButtonsDownload
                  :dButtonsDownloads="dButtonsDownloads"
                  @fnButtondownload="fnButtonDL6_1"
                />
                <span style="display: none">
                  <download-excel
                    id="downloadExcel6_1"
                    :data="dFinDataSnapshot"
                    :fields="json_fields"
                    :header="title"
                    :name="overviewName"
                  >
                    <i class="el-icon-download"></i>
                  </download-excel>
                </span>
              </div>

              <!-- <el-select class="Q3" v-model="Qvalue" clearable placeholder="下拉菜单">
                      <el-option
                        v-for="item in QOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select> -->
            </div>
            <div class="secondCon">
              <el-checkbox
                @change="fnGetFinDataSnapshot"
                v-model="concealChecked"
                >隐去上季度对比</el-checkbox
              >
            </div>

            <div class="thityCon">
              <!-- height="500" -->
              <!-- :height="" -->
              <el-table
                :height="OFFSETHEIGHT - 291"
                class="table1"
                :header-cell-style="styleBindFun"
                @cell-click="fnClick"
                :data="dFinDataSnapshot"
                style="width: 100%"
              >
                <el-table-column width="150" prop="代码" label="代码">
                </el-table-column>
                <el-table-column width="150" prop="名称" label="名称">
                </el-table-column>
                <el-table-column width="150" prop="资产大类" label="资产大类">
                </el-table-column>
                <el-table-column width="150" prop="资产细分" label="资产细分">
                </el-table-column>
                <el-table-column
                  align="left"
                  sortable
                  prop="总收入"
                  label="总收入（万）"
                >
                  <template slot-scope="scope">
                    <div v-show="scope.row.node_ZSR" class="value_area">
                      <div
                        :style="{ width: scope.row.node_ZSR }"
                        class="a"
                      ></div>
                      {{
                        scope.row["总收入"]?parseInt(scope.row["总收入"] / 10000).toLocaleString():''
                      }}
                    </div>

                    <div v-show="scope.row.node_ZSR_prev" class="value_area">
                      <div
                        :style="{ width: scope.row.node_ZSR_prev }"
                        class="b"
                      ></div>
                      {{
                        scope.row["总收入_prev"]
                          ? parseInt(
                              scope.row["总收入_prev"] / 10000
                            ).toLocaleString()
                          : ""
                      }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  align="left"
                  sortable
                  prop="EBITDA"
                  label="EBITDA（万）"
                >
                  <template slot-scope="scope">
                    <div v-show="scope.row.node_EBITDA" class="value_area">
                      <div
                        :style="{ width: scope.row.node_EBITDA }"
                        class="a"
                      ></div>
                      {{
                        scope.row["EBITDA"]?parseInt(scope.row["EBITDA"] / 10000).toLocaleString():''
                      }}
                    </div>

                    <div v-show="scope.row.node_EBITDA_prev" class="value_area">
                      <div
                        :style="{ width: scope.row.node_EBITDA_prev }"
                        class="b"
                      ></div>
                      {{
                        scope.row["EBITDA_prev"]
                          ? parseInt(
                              scope.row["EBITDA_prev"] / 10000
                            ).toLocaleString()
                          : ""
                      }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  align="left"
                  sortable
                  prop="可分派金额"
                  label="可分派金额（万）"
                >
                  <template slot-scope="scope">
                    <div v-show="scope.row.node_KFPJE" class="value_area">
                      <div
                        :style="{ width: scope.row.node_KFPJE }"
                        class="a"
                      ></div>
                      {{
                       scope.row["可分派金额"]?parseInt(
                          scope.row["可分派金额"] / 10000
                        ).toLocaleString():""
                      }}
                    </div>

                    <div v-show="scope.row.node_KFPJE_prev" class="value_area">
                      <div
                        :style="{ width: scope.row.node_KFPJE_prev }"
                        class="b"
                      ></div>
                      {{
                        scope.row["可分派金额_prev"]
                          ? parseInt(
                              scope.row["可分派金额_prev"] / 10000
                            ).toLocaleString()
                          : ""
                      }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  align="left"
                  sortable
                  prop="分红回报率"
                  label="分红回报率"
                >
                  <template slot-scope="scope">
                    <div v-show="scope.row.node_FHHBL"  class="value_area">
                      <div
                        :style="{ width: scope.row.node_FHHBL }"
                        class="a"
                      ></div>
                      {{
                        scope.row["分红回报率"]?
                        Number(scope.row["分红回报率"]) * 100 > 10
                          ? (Number(scope.row["分红回报率"]) * 100).toFixed(1) +
                            "%"
                          : (Number(scope.row["分红回报率"]) * 100).toFixed(2) +
                            "%":''
                      }}
                    </div>

                    <div
                      v-if="isNaN(scope.row.分红回报率_prev)"
                      class="value_area"
                    >
                      <div
                        :style="{ width: scope.row.node_FHHBL_prev }"
                        class="b"
                      ></div>
                    </div>

                    <div v-else class="value_area">
                      <div
                        :style="{ width: scope.row.node_FHHBL_prev }"
                        class="b"
                      ></div>
                      {{
                        scope.row["分红回报率_prev"]
                          ? Number(scope.row["分红回报率_prev"]) * 100 > 10
                            ? (
                                Number(scope.row["分红回报率_prev"]) * 100
                              ).toFixed(1) + "%"
                            : (
                                Number(scope.row["分红回报率_prev"]) * 100
                              ).toFixed(2) + "%"
                          : ""
                      }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div ref="thityCon" class="thityCon downloadTable">
              <!-- height="500" -->
              <!-- :height="" -->
              <el-table
                class="table1"
                :header-cell-style="styleBindFun"
                @cell-click="fnClick"
                :data="dFinDataSnapshot"
                style="width: 100%"
              >
                <el-table-column width="150" prop="代码" label="代码">
                </el-table-column>
                <el-table-column width="150" prop="名称" label="名称">
                </el-table-column>
                <el-table-column width="150" prop="资产大类" label="资产大类">
                </el-table-column>
                <el-table-column width="150" prop="资产细分" label="资产细分">
                </el-table-column>
                <el-table-column
                  align="left"
                  sortable
                  prop="总收入"
                  label="总收入（万）"
                >
                  <template slot-scope="scope">
                    <div class="value_area">
                      <div
                        :style="{ width: scope.row.node_ZSR }"
                        class="a"
                      ></div>
                      {{
                        parseInt(scope.row["总收入"] / 10000).toLocaleString()
                      }}
                    </div>

                    <div v-show="scope.row.node_ZSR_prev" class="value_area">
                      <div
                        :style="{ width: scope.row.node_ZSR_prev }"
                        class="b"
                      ></div>
                      {{
                        scope.row["总收入_prev"]
                          ? parseInt(
                              scope.row["总收入_prev"] / 10000
                            ).toLocaleString()
                          : ""
                      }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  align="left"
                  sortable
                  prop="EBITDA"
                  label="EBITDA（万）"
                >
                  <template slot-scope="scope">
                    <div class="value_area">
                      <div
                        :style="{ width: scope.row.node_EBITDA }"
                        class="a"
                      ></div>
                      {{
                        parseInt(scope.row["EBITDA"] / 10000).toLocaleString()
                      }}
                    </div>

                    <div v-show="scope.row.node_EBITDA_prev" class="value_area">
                      <div
                        :style="{ width: scope.row.node_EBITDA_prev }"
                        class="b"
                      ></div>
                      {{
                        scope.row["EBITDA_prev"]
                          ? parseInt(
                              scope.row["EBITDA_prev"] / 10000
                            ).toLocaleString()
                          : ""
                      }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  align="left"
                  sortable
                  prop="可分派金额"
                  label="可分派金额（万）"
                >
                  <template slot-scope="scope">
                    <div class="value_area">
                      <div
                        :style="{ width: scope.row.node_KFPJE }"
                        class="a"
                      ></div>
                      {{
                        parseInt(
                          scope.row["可分派金额"] / 10000
                        ).toLocaleString()
                      }}
                    </div>

                    <div v-show="scope.row.node_KFPJE_prev" class="value_area">
                      <div
                        :style="{ width: scope.row.node_KFPJE_prev }"
                        class="b"
                      ></div>
                      {{
                        scope.row["可分派金额_prev"]
                          ? parseInt(
                              scope.row["可分派金额_prev"] / 10000
                            ).toLocaleString()
                          : ""
                      }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  align="left"
                  sortable
                  prop="分红回报率"
                  label="分红回报率"
                >
                  <template slot-scope="scope">
                    <div class="value_area">
                      <div
                        :style="{ width: scope.row.node_FHHBL }"
                        class="a"
                      ></div>
                      {{
                        Number(scope.row["分红回报率"]) * 100 > 10
                          ? (Number(scope.row["分红回报率"]) * 100).toFixed(1) +
                            "%"
                          : (Number(scope.row["分红回报率"]) * 100).toFixed(2) +
                            "%"
                      }}
                    </div>

                    <div
                      v-if="isNaN(scope.row.分红回报率_prev)"
                      class="value_area"
                    >
                      <div
                        :style="{ width: scope.row.node_FHHBL_prev }"
                        class="b"
                      ></div>
                    </div>

                    <div v-else class="value_area">
                      <div
                        :style="{ width: scope.row.node_FHHBL_prev }"
                        class="b"
                      ></div>
                      {{
                        scope.row["分红回报率_prev"]
                          ? Number(scope.row["分红回报率_prev"]) * 100 > 10
                            ? (
                                Number(scope.row["分红回报率_prev"]) * 100
                              ).toFixed(1) + "%"
                            : (
                                Number(scope.row["分红回报率_prev"]) * 100
                              ).toFixed(2) + "%"
                          : ""
                      }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="财务分析" name="second">
          <div class="CH_Card profit_card">
            <div class="CH_Card_tit">
              各支公募REITs - {{ chartColumn1_title }} 对比

              <div class="downloadBtns">
                <CButtonsDownloadSingle
                  :dButtonsDownloads="dButtonsDownloads2_1"
                  @fnButtondownload="fnButtonDL6_2_1"
                />
              </div>
            </div>

            <div class="CH_Card_content fenlan1">
              <div class="fenlan1_left">
                <CCascader
                  @CCascader_select_node="fnCCascader_select_val_chart1"
                  :default="category_cascader_val_chart1"
                  :checkStrictly="false"
                  :multiple="true"
                  :collapsetags="true"
                  :options="category_cascader_options"
                  >选择类别</CCascader
                >
              </div>

              <div class="fenlan1_right">
                <!-- <CSelect
                  @CSelect_select_val="fnSelect_select_val_chart1_codename"
                  :multiple="true"
                  :clearable="false"
                  :limit="CODENAMEDATA.length"
                  :options="CODENAMEDATA"
                  :default="CODENAMEDATA_chart1"
                  >选择对比REITs</CSelect
                > -->

                <CCascader
                  ref="CCascader2_chart1"
                  :checkStrictly="true"
                  :multiple="true"
                  :isDelete="false"
                  :wide="true"
                  takeOut="takeOut"
                  placeholder="请选择个股"
                  :collapsetags="true"
                  @CCascader_select_val="fnSelect_select_val_chart1_codename"
                  :options="CODENAMEDATA"
                  >选择对比REITs</CCascader
                >
              </div>

              <div class="fenlan1_right">
                <CSelectPeriod
                  @CSelect_select_val="fnSelect_select_val_chart1_start"
                  :options="periodData"
                  ref="period_caiwu1"
                  :mini="true"
                  :clearable="false"
                  :default="targetData_chart1_start"
                  >选择报告期</CSelectPeriod
                >
                <span class="to">to</span>
                <CSelectPeriod
                  :mini="true"
                  @CSelect_select_val="fnSelect_select_val_chart1_end"
                  :options="periodData"
                  ref="period_caiwu2"
                  :clearable="false"
                  :default="targetData_chart1_end"
                ></CSelectPeriod>
              </div>
            </div>

            <div class="CH_Card_content fenlan1">
              <div class="fenlan1_left">
                <CCascader
                  @CCascader_select_val="fnCCascaderCaiWu"
                  class="CCascader_style mr30"
                  :needarr="false"
                  :wide="true"
                  :checkStrictly="false"
                  :default="targetData_chartone"
                  :options="fiveInoneDATA2"
                  >选择指标</CCascader
                >
              </div>

              <!-- <CSelect
                ref="target"
                @CSelect_select_val="fnSelect_FiveInOne"
                class="target_style"
                :options="fiveInoneDATA"
                :default="fiveInoneDATA_default"
                >选择内容</CSelect
              > -->

              <!-- <CSelect
                v-show="fiveInoneDATA_default == '利润表'"
                @CSelect_select_val="fnSelect_select_val_chart1"
                class="target_style"
                :wide="true"
                :clearable="true"
                :options="targetData_lirunbiao"
                :default="targetData_chartone"
                >选择指标</CSelect
              >

              <CSelect
                v-show="fiveInoneDATA_default == '现金流量表'"
                @CSelect_select_val="fnSelect_select_val_chart1"
                :wide="true"
                :clearable="true"
                class="target_style"
                :options="targetData_xianjinliuliang"
                :default="targetData_chart4"
                >选择指标</CSelect
              >

              <CSelect
                v-show="fiveInoneDATA_default == '分派表'"
                @CSelect_select_val="fnSelect_select_val_chart1"
                class="target_style"
                :wide="true"
                :options="targetData_fenpaibiao"
                :default="targetData_chart5"
                >选择指标</CSelect
              >

              <CSelect
                v-show="fiveInoneDATA_default == '资产回报'"
                @CSelect_select_val="fnSelect_select_val_chart1"
                class="target_style"
                :wide="true"
                :options="targetData_zichanhuibao"
                :default="targetData_chart6"
                >选择指标</CSelect
              >

              <CSelect
                v-show="fiveInoneDATA_default == '静态倍数'"
                @CSelect_select_val="fnSelect_select_val_chart1"
                class="target_style"
                :wide="true"
                :options="targetData_jingtaibeishu"
                :default="targetData_chart7"
                >选择指标</CSelect
              > -->
            </div>

            <div class="CH_Card_content doubleChart" ref="table6_2">
              <div style="width: 100%; position: relative">
                <span
                  v-show="bchartColumn1"
                  @click="fnOpenDialog4"
                  class="iconfont icon-fangda icon-fangda2"
                ></span>
                <div
                  v-show="bchartColumn1"
                  class="canvasArea1"
                  ref="chartColumn1"
                  style="width: 100%; height: 350px"
                ></div>
              </div>

              <div style="width: 100%; position: relative">
                <span
                  v-show="!bchartColumn1"
                  @click="fnOpenDialog2"
                  class="iconfont icon-fangda icon-fangda2"
                ></span>
                <div
                  v-show="!bchartColumn1"
                  class="canvasArea1"
                  ref="chartColumn1_1"
                  style="width: 100%; height: 350px"
                ></div>
              </div>

              <div style="width: 100%; position: relative">
                <span
                  v-show="!bchartColumn1"
                  @click="fnOpenDialog3"
                  class="iconfont icon-fangda icon-fangda2"
                ></span>

                <div
                  v-show="!bchartColumn1"
                  class="canvasArea1"
                  ref="chartColumn1_2"
                  style="width: 100%; height: 350px"
                ></div>
              </div>
            </div>
          </div>

          <div class="CH_Card profit_card secondCard">
            <div class="CH_Card_tit secondCardTit">
              各支公募REITs - {{ chartColumn1_title }} 对比（{{
                chartColumn1_unit
              }}）

              <div class="downloadBtns">
                <div class="downloadBtns">
                  <CButtonsDownloadSingle
                    :dButtonsDownloads="dButtonsDownloads2_2"
                    @fnButtondownload="fnButtonDL6_2_2"
                  />
                </div>

                <span style="display: none">
                  <download-excel
                    id="downloadExcel6_2"
                    :data="dReitsBenIndex_table"
                    :fields="json_fields_lirunbiao"
                    :header="chartColumn1_title"
                    :name="chartColumn1_title"
                  >
                  </download-excel>
                </span>
              </div>
            </div>
            <div>
              <el-table
                height="450"
                class="table1"
                :header-cell-style="styleBindFun"
                :data="dReitsBenIndex_table"
                @cell-click="fnClick2"
                style="width: 100%"
              >
                <el-table-column prop="reit_code" label="代码">
                </el-table-column>
                <el-table-column prop="name" label="名称"> </el-table-column>

                <el-table-column align="center" label="大类">
                  <template slot-scope="scope">
                    {{ scope.row["REITs_type_L1"] }}
                  </template>
                </el-table-column>

                <el-table-column align="center" label="类型">
                  <template slot-scope="scope">
                    {{ scope.row["REITs_type_L2"] }}
                  </template>
                </el-table-column>

                <el-table-column
                  v-for="(column, idx) in this.tableColumn_lirunbiao"
                  :key="idx"
                  align="left"
                  :label="column.slice(0, 6)"
                >
                  <template slot-scope="scope">
                    <div class="value_area">
                      <!-- <div
                        :style="{
                          width: scope.row[tableRectangle_lirunbiao[idx]],
                        }"
                        class="a"
                      ></div> -->
                      {{ scope.row[column] }}
                    </div>
                  </template>
                </el-table-column>

                <!-- 完成 ，漂亮 -->
                <!-- <el-table-column align="center" label="报告期">
                  <template slot-scope="scope">
                    {{ scope.row["rp_period"] }}
                  </template>
                </el-table-column> -->
              </el-table>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="交叉分析" name="seventh">
          <div class="CH_Card profit_card profit_card2">
            <div class="CH_Card_tit">
              数据分析器

              <div class="downloadBtns">
                <CButtonsDownloadSingle
                  :dButtonsDownloads="dButtonsDownloads3_1"
                  @fnButtondownload="fnButtonDL6_3_1"
                />
              </div>
            </div>

            <div class="CH_Card_content">
              <CCascader
                @CCascader_select_node="
                  fnCCascader_select_val_chart_jiaochafenxi
                "
                :default="category_cascader_val_chart_jiaochafenxi"
                :checkStrictly="false"
                :multiple="true"
                :collapsetags="true"
                :options="category_cascader_options"
                >选择类别</CCascader
              >

              <!-- <CSelect
                class="chart3_cascasder"
                @CSelect_select_val="
                  fnSelect_select_val_chart_jiaochafenxi_codename
                "
                :multiple="true"
                :limit="10"
                :options="CODENAMEDATA"
                :default="CODENAMEDATA_chart_jiaochafenxi"
                >选择对比REITs</CSelect
              > -->

              <CCascader
                ref="CCascader2_chart1"
                :checkStrictly="true"
                :multiple="true"
                :isDelete="false"
                :wide="true"
                class="chart3_cascasder"
                takeOut="takeOut"
                placeholder="请选择个股"
                :collapsetags="true"
                @CCascader_select_val="
                  fnSelect_select_val_chart_jiaochafenxi_codename
                "
                :options="CODENAMEDATA"
                >选择对比REITs</CCascader
              >

              <CSelectPeriod
                @CSelect_select_val="
                  fnSelect_select_val_chart_jiaochafenxi_start
                "
                :options="periodData"
                class="period_style2"
                :mini="true"
                ref="period_jiaocha1"
                :clearable="false"
                :default="targetData_chart_jiaochafenxi_start"
                >选择报告期</CSelectPeriod
              >
              <span class="to">to</span>
              <CSelectPeriod
                :mini="true"
                @CSelect_select_val="fnSelect_select_val_chart_jiaochafenxi_end"
                :options="periodData"
                :clearable="false"
                ref="period_jiaocha2"
                :default="targetData_chart_jiaochafenxi_end"
              ></CSelectPeriod>
            </div>

            <div class="CH_Card_content cho_area">
              <!-- <CSelect
                @CSelect_select_val="fnSelect_select_val_chart2_one"
                class="target_style"
                :clearable="false"
                :options="targetData_jiaochafenxi1"
                :default="targetData_chart2_one"
                >指标1名称</CSelect
              > -->

              <span class="mr30">
                <CCascader
                  @CCascader_select_val="fnCCascader1"
                  class="CCascader_style"
                  :needarr="false"
                  :wide="true"
                  :checkStrictly="false"
                  :default="targetData_chart2_one"
                  :options="fiveInoneDATA2"
                  >指标1</CCascader
                >
              </span>

              <CSelect
                @CSelect_select_val="fnSelect_graphical_val1"
                class="target_style"
                :mini="true"
                :clearable="false"
                :options="graphicalData"
                :default="graphicalData_chart2_one"
                >指标1图形</CSelect
              >

              <!-- <CSelect
                @CSelect_select_val="fnSelect_select_val_chart2_two"
                class="target_style"
                :clearable="false"
                :options="targetData_jiaochafenxi2"
                :default="targetData_chart2_two"
                >指标2</CSelect
              > -->

              <span class="mr30">
                <CCascader
                  @CCascader_select_val="fnCCascader2"
                  class="CCascader_style"
                  :needarr="false"
                  :wide="true"
                  :checkStrictly="false"
                  :default="targetData_chart2_two"
                  :options="fiveInoneDATA2"
                  >指标2</CCascader
                >
              </span>

              <CSelect
                :mini="true"
                @CSelect_select_val="fnSelect_graphical_val2"
                class="target_style"
                :clearable="false"
                :options="graphicalData2"
                :default="graphicalData_chart2_two"
                >指标2图形</CSelect
              >
            </div>

            <div class="CH_Card_content">
              <span
                @click="fnOpenDialog"
                class="iconfont icon-fangda icon-fangda3"
              ></span>

              <div
                v-loading="loading"
                element-loading-text="数据量巨大，正在计算中..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                class="canvasArea1"
                ref="chartColumn4"
                style="width: 100%; height: 550px"
              ></div>

             


              <div
                v-show="
                  graphicalData_chart2_one !== '散点图' ||
                  graphicalData_chart2_two !== '散点图'
                "
                class="chartColumn4_title1"
              >
                {{ titleLeft }}
              </div>
              <div
                v-show="
                  graphicalData_chart2_one !== '散点图' ||
                  graphicalData_chart2_two !== '散点图'
                "
                class="chartColumn4_title2"
              >
                {{ titleRight }}
              </div>
              <div
                v-show="
                  graphicalData_chart2_one == '散点图' &&
                  graphicalData_chart2_two == '散点图'
                "
                class="chartColumn4_title1"
              >
                {{ titleRight }}
              </div>
              <div
                v-show="
                  graphicalData_chart2_one == '散点图' &&
                  graphicalData_chart2_two == '散点图'
                "
                class="chartColumn4_title3"
              >
                {{ titleLeft }}
              </div>
            </div>
          </div>

          <div class="CH_Card profit_card secondCard">
            <div class="CH_Card_tit secondCardTit">
              数据分析器 - 交叉分析对比

              <div class="downloadBtns">
                <!-- <CButtonsDownload
                  :dButtonsDownloads="dButtonsDownloads3"
                  @fnButtondownload="fnButtonDL6_3"
                /> -->

                <div class="downloadBtns">
                  <CButtonsDownloadSingle
                    :dButtonsDownloads="dButtonsDownloads3_2"
                    @fnButtondownload="fnButtonDL6_3_2"
                  />
                </div>

                <span style="display: none">
                  <download-excel
                    id="downloadExcel6_3"
                    :data="jiaochafenxi_table"
                    :fields="jiaochafenxi_table_json_fields"
                    header="数据分析器 - 交叉分析对比"
                    name="数据分析器 - 交叉分析对比"
                  >
                    <!-- <i class="el-icon-download"></i> -->
                  </download-excel>
                </span>
              </div>
            </div>
            <div>
              <el-table
                class="table1"
                :header-cell-style="styleBindFun"
                :data="jiaochafenxi_table"
                @cell-click="fnClick"
                height="500"
                style="width: 100%"
              >
                <el-table-column prop="代码" label="代码"> </el-table-column>
                <el-table-column prop="名称" label="名称"> </el-table-column>

                <el-table-column align="center" label="大类">
                  <template slot-scope="scope">
                    {{ scope.row["大类"] }}
                  </template>
                </el-table-column>

                <el-table-column align="center" label="类型">
                  <template slot-scope="scope">
                    {{ scope.row["类型"] }}
                  </template>
                </el-table-column>

                <el-table-column align="left" :label="titleLeft" width="320">
                  <template slot-scope="scope">
                    <div class="value_area">
                      {{ scope.row[titleLeft] }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column align="left" :label="titleRight" width="320">
                  <template slot-scope="scope">
                    <div class="value_area">
                      {{ scope.row[titleRight] }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column align="center" label="报告期">
                  <template slot-scope="scope">
                    {{ scope.row["报告期"] }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

           <div
                class="downloadTable"
                ref="chartColumn4_download"
                style="width: 4000px; height: 550px"
              ></div>
        </el-tab-pane>
        <el-tab-pane label="Yield 传递推导" name="eighth">
          <div class="CH_Card profit_card profit_card3">
            <div class="CH_Card_tit">
              公募REITS各部分yield传递规律构成

              <div class="downloadBtns">
                <CButtonsDownloadSingle
                  :dButtonsDownloads="dButtonsDownloads4_1"
                  @fnButtondownload="fnButtonDL6_4_1"
                />
              </div>
            </div>

            <div class="CH_Card_content">
              <CCascader
                @CCascader_select_val="fnCCascader_select_val_chart3"
                :default="category_cascader_val_chart3"
                :checkStrictly="true"
                :multiple="false"
                :collapsetags="true"
                :options="category_cascader_options"
                >选择类别</CCascader
              >

              <CSelectPeriod
                class="chart3_cascasder"
                @CSelect_select_val="fnSelect_select_val_chart3_start"
                :options="periodData"
                :clearable="false"
                :mini="true"
                ref="period_waterfall1"
                :default="factor_chart3_start"
                >选择报告期</CSelectPeriod
              >
              <span class="to">to</span>
              <CSelectPeriod
                @CSelect_select_val="fnSelect_select_val_chart3_end"
                :options="periodData"
                :clearable="false"
                :mini="true"
                ref="period_waterfall2"
                :default="factor_chart3_end"
              ></CSelectPeriod>
            </div>

            <div class="CH_Card_content">
              <CButtonsSeparate
                @Cbtns_sep="fnGetCbtnsSep_chart3_one"
                class="period_style"
                :dButtons1="dButtons_separrate_chart3_one"
                :dButtons1_current="dButtons_separrate_current_chart3_one"
              />

              <CButtonsSeparate
                class="ml20"
                v-show="bchart3_two"
                @Cbtns_sep="fnGetCbtnsSep_chart3_two"
                :dButtons1="dButtons_separrate_chart3_two"
                :dButtons1_current="dButtons_separrate_current_chart3_two"
              />
            </div>

            <div class="CH_Card_content">
              <span
                @click="fnOpenDialog5"
                class="iconfont icon-fangda icon-fangda3"
              ></span>

              <div
                class="canvasArea1"
                ref="chartColumn3"
                style="width: 100%; height: 420px"
              ></div>
              <div class="tuli">
                <span><i class="zengjia"></i>增加</span>
                <span><i class="jianshao"></i>减少</span>
              </div>
            </div>
          </div>

          <div class="CH_Card profit_card secondCard">
            <div class="CH_Card_tit secondCardTit">
              公募REITS各部分yield传递规律构成  

              <div class="downloadBtns">
                <!-- <CButtonsDownload
                  :dButtonsDownloads="dButtonsDownloads4"
                  @fnButtondownload="fnButtonDL6_4"
                /> -->

                <div class="downloadBtns">
                  <CButtonsDownloadSingle
                    :dButtonsDownloads="dButtonsDownloads4_2"
                    @fnButtondownload="fnButtonDL6_4_2"
                  />
                </div>

                <span style="display: none">
                  <download-excel
                    id="downloadExcel6_4"
                    :data="dFinDataWaterfall"
                    :fields="json_fields_water"
                    header="公募REITS各部分yield传递规律构成"
                    name="公募REITS各部分yield传递规律构成"
                  >
                  </download-excel>
                </span>
              </div>

              <!-- <download-excel
                :data="dFinDataWaterfall"
                :fields="json_fields_water"
                :header="title_water"
                name="Yield传递推导.xls"
              >
                                <i class="el-icon-download"></i>              
              </download-excel> -->
            </div>
            <div>
              <el-table
                class="table1"
                :header-cell-style="styleBindFun"
                :data="dFinDataWaterfall"
                style="width: 100%; whitespace: nowrap"
              >
                <el-table-column prop="代码" label="代码">
                  <template slot-scope="scope">
                    {{ scope.row["代码"] }}
                  </template>
                </el-table-column>
                <el-table-column prop="名称" label="名称">
                  <template slot-scope="scope">
                    {{ scope.row["名称"] }}
                  </template>
                </el-table-column>

                <el-table-column prop="REITs_type_L1" label="大类">
                  <template slot-scope="scope">
                    {{ scope.row["REITs_type_L1"] }}
                  </template>
                </el-table-column>
                <el-table-column prop="REITs_type_L2" label="类型">
                  <template slot-scope="scope">
                    {{ scope.row["REITs_type_L2"] }}
                  </template>
                </el-table-column>
                <el-table-column label="Gross Yield">
                  <template slot-scope="scope">
                    {{
                      (Number(scope.row["gross_yield"]) * 100).toFixed(2) + "%"
                    }}
                  </template>
                </el-table-column>

                <el-table-column label="opex_损耗">
                  <template slot-scope="scope">
                    {{
                      (Number(scope.row["opex_损耗"]) * 100).toFixed(2) + "%"
                    }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label-style="{ whiteSpace: 'pre-wrap' }"
                  width="170"
                  label="EBITDA_yield"
                >
                  <template slot-scope="scope">
                    {{
                      (Number(scope.row["EBITDA_yield"]) * 100).toFixed(2) + "%"
                    }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label-style="{ whiteSpace: 'pre-wrap' }"
                  width="160"
                  label="EBITDA后调整项目"
                >
                  <template slot-scope="scope">
                    {{
                      (Number(scope.row["EBITDA后调整项目"]) * 100).toFixed(2) +
                      "%"
                    }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label-style="{ whiteSpace: 'pre-wrap' }"
                  label="div_yield(base on nav)"
                  width="200"
                >
                  <template slot-scope="scope">
                    {{
                      (
                        Number(scope.row["div_yield(base_on_nav)"]) * 100
                      ).toFixed(2) + "%"
                    }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label-style="{ whiteSpace: 'pre-wrap' }"
                  label="市场价格调整"
                  width="120"
                >
                  <template slot-scope="scope">
                    {{
                      (Number(scope.row["市场价格调整"]) * 100).toFixed(2) + "%"
                    }}
                  </template>
                </el-table-column>

                <el-table-column label="div_yield">
                  <template slot-scope="scope">
                    {{
                      (Number(scope.row["div_yield"]) * 100).toFixed(2) + "%"
                    }}
                  </template>
                </el-table-column>

                <el-table-column label="报告期" width="150">
                  <template slot-scope="scope">
                    {{ scope.row["rp_period"] }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <CDialog
        ref="CDialog"
        :DIALOGHEIGHT="height_dialog"
        :dialogVisible="dialogVisible"
      >
        <el-dialog
          title="葱花投研"
          :visible.sync="dialogVisible"
          :width="width_dialog"
          :top="top_dialog"
        >
          <div>
            <el-dropdown
              size="mini"
              trigger="click"
              type="primary"
              @command="fnDropdown"
            >
              <span class="el-dropdown-link">
                {{ popUpWindowDatas[popUpWindowIndex]
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="idx"
                  v-for="(node, idx) in popUpWindowDatas"
                  :key="idx"
                >
                  {{ node }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div :style="{ height: height_chart }">
            <!-- v-loading="loading"
                element-loading-text="数据量巨大，正在计算中..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" -->
            <div
              class="canvasArea1"
              ref="chartColumn4_dialog"
              style="width: 100%; height: 100%"
            ></div>

            <div
              v-show="
                graphicalData_chart2_one !== '散点图' ||
                graphicalData_chart2_two !== '散点图'
              "
              class="chartColumn4_title1"
            >
              {{ titleLeft }}
            </div>
            <div
              v-show="
                graphicalData_chart2_one !== '散点图' ||
                graphicalData_chart2_two !== '散点图'
              "
              class="chartColumn4_title2"
            >
              {{ titleRight }}
            </div>
            <div
              v-show="
                graphicalData_chart2_one == '散点图' &&
                graphicalData_chart2_two == '散点图'
              "
              class="chartColumn4_title1"
            >
              {{ titleRight }}
            </div>
            <div
              v-show="
                graphicalData_chart2_one == '散点图' &&
                graphicalData_chart2_two == '散点图'
              "
              class="chartColumn4_title3"
            >
              {{ titleLeft }}
            </div>
          </div>
        </el-dialog>
      </CDialog>

      <CDialog
        ref="CDialog"
        :DIALOGHEIGHT="height_dialog"
        :dialogVisible="dialogVisible2"
      >
        <el-dialog
          title="葱花投研"
          :visible.sync="dialogVisible2"
          :width="width_dialog"
          :top="top_dialog"
        >
          <div>
            <el-dropdown
              size="mini"
              trigger="click"
              type="primary"
              @command="fnDropdown2"
            >
              <span class="el-dropdown-link">
                {{ popUpWindowDatas[popUpWindowIndex]
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="idx"
                  v-for="(node, idx) in popUpWindowDatas"
                  :key="idx"
                >
                  {{ node }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div :style="{ height: height_chart }">
            <div
              v-show="!bchartColumn1"
              class="canvasArea1"
              ref="chartColumn1_1_dialog"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </el-dialog>
      </CDialog>

      <CDialog
        ref="CDialog"
        :DIALOGHEIGHT="height_dialog"
        :dialogVisible="dialogVisible3"
      >
        <el-dialog
          title="葱花投研"
          :visible.sync="dialogVisible3"
          :width="width_dialog"
          :top="top_dialog"
        >
          <div>
            <el-dropdown
              size="mini"
              trigger="click"
              type="primary"
              @command="fnDropdown3"
            >
              <span class="el-dropdown-link">
                {{ popUpWindowDatas[popUpWindowIndex]
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="idx"
                  v-for="(node, idx) in popUpWindowDatas"
                  :key="idx"
                >
                  {{ node }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div :style="{ height: height_chart }">
            <div
              v-show="!bchartColumn1"
              class="canvasArea1"
              ref="chartColumn1_2_dialog"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </el-dialog>
      </CDialog>

      <CDialog
        ref="CDialog"
        :DIALOGHEIGHT="height_dialog"
        :dialogVisible="dialogVisible4"
      >
        <el-dialog
          title="葱花投研"
          :visible.sync="dialogVisible4"
          :width="width_dialog"
          :top="top_dialog"
        >
          <div>
            <el-dropdown
              size="mini"
              trigger="click"
              type="primary"
              @command="fnDropdown4"
            >
              <span class="el-dropdown-link">
                {{ popUpWindowDatas[popUpWindowIndex]
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="idx"
                  v-for="(node, idx) in popUpWindowDatas"
                  :key="idx"
                >
                  {{ node }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div :style="{ height: height_chart }">
            <div
              v-show="bchartColumn1"
              class="canvasArea1"
              ref="chartColumn1_dialog"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </el-dialog>
      </CDialog>

      <CDialog
        ref="CDialog"
        :DIALOGHEIGHT="height_dialog"
        :dialogVisible="dialogVisible5"
      >
        <el-dialog
          title="葱花投研"
          :visible.sync="dialogVisible5"
          :width="width_dialog"
          :top="top_dialog"
        >
          <div>
            <el-dropdown
              size="mini"
              trigger="click"
              type="primary"
              @command="fnDropdown5"
            >
              <span class="el-dropdown-link">
                {{ popUpWindowDatas[popUpWindowIndex]
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="idx"
                  v-for="(node, idx) in popUpWindowDatas"
                  :key="idx"
                >
                  {{ node }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div :style="{ height: height_chart }">
            <div
              class="canvasArea1"
              ref="chartColumn3_dialog"
              style="width: 100%; height: 100%"
            ></div>
            <div class="tuli">
              <span><i class="zengjia"></i>增加</span>
              <span><i class="jianshao"></i>减少</span>
            </div>
          </div>
        </el-dialog>
      </CDialog>
    </div>

    <!-- <div
                v-loading="loading"
                element-loading-text="数据量巨大，正在计算中..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                class="canvasArea1 downloadTable DNStyle"
                ref="chartColumn4_download"
                style="width: 100%; height: 550px"
              ></div> -->
  </div>
</template>

<script>
import CSelect from "@/components/Basic/CSelect";
import CSelectPeriod from "@/components/Basic/CSelectPeriod";

import CCheckbox from "@/components/Basic/CCheckbox";
import CDropDownSelects from "@/components/Basic/CDropDownSelects";
import CCategoryButton from "@/components/Basic/CCategoryButton";
import CCascader from "@/components/Basic/CCascader";
import CButtonsSeparate from "@/components/Basic/CButtons_separate";
import CButtonsDownload from "@/components/Basic/CButtons_download";
import CButtonsDownloadSingle from "@/components/Basic/CButtons_download_single";

import html2canvas from "html2canvas";

import CDialog from "@/components/Basic/CDialog";

import {
  fnJudgePeriodRational,
  fnJudgePeriodOrder,
  fnGetMuchDecimals,
  fnReturnTableStyle,
  fnDownloadBgc,
} from "@/utils/util";

export default {
  data() {
    return {
      sCurrentTheme: this.$store.state.theme == "dark" ? true : false,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,

      width_dialog: "80%",
      height_dialog: "73vh",
      height_chart: "71vh",
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: "5vh",

      OFFSETHEIGHT: 0,
      loading: false,
      MAP: JSON.parse(localStorage.getItem("CODEobj")),

      Qvalue: "0",
      QOptions: [
        {
          label: "2023Q2",
          value: "0",
        },
      ],

      chartColumn: null,

      dBtn1: ["3M", "6M", "1Y", "YTD", "MAX"],
      activeBtn1: "3M",

      dBenchMark: [
        {
          value: "沪深300",
          label: "沪深300",
        },
        {
          value: "CN10YR",
          label: "CN10YR",
        },
        {
          value: "公用指数",
          label: "公用指数",
        },
      ],

      activeName: "first",
      category_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      CLASSA: ["产权类", "经营权类"],
      CLASSB: ["高速公路"],
      BusinessFormat: ["分报告期", "分业态"],
      property: ["资产大类", "资产细分"],

      concealChecked: false,

      // 表格
      period: "",
      dFinDataSnapshot: [],
      dFinDataWaterfall: [],
      dReitsBenIndex_table: [],
      tableColumn_lirunbiao: [],
      tableRectangle_lirunbiao: [],

      dReitsBenIndex_table2: [],
      tableColumn_xianjinliuliang: [],
      tableRectangle_xianjinliuliang: [],

      dReitsBenIndex_table3: [],
      tableColumn_fenpaibiao: [],
      tableRectangle_fenpaibiao: [],

      dReitsBenIndex_table4: [],
      dReitsBenIndex_table5: [],
      tableColumn_jingtaibeishu: [],
      tableRectangle_jingtaibeishu: [],

      dReitsBenIndex_table6: [],
      jiaochafenxi_table: [],
      jiaochafenxi_table_json_fields: {},
      dReitsBenIndex_table_zichanhuibao: [],
      tableColumn_zichanhuibao: [],
      tableRectangle_zichanhuibao: [],

      chartColumn2: null,

      chartColumn3: null,
      chartColumn3_dialog: null,

      chart3_SERIES_FINALLDATA: null,
      chart3_color_Obj: null,

      dFinDataWaterfall2_X: [],
      dFinDataWaterfall2_s1: [],
      dFinDataWaterfall2_s2: [],
      dFinDataWaterfall2_s3: [],

      chartColumn1: null,
      chartColumn1_dialog: null,

      chart1_bpercentage: null,
      chart1_obj: null,

      dReitsBenIndex_X: [],
      dReitsBenIndex_Y: [],
      chartColumn1_title: "总收入（单季度）",
      chartColumn1_unit: "",
      unitname: "",

      chartColumn1_1: null,
      chartColumn1_1_dialog: null,

      chart1_1_bpercentage: null,
      chart1_1_DATA: null,

      chartColumn1_2: null,
      chartColumn1_2_dialog: null,

      chart1_2_bpercentage: null,
      chart1_2_DATA: null,

      // 判断是否需要分行
      bchartColumn1: true,
      bchartColumn2: true,
      bchartColumn3: true,
      bchartColumn4: true,
      bchartColumn5: true,

      // 交叉分析 start
      chartColumn4: null,
      chartColumn4_dialog: null,
      chartColumn4_download: null,

      dReitsBenIndex2_X: [],
      dReitsBenIndex2_Y: [],
      dReitsBenIndex2_Y_right: [],

      targetData_jiaochafenxi1: [
        {
          label: "总收入（单季度）",
          value: "revenue",
        },
      ],

      targetData_jiaochafenxi2: [
        {
          label: "EBITDA Yield （单季度）",
          value: "EBITDA_yield",
        },
      ],

      targetData_chart2_one: "revenue",
      targetData_chart2_two: "EBITDA_yield",

      graphicalData: [
        {
          label: "柱状图",
          value: "柱状图",
        },
        {
          label: "散点图",
          value: "散点图",
        },

        {
          label: "折线图",
          value: "折线图",
        },
      ],
      graphicalData2: [
        // {
        //   label: "柱状图",
        //   value: "柱状图",
        // },
        {
          label: "散点图",
          value: "散点图",
        },
        // {
        //   label: "折线图",
        //   value: "折线图",
        // },
      ],
      graphicalData_chart2_one: "柱状图",
      graphicalData_chart2_two: "散点图",

      targetData_chart_jiaochafenxi_start: "2023Q1",
      targetData_chart_jiaochafenxi_end: "2023Q4",
      category_cascader_val_chart_jiaochafenxi: ["公募REITs"],
      CODENAMEDATA_chart_jiaochafenxi: [],

      // 散点图需要的两个未经处理的原数据
      originalData1: [],
      originalData2: [],

      unitname2: "",
      unitname3: "",
      timeGap_JIAOCHAFENXI: 0,
      code_jiaocha: [],

      targetDefault1: "",
      targetDefault2: "",
      bpercentageLeft: false,
      bpercentageRight: false,

      // 交叉分析 end

      // 五合一 start
      fiveInoneDATA: [
        {
          label: "利润表",
          value: "利润表",
        },
        {
          label: "现金流量表",
          value: "现金流量表",
        },
        {
          label: "分派表",
          value: "分派表",
        },
        {
          label: "资产回报",
          value: "资产回报",
        },
        {
          label: "静态倍数",
          value: "静态倍数",
        },
      ],
      fiveInoneDATA_default: "利润表",
      fiveInoneDATA2: [
        {
          label: "利润表",
          value: "利润表",
          children: [
            {
              label: "总收入（单季度）",
              value: "revenue",
            },
            {
              label: "EBITDA（单季度）",
              value: "EBITDA",
            },
            {
              label: "总收入增长率（单季度）",
              value: "revenue_QoQ",
            },
          ],
        },
        {
          label: "现金流量表",
          value: "现金流量表",
          children: [
            {
              label: "CFO（单季度）",
              value: "CFO",
            },
            {
              label: "FFO（单季度）",
              value: "FFO",
            },
            {
              label: "CFO增长率（单季度）",
              value: "CFO_QoQ",
            },
            {
              label: "FFO增长率（单季度）",
              value: "FFO_QoQ",
            },
            {
              label: "CFO与可分派金额比值（单季度）",
              value: "CFO/Distri_amt",
            },
            {
              label: "FFO与可分派金额比值（单季度）",
              value: "FFO/Distri_amt",
            },
          ],
        },
        {
          label: "分派表",
          value: "分派表",
          children: [
            {
              label: "可分派金额（单季度）",
              value: "distributable_amount(period)",
            },
            {
              label: "可分派金额（本年各季度累计）",
              value: "acc_distributable_amount",
            },
            {
              label: "分红回报率（单季度年化）",
              value: "div_yield",
            },
            {
              label: "可分派金额增长率（单季度）",
              value: "distributable_amount(period)_QoQ",
            },
            {
              label: "分红回报率（单季度回报）",
              value: "div_yield(single_quarter)",
            },
            {
              label: "分红回报率（本年各季度累计）",
              value: "div_yield(accumulated)",
            },
          ],
        },
        {
          label: "资产回报",
          value: "资产回报",
          children: [
            {
              label: "EBITDA Margin（单季度）",
              value: "EBITDA_Margin",
            },
            {
              label: "EBITDA Yield （单季度）",
              value: "EBITDA_yield",
            },
            {
              label: "可分派金额比值占总收入比例（单季度）",
              value: "Distri_amt/revenue",
            },
          ],
        },
        {
          label: "静态倍数",
          value: "静态倍数",
          children: [
            {
              label: "EV/EBITDA倍数",
              value: "EV/EBITDA",
            },
            {
              label: "P/FFO倍数",
              value: "PFFO",
            },

            {
              label: "分红回报率（单季度回报）",
              value: "div_yield(single_quarter)",
            },
            {
              label: "分红回报率（本年各季度累计）",
              value: "div_yield(accumulated)",
            },
          ],
        },
      ],
      // 五合一 end

      // chart1
      targetData: [
        {
          label: "revenue",
          value: "revenue",
        },
        {
          label: "P/FFO倍数",
          value: "PFFO",
        },
        {
          label: "PCFO",
          value: "PCFO",
        },
        {
          label: "PNAV",
          value: "PNAV",
        },
        {
          label: "PB",
          value: "PB",
        },
        {
          label: "EV/EBITDA倍数",
          value: "EV/EBITDA",
        },
      ],

      targetData_lirunbiao: [
        {
          label: "总收入（单季度）",
          value: "revenue",
        },
        {
          label: "EBITDA（单季度）",
          value: "EBITDA",
        },
        {
          label: "总收入增长率（单季度）",
          value: "revenue_QoQ",
        },
      ],
      targetData_chartone: "revenue",
      targetData_chartone_backup: "revenue",

      targetData_chart1_start: "2023Q1",
      targetData_chart1_end: "2023Q4",
      category_cascader_val_chart1: ["公募REITs"],
      CODENAMEDATA: [],
      CODENAMEDATA_chart1: [],

      // chart2
      periodData2: [
        {
          value: "2023Q4",
          label: "2023Q4",
        },
        {
          value: "2023Q3",
          label: "2023Q3",
        },
        {
          value: "2023Q2",
          label: "2023Q2",
        },

        {
          value: "2023Q1",
          label: "2023Q1",
        },

        {
          value: "2022Q4",
          label: "2022Q4",
        },
        {
          value: "2022Q3",
          label: "2022Q3",
        },
        // {
        //   value: "2022Q2",
        //   label: "2022Q2",
        // },
        // {
        //   value: "2022Q1",
        //   label: "2022Q1",
        // },

        // {
        //   value: "2021Q4",
        //   label: "2021Q4",
        // },
        // {
        //   value: "2021Q3",
        //   label: "2021Q3",
        // },
        // {
        //   value: "2021Q2",
        //   label: "2021Q2",
        // },
        // {
        //   value: "2021Q1",
        //   label: "2021Q1",
        // },
      ],
      // chart3
      periodData: [
        // {
        //   value: "2023Q4",
        //   label: "2023Q4",
        // },
        // {
        //   value: "2023Q3",
        //   label: "2023Q3",
        // },
        // {
        //   value: "2023Q2",
        //   label: "2023Q2",
        // },
        // {
        //   value: "2023Q1",
        //   label: "2023Q1",
        // },
        // {
        //   value: "2022Q4",
        //   label: "2022Q4",
        // },
        // {
        //   value: "2022Q3",
        //   label: "2022Q3",
        // },
        // {
        //   value: "2022Q2",
        //   label: "2022Q2",
        // },
        // {
        //   value: "2022Q1",
        //   label: "2022Q1",
        // },
        // {
        //   value: "2021Q4",
        //   label: "2021Q4",
        // },
        // {
        //   value: "2021Q3",
        //   label: "2021Q3",
        // },
        // {
        //   value: "2021Q2",
        //   label: "2021Q2",
        // },
        // {
        //   value: "2021Q1",
        //   label: "2021Q1",
        // },
      ],

      factor_chart3_start: "2022Q3",
      factor_chart3_end: "2023Q4",

      category_cascader_options: [
        {
          value: "产权类",
          label: "产权类",
          children: [
            {
              value: "物流仓储",
              label: "物流仓储",
            },
            {
              value: "产业园",
              label: "产业园",
            },
            {
              value: "保障性住房",
              label: "保障性住房",
            },
            {
              value: "消费",
              label: "消费",
            },
          ],
        },
        {
          value: "经营权类",
          label: "经营权类",
          children: [
            {
              value: "市政生态",
              label: "市政生态",
            },
            {
              value: "能源",
              label: "能源",
            },
            {
              value: "收费公路",
              label: "收费公路",
            },
          ],
        },
        {
          value: "公募REITs",
          label: "公募REITs",
        },
      ],
      category_cascader_val_chart3: "公募REITs",
      L1L2DUIBI: {
        物流仓储: "产权类",
        产业园: "产权类",
        保障性住房: "产权类",
        消费: "产权类",
        市政生态: "经营权类",
        能源: "经营权类",
        收费公路: "经营权类",
      },

      dButtons_separrate_chart3_one: ["分报告期", "分业态"],
      dButtons_separrate_current_chart3_one: "分报告期",

      dButtons_separrate_chart3_two: ["资产大类", "资产细分"],
      dButtons_separrate_current_chart3_two: "资产大类",
      bchart3_two: false,

      // chart4
      targetData_xianjinliuliang: [
        {
          label: "CFO（单季度）",
          value: "CFO",
        },
        {
          label: "FFO（单季度）",
          value: "FFO",
        },
        {
          label: "CFO增长率（单季度）",
          value: "CFO_QoQ",
        },
        {
          label: "FFO增长率（单季度）",
          value: "FFO_QoQ",
        },
        {
          label: "CFO与可分派金额比值（单季度）",
          value: "CFO/Distri_amt",
        },
        {
          label: "FFO与可分派金额比值（单季度）",
          value: "FFO/Distri_amt",
        },
      ],
      targetData_chart4: "CFO",
      targetData_chart4_start: "2023Q1",
      targetData_chart4_end: "2023Q4",
      category_cascader_val_chart4: ["公募REITs"],
      CODENAMEDATA_chart4: [],
      chartColumn4_1: null,
      chartColumn4_1_1: null,
      chartColumn4_1_2: null,
      chartColumn4_title: "CFO（单季度）",
      chartColumn4_unit: "",
      titleLeft: "",
      titleRight: "",

      // chart5
      targetData_fenpaibiao: [
        {
          label: "可分派金额（单季度）",
          value: "distributable_amount(period)",
        },
        {
          label: "可分派金额（本年各季度累计）",
          value: "acc_distributable_amount",
        },
        {
          label: "分红回报率（单季度年化）",
          value: "div_yield",
        },
        {
          label: "可分派金额增长率（单季度）",
          value: "distributable_amount(period)_QoQ",
        },
        {
          label: "分红回报率（单季度回报）",
          value: "div_yield(single_quarter)",
        },
        {
          label: "分红回报率（本年各季度累计）",
          value: "div_yield(accumulated)",
        },
      ],
      targetData_chart5: "distributable_amount(period)",
      targetData_chart5_start: "2023Q1",
      targetData_chart5_end: "2023Q4",
      category_cascader_val_chart5: ["公募REITs"],
      CODENAMEDATA_chart5: [],
      chartColumn5_1: null,
      chartColumn5_1_1: null,
      chartColumn5_1_2: null,
      chartColumn5_title: "可分派金额（单季度）",
      chartColumn5_unit: "",

      // chart6
      targetData_zichanhuibao: [
        {
          label: "EBITDA Margin（单季度）",
          value: "EBITDA_Margin",
        },
        {
          label: "EBITDA Yield （单季度）",
          value: "EBITDA_yield",
        },
        {
          label: "可分派金额比值占总收入比例（单季度）",
          value: "Distri_amt/revenue",
        },
      ],
      targetData_chart6: "EBITDA_yield",
      targetData_chart6_start: "2023Q1",
      targetData_chart6_end: "2023Q4",
      category_cascader_val_chart6: ["公募REITs"],
      CODENAMEDATA_chart6: [],
      chartColumn6_1: null,
      chartColumn6_1_1: null,
      chartColumn6_1_2: null,
      chartColumn6_title: "EBITDA Yield （单季度）",
      chartColumn6_unit: "",

      // chart7
      targetData_jingtaibeishu: [
        {
          label: "EV/EBITDA倍数",
          value: "EV/EBITDA",
        },
        {
          label: "P/FFO倍数",
          value: "PFFO",
        },

        {
          label: "分红回报率（单季度回报）",
          value: "div_yield(single_quarter)",
        },
        {
          label: "分红回报率（本年各季度累计）",
          value: "div_yield(accumulated)",
        },
      ],
      targetData_chart7: "PFFO",
      targetData_chart7_start: "2023Q1",
      targetData_chart7_end: "2023Q4",
      category_cascader_val_chart7: ["公募REITs"],
      CODENAMEDATA_chart7: [],
      chartColumn7_1: null,
      chartColumn7_1_1: null,
      chartColumn7_1_2: null,
      chartColumn7_title: "P/FFO倍数",
      chartColumn7_unit: "",

      // 表格
      title: "财报分析",
      json_fields: {
        代码: "代码",
        名称: "名称",
        资产大类: "资产大类",
        资产细分: "资产细分",
        rp_period: "rp_period",
        EBITDA: "EBITDA",
        分红回报率: "分红回报率",
        可分派金额: "可分派金额",
        总收入: "总收入",
      },
      overviewName: "",
      json_fields_lirunbiao: null,
      title_lirunbiao: "利润表",

      json_fields_xianjinliuliang: null,
      title_xianjinliuliang: "现金流量表",

      json_fields_fenpai: null,
      title_fenpai: "分派表",

      json_fields_zichanhuibao: null,
      title_zichanhuibao: "资产回报表",

      json_fields_jingtai: null,
      title_jingtai: "静态倍数",

      json_fields_water: null,
      title_water: "Yield传递推导",

      dButtonsDownloads: [
        {
          id: "241020",
          name: "下载图片",
        },
        {
          id: "241020",
          name: "下载表格",
        },
      ],

      dButtonsDownloads2: [
        {
          id: "241023",
          name: "下载图片",
        },
        {
          id: "241022",
          name: "下载表格",
        },
      ],

      dButtonsDownloads2_1: [
        {
          id: "241023",
          name: "下载图片",
        },
      ],

      dButtonsDownloads2_2: [
        {
          id: "241022",
          name: "下载表格",
        },
      ],

      // dButtonsDownloads3: [
      //   {
      //     id: "241040",
      //     name: "下载图片",
      //   },
      //   {
      //     id: "241039",
      //     name: "下载表格",
      //   },
      // ],

      dButtonsDownloads3_1: [
        {
          id: "241040",
          name: "下载图片",
        },
      ],

      dButtonsDownloads3_2: [
        {
          id: "241039",
          name: "下载表格",
        },
      ],

      // dButtonsDownloads4: [
      //   {
      //     id: "241043",
      //     name: "下载图片",
      //   },
      //   {
      //     id: "241042",
      //     name: "下载表格",
      //   },
      // ],

      dButtonsDownloads4_1: [
        {
          id: "241043",
          name: "下载图片",
        },
      ],

      dButtonsDownloads4_2: [
        {
          id: "241042",
          name: "下载表格",
        },
      ],

      Qcolors: [
        {
          name: "Q4",
          value: "#5ae8ed",
        },
        {
          name: "Q3",
          value: "#a1a1a1",
        },
        {
          name: "Q2",
          value: "#c6cccc",
        },
        {
          name: "Q1",
          // value: "#fafafa",
          value: "#E1E3E3",
        },
        {
          name: "Qq",
          value: "#e64032",
        },
      ],

      ROOTFONTFIZE: 0,
      TABPANEHEIGHT: 0,
    };
  },

  created() {
    this.fnGetFinDataSnapshot();
    this.fnGetpPeriodData();

    // this.fnGetFinDataWaterfall();
    // this.fnGetFinDataWaterfall2();
    this.fnCodeName();
    this.fnGetUnits();
    let name2 = localStorage.getItem("activeName");
    this.handleClick({ name: name2 });
  },
  mounted() {
    window.addEventListener("resize", this.funResize);
    this.fnGetWidth();
  },
  methods: {
    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById("rootchart");
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      let OFFSETHEIGHT = element.offsetHeight;
      this.OFFSETHEIGHT = OFFSETHEIGHT;

      this.TABPANEHEIGHT = localStorage.getItem("TABPANEHEIGHT");

      // this.TABPANEHEIGHT = OFFSETHEIGHT - 135;

      // var chartColumn1 = document.getElementById("chartColumn1");
      // chartColumn1.style.height = this.ROOTFONTFIZE * (371 / 144)+'px';

      // var chartColumn2 = document.getElementById("chartColumn2");
      // chartColumn2.style.height = this.ROOTFONTFIZE * (360 / 144)+'px';

      // var chartColumn4 = document.getElementById("chartColumn4");
      // chartColumn4.style.height = this.ROOTFONTFIZE * (450 / 144)+'px';

      // var chartColumn5 = document.getElementById("chartColumn5");
      // chartColumn5.style.height = this.ROOTFONTFIZE * (450 / 144)+'px';

      // var chartColumn3 = document.getElementById("chartColumn3");
      // chartColumn3.style.height = this.ROOTFONTFIZE * (358 / 144)+'px';

      return;
    },

    funResize() {
      this.fnGetWidth();
      if (this.chartColumn1) {
        this.chartColumn1.resize();
      }
      if (this.chartColumn1_1) {
        this.chartColumn1_1.resize();
      }
      if (this.chartColumn1_2) {
        this.chartColumn1_2.resize();
      }
      if (this.chartColumn3) {
        this.chartColumn3.resize();
      }
      if (this.chartColumn4) {
        this.chartColumn4.resize();
      }
    },

    // 获取单位
    fnGetUnits() {
      this.UNITS = JSON.parse(localStorage.getItem("UNITS"));
    },

    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChart2(true);
      });
    },

    fnDropdown(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart2(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart2("样式二");
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart2(true);
        });
      }
    },

    fnOpenDialog2() {
      this.dialogVisible2 = true;
      this.$nextTick(() => {
        this.initChart1_1(true);
      });
    },
    fnDropdown2(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1_1(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1_1(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1_1(true);
        });
      }
    },

    fnOpenDialog3() {
      this.dialogVisible3 = true;
      this.$nextTick(() => {
        this.initChart1_2(true);
      });
    },
    fnDropdown3(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1_2(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1_2(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1_2(true);
        });
      }
    },

    fnOpenDialog4() {
      this.dialogVisible4 = true;
      this.$nextTick(() => {
        this.initChart1(true);
      });
    },

    fnDropdown4(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      }
    },

    fnOpenDialog5() {
      this.dialogVisible5 = true;
      this.$nextTick(() => {
        this.initChart3(true);
      });
    },

    fnDropdown5(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart3(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart3(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart3(true);
        });
      }
    },

    //  表头方法
    styleBindFun() {
      let val = this.$store.state.theme == "dark" ? true : false;
      return fnReturnTableStyle(val);

      let styleBind =
        "background:#343742;fontSize:16px;color:#dadada;fontWeight: 700; border-bottom: 3px solid #4c4d5d; border-top: 2px solid #4c4d5f;";
      let styleBind_light =
        "background:#f7f7f7;fontSize:16px;color:#707070;fontWeight: 700; border-bottom: 3px solid #decfcf; border-top: 2px solid #bda0a0;";

      return this.$store.state.theme == "dark" ? styleBind : styleBind_light;
    },

    // 下载 start
    fnButtonDL6_1(val) {
      if (val == "下载图片") {
        this.FnDownload6_1();
      } else {
        this.overviewName = "财报分析" + this.period;
        setTimeout(() => {
          document.getElementById("downloadExcel6_1").click();
        }, 0);
      }
    },

    FnDownload6_1() {
      let h = this.$refs.thityCon.scrollHeight;
      let w = this.$refs.thityCon.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.thityCon, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "财报分析"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    // fnButtonDL6_2(val) {
    //   if (val == "下载图片") {
    //     this.FnDownload6_2();
    //   } else {
    //     document.getElementById("downloadExcel6_2").click();
    //   }
    // },

    fnButtonDL6_2_1(val) {
      this.FnDownload6_2();
    },

    fnButtonDL6_2_2(val) {
      document.getElementById("downloadExcel6_2").click();
    },

    FnDownload6_2() {
      let h = this.$refs.table6_2.scrollHeight;
      let w = this.$refs.table6_2.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.table6_2, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = this.chartColumn1_title; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    // fnButtonDL6_3(val) {
    //   if (val == "下载图片") {
    //     this.FnDownload6_3();
    //   } else {
    //     document.getElementById("downloadExcel6_3").click();
    //   }
    // },

    fnButtonDL6_3_1(val) {
      this.FnDownload6_3();
    },

    fnButtonDL6_3_2(val) {
      document.getElementById("downloadExcel6_3").click();
    },

    FnDownload6_3() {

      let str = 'chartColumn4_download'
      if (
        this.graphicalData_chart2_one == "散点图" &&
        this.graphicalData_chart2_two == "散点图"
      ) {
         str = 'chartColumn4'
      }

      let h = this.$refs[str].scrollHeight;
      let w = this.$refs[str].scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs[str], {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "数据分析器 - 交叉分析对比"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    // fnButtonDL6_4(val) {
    //   if (val == "下载图片") {
    //   } else {
    //     document.getElementById("downloadExcel6_4").click();
    //   }
    // },

    fnButtonDL6_4_1(val) {
      this.FnDownload6_4();
    },

    fnButtonDL6_4_2(val) {
      document.getElementById("downloadExcel6_4").click();
    },

    FnDownload6_4() {
      let h = this.$refs.chartColumn3.scrollHeight;
      let w = this.$refs.chartColumn3.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn3, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "公募REITS各部分yield传递规律构成"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == "dark" ? true : false;
    },

    // 下载 end

    // 初始化codename
    fnCodeName() {
      // let data = this.$store.state.CODENAME;
      // let data = JSON.parse(localStorage.getItem("CODEobj"));
      // for (let i in data) {
      //   let obj = {
      //     value: i,
      //     label: data[i],
      //   };
      //   this.CODENAMEDATA.push(obj);
      // }

      // 获取对比reits
      let REITsNAME = JSON.parse(localStorage.getItem("REITsNAME"));
      this.CODENAMEDATA = REITsNAME;
    },

    // 报告期
    fnGetpPeriodData() {
      let data = JSON.parse(localStorage.getItem("PERIODDATA"));
      this.periodData = data.reverse();
      this.targetData_chart1_end = this.periodData[0].value;
      this.targetData_chart1_start = this.periodData[5].value;

      this.targetData_chart_jiaochafenxi_start = this.periodData[5].value;
      this.targetData_chart_jiaochafenxi_end = this.periodData[0].value;

      this.factor_chart3_start = this.periodData[5].value;
      this.factor_chart3_end = this.periodData[0].value;
    },

    // 五合一 start
    fnSelect_FiveInOne(val) {
      this.fiveInoneDATA_default = val;

      if (val == "利润表") {
        this.targetData_chartone = this.targetData_chartone_backup;
        this.fnGetReitsBenIndex();
      }

      if (val == "现金流量表") {
        this.targetData_chartone = this.targetData_chart4;
        this.fnGetReitsBenIndex();
      }

      if (val == "分派表") {
        this.targetData_chartone = this.targetData_chart5;
        this.fnGetReitsBenIndex();
      }

      if (val == "资产回报") {
        this.targetData_chartone = this.targetData_chart6;
        this.fnGetReitsBenIndex();
      }

      if (val == "静态倍数") {
        this.targetData_chartone = this.targetData_chart7;
        this.fnGetReitsBenIndex();
      }
    },

    fnCCascaderCaiWu(val) {
      this.targetData_chartone = val;
      this.fnGetReitsBenIndex();
    },

    // 五合一 end

    // chart1
    fnSelect_select_val_chart1(val) {
      this.targetData_chartone = val;
      this.fnGetReitsBenIndex();
    },
    fnSelect_select_val_chart1_start(val) {
      let flag2 = fnJudgePeriodOrder(
        val,
        this.targetData_chart1_end,
        this.periodData
      );
      if (!flag2) return this.$message.error("结束报告期不能小于开始报告期");
      let flag = fnJudgePeriodRational(
        val,
        this.targetData_chart1_end,
        this.periodData
      );
      if (!flag) return this.$message.error("报告期之间的间隔不能大于6个！");

      this.targetData_chart1_start = val;
      this.$refs.period_caiwu1.value = this.targetData_chart1_start;
      this.fnGetReitsBenIndex();
    },
    fnSelect_select_val_chart1_end(val) {
      let flag2 = fnJudgePeriodOrder(
        this.targetData_chart1_start,
        val,
        this.periodData
      );
      if (!flag2) return this.$message.error("结束报告期不能小于开始报告期");
      let flag = fnJudgePeriodRational(
        this.targetData_chart1_start,
        val,
        this.periodData
      );
      if (!flag) return this.$message.error("报告期之间的间隔不能大于6个！");

      this.targetData_chart1_end = val;
      this.$refs.period_caiwu2.value = this.targetData_chart1_end;
      this.fnGetReitsBenIndex();
    },
    fnCCascader_select_val_chart1(val) {
      // this.category_cascader_val_chart1 = val.filter((node, idx) => {
      //   return idx == val.length - 1;
      // });
      this.category_cascader_val_chart1 = val;
      this.fnGetReitsBenIndex();
    },

    fnSelect_select_val_chart1_codename(val) {
      val = val.filter((node) => {
        return node.includes(".");
      });

      this.CODENAMEDATA_chart1 = val;
      this.fnGetReitsBenIndex();
    },

    async fnGetReitsBenIndex() {
      let factor = this.targetData_chartone;
      let r_type = this.category_cascader_val_chart1;
      // let r_type = ['公募REITs'];
      let str = r_type.join("&r_type=");
      let codes = this.CODENAMEDATA_chart1;
      let str2 = codes.join("&codes=");

      let rp_period_start = this.targetData_chart1_start;
      let rp_period_end = this.targetData_chart1_end;

      let flag = false;
      this.bchartColumn1 = true;

      if (
        r_type.includes("公募REITs") ||
        (r_type.includes("产权类") && r_type.includes("经营权类"))
      ) {
        flag = true;
        this.bchartColumn1 = false;
      }

      const { data } = await this.$https.get(
        "/api/get_fin_data?factor=" +
          factor +
          "&r_type=" +
          str +
          "&codes=" +
          str2 +
          "&rp_period_start=" +
          rp_period_start +
          "&rp_period_end=" +
          rp_period_end
      );

      if (data.code !== 200) return this.$message.error(data.msg);


      // 赋值title
      let data9 = [];
      if (this.fiveInoneDATA_default == "利润表") {
        data9 = this.targetData_lirunbiao;
      }
      if (this.fiveInoneDATA_default == "现金流量表") {
        data9 = this.targetData_xianjinliuliang;
      }
      if (this.fiveInoneDATA_default == "分派表") {
        data9 = this.targetData_fenpaibiao;
      }
      if (this.fiveInoneDATA_default == "资产回报") {
        data9 = this.targetData_zichanhuibao;
      }
      if (this.fiveInoneDATA_default == "静态倍数") {
        data9 = this.targetData_jingtaibeishu;
      }

      let TITLE = "";
      this.fiveInoneDATA2.forEach((node) => {
        node.children.forEach((item) => {
          if (item.value == this.targetData_chartone) {
            TITLE = item.label;
          }
        });
      });
      this.chartColumn1_title = TITLE;

      // 利润表图
      let dataTmp2 = data.data;
      // 单位

      let objunit = this.UNITS.find((node) => {
        return node.metrics == factor;
      });
      let unitname = objunit.labelFormat;

      this.unitname = unitname;

      this.chartColumn1_unit =
        unitname !== "pct" ? (unitname !== "mil" ? "无" : "百万") : "%";

      // 处理数据是不是百分比的情况
      // let bpercentage =
      //   dataTmp2[0].data.toString().split(".")[1].length > 7 ? true : false;

      let bpercentage =
        unitname == "pct" ? true : unitname == "mil" ? false : true;

      dataTmp2.map((node) => {
        let val = node.data;
        if (!bpercentage) {
          node.data = (Number(val) / 1000000).toFixed(1);
        }
      });

      // 表格 start  ----------
      this.dReitsBenIndex_table = data.data;

      this.dReitsBenIndex_table.map((node) => {
        let reit_code = node.reit_code;
        node["name"] = this.MAP[reit_code];

        let data = node.data;
        data = data.toLocaleString();
        node["data"] = data;
      });

      let NAME = this.dReitsBenIndex_table.map((node) => {
        return node["name"];
      });

      NAME = [...new Set(NAME)];

      // max
      let ARR1 = [];
      NAME.forEach((node) => {
        let data = this.dReitsBenIndex_table.filter((e) => {
          return e["name"] == node;
        });

        data = data.map((node) => {
          return node.data;
        });

        let obj = {
          name: node,
          max: Math.max(...data),
        };

        ARR1.push(obj);
      });

      // 赋值
      ARR1.forEach((e) => {
        this.dReitsBenIndex_table.forEach((node) => {
          if (e.name == node.name) {
            let node_rectangle = "";
            let val = Number(node.data) > 0 ? Number(node.data) / e.max : 0;
            val = (Number(val) * 100).toFixed(0);
            node_rectangle = val + "px";
            node.node_rectangle = node_rectangle;
          }
        });
      });

      let rp_period_table = this.dReitsBenIndex_table.map((node) => {
        return node.rp_period;
      });

      rp_period_table = [...new Set(rp_period_table)];

      let name_table = this.dReitsBenIndex_table.map((node) => {
        return node.name;
      });

      name_table = [...new Set(name_table)];

      let TBALE = [];

      name_table.forEach((item) => {
        let obj = {
          name: item,
          reit_code: "",
          node_rectangle: "",
          REITs_type_L2: "",
        };
        rp_period_table.forEach((node) => {
          this.dReitsBenIndex_table.forEach((ele) => {
            if (ele.name == item && ele.rp_period == node) {
              obj[node + "总收入"] = ele.data;
              obj["reit_code"] = ele.reit_code;
              obj["REITs_type_L1"] = ele.REITs_type_L1;
              obj["REITs_type_L2"] = ele.REITs_type_L2;
              obj[node + "node_rectangle"] = ele.node_rectangle;
            }
          });
        });

        TBALE.push(obj);
      });

      this.dReitsBenIndex_table = TBALE;
      let DATATMP = [];
      if (TBALE.length > 0) {
        DATATMP = Object.keys(TBALE[0]);
      } else {
        DATATMP = [];
      }

      let tableColumn_lirunbiao = DATATMP.filter((node) => {
        if (node.substring(node.length - 3) == "总收入") {
          return node;
        }
      });

      let tableRectangle_lirunbiao = DATATMP.filter((node) => {
        if (
          node.substring(node.length - 9) == "rectangle" &&
          node.length > 14
        ) {
          return node;
        }
      });

      this.tableColumn_lirunbiao = tableColumn_lirunbiao;

      this.tableRectangle_lirunbiao = tableRectangle_lirunbiao;

      let aaa = Object.keys(this.dReitsBenIndex_table[0]).filter((node) => {
        if (node.startsWith("2")) {
          return node;
        }
      });

      aaa = aaa.map((node) => {
        node = String(node).slice(0, 6);
        return node;
      });

      aaa = [...new Set(aaa)];
      let json_fields = {
        资产大类: "REITs_type_L1",
        类型: "REITs_type_L2",
        名称: "name",
        代码: "reit_code",
      };
      aaa.forEach((node) => {
        json_fields[node] = node + "总收入";
      });

      this.json_fields_lirunbiao = json_fields;

      let orderData = JSON.parse(localStorage.getItem("ORDER"));
      let TMP = [];
      orderData.forEach((node) => {
        let obj = this.dReitsBenIndex_table.filter(
          (item) => item["REITs_type_L2"] == node
        );
        if (obj.length > 0) {
          obj.forEach((item) => {
            TMP.push(item);
          });
        }
      });

      this.dReitsBenIndex_table = TMP;

      // 表格 end  ----------

      // 0109 重新整理下逻辑，需要按类分别
      // 利润表图

      // 值为公募REITs时，分类
      let L1 = dataTmp2.map((node) => {
        return node.REITs_type_L1;
      });

      L1 = [...new Set(L1)];

      let DATA = [];
      L1.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };

        dataTmp2.forEach((e) => {
          if (e.REITs_type_L1 == node) {
            obj.data.push(e);
          }
        });

        DATA.push(obj);
      });

      // 再次判断flag
      if (DATA.length == 1) {
        flag = false;
        this.bchartColumn1 = true;
      } else {
        flag = true;
        this.bchartColumn1 = false;
      }

      if (flag) {
        this.chart1_1_bpercentage = bpercentage;
        this.chart1_1_DATA = [];
        this.chart1_1_DATA = DATA[0];

        this.chart1_2_bpercentage = bpercentage;
        this.chart1_2_DATA = [];
        this.chart1_2_DATA = DATA[1];
        this.$nextTick(() => {
          this.initChart1_1();
          this.initChart1_2();
        });
        return;
      }

      // 按照L2排序 start
      let L2DATA = dataTmp2.map((node) => {
        return node.REITs_type_L2;
      });

      L2DATA = [...new Set(L2DATA)];

      let objTmp = [];
      L2DATA.forEach((node) => {
        let arr = dataTmp2.filter((item) => {
          return item.REITs_type_L2 == node;
        });
        arr.forEach((ele) => {
          objTmp.push(ele);
        });
      });
      dataTmp2 = objTmp;
      // 按照L2排序 end

      // x轴 （CODE）
      let reit_code = dataTmp2.map((node) => {
        return node.reit_code;
      });

      let ARR_X = reit_code.map((e) => {
        for (let i in this.MAP) {
          if (e == i) return this.MAP[i];
        }
      });

      // 中文CODE
      ARR_X = [...new Set(ARR_X)];

      // y轴 (报告期)
      // 步骤1
      let rp_period = dataTmp2.map((node) => {
        return node.rp_period;
      });

      // 去重
      rp_period = [...new Set(rp_period)];
      reit_code = [...new Set(reit_code)];

      // 利润表图的值
      let ARR_Y = [];
      rp_period.forEach((node, idx) => {
        let obj = {
          name: node,
          data: [],
        };

        reit_code.forEach((e) => {
          let obj2 = dataTmp2.find((item) => {
            return item.reit_code == e && item.rp_period == node;
          });

          if (obj2) {
            // 判断是不是百分比
            let val = obj2.data;
            obj.data.push(val);
          } else {
            obj.data.push(0);
          }
        });
        ARR_Y.push(obj);
      });

      // 完美 ！ 漂亮

      this.dReitsBenIndex_X = ARR_X;

      ARR_Y.reverse();

      this.dReitsBenIndex_Y = [];
      this.dReitsBenIndex_Y = ARR_Y;

      let obj = dataTmp2[0];
      this.chart1_bpercentage = bpercentage;
      this.chart1_obj = obj;
      this.$nextTick(() => {
        this.initChart1();
      });
    },

    initChart1(val) {
      if (!val) {
        this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      } else {
        this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1_dialog);
      }

      // this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      this.chartColumn1.clear();

      let bpercentage = this.chart1_bpercentage;
      let obj = this.chart1_obj;

      let dReitsBenIndex_X = this.dReitsBenIndex_X;
      let dReitsBenIndex_Y = this.dReitsBenIndex_Y;

      let name = dReitsBenIndex_Y.map((node) => {
        return node.name;
      });

      name = name.map((node) => {
        return node.substring(node.length - 2);
      });

      let colorArr = this.Qcolors.map((node) => {
        return node.value;
      });

      // let colorArr = [];
      // name.forEach((e) => {
      //   this.Qcolors.forEach((node) => {
      //     if (e == node.name) {
      //       colorArr.push(node.value);
      //     }
      //   });
      // });

      let length = dReitsBenIndex_X.length;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let seriesArr = dReitsBenIndex_Y.map((v, index) => {
        return {
          name: v.name.substring(v.name.length - 2),
          type: "bar",
          data: v.data,
          // barWidth: "10px",
          // barWidth: "10px",
          label: {
            normal: {
              show: index % 4 == 0 ? true : false,
              position: "bottom",
              formatter: v.name.substring(0, 4),
              offset: [this.ROOTFONTFIZE * (8 / 144), 0],
              fontSize:
                length > 10
                  ? this.ROOTFONTFIZE * (12 / 144)
                  : length < 5
                  ? this.ROOTFONTFIZE * (16 / 144)
                  : this.ROOTFONTFIZE * (14 / 144),
              // distance: Math.min(...v.data)<0?Math.min(...v.data): 5,
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
            },
          },
          itemStyle: {
            color: function (val) {
              if (val.seriesName == "Q5") {
                return "#333";
              } else {
                return val.color;
              }
            },
          },
        };
      });

      let text = obj.REITs_type_L1;
      let str = "";
      text.split("").forEach((node) => {
        str = str + node + "\n\n";
      });

      let chartColumn1_unit = this.chartColumn1_unit;

      let unitname = this.unitname;

      let option = {
        title: {
          // 标题设置
          text: str, // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (16 / 144),
          },
          top: "middle",
          left: this.ROOTFONTFIZE * (10 / 144),
        },
        grid: {
          top: this.ROOTFONTFIZE * (70 / 144),
          left: this.ROOTFONTFIZE * (50 / 144),
          right: this.ROOTFONTFIZE * (30 / 144),
          bottom: this.ROOTFONTFIZE * (50 / 144),
          containLabel: true,
        },
        legend: {
          show: true,
          orient: "horizontal",
          left: this.ROOTFONTFIZE * (30 / 144),
          top: this.ROOTFONTFIZE * (20 / 144),
          align: "left",
          icon: "roundRect",
          itemGap: this.ROOTFONTFIZE * (15 / 144),
          itemWidth: this.ROOTFONTFIZE * (12 / 144),
          itemHeight: this.ROOTFONTFIZE * (12 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },

          data: name,
        },
        color: colorArr,
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let name = dReitsBenIndex_Y[node.seriesIndex].name;
              str += "<br>";
              let val = node.value;
              if (bpercentage) {
                if (args == 0) {
                  str += `${node.marker}${name}：${val}`;
                } else {
                  if (unitname == "pct") {
                    val = Number(val * 100).toFixed(1) + "%";
                  } else {
                    val = Number(val).toFixed(1);
                  }
                  str += `${node.marker}${name}：${val}`;
                }
              } else {
                str += `${node.marker}${name}：${val}`;
              }

              return str;
            });
            return str;
          },
        },

        xAxis: [
          //   {
          //   type: "category",
          //   data: X,
          //   boundaryGap: true,
          //   axisLine: {
          //     show: true,
          //     onZero: false, //轴线是否在0刻度轴上
          //   },

          //   axisTick: {
          //     show: true,
          //     alignWithLabel: true,
          //   },
          //   offset: 10,
          //   axisLabel: {
          //     interval: 0,
          //     fontSize: 14,
          //     margin: 10,
          //     show: true,
          //     showMaxLabel: true,
          //     color: "rgba(218, 218, 218, 1)",
          //   },
          // },
          {
            type: "category",
            offset: this.ROOTFONTFIZE * (20 / 144),
            data: dReitsBenIndex_X,
            boundaryGap: true,
            axisLine: {
              show: true,
              onZero: true, //轴线是否在0刻度轴上
            },

            axisTick: {
              show: true,
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              margin: this.ROOTFONTFIZE * (10 / 144),
              rotate: this.ROOTFONTFIZE * (12 / 144),
              padding: [0, this.ROOTFONTFIZE * (-30 / 144), 0, 0],
              show: true,
              showMaxLabel: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
          },
        ],

        // dataZoom: {
        //   // 开启数据平移qc
        //   type: "slider", // 滑动条单独显示
        //   show: true, // 是否显示滑动条
        //   startValue: 0, // 展示区域内容的起始数值
        //   endValue: 15, // 展示区域内容的结束数值 当前展示x坐标下标为0-16
        //   height: 5, // 滑动条组件高度
        //   bottom: 10, // 距离图表区域下边的距离
        //   showDetail: false, // 拖拽时是否显示详情
        //   showDataShadow: false,
        //   fillerColor: "#D4D4D4", // 平移条的填充颜色
        //   borderColor: "transparent", // 边框颜色
        //   zoomLock: true, // 锁定视图
        //   brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
        //   // 通过该属性可以只滑动，不显示缩放功能
        //   handleStyle: {
        //     opacity: 0,
        //   },
        //   lineStyle: {
        //     opacity: 0,
        //   },
        //   textStyle: {
        //     fontSize: 0,
        //   },
        // },
        yAxis: {
          name: "单位(" + chartColumn1_unit + ")",
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          scale: false,
          // type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              if (bpercentage) {
                if (args == 0) {
                  return 0;
                } else {
                  if (unitname == "pct") {
                    return (args * 100).toFixed(1) + "%";
                  } else {
                    return args.toFixed(1);
                  }
                }
              } else {
                return args;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: seriesArr,
      };

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();

      // this.initChart2();
      // this.funResize()
    },
    initChart1_1(val) {
      if (!val) {
        this.chartColumn1_1 = this.$echarts.init(this.$refs.chartColumn1_1);
      } else {
        this.chartColumn1_1 = this.$echarts.init(
          this.$refs.chartColumn1_1_dialog
        );
      }

      // this.chartColumn1_1 = this.$echarts.init(this.$refs.chartColumn1_1);
      this.chartColumn1_1.clear();

      let bpercentage = this.chart1_1_bpercentage;
      let DATA = this.chart1_1_DATA;

      if (!DATA) return;

      let dataTmp2 = DATA.data;

      // 按照L2排序 start
      let L2DATA = dataTmp2.map((node) => {
        return node.REITs_type_L2;
      });

      L2DATA = [...new Set(L2DATA)];

      let objTmp = [];
      L2DATA.forEach((node) => {
        let arr = dataTmp2.filter((item) => {
          return item.REITs_type_L2 == node;
        });
        arr.forEach((ele) => {
          objTmp.push(ele);
        });
      });
      dataTmp2 = objTmp;
      // 按照L2排序 end

      // x轴 （CODE）
      let reit_code = dataTmp2.map((node) => {
        return node.reit_code;
      });

      let ARR_X = reit_code.map((e) => {
        for (let i in this.MAP) {
          if (e == i) return this.MAP[i];
        }
      });

      // 中文CODE
      ARR_X = [...new Set(ARR_X)];

      // y轴 (报告期)
      // 步骤1
      let rp_period = dataTmp2.map((node) => {
        return node.rp_period;
      });

      // 去重
      rp_period = [...new Set(rp_period)];
      reit_code = [...new Set(reit_code)];

      // 利润表图的值
      let ARR_Y = [];
      rp_period.forEach((node, idx) => {
        let obj = {
          name: node,
          data: [],
        };

        reit_code.forEach((e) => {
          let obj2 = dataTmp2.find((item) => {
            return item.reit_code == e && item.rp_period == node;
          });

          if (obj2) {
            // 判断是不是百分比
            let val = obj2.data;
            obj.data.push(val);
          } else {
            obj.data.push(0);
          }
        });
        ARR_Y.push(obj);
      });

      ARR_Y.reverse();

      let dReitsBenIndex_X = ARR_X;
      let dReitsBenIndex_Y = ARR_Y;

      let name = dReitsBenIndex_Y.map((node) => {
        return node.name;
      });

      name = name.map((node) => {
        return node.substring(node.length - 2);
      });

      let colorArr = this.Qcolors.map((node) => {
        return node.value;
      });
      // name.forEach((e) => {
      //   this.Qcolors.forEach((node) => {
      //     if (e == node.name) {
      //       colorArr.push(node.value);
      //     }
      //   });
      // });

      let text = DATA.name;
      let str = "";
      text.split("").forEach((node) => {
        str = str + node + "\n\n";
      });

      let length = dReitsBenIndex_X.length;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let seriesArr = dReitsBenIndex_Y.map((v, index) => {
        return {
          name: v.name.substring(v.name.length - 2),
          type: "bar",
          data: v.data,
          // barWidth: "10px",
          label: {
            normal: {
              show: index % 4 == 0 ? true : false,
              position: "bottom",
              formatter: v.name.substring(0, 4),
              offset: [8, 0],
              fontSize:
                length > 10
                  ? this.ROOTFONTFIZE * (12 / 144)
                  : length < 5
                  ? this.ROOTFONTFIZE * (16 / 144)
                  : this.ROOTFONTFIZE * (14 / 144),
              // fontSize: 10,

              // distance: Math.min(...v.data)<0?Math.min(...v.data): 5,
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
            },
          },
          itemStyle: {
            color: function (val) {
              if (val.seriesName == "Q5") {
                return "#333";
              } else {
                return val.color;
              }
            },
          },
        };
      });

      let chartColumn1_unit = this.chartColumn1_unit;

      let unitname = this.unitname;

      let option = {
        title: {
          // 标题设置
          text: str,
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (16 / 144),
          },
          top: "middle",
          left: this.ROOTFONTFIZE * (10 / 144),
        },
        grid: {
          top: this.ROOTFONTFIZE * (80 / 144),
          left: this.ROOTFONTFIZE * (50 / 144),
          right: this.ROOTFONTFIZE * (30 / 144),
          bottom: this.ROOTFONTFIZE * (50 / 144),
          containLabel: true,
        },
        legend: {
          show: true,
          orient: "horizontal",
          left: this.ROOTFONTFIZE * (30 / 144),
          top: this.ROOTFONTFIZE * (20 / 144),
          align: "left",
          icon: "roundRect",
          itemGap: this.ROOTFONTFIZE * (15 / 144),
          itemWidth: this.ROOTFONTFIZE * (12 / 144),
          itemHeight: this.ROOTFONTFIZE * (12 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },

          data: name,
        },
        color: colorArr,
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let name = dReitsBenIndex_Y[node.seriesIndex].name;
              str += "<br>";
              let val = node.value;
              if (bpercentage) {
                if (args == 0) {
                  str += `${node.marker}${name}：${val}`;
                } else {
                  if (unitname == "pct") {
                    val = Number(val * 100).toFixed(1) + "%";
                  } else {
                    val = Number(val).toFixed(1);
                  }

                  str += `${node.marker}${name}：${val}`;
                }
              } else {
                str += `${node.marker}${name}：${val}`;
              }

              return str;
            });
            return str;
          },
        },

        xAxis: [
          {
            type: "category",
            offset: this.ROOTFONTFIZE * (20 / 144),
            data: dReitsBenIndex_X,
            boundaryGap: true,
            axisLine: {
              show: true,
              onZero: true, //轴线是否在0刻度轴上
            },

            axisTick: {
              show: true,
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              rotate: this.ROOTFONTFIZE * (12 / 144),
              margin: this.ROOTFONTFIZE * (10 / 144),
              padding: [0, this.ROOTFONTFIZE * (-30 / 144), 0, 0],
              show: true,
              showMaxLabel: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
          },
        ],

        // dataZoom: {
        //   // 开启数据平移qc
        //   type: "slider", // 滑动条单独显示
        //   show: true, // 是否显示滑动条
        //   startValue: 0, // 展示区域内容的起始数值
        //   endValue: 15, // 展示区域内容的结束数值 当前展示x坐标下标为0-16
        //   height: 5, // 滑动条组件高度
        //   bottom: 10, // 距离图表区域下边的距离
        //   showDetail: false, // 拖拽时是否显示详情
        //   showDataShadow: false,
        //   fillerColor: "#D4D4D4", // 平移条的填充颜色
        //   borderColor: "transparent", // 边框颜色
        //   zoomLock: true, // 锁定视图
        //   brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
        //   // 通过该属性可以只滑动，不显示缩放功能
        //   handleStyle: {
        //     opacity: 0,
        //   },
        //   lineStyle: {
        //     opacity: 0,
        //   },
        //   textStyle: {
        //     fontSize: 0,
        //   },
        // },
        yAxis: {
          name: "单位(" + chartColumn1_unit + ")",
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          scale: false,
          // type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,

            formatter: function (args) {
              if (bpercentage) {
                if (args == 0) {
                  return 0;
                } else {
                  if (unitname == "pct") {
                    return (args * 100).toFixed(1) + "%";
                  } else {
                    return args.toFixed(1);
                  }
                }
              } else {
                return args;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: seriesArr,
      };

      this.chartColumn1_1.setOption(option);
      this.chartColumn1_1.resize();

      // this.initChart2();
      // this.funResize()
    },
    initChart1_2(val) {
      if (!val) {
        this.chartColumn1_2 = this.$echarts.init(this.$refs.chartColumn1_2);
      } else {
        this.chartColumn1_2 = this.$echarts.init(
          this.$refs.chartColumn1_2_dialog
        );
      }

      // this.chartColumn1_2 = this.$echarts.init(this.$refs.chartColumn1_2);
      this.chartColumn1_2.clear();

      let bpercentage = this.chart1_2_bpercentage;
      let DATA = this.chart1_2_DATA;

      if (!DATA) return;

      let dataTmp2 = DATA.data;

      // 按照L2排序 start
      let L2DATA = dataTmp2.map((node) => {
        return node.REITs_type_L2;
      });

      L2DATA = [...new Set(L2DATA)];

      let objTmp = [];
      L2DATA.forEach((node) => {
        let arr = dataTmp2.filter((item) => {
          return item.REITs_type_L2 == node;
        });
        arr.forEach((ele) => {
          objTmp.push(ele);
        });
      });
      dataTmp2 = objTmp;
      // 按照L2排序 end

      // x轴 （CODE）
      let reit_code = dataTmp2.map((node) => {
        return node.reit_code;
      });

      let ARR_X = reit_code.map((e) => {
        for (let i in this.MAP) {
          if (e == i) return this.MAP[i];
        }
      });

      // 中文CODE
      ARR_X = [...new Set(ARR_X)];

      // y轴 (报告期)
      // 步骤1
      let rp_period = dataTmp2.map((node) => {
        return node.rp_period;
      });

      // 去重
      rp_period = [...new Set(rp_period)];
      reit_code = [...new Set(reit_code)];

      // 利润表图的值
      let ARR_Y = [];
      rp_period.forEach((node, idx) => {
        let obj = {
          name: node,
          data: [],
        };

        reit_code.forEach((e) => {
          let obj2 = dataTmp2.find((item) => {
            return item.reit_code == e && item.rp_period == node;
          });

          if (obj2) {
            // 判断是不是百分比
            let val = obj2.data;
            obj.data.push(val);
          } else {
            obj.data.push(0);
          }
        });
        ARR_Y.push(obj);
      });

      ARR_Y.reverse();

      let dReitsBenIndex_X = ARR_X;
      let dReitsBenIndex_Y = ARR_Y;

      let name = dReitsBenIndex_Y.map((node) => {
        return node.name;
      });

      name = name.map((node) => {
        return node.substring(node.length - 2);
      });

      let colorArr = this.Qcolors.map((node) => {
        return node.value;
      });

      // let colorArr = [];
      // name.forEach((e) => {
      //   this.Qcolors.forEach((node) => {
      //     if (e == node.name) {
      //       colorArr.push(node.value);
      //     }
      //   });
      // });

      let text = DATA.name;
      let str = "";
      text.split("").forEach((node) => {
        str = str + node + "\n\n";
      });

      let length = dReitsBenIndex_X.length;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let seriesArr = dReitsBenIndex_Y.map((v, index) => {
        return {
          name: v.name.substring(v.name.length - 2),
          type: "bar",
          data: v.data,
          // barWidth: "10px",
          label: {
            normal: {
              show: index % 4 == 0 ? true : false,
              position: "bottom",
              formatter: v.name.substring(0, 4),
              offset: [this.ROOTFONTFIZE * (8 / 144), 0],
              fontSize:
                length > 10
                  ? this.ROOTFONTFIZE * (12 / 144)
                  : length < 5
                  ? this.ROOTFONTFIZE * (16 / 144)
                  : this.ROOTFONTFIZE * (14 / 144),
              // distance: Math.min(...v.data)<0?Math.min(...v.data): 5,
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
            },
          },
          itemStyle: {
            color: function (val) {
              if (val.seriesName == "Q5") {
                return "#333";
              } else {
                return val.color;
              }
            },
          },
        };
      });

      let chartColumn1_unit = this.chartColumn1_unit;

      let unitname = this.unitname;

      let option = {
        title: {
          // 标题设置
          text: str,
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (16 / 144),
          },
          top: "middle",
          left: this.ROOTFONTFIZE * (10 / 144),
        },
        grid: {
          top: this.ROOTFONTFIZE * (70 / 144),
          left: this.ROOTFONTFIZE * (50 / 144),
          right: this.ROOTFONTFIZE * (30 / 144),
          bottom: this.ROOTFONTFIZE * (50 / 144),
          containLabel: true,
        },
        legend: {
          show: true,
          orient: "horizontal",
          left: this.ROOTFONTFIZE * (30 / 144),
          top: this.ROOTFONTFIZE * (20 / 144),
          align: "left",
          icon: "roundRect",
          itemGap: this.ROOTFONTFIZE * (15 / 144),
          itemWidth: this.ROOTFONTFIZE * (12 / 144),
          itemHeight: this.ROOTFONTFIZE * (12 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },

          data: name,
        },
        color: colorArr,
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let name = dReitsBenIndex_Y[node.seriesIndex].name;
              str += "<br>";
              let val = node.value;
              if (bpercentage) {
                if (args == 0) {
                  str += `${node.marker}${name}：${val}`;
                } else {
                  if (unitname == "pct") {
                    val = Number(val * 100).toFixed(1) + "%";
                  } else {
                    val = Number(val).toFixed(1);
                  }

                  str += `${node.marker}${name}：${val}`;
                }
              } else {
                str += `${node.marker}${name}：${val}`;
              }

              return str;
            });
            return str;
          },
        },

        xAxis: [
          //   {
          //   type: "category",
          //   data: X,
          //   boundaryGap: true,
          //   axisLine: {
          //     show: true,
          //     onZero: false, //轴线是否在0刻度轴上
          //   },

          //   axisTick: {
          //     show: true,
          //     alignWithLabel: true,
          //   },
          //   offset: 10,
          //   axisLabel: {
          //     interval: 0,
          //     fontSize: 14,
          //     margin: 10,
          //     show: true,
          //     showMaxLabel: true,
          //     color: "rgba(218, 218, 218, 1)",
          //   },
          // },
          {
            type: "category",
            offset: this.ROOTFONTFIZE * (20 / 144),
            data: dReitsBenIndex_X,
            boundaryGap: true,
            axisLine: {
              show: true,
              onZero: true, //轴线是否在0刻度轴上
            },

            axisTick: {
              show: true,
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              margin: this.ROOTFONTFIZE * (10 / 144),
              padding: [0, this.ROOTFONTFIZE * (-30 / 144), 0, 0],
              rotate: 12,
              show: true,
              showMaxLabel: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
          },
        ],

        // dataZoom: {
        //   // 开启数据平移qc
        //   type: "slider", // 滑动条单独显示
        //   show: true, // 是否显示滑动条
        //   startValue: 0, // 展示区域内容的起始数值
        //   endValue: 15, // 展示区域内容的结束数值 当前展示x坐标下标为0-16
        //   height: 5, // 滑动条组件高度
        //   bottom: 10, // 距离图表区域下边的距离
        //   showDetail: false, // 拖拽时是否显示详情
        //   showDataShadow: false,
        //   fillerColor: "#D4D4D4", // 平移条的填充颜色
        //   borderColor: "transparent", // 边框颜色
        //   zoomLock: true, // 锁定视图
        //   brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
        //   // 通过该属性可以只滑动，不显示缩放功能
        //   handleStyle: {
        //     opacity: 0,
        //   },
        //   lineStyle: {
        //     opacity: 0,
        //   },
        //   textStyle: {
        //     fontSize: 0,
        //   },
        // },
        yAxis: {
          name: "单位(" + chartColumn1_unit + ")",
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          scale: false,
          // type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,

            formatter: function (args) {
              if (bpercentage) {
                if (args == 0) {
                  return 0;
                } else {
                  if (unitname == "pct") {
                    return (args * 100).toFixed(1) + "%";
                  } else {
                    return args.toFixed(1);
                  }
                }
              } else {
                return args;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: seriesArr,
      };

      this.chartColumn1_2.setOption(option);
      this.chartColumn1_2.resize();

      // this.initChart2();
      // this.funResize()
    },

    // 交叉分析 start  ----------------------------------------------
    // 指标1名称
    fnCCascader1(val) {
      this.targetData_chart2_one = val;
      this.fnGetReitsBenIndex_jiaochafenxi_left();
    },

    fnCCascader2(val) {
      this.targetData_chart2_two = val;
      this.fnGetReitsBenIndex_jiaochafenxi_left();
    },

    fnSelect_graphical_val1(val) {
      this.graphicalData_chart2_one = val;
      this.fnGetReitsBenIndex_jiaochafenxi_left();
    },
    fnSelect_graphical_val2(val) {
      this.graphicalData_chart2_two = val;
      this.fnGetReitsBenIndex_jiaochafenxi_left();
    },
    fnSelect_select_val_chart2_one(val) {
      this.targetData_chart2_one = val;
      this.fnGetReitsBenIndex_jiaochafenxi_left();
    },
    fnSelect_select_val_chart2_two(val) {
      this.targetData_chart2_two = val;
      this.fnGetReitsBenIndex_jiaochafenxi_left();
    },
    fnSelect_select_val_chart_jiaochafenxi_start(val) {
      let flag2 = fnJudgePeriodOrder(
        val,
        this.targetData_chart_jiaochafenxi_end,
        this.periodData
      );
      if (!flag2) return this.$message.error("结束报告期不能小于开始报告期");
      let flag = fnJudgePeriodRational(
        val,
        this.targetData_chart_jiaochafenxi_end,
        this.periodData
      );
      if (!flag) return this.$message.error("报告期之间的间隔不能大于6个！");

      this.targetData_chart_jiaochafenxi_start = val;
      this.$refs.period_jiaocha1.value =
        this.targetData_chart_jiaochafenxi_start;
      this.fnGetReitsBenIndex_jiaochafenxi_left();
    },
    fnSelect_select_val_chart_jiaochafenxi_end(val) {
      let flag2 = fnJudgePeriodOrder(
        this.targetData_chart_jiaochafenxi_start,
        val,
        this.periodData
      );
      if (!flag2) return this.$message.error("结束报告期不能小于开始报告期");
      let flag = fnJudgePeriodRational(
        this.targetData_chart_jiaochafenxi_start,
        val,
        this.periodData
      );
      if (!flag) return this.$message.error("报告期之间的间隔不能大于6个！");
      this.targetData_chart_jiaochafenxi_end = val;
      this.$refs.period_jiaocha2.value = this.targetData_chart_jiaochafenxi_end;
      this.fnGetReitsBenIndex_jiaochafenxi_left();
    },

    fnCCascader_select_val_chart_jiaochafenxi(val) {
      this.category_cascader_val_chart_jiaochafenxi = val;
      this.fnGetReitsBenIndex_jiaochafenxi_left();
    },

    fnSelect_select_val_chart_jiaochafenxi_codename(val) {
      val = val.filter((node) => {
        return node.includes(".");
      });

      this.CODENAMEDATA_chart_jiaochafenxi = val;
      this.fnGetReitsBenIndex_jiaochafenxi_left();
    },

    // 交叉分析左轴数据
    async fnGetReitsBenIndex_jiaochafenxi_left() {
      this.loading = true;
      let factor = this.targetData_chart2_one;
      let r_type = this.category_cascader_val_chart_jiaochafenxi;
      let str = r_type.join("&r_type=");
      let codes = this.CODENAMEDATA_chart_jiaochafenxi;
      let str2 = codes.join("&codes=");

      let rp_period_start = this.targetData_chart_jiaochafenxi_start;
      let rp_period_end = this.targetData_chart_jiaochafenxi_end;

      const { data } = await this.$https.get(
        "/api/get_fin_data?factor=" +
          factor +
          "&r_type=" +
          str +
          "&codes=" +
          str2 +
          "&rp_period_start=" +
          rp_period_start +
          "&rp_period_end=" +
          rp_period_end
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 赋值title
      // let TITLE =this.targetData_lirunbiao.find(node=>{
      //   return node.value == this.targetData_chartone
      // })
      // this.chartColumn1_title = TITLE.label

      // 排序
      let orderData = JSON.parse(localStorage.getItem("ORDER"));
      let ARRAY123 = [];
      orderData.forEach((node) => {
        let arr = data.data.filter((item) => item.REITs_type_L2 == node);
        arr.forEach((ele) => {
          ARRAY123.push(ele);
        });
      });

      // data.data.reverse()
      let dataTmp2 = ARRAY123;
      this.originalData1 = ARRAY123;

      // 处理数据是不是百分比的情况
      // 处理数据是不是百分比的情况

      let objunit = this.UNITS.find((node) => {
        return node.metrics == factor;
      });
      let unitname = objunit.labelFormat;

      this.unitname2 = unitname;

      // this.bpercentageLeft =
      //   dataTmp2[0].data.toString().split(".")[1].length > 7 ? true : false;

      this.bpercentageLeft =
        unitname == "pct" ? true : unitname == "mil" ? false : true;

      dataTmp2.map((node) => {
        let val = node.data;
        if (!this.bpercentageLeft) {
          node.data = (Number(val) / 1000000).toFixed(1);
        }
      });

      // 单位 end

      // this.dReitsBenIndex_table = data.data;

      // this.dReitsBenIndex_table.map((node) => {
      //   let reit_code = node.reit_code;
      //   node["name"] = this.MAP[reit_code];

      //   let data = (Number(node["data"]) / 1000000).toFixed(1);
      //   data = data.toLocaleString();
      //   node["data"] = data;
      // });

      // let NAME = this.dReitsBenIndex_table.map((node) => {
      //   return node["name"];
      // });

      // NAME = [...new Set(NAME)];

      // // max
      // let ARR1 = [];
      // NAME.forEach((node) => {
      //   let data = this.dReitsBenIndex_table.filter((e) => {
      //     return e["name"] == node;
      //   });

      //   data = data.map((node) => {
      //     return node.data;
      //   });

      //   let obj = {
      //     name: node,
      //     max: Math.max(...data),
      //   };

      //   ARR1.push(obj);
      // });

      // // 赋值
      // ARR1.forEach((e) => {
      //   this.dReitsBenIndex_table.forEach((node) => {
      //     if (e.name == node.name) {
      //       let node_rectangle = "";
      //       let val = Number(node.data) > 0 ? Number(node.data) / e.max : 0;
      //       val = (Number(val) * 100).toFixed(0);
      //       node_rectangle = val + "px";
      //       node.node_rectangle = node_rectangle;
      //     }
      //   });
      // });

      // 0109 重新整理下逻辑，需要按类分别

      // x轴
      let reit_code = dataTmp2.map((node) => {
        return node.reit_code;
      });

      // 排序 start
      // 排序 end

      this.code_jiaocha = [...new Set(JSON.parse(JSON.stringify(reit_code)))];

      let ARR_X = this.code_jiaocha.map((e) => {
        let val = "";
        for (let i in this.MAP) {
          if (e == i) {
            val = this.MAP[i];
          }
        }
        return val;
      });

      ARR_X = [...new Set(ARR_X)];

      // y轴
      // 步骤1
      let rp_period = dataTmp2.map((node) => {
        return node.rp_period;
      });

      rp_period = [...new Set(rp_period)];

      reit_code = [...new Set(reit_code)];

      let ARR_Y = [];
      rp_period.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };

        reit_code.forEach((e) => {
          let obj2 = dataTmp2.find((item) => {
            return item.reit_code == e && item.rp_period == node;
          });

          if (obj2) {
            obj.data.push(obj2.data);
          } else {
            obj.data.push(0);
          }
        });

        ARR_Y.push(obj);
      });

      // 完美 ！ 漂亮

      ARR_Y.reverse();
      this.dReitsBenIndex2_Y = ARR_Y;
      this.dReitsBenIndex2_X = ARR_X;
      this.fnGetReitsBenIndex_jiaochafenxi_right(reit_code);
    },

    // 交叉分析右轴数据
    async fnGetReitsBenIndex_jiaochafenxi_right(reit_code1) {
      let factor = this.targetData_chart2_two;
      let r_type = this.category_cascader_val_chart_jiaochafenxi;
      let str = r_type.join("&r_type=");
      let codes = this.CODENAMEDATA_chart_jiaochafenxi;
      let str2 = codes.join("&codes=");

      let rp_period_start = this.targetData_chart_jiaochafenxi_start;
      let rp_period_end = this.targetData_chart_jiaochafenxi_end;

      const { data } = await this.$https.get(
        "/api/get_fin_data?factor=" +
          factor +
          "&r_type=" +
          str +
          "&codes=" +
          str2 +
          "&rp_period_start=" +
          rp_period_start +
          "&rp_period_end=" +
          rp_period_end
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      let dataTmp2 = data.data;

      // 处理数据是不是百分比的情况

      let objunit = this.UNITS.find((node) => {
        return node.metrics == factor;
      });
      let unitname = objunit.labelFormat;

      this.unitname3 = unitname;

      // this.bpercentageRight =
      //   dataTmp2[0].data.toString().split(".")[1].length > 7 ? true : false;

      this.bpercentageRight =
        unitname == "pct" ? true : unitname == "mil" ? false : true;

      dataTmp2.map((node) => {
        let val = node.data;
        if (!this.bpercentageRight) {
          node.data = (Number(val) / 1000000).toFixed(1);
        }
      });

      // 单位  end

      this.originalData2 = data.data;

      // let reit_code = dataTmp2.map((node) => {
      //   return node.reit_code;
      // });

      // y轴
      // 步骤1
      let rp_period = dataTmp2.map((node) => {
        return node.rp_period;
      });

      rp_period = [...new Set(rp_period)];

      // reit_code = [...new Set(reit_code)];

      // reit_code1

      // function findDifferentElements(arr1, arr2) {
      //   var differentElements = [];

      //   for (var i = 0; i < arr1.length; i++) {
      //     if (arr2.indexOf(arr1[i]) === -1) {
      //       differentElements.push(arr1[i]);
      //     }
      //   }

      //   for (var j = 0; j < arr2.length; j++) {
      //     if (arr1.indexOf(arr2[j]) === -1) {
      //       differentElements.push(arr2[j]);
      //     }
      //   }

      //   return differentElements;
      // }

      // let result = findDifferentElements(reit_code1, reit_code);

      let ARR_Y = [];

      let reit_code = this.code_jiaocha;

      rp_period.forEach((node, idx) => {
        let obj = {
          name: node,
          data: [],
        };

        reit_code.forEach((e) => {
          let obj2 = dataTmp2.find((item) => {
            return item.reit_code == e && item.rp_period == node;
          });

          if (e == "508056.SH" && node == "2023Q3") {
          }

          if (obj2) {
            obj.data.push(obj2.data);
          } else {
            obj.data.push(0);
          }
        });

        ARR_Y.push(obj);
      });

      ARR_Y.reverse();

      this.dReitsBenIndex2_Y_right = ARR_Y;
      this.$nextTick(() => {
        this.initChart2();
      });

      return;
    },

    initChart2(val) {
      if (!val) {
        this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      } else {
        this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4_dialog);
      }

      let endValue = val == "样式二" ? 5 : 15;

      // this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      this.chartColumn4_download = this.$echarts.init(
        this.$refs.chartColumn4_download
      );

      this.chartColumn4.clear();
      let dReitsBenIndex2_X = this.dReitsBenIndex2_X;

      let dReitsBenIndex2_Y = this.dReitsBenIndex2_Y;
      let dReitsBenIndex2_Y_right = this.dReitsBenIndex2_Y_right;
      let start = this.periodData.findIndex((node) => {
        return node.value == this.targetData_chart_jiaochafenxi_start;
      });
      let end = this.periodData.findIndex((node) => {
        return node.value == this.targetData_chart_jiaochafenxi_end;
      });

      let timeGap = start - end + 1;
      this.timeGap_JIAOCHAFENXI = start - end + 1;

      let unitname2 = this.unitname2;
      let unitname3 = this.unitname3;

      let name = dReitsBenIndex2_Y.map((node) => {
        return node.name;
      });

      let reit_code = this.originalData1.map((node) => {
        return node.reit_code;
      });

      reit_code = [...new Set(reit_code)];

      let FINALLDATA = [];

      let bpercentageLeft = this.bpercentageLeft;
      let bpercentageRight = this.bpercentageRight;

      reit_code.forEach((ele) => {
        name.forEach((node) => {
          this.originalData1.forEach((item) => {
            if (item.reit_code == ele && item.rp_period == node) {
              let ARR = [];
              ARR.push(ele);
              ARR.push(node);
              ARR.push(Number(item.data));

              this.originalData2.forEach((e) => {
                if (e.reit_code == ele && e.rp_period == node) {
                  ARR.push(e.data);
                }
              });
              FINALLDATA.push(ARR);
            }
          });
        });
      });

      name = name.map((node) => {
        return node.substring(node.length - 2);
      });

      let colors = [
        {
          name: "Q4",
          value: "#5ae8ed",
        },
        {
          name: "Q3",
          value: "#a1a1a1",
        },
        {
          name: "Q2",
          value: "#c6cccc",
        },
        {
          name: "Q1",
          value: "#E1E3E3",
          // value: 'E1E3E3'
        },
      ];

      let colorArr = colors.map((node) => {
        return node.value;
      });
      // name.forEach((e) => {
      //   colors.forEach((node) => {
      //     if (e == node.name) {
      //       colorArr.push(node.value);
      //     }
      //   });
      // });

      // 标题
      this.fiveInoneDATA2.forEach((node) => {
        node.children.forEach((item) => {
          if (item.value == this.targetData_chart2_one) {
            this.titleLeft = item.label;
          }
        });
      });

      this.fiveInoneDATA2.forEach((node) => {
        node.children.forEach((item) => {
          if (item.value == this.targetData_chart2_two) {
            this.titleRight = item.label;
          }
        });
      });

      let graphicalData_chart2_one = this.graphicalData_chart2_one;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      // 散点图option
      let option = null;
      let titleLeft = this.titleLeft;
      let titleRight = this.titleRight;

      option = {
        title: {
          // 标题设置
          text: `${titleLeft}   对比    ${titleRight}`, // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (16 / 144),
          },
          top: this.ROOTFONTFIZE * (15 / 144),
          left: "center",
        },

        grid: {
          top: this.ROOTFONTFIZE * (95 / 144),
          left: this.ROOTFONTFIZE * (80 / 144),
          right: this.ROOTFONTFIZE * (80 / 144),
          bottom: this.ROOTFONTFIZE * (50 / 144),
          containLabel: true,
        },
        legend: {
          show: true,
          orient: "horizontal",
          left: this.ROOTFONTFIZE * (30 / 144),
          top: this.ROOTFONTFIZE * (40 / 144),
          align: "left",
          // x:50,
          // x2: 150,
          icon: "roundRect",
          itemGap: this.ROOTFONTFIZE * (15 / 144),
          itemWidth: this.ROOTFONTFIZE * (12 / 144),
          itemHeight: this.ROOTFONTFIZE * (12 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },

          data: name,
          // color: ['#c23531','#5754f7','#a84d38','#ff7432']
        },
        color: colorArr,
      };

      // 无意义
      this.chartColumn4.setOption(option);
      this.chartColumn4_download.setOption(option);

      // 基础option end

      if (
        this.graphicalData_chart2_one == "散点图" &&
        this.graphicalData_chart2_two == "散点图"
      ) {
        let seriesArr2 = FINALLDATA.map((v, index) => {
          return {
            name: v[1].substring(v[1].length - 2),
            data: [[v[2], v[3]]],
            type: "scatter",
            // symbolSize: function () {
            //   console.log(Math.sqrt(v[1] * v[2]) / 10,'sqrt');
            //   return Math.sqrt(v[1] * v[2]);
            // },
            // symbolSize: (Number(v[3]) * 50) + 10,

            cursor: "pointer",
          };
        });

        let CODENAME = this.MAP;

        let option3 = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                show: false,
              },
            },
            triggerOn: "mousemove|click",
            formatter: function (args) {
              let idx = args[0].seriesIndex;
              let name = CODENAME[FINALLDATA[idx][0]];

              let period = FINALLDATA[idx][1];

              let bpercentage =
                unitname3 == "pct" ? true : unitname2 == "mil" ? false : true;

              let val = null;
              let val2 = null;

              if (bpercentage && unitname3 == "pct") {
                val = (Number(FINALLDATA[idx][3]) * 100).toFixed(1) + "%";
              } else {
                val = Number(FINALLDATA[idx][3]).toFixed(1);
              }

              if (bpercentage && unitname2 == "pct") {
                val2 = (Number(FINALLDATA[idx][2]) * 100).toFixed(1) + "%";
              } else {
                val2 = Number(FINALLDATA[idx][2]).toFixed(1);
              }
              let marker2 = `<span style="display:inline-block;margin-right:4px;border-radius:0px;width:10px;height:10px;background-color:${args[0].color};"></span>`;

              let str = "";
              str += `${name}`;
              str += "<br>";
              str += `${args[0].marker}${period}：${val}`;
              str += "<br>";
              str += `${marker2}${period}：${val2}`;

              return str;
            },
          },

          xAxis: {
            // name: bpercentageLeft ? "单位(%)" : "单位(百万)",
            name: bpercentageLeft
              ? unitname2 == "pct"
                ? "单位(%)"
                : "单位(无)"
              : "单位(百万)",

            nameTextStyle: {
              color: OPTIONDATA.yAxis.nameTextStyle.color,
              fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
            },
            nameGap: OPTIONDATA.yAxis.nameGap,
            type: "value",

            axisLine: {
              show: true,
              onZero: true, //轴线是否在0刻度轴上
            },

            axisTick: {
              show: true,
            },
            axisLabel: {
              interval: 0,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              margin: this.ROOTFONTFIZE * (10 / 144),
              show: true,
              showMaxLabel: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
              formatter: function (args) {
                if (bpercentageLeft) {
                  if (unitname2 == "pct") {
                    return Number(args * 100).toFixed(1) + "%";
                  } else {
                    return Number(args).toFixed(1);
                  }
                } else {
                  return args;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },

          yAxis: {
            // name: bpercentageRight ? "单位(%)" : "单位(百万)",
            name: bpercentageRight
              ? unitname3 == "pct"
                ? "单位(%)"
                : "单位(无)"
              : "单位(百万)",
            nameLocation: "end",
            nameTextStyle: {
              color: OPTIONDATA.yAxis.nameTextStyle.color,
              fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
            },
            nameGap: OPTIONDATA.yAxis.nameGap,
            type: "value",
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              //x轴刻度相关设置
              alignWithLabel: false,
            },
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (bpercentageRight) {
                  if (unitname3 == "pct") {
                    return Number(args * 100).toFixed(1) + "%";
                  } else {
                    return Number(args).toFixed(1);
                  }
                } else {
                  return args;
                }
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },

          series: seriesArr2,
        };
        this.chartColumn4.setOption(option3);

      } else {
        //  除了2个都是散点图 之外的情况

        let seriesArr = null;
        // 1.1 第一个是折线图
        if (graphicalData_chart2_one == "折线图") {
          seriesArr = dReitsBenIndex2_Y.map((v, index) => {
            return {
              name: v.name.substring(v.name.length - 2),
              type: "line",
              symbol: "none",
              yAxisIndex: 0,
              data: v.data,
              barWidth: "10px",
            };
          });
        } else {
          // 1.2 第一个是柱状图
          seriesArr = dReitsBenIndex2_Y.map((v, index) => {
            return {
              name: v.name.substring(v.name.length - 2),
              type: "bar",
              yAxisIndex: 0,
              data: v.data,
              barWidth: "10px",
            };
          });
        }

        //  第二个图  start

        // 2.1 第二个图是 柱状图改的散点图
        dReitsBenIndex2_Y_right.map((v, index) => {
          seriesArr.push({
            name: v.name.substring(v.name.length - 2),
            type: "bar",
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: v.data,
            barWidth: "10px",
            itemStyle: {
              color: "rgba(0,0,0,0)",
              // opacity: 0,
            },
            //重点：可以记在文章里
            label: {
              show: true,
              // formatter: "{a|}",
              formatter: function (val) {
                if (val.value == 0) {
                  return `{b|}`;
                } else {
                  return `{a|}`;
                }
              },
              position: "top",
              distance: -10,
              backgroundColor: colorArr[index % 4],
              shadowBlur: 10,
              borderRadius: this.ROOTFONTFIZE * (8 / 144),
              rich: {
                a: {
                  width: this.ROOTFONTFIZE * (8 / 144),
                  height: this.ROOTFONTFIZE * (8 / 144),
                  // borderRadius: this.ROOTFONTFIZE * (4 / 144),
                },
                b: {
                  padding: this.ROOTFONTFIZE * (0 / 144),
                  borderRadius: this.ROOTFONTFIZE * (0 / 144),
                  width: this.ROOTFONTFIZE * (0 / 144),
                  height: this.ROOTFONTFIZE * (0 / 144),
                  borderRadius: this.ROOTFONTFIZE * (0 / 144),
                  lineHeight: this.ROOTFONTFIZE * (0 / 144),
                },
              },
            },
          });
        });

        // 2.1 第二个图是  折线图
        // dReitsBenIndex2_Y_right.map((v, index) => {
        //   seriesArr.push({
        //     name: v.name.substring(v.name.length - 2),
        //     type: "line",
        //     symbol: "none",
        //     xAxisIndex: 1,
        //     yAxisIndex: 1,
        //     data: v.data,
        //   });
        // });

        // 2.1 第二个图是  柱状图
        //  dReitsBenIndex2_Y_right.map((v, index) => {
        //   seriesArr.push({
        //     name: v.name.substring(v.name.length - 2),
        //     type: "bar",
        //     xAxisIndex: 1,
        //     yAxisIndex: 1,
        //     data: v.data,
        //      barWidth: "10px",
        //   });
        // });

        //  第二个图  end

        let option2 = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                show: false,
              },
            },
            triggerOn: "mousemove|click",
            formatter: function (args) {
              let str = "";
              str += `${args[0].axisValueLabel}`;
              str += "<br>";
              let length2 = args.length / 2;
              args.forEach((node, index) => {
                let name = dReitsBenIndex2_Y[node.seriesIndex % timeGap].name;
                let bpercentage = null;

                if (!node.axisIndex) {
                  bpercentage =
                    unitname2 == "pct"
                      ? true
                      : unitname2 == "mil"
                      ? false
                      : true;
                  let value = node.value;
                  if (bpercentage && unitname2 == "pct") {
                    value = (value * 100).toFixed(1) + "%";
                  } else {
                    value = Number(value).toFixed(1);
                  }

                  let bgc = colors[index % 4].value;

                  let marker = `<span style=\"display:inline-block;margin-right:4px;border-radius:0px;width:10px;height:10px;background-color:${bgc};\"></span>`;

                  // str += `${node.marker}${name}：${value}`;
                  if (value == 0) {
                    str += "";
                  } else {
                    str += `${marker}${name}：${value}`;
                    str += "<br>";
                  }
                  return str;
                } else {
                  bpercentage =
                    unitname3 == "pct"
                      ? true
                      : unitname2 == "mil"
                      ? false
                      : true;
                  let value = node.value;
                  let isZero = value ? false : true;
                  if (bpercentage && unitname3 == "pct") {
                    value =
                      (value * 100).toFixed(fnGetMuchDecimals(value * 100)) +
                      "%";
                  } else {
                    value = Number(value).toFixed(fnGetMuchDecimals(value));
                  }

                  let bgc = colors[(index - 6) % 4].value;

                  let marker = `<span style=\"display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${bgc};\"></span>`;

                  if (isZero) {
                    str += "";
                  } else {
                    str += `${marker}${name}：${value}`;
                    str += "<br>";
                  }
                  return str;

                  // str += `${marker}${name}：${value}`;
                  // str += "<br>";
                  // return str;
                }
              });
              return str;
            },
          },

          dataZoom: {
            // 开启数据平移qc
            type: "slider", // 滑动条单独显示
            show: true, // 是否显示滑动条
            startValue: 0, // 展示区域内容的起始数值
            endValue: endValue, // 展示区域内容的结束数值 当前展示x坐标下标为0-16
            height: this.ROOTFONTFIZE * (5 / 144), // 滑动条组件高度
            bottom: this.ROOTFONTFIZE * (20 / 144), // 距离图表区域下边的距离
            showDetail: false, // 拖拽时是否显示详情
            showDataShadow: false,
            fillerColor: "#D4D4D4", // 平移条的填充颜色
            borderColor: "transparent", // 边框颜色
            zoomLock: true, // 锁定视图
            brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
            // 通过该属性可以只滑动，不显示缩放功能
            handleStyle: {
              opacity: 0,
            },
            lineStyle: {
              opacity: 0,
            },
            textStyle: {
              fontSize: 0,
            },
          },

          xAxis: [
            {
              type: "category",
              data: dReitsBenIndex2_X,

              axisLine: {
                show: true,
                onZero: false, //轴线是否在0刻度轴上
                // onZero: true, //轴线是否在0刻度轴上
              },

              axisTick: {
                show: true,
              },
              axisLabel: {
                interval: 0,
                fontSize: this.ROOTFONTFIZE * (14 / 144),
                margin: this.ROOTFONTFIZE * (20 / 144),
                show: true,
                rotate: 10,
                padding: [0, this.ROOTFONTFIZE * (-30 / 144), 0, 0],
                showMaxLabel: true,
                color: OPTIONDATA.xAxis.axisLabel.color,
              },
            },
            {
              type: "category",
              data: dReitsBenIndex2_X,

              axisLine: {
                show: false,
                onZero: false, //轴线是否在0刻度轴上
                // onZero: true, //轴线是否在0刻度轴上
              },

              axisTick: {
                show: false,
              },
              axisLabel: {
                interval: 0,
                fontSize: this.ROOTFONTFIZE * (14 / 144),
                margin: this.ROOTFONTFIZE * (10 / 144),
                show: false,
                showMaxLabel: true,
                color: OPTIONDATA.xAxis.axisLabel.color,
              },
            },
          ],

          yAxis: [
            {
              // name: bpercentageLeft ? "单位(%)" : "单位(百万)",
              name: bpercentageLeft
                ? unitname2 == "pct"
                  ? "单位(%)"
                  : "单位(无)"
                : "单位(百万)",
              nameTextStyle: {
                color: OPTIONDATA.yAxis.nameTextStyle.color,
                fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
              },
              nameGap: OPTIONDATA.yAxis.nameGap,
              scale: true,
              type: "value",
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
                //x轴刻度相关设置
                alignWithLabel: true,
              },
              axisLabel: {
                fontSize: this.ROOTFONTFIZE * (14 / 144),
                show: true,
                color: OPTIONDATA.yAxis.axisLabel.color,
                formatter: function (args) {
                  if (bpercentageLeft) {
                    if (unitname2 == "pct") {
                      return Number(args * 100).toFixed(1) + "%";
                    } else {
                      return Number(args).toFixed(1);
                    }
                  } else {
                    return args;
                  }
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: OPTIONDATA.splitLine.lineStyle.color,
                },
              },
            },
            {
              // name: bpercentageRight ? "单位(%)" : "单位(百万)",
              name: bpercentageRight
                ? unitname3 == "pct"
                  ? "单位(%)"
                  : "单位(无)"
                : "单位(百万)",
              nameTextStyle: {
                color: OPTIONDATA.yAxis.nameTextStyle.color,
                fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
              },
              nameGap: OPTIONDATA.yAxis.nameGap,
              scale: true,
              type: "value",
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
                //x轴刻度相关设置
                alignWithLabel: true,
              },
              axisLabel: {
                fontSize: this.ROOTFONTFIZE * (14 / 144),
                show: true,
                color: OPTIONDATA.yAxis.axisLabel.color,
                formatter: function (args) {
                  if (bpercentageRight) {
                    if (unitname3 == "pct") {
                      return Number(args * 100).toFixed(1) + "%";
                    } else {
                      return Number(args).toFixed(1);
                    }
                  } else {
                    return args;
                  }
                },
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: "dashed",
                  color: OPTIONDATA.splitLine.lineStyle.color,
                },
              },
            },
          ],

          series: seriesArr,
        };

        // 下载想要的
        this.chartColumn4.setOption(option2);

        // 下载
        this.chartColumn4_download.setOption(option2);
        let option3 = {
          dataZoom: {
            show: false,
            endValue: dReitsBenIndex2_X.length - 1,
          },
        };
        this.chartColumn4_download.setOption(option3);
      }

      this.chartColumn4.resize();
      this.fnTable_jiaochafenxi();
    },

    // 表格
    fnTable_jiaochafenxi() {
      this.loading = false

      let data1 = this.originalData1;
      let data2 = this.originalData2;

      // 代码  名称 大类 类型   报告期
      let FINALLDATA = [];
      let targetData_jiaochafenxi1 = this.targetData_jiaochafenxi1;
      let targetData_jiaochafenxi2 = this.targetData_jiaochafenxi2;

      let CODENAME = this.$store.state.CODENAME;

      data1.forEach((node) => {
        // let name2 = node['factor_name']
        //     name2 = targetData_jiaochafenxi1.filter(node=>{
        //       return node.value == name2
        //     })
        let name2 = this.titleLeft;
        let obj = {
          代码: node.reit_code,
          名称: CODENAME[node.reit_code],
          大类: node.REITs_type_L1,
          类型: node.REITs_type_L2,
          报告期: node.rp_period,
          // name:node['data']
        };

        let bpercentage =
          node["data"].toString().split(".")[1].length > 7 ? true : false;

        if (bpercentage) {
          obj[name2] = (Number(node["data"]) * 100).toFixed(2) + "%";
        } else {
          obj[name2] = node["data"];
        }

        FINALLDATA.push(obj);
      });

      // 添加第二张图
      FINALLDATA.forEach((node) => {
        let arr = data2.filter((item) => {
          return (
            node["代码"] == item["reit_code"] &&
            node["报告期"] == item["rp_period"]
          );
        });

        if (arr.length > 0) {
          let val2 = arr[0];
          //  let name =  arr[0]['factor_name']
          //     name = targetData_jiaochafenxi2.filter(node=>{
          //       return node.value == name
          //     })
          let name = this.titleRight;

          let bpercentage =
            val2["data"].toString().split(".")[1].length > 7 ? true : false;

          if (bpercentage) {
            node[name] = (Number(val2["data"]) * 100).toFixed(2) + "%";
          } else {
            node[name] = val2["data"];
          }
        }
      });

      this.jiaochafenxi_table = FINALLDATA;
      this.jiaochafenxi_table_json_fields = {
        大类: "大类",
        类型: "类型",
        名称: "名称",
        代码: "代码",
      };

      this.jiaochafenxi_table_json_fields[this.titleLeft] = this.titleLeft;
      this.jiaochafenxi_table_json_fields[this.titleRight] = this.titleRight;
      this.jiaochafenxi_table_json_fields["报告期"] = "报告期";

      // 排序
      let orderData = JSON.parse(localStorage.getItem("ORDER"));
      let TMP = [];
      orderData.forEach((node) => {
        let obj = this.jiaochafenxi_table.filter(
          (item) => item["类型"] == node
        );
        if (obj.length > 0) {
          obj.forEach((item) => {
            TMP.push(item);
          });
        }
      });

      this.jiaochafenxi_table = TMP;
    },

    // 交叉分析 end ----------------------------------------------

    initChart3(FINALLDATA) {
      return;
      this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      this.chartColumn4.clear();

      let colors = [
        {
          name: "2023Q4",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "2023Q3",
          value: "rgba(114, 113, 211, 1)",
        },
        {
          name: "2023Q2",
          value: "rgba(142, 141, 220, 1)",
        },
        {
          name: "2023Q1",
          value: "rgba(171, 170, 229, 1)",
        },
        {
          name: "2022Q4",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "2022Q3",
          value: "rgba(117, 162, 209, 1)",
        },
        {
          name: "2022Q2",
          value: "rgba(144, 180, 218, 1)",
        },

        {
          name: "2022Q1",
          value: "rgba(172, 199, 227, 1)",
        },
        {
          name: "2021Q4",
          value: "rgba(90, 202, 198, 1)",
        },
        {
          name: "2021Q3",
          value: "rgba(117, 211, 208, 1)",
        },
        {
          name: "2021Q2",
          value: "rgba(145, 220, 217, 1)",
        },
        {
          name: "2021Q1",
          value: "rgba(173, 228, 227, 1)",
        },
      ];

      let seriesArr = FINALLDATA.map((v, index) => {
        return {
          // name: v.name,
          // type: "bar",
          // data: v.data,
          // barWidth: "5px",
          //  name: v[0],
          data: [[v[0], v[1]]],
          type: "scatter",
          yAxisIndex: 1,
          symbolSize: 13,
        };
      });

      let option = {
        legend: {
          show: true,
          orient: "horizontal",
          left: 30,
          top: 20,
          align: "left",
          // x:50,
          // x2: 150,
          icon: "roundRect",
          itemGap: 15,
          itemWidth: 12,
          itemHeight: 12,
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },

          data: name,
          // color: ['#c23531','#5754f7','#a84d38','#ff7432']
        },

        xAxis: {
          type: "category",
          data: dReitsBenIndex2_X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
            // onZero: true, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            margin: 10,
            show: true,
            showMaxLabel: true,
            color: "rgba(218, 218, 218, 1)",
          },
        },
        yAxis: {
          scale: true,
          // type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            color: "rgba(243, 246, 247, 0.84)",
            // formatter: function(val){
            //   console.log(val,'val1');
            //   val = Number(val) / 1000000
            //   if(val == 0){
            //     return 0
            //   }else{
            //     return val + "M"
            //   }
            // }
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#48494c",
            },
          },
        },
        series: seriesArr,
      };

      this.chartColumn4.setOption(option);
      this.chartColumn4.resize();
      this.loading = false;
      // this.funResize()
    },

    // 表格2
    // 是否要去掉
    async fnGetFinDataWaterfall() {
      let r_type = "产业园";
      let rp_period_start = "2023Q1";
      let rp_period_end = "2023Q2";
      let mode = "by_period";
      let level = "L2";

      const { data } = await this.$https.get(
        "/api/get_fin_data_waterfall?r_type=" +
          r_type +
          "&rp_period_start=" +
          rp_period_start +
          "&rp_period_end=" +
          rp_period_end +
          "&mode=" +
          mode +
          "&level=" +
          level
      );

      if (data.code !== 200) return this.$message.error(data.msg);
      this.dFinDataWaterfall = data.data;
    },

    // 表格2
    async fnGetFinDataSnapshot() {
      let hide = this.concealChecked ? 1 : 0;

      const { data } = await this.$https.get(
        "/api/get_fin_data_snapshot?hide=" + hide
      );

      if (data.code !== 200) return this.$message.error(data.msg);
      this.dFinDataSnapshot = data.data;
      let last_quarter = data.last_quarter;

      let name = this.dFinDataSnapshot.map((node) => {
        return node["名称"];
      });
      name = [...new Set(name)];

      this.period = last_quarter;
      // localStorage.setItem('period',period)

      this.title = "财报分析 " + this.period;
      let target_ZSR = [];
      let target_EBITDA = [];
      let target_KFPJE = [];
      let target_FHHBL = [];

      // 1. 判断是否有上一季度
      let dFinDataSnapshot_previous = [];
      let dFinDataSnapshot_now = [];
      if (!hide) {
        dFinDataSnapshot_previous = this.dFinDataSnapshot.filter(
          (node, index) => {
            return node.rp_period !== last_quarter;
          }
        );

        dFinDataSnapshot_now = this.dFinDataSnapshot.filter((node, index) => {
          return node.rp_period == last_quarter;
        });

        // dFinDataSnapshot_previous.forEach((node) => {
        //   let idx = this.dFinDataSnapshot.indexOf(node);
        //   this.dFinDataSnapshot.splice(idx, 1);
        // });

        // 拿到所有的股票代码项
        let code = this.dFinDataSnapshot.map((node) => {
          return node["代码"];
        });
        code = [...new Set(code)];

        let tmparr = [];

        code.forEach((node) => {
          let obj = dFinDataSnapshot_now.find((item) => {
            return item["代码"] == node;
          });

          let obj2 = {};

          let obj3 = dFinDataSnapshot_previous.find((item) => {
            return item["代码"] == node;
          });

          // 1. 先存共有的
          if (obj) {
            obj2["代码"] = obj["代码"];
            obj2["名称"] = obj["名称"];
            obj2["资产大类"] = obj["资产大类"];
            obj2["资产细分"] = obj["资产细分"];
          } else {
            obj2["代码"] = obj3["代码"];
            obj2["名称"] = obj3["名称"];
            obj2["资产大类"] = obj3["资产大类"];
            obj2["资产细分"] = obj3["资产细分"];
          }

          // 2. 再存现在的
          if (obj) {
            obj2["EBITDA"] = obj["EBITDA"];
            obj2["分红回报率"] = obj["分红回报率"];
            obj2["可分派金额"] = obj["可分派金额"];
            obj2["总收入"] = obj["总收入"];
          } else {
            obj2["EBITDA"] = "";
            obj2["分红回报率"] = "";
            obj2["可分派金额"] = "";
            obj2["总收入"] = "";
          }

          // 3. 再存上一季的
          if (obj3) {
            obj2["EBITDA_prev"] = obj3["EBITDA"];
            obj2["可分派金额_prev"] = obj3["可分派金额"];
            obj2["总收入_prev"] = obj3["总收入"];
            obj2["分红回报率_prev"] = obj3["分红回报率"];
          } else {
            obj2["EBITDA_prev"] = "";
            obj2["可分派金额_prev"] = "";
            obj2["总收入_prev"] = "";
            obj2["分红回报率_prev"] = "";
          }

          tmparr.push(obj2);
        });

        this.dFinDataSnapshot = tmparr;

        // 计算上一季度

        let EBITDA_prev = this.dFinDataSnapshot.map((node) => {
          return node["EBITDA_prev"];
        });

        EBITDA_prev = EBITDA_prev.filter((node) => {
          return node !== undefined;
        });

        let KFPJE_prev = this.dFinDataSnapshot.map((node) => {
          return node["可分派金额_prev"];
        });

        KFPJE_prev = KFPJE_prev.filter((node) => {
          return node !== undefined;
        });

        let ZSR_prev = this.dFinDataSnapshot.map((node) => {
          return node["总收入_prev"];
        });

        ZSR_prev = ZSR_prev.filter((node) => {
          return node !== undefined;
        });

        let FHHBL_prev = this.dFinDataSnapshot.map((node) => {
          return node["分红回报率_prev"];
        });

        FHHBL_prev = FHHBL_prev.filter((node) => {
          return node !== undefined;
        });

        let max_EBITDA_prev = Math.max(...EBITDA_prev);
        let max_KFPJE_prev = Math.max(...KFPJE_prev);

        let max_ZSR_prev = Math.max(...ZSR_prev);
        let max_FHHBL_prev = Math.max(...FHHBL_prev);

        target_ZSR.push(max_ZSR_prev);
        target_EBITDA.push(max_EBITDA_prev);
        target_KFPJE.push(max_KFPJE_prev);
        target_FHHBL.push(max_FHHBL_prev);
      }

      let EBITDA = this.dFinDataSnapshot.map((node) => {
        return node.EBITDA;
      });

      let KFPJE = this.dFinDataSnapshot.map((node) => {
        return node["可分派金额"];
      });

      let ZSR = this.dFinDataSnapshot.map((node) => {
        return node["总收入"];
      });

      let FHHBL = this.dFinDataSnapshot.map((node) => {
        return node["分红回报率"];
      });

      let max_EBITDA = Math.max(...EBITDA);
      let max_KFPJE = Math.max(...KFPJE);
      let max_ZSR = Math.max(...ZSR);
      let max_FHHBL = Math.max(...FHHBL);

      target_ZSR.push(max_ZSR);
      target_EBITDA.push(max_EBITDA);
      target_KFPJE.push(max_KFPJE);
      target_FHHBL.push(max_FHHBL);

      max_EBITDA = Math.max(...target_EBITDA);
      max_KFPJE = Math.max(...target_KFPJE);
      max_ZSR = Math.max(...target_ZSR);
      max_FHHBL = Math.max(...target_FHHBL);

      // --------------------------------------------
      if (!hide) {
        this.dFinDataSnapshot.forEach((e) => {
          let node_EBITDA_prev = "";
          let val =
            Number(e["EBITDA_prev"]) > 0
              ? Number(e["EBITDA_prev"]) / max_EBITDA
              : 0;
          val = (Number(val) * 100).toFixed(0);
          node_EBITDA_prev = val + "px";
          e.node_EBITDA_prev = node_EBITDA_prev;

          let node_KFPJE_prev = "";
          let val2 =
            Number(e["可分派金额_prev"]) > 0
              ? Number(e["可分派金额_prev"]) / max_KFPJE
              : 0;
          val2 = (Number(val2) * 100).toFixed(0);
          node_KFPJE_prev = val2 + "px";
          e.node_KFPJE_prev = node_KFPJE_prev;

          let node_ZSR_prev = "";
          let val3 =
            Number(e["总收入_prev"]) > 0
              ? Number(e["总收入_prev"]) / max_ZSR
              : 0;
          val3 = (Number(val3) * 100).toFixed(0);
          node_ZSR_prev = val3 + "px";
          e.node_ZSR_prev = node_ZSR_prev;

          let node_FHHBL_prev = "";
          let val4 =
            Number(e["分红回报率_prev"]) > 0
              ? Number(e["分红回报率_prev"]) / max_FHHBL
              : 0;
          val4 = (Number(val4) * 100).toFixed(0);
          node_FHHBL_prev = val4 + "px";
          e.node_FHHBL_prev = node_FHHBL_prev;
        });
      }

      //  --------------------------------------------

      this.dFinDataSnapshot.forEach((e) => {
        let node_EBITDA = "";
        let val =
          Number(e["EBITDA"]) > 0 ? Number(e["EBITDA"]) / max_EBITDA : 0;
        val = (Number(val) * 100).toFixed(0);
        node_EBITDA = val + "px";
        e.node_EBITDA = node_EBITDA;

        let node_KFPJE = "";
        let val2 =
          Number(e["可分派金额"]) > 0 ? Number(e["可分派金额"]) / max_KFPJE : 0;
        val2 = (Number(val2) * 100).toFixed(0);
        node_KFPJE = val2 + "px";
        e.node_KFPJE = node_KFPJE;

        let node_ZSR = "";
        let val3 = Number(e["总收入"]) > 0 ? Number(e["总收入"]) / max_ZSR : 0;
        val3 = (Number(val3) * 100).toFixed(0);
        node_ZSR = val3 + "px";
        e.node_ZSR = node_ZSR;

        let node_FHHBL = "";
        let val4 =
          Number(e["分红回报率"]) > 0 ? Number(e["分红回报率"]) / max_FHHBL : 0;
        val4 = (Number(val4) * 100).toFixed(0);
        node_FHHBL = val4 + "px";
        e.node_FHHBL = node_FHHBL;
      });

      // 排序 start
      // 1. 拿到 资产细分
      let assetsSubdivide = this.dFinDataSnapshot.map((node) => {
        return node["资产细分"];
      });

      assetsSubdivide = [...new Set(assetsSubdivide)];

      let orderData = JSON.parse(localStorage.getItem("ORDER"));
      let TMP = [];
      orderData.forEach((node) => {
        let obj = assetsSubdivide.filter((item) => item == node);
        if (obj.length > 0) {
          obj.forEach((item) => {
            TMP.push(item);
          });
        }
      });

      assetsSubdivide = TMP;

      let tmp = [];
      assetsSubdivide.forEach((node) => {
        let arr = this.dFinDataSnapshot.filter((item) => {
          return item["资产细分"] == node;
        });

        arr.forEach((ele) => {
          tmp.push(ele);
        });
      });

      this.dFinDataSnapshot = tmp;
      // 排序 end
    },

    fnClick(row, column, cell) {
      let arr = [row["名称"], row["代码"]];
      this.$eventBus.$emit("skipPage", arr);
    },
    fnClick2(row, column, cell) {
      let arr = [row["name"], row["reit_code"]];
      this.$eventBus.$emit("skipPage", arr);
    },

    // 瀑布图
    fnSelect_select_val_chart3_start(val) {
      let flag2 = fnJudgePeriodOrder(
        val,
        this.factor_chart3_end,
        this.periodData
      );
      if (!flag2) return this.$message.error("结束报告期不能小于开始报告期");
      let flag = fnJudgePeriodRational(
        val,
        this.factor_chart3_end,
        this.periodData
      );
      if (!flag) return this.$message.error("报告期之间的间隔不能大于6个！");

      this.factor_chart3_start = val;
      this.$refs.period_waterfall1.value = this.factor_chart3_start;

      this.fnGetFinDataWaterfall2();
    },
    fnSelect_select_val_chart3_end(val) {
      let flag2 = fnJudgePeriodOrder(
        this.factor_chart3_start,
        val,
        this.periodData
      );
      if (!flag2) return this.$message.error("结束报告期不能小于开始报告期");
      let flag = fnJudgePeriodRational(
        this.factor_chart3_start,
        val,
        this.periodData
      );
      if (!flag) return this.$message.error("报告期之间的间隔不能大于6个！");

      this.factor_chart3_end = val;
      this.$refs.period_waterfall2.value = this.factor_chart3_end;

      this.fnGetFinDataWaterfall2();
    },
    fnCCascader_select_val_chart3(val) {
      this.category_cascader_val_chart3 = val;
      this.fnGetFinDataWaterfall2();
    },
    fnGetCbtnsSep_chart3_one(val) {
      if (val == "分业态") {
        this.bchart3_two = true;
      } else {
        this.bchart3_two = false;
      }
      this.dButtons_separrate_current_chart3_one = val;
      this.fnGetFinDataWaterfall2();
    },
    fnGetCbtnsSep_chart3_two(val) {
      this.dButtons_separrate_current_chart3_two = val;
      this.fnGetFinDataWaterfall2();
    },

    async fnGetFinDataWaterfall2() {
      let r_type = this.category_cascader_val_chart3;
      // let str = r_type.join("&r_type=");
      let rp_period_start = this.factor_chart3_start;
      let rp_period_end = this.factor_chart3_end;
      let mode =
        this.dButtons_separrate_current_chart3_one == "分报告期"
          ? "by_period"
          : "by_type";
      let level =
        this.dButtons_separrate_current_chart3_two == "资产大类" ? "L1" : "L2";

      const { data } = await this.$https.get(
        "/api/get_fin_data_waterfall?r_type=" +
          r_type +
          "&rp_period_start=" +
          rp_period_start +
          "&rp_period_end=" +
          rp_period_end +
          "&mode=" +
          mode +
          "&level=" +
          level
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dFinDataWaterfall2 = data.data;
      this.dFinDataWaterfall2_X = [];
      this.dFinDataWaterfall2_Y1 = [];
      this.dFinDataWaterfall2_Y2 = [];

      let dataTMP10 = JSON.parse(JSON.stringify(this.dFinDataWaterfall2));
      let dataTMP11 = JSON.parse(JSON.stringify(this.dFinDataWaterfall2));

      this.fnTableWater(dataTMP11);

      //客户要求的
      let dataTmp2 = data.data;
      let FINALLDATA = [];
      if (mode == "by_period") {
        dataTmp2.forEach((node, index) => {
          let s1 = [];
          let s2 = [];
          let rp_period = node.rp_period;

          delete node.rp_period;

          s2 = Object.values(node);

          // 计算出s1
          s1[0] = 0;
          s1[1] =
            Number(s2[1]) > 0 ? Number(s2[0]) : Number(s2[0]) + Number(s2[1]);
          s1[2] = 0;
          s1[3] =
            Number(s2[3]) > 0 ? Number(s2[2]) : Number(s2[2]) + Number(s2[3]);
          s1[4] = 0;
          s1[5] =
            Number(s2[5]) > 0 ? Number(s2[4]) : Number(s2[4]) + Number(s2[5]);
          s1[6] = 0;

          s2 = s2.map((item) => {
            return Math.abs(item);
          });

          let obj = {
            s1: s1,
            s2: s2,
            name: rp_period,
          };

          FINALLDATA.push(obj);
        });
      }

      if (mode == "by_type" && level == "L1") {
        dataTmp2.forEach((node, index) => {
          let s1 = [];
          let s2 = [];
          let REITs_type_L1 = node.REITs_type_L1;

          delete node.REITs_type_L1;

          s2 = Object.values(node);

          // 计算出s1
          s1[0] = 0;
          s1[1] =
            Number(s2[1]) > 0 ? Number(s2[0]) : Number(s2[0]) + Number(s2[1]);
          s1[2] = 0;
          s1[3] =
            Number(s2[3]) > 0 ? Number(s2[2]) : Number(s2[2]) + Number(s2[3]);
          s1[4] = 0;
          s1[5] =
            Number(s2[5]) > 0 ? Number(s2[4]) : Number(s2[4]) + Number(s2[5]);
          s1[6] = 0;

          s2 = s2.map((item) => {
            return Math.abs(item);
          });

          let obj = {
            s1: s1,
            s2: s2,
            name: REITs_type_L1,
          };

          FINALLDATA.push(obj);
        });
      }

      if (mode == "by_type" && level == "L2") {
        dataTmp2.forEach((node, index) => {
          let s1 = [];
          let s2 = [];
          let REITs_type_L2 = node.REITs_type_L2;

          delete node.REITs_type_L2;

          s2 = Object.values(node);

          // 计算出s1
          s1[0] = 0;
          s1[1] =
            Number(s2[1]) > 0 ? Number(s2[0]) : Number(s2[0]) + Number(s2[1]);
          s1[2] = 0;
          s1[3] =
            Number(s2[3]) > 0 ? Number(s2[2]) : Number(s2[2]) + Number(s2[3]);
          s1[4] = 0;
          s1[5] =
            Number(s2[5]) > 0 ? Number(s2[4]) : Number(s2[4]) + Number(s2[5]);
          s1[6] = 0;

          s2 = s2.map((item) => {
            return Math.abs(item);
          });

          let obj = {
            s1: s1,
            s2: s2,
            name: REITs_type_L2,
          };

          FINALLDATA.push(obj);
        });
      }

      let colors = [
        {
          name: "gross_yield",
          // data: [
          //   "rgba(69, 67, 69, 1)",
          //   "rgba(73, 71, 73, 1)",
          //   "rgba(93, 91, 93, 1)",
          //   "rgba(114, 111, 114, 1)",
          //   "rgba(134, 132, 134, 1)",
          //   "rgba(154, 152, 154, 1)",
          // ],
          data: [
            "rgba(57, 221, 227, 1)",
            "rgba(85, 226, 231, 1)",
            "rgba(114, 231, 235, 1)",
            "rgba(142, 236, 239, 1)",
            "rgba(170, 240, 243, 1)",
            "rgba(198, 245, 247, 1)",
          ],
          value: "#343334",
        },
        {
          name: "opex_损耗",
          data: [
            "rgba(255, 82, 41, 1)",
            "rgba(250, 121, 92, 1)",
            "rgba(255, 172, 153, 1)",
            "rgba(250, 201, 190, 1)",
            "rgba(252, 230, 225, 1)",
            "rgba(252, 230, 225, 1)",
          ],
          value: "#ff5229",
        },
        {
          name: "EBITDA_yield",
          // data: [
          //   "rgba(69, 67, 69, 1)",
          //   "rgba(93, 91, 93, 1)",
          //   "rgba(114, 111, 114, 1)",
          //   "rgba(134, 132, 134, 1)",
          //   "rgba(154, 152, 154, 1)",
          //   "rgba(174, 173, 174, 1)",
          // ],
          data: [
            "rgba(57, 221, 227, 1)",
            "rgba(85, 226, 231, 1)",
            "rgba(114, 231, 235, 1)",
            "rgba(142, 236, 239, 1)",
            "rgba(170, 240, 243, 1)",
            "rgba(198, 245, 247, 1)",
          ],
          value: "#494749",
        },
        {
          name: "EBITDA后调整项目",
          data: [
            "rgba(54, 194, 245, 1)",
            "rgba(137, 220, 250, 1)",
            "rgba(167, 230, 252, 1)",
            "rgba(187, 246, 252, 1)",
            "rgba(199, 234, 242, 1)",
            "rgba(237, 248, 250, 1)",
          ],
          value: "#36c2f5",
        },
        {
          name: "div_yield(base_on_nav)",
          // data: [
          //   "rgba(69, 67, 69, 1)",
          //   "rgba(93, 91, 93, 1)",
          //   "rgba(114, 111, 114, 1)",
          //   "rgba(134, 132, 134, 1)",
          //   "rgba(154, 152, 154, 1)",
          //   "rgba(174, 173, 174, 1)",
          // ],
          data: [
            "rgba(57, 221, 227, 1)",
            "rgba(85, 226, 231, 1)",
            "rgba(114, 231, 235, 1)",
            "rgba(142, 236, 239, 1)",
            "rgba(170, 240, 243, 1)",
            "rgba(198, 245, 247, 1)",
          ],
          value: "#494749",
        },
        {
          name: "市场价格调整",
          data: [
            "rgba(255, 82, 41, 1)",
            "rgba(250, 121, 92, 1)",
            "rgba(255, 172, 153, 1)",
            "rgba(250, 201, 190, 1)",
            "rgba(250, 217, 210, 1)",
            "rgba(252, 230, 225, 1)",
          ],
          value: "#ff5229",
        },
        {
          name: "div_yield",
          // data: [
          //   "rgba(69, 67, 69, 1)",
          //   "rgba(73, 71, 73, 1)",
          //   "rgba(93, 91, 93, 1)",
          //   "rgba(114, 111, 114, 1)",
          //   "rgba(134, 132, 134, 1)",
          //   "rgba(154, 152, 154, 1)",
          // ],
          data: [
            "rgba(57, 221, 227, 1)",
            "rgba(85, 226, 231, 1)",
            "rgba(114, 231, 235, 1)",
            "rgba(142, 236, 239, 1)",
            "rgba(170, 240, 243, 1)",
            "rgba(198, 245, 247, 1)",
          ],
          value: "#343334",
        },
      ];

      let colors2 = this.$store.state.colors;

      let flag = mode == "by_type" ? true : false;

      let color_Obj = null;
      if (flag) {
        let data = FINALLDATA.map((node) => {
          return node.name;
        });

        let objTMP = {
          data: [],
        };

        data.forEach((node) => {
          let val = colors2.filter((e) => {
            return e.name == node;
          });
          objTMP.data.push(val[0].value);
        });

        color_Obj = objTMP;
      } else {
        color_Obj = colors[0];
      }

      let SERIES_FINALLDATA = [];

      FINALLDATA.forEach((node, index) => {
        let series = [
          {
            name: "Placeholder",
            type: "bar",
            stack: "Total" + index,
            silent: true,
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
              // color: '#c25552'
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent",
              },
            },

            data: node.s1,
          },
          {
            name: node.name,
            type: "bar",
            stack: "Total" + index,

            data: node.s2,
            // barWidth: "10px",
            itemStyle: {
              normal: {
                color: function (params) {
                  if (flag) {
                    let seriesName = params.seriesName;
                    let name = params.name;

                    if (
                      name == "opex_损耗" ||
                      name == "EBITDA后调整项目" ||
                      name == "市场价格调整"
                    ) {
                      let node = dataTMP10.filter((node) => {
                        return (
                          node.REITs_type_L1 == seriesName ||
                          node.REITs_type_L2 == seriesName
                        );
                      });

                      let value = node[0][name];
                      if (value > 0) {
                        return "rgba(255, 82, 41, 1)";
                      } else {
                        return "rgba(54, 194, 245, 1)";
                      }
                    } else {
                      let obj = colors2.filter((node) => {
                        return node.name == seriesName;
                      });

                      return obj[0].value;
                    }
                  } else {
                    let name = params.name;
                    if (
                      name == "opex_损耗" ||
                      name == "EBITDA后调整项目" ||
                      name == "市场价格调整"
                    ) {
                      let seriesName = params.seriesName;
                      let node = dataTMP10.filter((node) => {
                        return node.rp_period == seriesName;
                      });

                      let value = node[0][name];
                      if (value > 0) {
                        return "rgba(255, 82, 41, 1)";
                      } else {
                        return "rgba(54, 194, 245, 1)";
                      }
                    } else {
                      let obj2 = {};
                      obj2 = colors.find((item) => {
                        return item.name == name;
                      });

                      return obj2.data[index];
                    }

                    return;

                    // 漂亮！！！
                  }
                },
              },
            },
          },
        ];
        SERIES_FINALLDATA.push(series);
      });

      SERIES_FINALLDATA = SERIES_FINALLDATA.flat();

      this.dFinDataWaterfall2_X = [
        "gross_yield",
        "opex_损耗",
        "EBITDA_yield",
        "EBITDA后调整项目",
        "div_yield(base_on_nav)",
        "市场价格调整",
        "div_yield",
      ];

      // 瀑布图init
      this.chart3_SERIES_FINALLDATA = SERIES_FINALLDATA;
      this.chart3_color_Obj = color_Obj;

      this.$nextTick(() => {
        this.initChart3();
      });

      return;

      // 之前的瀑布图（标准的）

      // let dataTmp2 = data.data;
      // let FINALLDATA = [];
      // dataTmp2.forEach((node, index) => {
      //   let s1 = [];
      //   let s2 = [];
      //   let s3 = [];

      //   for (let i in node) {
      //     if (i == "gross_yield") {
      //       s2.push(node[i]);
      //       s3.push("-");
      //     }

      //     if (i == "opex_损耗") {
      //       s3.push(Math.abs(node[i]));
      //       s2.push("-");
      //     }

      //     if (i == "EBITDA_yield") {
      //       s2.push(node[i]);
      //       s3.push("-");
      //     }

      //     if (i == "EBITDA后调整项目") {
      //       s3.push(Math.abs(node[i]));
      //       s2.push("-");
      //     }

      //     if (i == "div_yield(base on nav)") {
      //       s2.push(node[i]);
      //       s3.push("-");
      //     }

      //     if (i == "市场价格调整") {
      //       s3.push(Math.abs(node[i]));
      //       s2.push("-");
      //     }

      //     if (i == "div_yield") {
      //       s2.push(node[i]);
      //       s3.push("-");
      //     }
      //   }

      //   // 计算出s1
      //   s1[0] = 0;
      //   s1[1] = Number(s2[0]) - Number(s3[1]);
      //   s1[2] = s1[1];
      //   s1[3] = Number(s1[2]) + Number(s2[2]) - Number(s3[3]);
      //   s1[4] = s1[3];
      //   s1[5] = Number(s1[4]) + Number(s2[4]) - Number(s3[5]);
      //   s1[6] = s1[5];

      //   let obj = {
      //     s1: s1,
      //     s2: s2,
      //     s3: s3,
      //   };

      //   FINALLDATA.push(obj);
      // });

      // let colors = [
      //   {
      //     name: "gross_yield",
      //     value: "rgba(52, 51, 52, 1)",
      //   },
      //   {
      //     name: "opex_损耗",
      //     value: "rgba(86, 84, 202, 1)",
      //   },
      //   {
      //     name: "EBITDA_yield",
      //     value: "rgba(52, 51, 52, 1)",
      //   },
      //   {
      //     name: "EBITDA后调整项目",
      //     value: "rgba(86, 84, 202, 1)",
      //   },
      //   {
      //     name: "div_yield(base on nav)",
      //     value: "rgba(52, 51, 52, 1)",
      //   },
      //   {
      //     name: "市场价格调整",
      //     value: "rgba(194, 68, 146, 1)",
      //   },
      //   {
      //     name: "div_yield",
      //     value: "rgba(52, 51, 52, 1)",
      //   },
      // ];

      // let SERIES_FINALLDATA = [];

      // FINALLDATA.forEach((node, index) => {
      //   let series = [
      //     {
      //       name: "Placeholder",
      //       type: "bar",
      //       stack: "Total" + index,
      //       silent: true,
      //       itemStyle: {
      //         borderColor: "transparent",
      //         color: "transparent",
      //         // color: '#c25552'
      //       },
      //       emphasis: {
      //         itemStyle: {
      //           borderColor: "transparent",
      //           color: "transparent",
      //         },
      //       },

      //       data: node.s1,
      //     },
      //     {
      //       type: "bar",
      //       stack: "Total" + index,

      //       data: node.s2,
      //       barWidth: "10px",
      //       itemStyle: {
      //         normal: {
      //           color: function (params) {
      //             let name = params.name;
      //             let obj2 = {};
      //             obj2 = colors.find((item) => {
      //               return item.name == name;
      //             });
      //             return obj2.value;
      //           },
      //         },
      //       },
      //     },
      //     {
      //       type: "bar",
      //       stack: "Total" + index,
      //       barWidth: "10px",
      //       itemStyle: {
      //         normal: {
      //           color: function (params) {
      //             let name = params.name;
      //             let obj2 = {};
      //             obj2 = colors.find((item) => {
      //               return item.name == name;
      //             });
      //             return obj2.value;
      //           },
      //         },
      //       },
      //       data: node.s3,
      //     },
      //   ];
      //   SERIES_FINALLDATA.push(series);
      // });

      // SERIES_FINALLDATA = SERIES_FINALLDATA.flat();

      // this.dFinDataWaterfall2_X = [
      //   "gross_yield",
      //   "opex_损耗",
      //   "EBITDA_yield",
      //   "EBITDA后调整项目",
      //   "div_yield(base on nav)",
      //   "市场价格调整",
      //   "div_yield",
      // ];

      // this.dFinDataWaterfall2_s1 = s1;
      // this.dFinDataWaterfall2_s2 = s2;
      // this.dFinDataWaterfall2_s3 = s3;

      // 瀑布图完成

      // this.initChart3(SERIES_FINALLDATA);
    },
    initChart3(val) {
      if (!val) {
        this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      } else {
        this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3_dialog);
      }

      // this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      this.chartColumn3.clear();

      let SERIES_FINALLDATA = this.chart3_SERIES_FINALLDATA;
      let color_Obj = this.chart3_color_Obj;

      let X = this.dFinDataWaterfall2_X;

      let NAME = SERIES_FINALLDATA.map((node) => {
        return node.name;
      });

      NAME = [...new Set(NAME)];

      NAME.shift();

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let dFinDataWaterfall2 = this.dFinDataWaterfall2;

      let option = {
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let index = 0;
            let str = "";
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              if (node.seriesName !== "Placeholder") {
                let val = Number(node.value) * 100;
                let num = fnGetMuchDecimals(val);
                val = val.toFixed(num);

                let fuhao = "";
                fuhao = dFinDataWaterfall2[index][node.name] > 0 ? "" : "-";
                index++;
                str += "<br>";
                str += `${node.marker}${node.seriesName}：${fuhao}${val}%`;
              }

              return str;
            });
            return str;

            return;
          },
        },
        color: color_Obj.data,
        legend: {
          show: true,
          orient: "horizontal",
          left: this.ROOTFONTFIZE * (30 / 144),
          top: this.ROOTFONTFIZE * (20 / 144),
          align: "left",
          icon: "roundRect",
          itemGap: this.ROOTFONTFIZE * (15 / 144),
          itemWidth: this.ROOTFONTFIZE * (12 / 144),
          itemHeight: this.ROOTFONTFIZE * (12 / 144),
          padding: [0, 0, 0, 0],

          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
          data: NAME,
        },

        grid: {
          left: this.ROOTFONTFIZE * (20 / 144),
          right: this.ROOTFONTFIZE * (20 / 144),
          bottom: this.ROOTFONTFIZE * (55 / 144),
          top: this.ROOTFONTFIZE * (80 / 144),
          containLabel: true,
        },

        xAxis: {
          type: "category",
          data: X,
          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
            // onZero: true, //轴线是否在0刻度轴上
            lineStyle: {},
          },

          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            padding: [0, 0, 0, 0],
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          name: "单位(%)",
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (val) {
              if (val == 0) {
                return 0;
              } else {
                val = Number(val) * 100;
                val = val.toFixed(1);
                // val = val + "%";
                return val;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },

        series: SERIES_FINALLDATA,
      };

      this.chartColumn3.setOption(option);
      this.chartColumn3.resize();
      // this.funResize()
    },

    fnTableWater(data) {
      if (this.dButtons_separrate_current_chart3_one == "分报告期") {
        this.dFinDataWaterfall = data;
      } else {
        let start = this.factor_chart3_start;
        let end = this.factor_chart3_end;
        let rp_period = start + "至" + end;

        data.forEach((node) => {
          node["rp_period"] = rp_period;
        });

        this.dFinDataWaterfall = data;
      }

      // 赋值表格前几个
      // REITs_type_L2
      // REITs_type_L1
      // 1.first
      let keys = Object.keys(this.dFinDataWaterfall[0]);
      if (!keys.includes("REITs_type_L1") && !keys.includes("REITs_type_L2")) {
        this.dFinDataWaterfall.map((node) => {
          node["REITs_type_L1"] = this.category_cascader_val_chart3;
          node["代码"] = "一";
          node["名称"] = "一";
          node["REITs_type_L2"] = "一";
        });
      }

      if (keys.includes("REITs_type_L1") && !keys.includes("REITs_type_L2")) {
        this.dFinDataWaterfall.map((node) => {
          // node['REITs_type_L1'] = this.category_cascader_val_chart3
          node["代码"] = "一";
          node["名称"] = "一";
          node["REITs_type_L2"] = "一";
        });
      }

      if (!keys.includes("REITs_type_L1") && keys.includes("REITs_type_L2")) {
        let L1L2DUIBI = this.L1L2DUIBI;
        this.dFinDataWaterfall.map((node) => {
          node["REITs_type_L1"] = L1L2DUIBI[node["REITs_type_L2"]];
          node["代码"] = "一";
          node["名称"] = "一";
        });
      }

      let json_fields = {
        代码: "a",
        名称: "b",
        大类: "REITs_type_L1",
        类型: "REITs_type_L2",
        gross_yield: "gross_yield",
        opex_损耗: "opex_损耗",
        EBITDA_yield: "EBITDA_yield",
        EBITDA后调整项目: "EBITDA后调整项目",
        div_yield_base_on_nav: "div_yield(base on nav)",
        市场价格调整: "市场价格调整",
        div_yield: "div_yield",
        日期: "rp_period",
      };
      this.json_fields_water = json_fields;

      // 排序
      if (this.dFinDataWaterfall[0].REITs_type_L2 !== "一") {
        let orderData = JSON.parse(localStorage.getItem("ORDER"));
        let TMP = [];
        orderData.forEach((node) => {
          let obj = this.dFinDataWaterfall.filter(
            (item) => item["REITs_type_L2"] == node
          );
          if (obj.length > 0) {
            obj.forEach((item) => {
              TMP.push(item);
            });
          }
        });

        this.dFinDataWaterfall = TMP;
      }
    },

    handleClick(e) {
      this.activeName = e.name;
      localStorage.setItem("activeName", this.activeName);

      if (this.activeName == "first") {
        this.$eventBus.$emit("setBreadcrumbs", "概览");
      }

      if (this.activeName == "second") {
        this.$eventBus.$emit("setBreadcrumbs", "财务分析");
        this.fnGetReitsBenIndex();
      }
      if (this.activeName == "seventh") {
        this.$eventBus.$emit("setBreadcrumbs", "交叉分析");

        this.fnGetReitsBenIndex_jiaochafenxi_left();
      }
      if (this.activeName == "third") {
        this.$eventBus.$emit("setBreadcrumbs", "现金流量表");

        this.fnGetReitsBenIndex2();
      }
      if (this.activeName == "fourth") {
        this.$eventBus.$emit("setBreadcrumbs", "分派表");

        this.fnGetReitsBenIndex5_1();
      }
      if (this.activeName == "fivth") {
        this.$eventBus.$emit("setBreadcrumbs", "资产回报");

        this.fnGetReitsBenIndex6_1();
      }
      if (this.activeName == "sixth") {
        this.$eventBus.$emit("setBreadcrumbs", "静态倍数");

        this.fnGetReitsBenIndex7_1();
      }
      if (this.activeName == "eighth") {
        this.$eventBus.$emit("setBreadcrumbs", "Yield 传递推导");

        this.fnGetFinDataWaterfall2();
      }
    },

    // 以下是废弃的 start

    // 以下是废弃的 end
  },
  components: {
    CSelect,
    CSelectPeriod,
    CCheckbox,
    CDropDownSelects,
    CCategoryButton,
    CCascader,
    CButtonsDownload,
    CButtonsSeparate,
    CDialog,
    CButtonsDownloadSingle,
    // CButtonsDownload

    // FinancialData
  },
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
        "--TABPANEHEIGHT": this.TABPANEHEIGHT + "px",
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.styleBindFun();
      this.fnGetTheme();
      fnDownloadBgc();
      if (this.activeName == "second") {
        this.initChart1_1();
        this.initChart1_2();
        this.initChart1();
      }

      if (this.activeName == "seventh") {
        this.initChart2();
      }

      if (this.activeName == "eighth") {
        this.initChart3();
      }

      // this.fnGetReitsBenIndex();
      // this.fnGetReitsBenIndex_jiaochafenxi_left();
      // this.fnGetFinDataWaterfall2();
      return;

      this.initChart();
      this.initChart2();
      this.initChart3();
      this.initChart4();
      this.initChart5();
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  // padding-right: 45px;
}

// .main {
//   background-color: #1d1f25;
//   height: 90%;
// }

// el-tabs
.el-tabs {
  background-color: #141518;
  background-color: var(--bigger-card-bgc);
  // padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
}

::v-deep .el-tabs__item {
  color: var(--el_tabs_item_color);
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
}

::v-deep .el-tabs__item:hover {
  color: var(--el_tabs_item_color_active_hover) !important;
}

::v-deep .el-tabs__item.is-active {
  color: var(--el_tabs_item_color_active) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

/*去掉tabs底部的下划线*/
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/*去掉切换时el-tab-pane底部的蓝色下划线*/
::v-deep .el-tabs__active-bar {
  // background-color: #1573fe !important;
  background-color: var(--primary-color) !important;
}

::v-deep .el-tab-pane {
  display: block;
  height: var(--TABPANEHEIGHT);
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

// =============elmentui end ==========================================

.CH_Card_tit {
  font-size: calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  // border-left: 5px solid rgba(21, 115, 254, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));

  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  display: flex;
  justify-content: space-between;
}

.profit_card {
  // height: 560px;
}

.profit_card2 {
  height: calc(var(--ROOTFONTFIZE) * (750 / 144));
}

.profit_card3 {
  height: calc(var(--ROOTFONTFIZE) * (620 / 144));
}

.secondCard {
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  height: auto;
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.secondCard .secondCardTit {
  display: flex;
  justify-content: space-between;
  padding-right: calc(var(--ROOTFONTFIZE) * (35 / 144));
}

.secondCard div:nth-child(2) {
  padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.profit_card_conLeft {
}

.profit_card_conRight {
  padding-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
  box-sizing: border-box;
}

.profit_card_conRight div {
  display: flex;
  align-items: center;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
  // margin-top: 10px;
}

.profit_card_conRight div span:nth-child(2) {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);
  margin-left: calc(var(--ROOTFONTFIZE) * (15 / 144));
}

.target2 {
  border-bottom: 1px solid rgba(102, 102, 102, 1);
  padding-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.profit_card_conLeft {
  padding: calc(var(--ROOTFONTFIZE) * (10 / 144))
    calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.canvasArea1 {
  // background-color: #1a1c21;
  // border: 1px solid #4f5053;
  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  position: relative;
  // margin-left:20px;
  // margin-right:20px;
}
.tuli {
  position: absolute;
  top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  left: 50%;
  transform: translateX(-50%);
  color: var(--stock_area_item_color1);
  display: flex;
}

.tuli span {
  display: flex;
  align-items: center;
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144)) !important;
}

.zengjia {
  display: block;
  width: calc(var(--ROOTFONTFIZE) * (16 / 144));
  height: calc(var(--ROOTFONTFIZE) * (16 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  background-color: #ff5229;
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.jianshao {
  display: block;
  width: calc(var(--ROOTFONTFIZE) * (16 / 144));
  height: calc(var(--ROOTFONTFIZE) * (16 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #36c2f5;
}

.overfall {
  display: flex;
  justify-content: space-between;
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}
.el-icon-download {
  font-size: calc(var(--ROOTFONTFIZE) * (24 / 144));
  font-weight: 700;
}

.secondCon .el-checkbox {
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  padding-left: calc(var(--ROOTFONTFIZE) * (0 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  color: var(--stock_area_item_color1);
}

.Q3.el-select {
  margin-left: calc(var(--ROOTFONTFIZE) * (17 / 144));
  width: calc(var(--ROOTFONTFIZE) * (101 / 144));
  height: calc(var(--ROOTFONTFIZE) * (32 / 144));
  color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: calc(var(--ROOTFONTFIZE) * (5 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  box-sizing: border-box;
}

::v-deep .Q3.el-select .el-input__inner {
  background-color: #1d1f25 !important;
  border: none;
  padding: 0 !important;
  margin: 0 !important;
  height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (32 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 400;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (22 / 144));
  color: rgba(255, 255, 255, 0.65);
}

// el-table start

::v-deep .el-table {
  border-bottom: none !important;
  background-color: var(--table_bgc) !important;
}

::v-deep .el-table__body .el-table__row .el-table__cell {
  background-color: var(--table_bgc);
  color: var(--table_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  height: calc(var(--ROOTFONTFIZE) * (60 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid var(--table_brc);
}

::v-deep .table1 table {
  border: none;
}

::v-deep .el-table__body .el-table__row .el-table__cell {
  background-color: var(--table_bgc);
  color: var(--table_color);
  border-bottom: 1px solid var(--table_brc);
  height: calc(var(--ROOTFONTFIZE) * (60 / 144));
}

::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: var(--table_bgc_hover);
  color: var(--table_color_hover);
  cursor: pointer;
}

::v-deep .el-table::before {
  background-color: var(--table_bgc) !important;
}

::v-deep .el-table {
  border-bottom: none !important;
  background-color: #1d1f25;
  padding-bottom: 75px;
}

::v-deep .el-table__body-wrapper {
  height: 100% !important;
}

// el-table end

.autoArea {
  height: auto;
  width: 100%;
}

.thityCon {
  padding: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.value_area {
  display: flex;
  align-items: center;
  justify-content: start;
}

.a {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #6088e5;
}

.b {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #62c2e3;
}

.c {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: rgba(59, 79, 207, 1);
}

.d {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: rgba(255, 51, 0, 1);
}

.CH_Card_content {
  display: flex;
  align-items: center;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
  position: relative;
}

.cho_area {
  // background-color: pink;
}

.chartColumn4_title1 {
  color: var(--chart_title);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3%;
  letter-spacing: calc(var(--ROOTFONTFIZE) * (2 / 144));
  writing-mode: tb-rl;
  white-space: nowrap;
}

.chartColumn4_title2 {
  color: var(--chart_title);

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3%;
  letter-spacing: calc(var(--ROOTFONTFIZE) * (2 / 144));
  writing-mode: tb-rl;
  white-space: nowrap;
}

.chartColumn4_title3 {
  color: var(--chart_title);

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 6%;
}

.doubleChart {
  flex-direction: column;
}

.to {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
  color: var(--legndAreaItem_color);
  margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.period_style {
  margin-left: 0px !important;
}

.chart3_cascasder {
  margin-left: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.target_style {
  margin-right: calc(var(--ROOTFONTFIZE) * (50 / 144)) !important;
}

.period_style2 {
  margin-left: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.mr30 {
  margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.fenlan1 {
  display: flex;
}

.fenlan1_left {
  // flex: 1;
}

.fenlan1_right {
  // flex: 1;
  margin-left: calc(var(--ROOTFONTFIZE) * (50 / 144));
  display: flex !important;
  justify-content: flex-start !important;
}

.ds {
}
</style>
