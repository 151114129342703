<template>
  <div class="home" id="rootchart" :style="cssVars">
    <div class="title">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="REITs概况" name="first">
          <Overview :isFlag="isFlagFirst" ref="first" />
        </el-tab-pane>
        <el-tab-pane label="市场数据" name="second">
          <Marketdata :isFlag="isFlagSecond" ref="second" />
        </el-tab-pane>
        <el-tab-pane label="底层资产" name="third">
          <Underlyingasset ref="third" :isFlag="isFlagThird" />
        </el-tab-pane>
        <el-tab-pane label="财务报告" name="fourth">
          <Financialreports ref="fourth" :isFlag="isFlagFourth" />
        </el-tab-pane>
        <el-tab-pane label="估值分析" name="fivth">
          <Valuationanalysis ref="fivth" :isFlag="isFlagFivth" />
        </el-tab-pane>
        <el-tab-pane label="新闻公告" name="sixth">
          <Newsannouncement ref="sixth" :isFlag="isFlagSixth" />
        </el-tab-pane>
        <el-tab-pane label="股东分红" name="seventh">
          <Shareholderdividends ref="seventh" :isFlag="isFlagSeventh" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Overview from "@/components/IndividualShare/overview";
import Marketdata from "@/components/IndividualShare/marketdata";
import Underlyingasset from "@/components/IndividualShare/underlyingasset";
import Financialreports from "@/components/IndividualShare/financialreports";
import Valuationanalysis from "@/components/IndividualShare/valuationanalysis";
import Newsannouncement from "@/components/IndividualShare/Newsannouncement";
import Shareholderdividends from "@/components/IndividualShare/shareholderdividends";

export default {
  data() {
    return {
      activeName: "first",
      isFlagFirst: false,
      isFlagSecond: false,
      isFlagThird: false,
      isFlagFourth: false,
      isFlagFivth: false,
      isFlagSixth: false,
      isFlagSeventh: false,
      ROOTFONTFIZE: 0,
      TABPANEHEIGHT: 0,

    };
  },

  created() {
    let label = localStorage.getItem('activeLabel')
    console.log(label,'刘亦菲');
    this.$eventBus.$emit("setBreadcrumbs", label);
    let name = localStorage.getItem('activeName')
    this.$nextTick(()=>{
      this.handleClick({name:name})
    })
    this.$eventBus.$on("getIndividualShare", (data) => {
      this.handleClick({name:'first'});
    });

    // this.handleClick({name:name})
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener("resize", this.fnGetWidth);
  },
  methods: {
    // 获取当前屏幕宽度
    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem("ROOTFONTFIZE");
      this.TABPANEHEIGHT = localStorage.getItem("TABPANEHEIGHT");

      // var element = document.getElementById("rootchart");
      // let ROOTFONTFIZE = element.offsetWidth;
      // ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      // ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      // this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      // let OFFSETHEIGHT = element.offsetHeight;
      // this.TABPANEHEIGHT = OFFSETHEIGHT - 135;

      // var chartColumn1 = document.getElementById("chartColumn1");
      // chartColumn1.style.height = this.ROOTFONTFIZE * (371 / 144) + "px";

      // var chartColumn2 = document.getElementById("chartColumn2");
      // chartColumn2.style.height = this.ROOTFONTFIZE * (360 / 144) + "px";

      // var chartColumn4 = document.getElementById("chartColumn4");
      // chartColumn4.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      // var chartColumn5 = document.getElementById("chartColumn5");
      // chartColumn5.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      // var chartColumn3 = document.getElementById("chartColumn3");
      // chartColumn3.style.height = this.ROOTFONTFIZE * (358 / 144) + "px";

      return;
    },

    handleClick(e) {
      this.activeName = e.name;
      localStorage.setItem('activeName',this.activeName)
      localStorage.setItem('activeLabel',e.label)

      console.log(e,'e');

      if (this.activeName == "first") {
        this.$eventBus.$emit("setBreadcrumbs", "REITs概况");
        this.isFlagFirst = true;
        this.$refs.first.fnGetCbtns("概览");
        // this.$refs.second.fnGetFundflowPieTableReit();
        // this.$refs.second.fnGetFundflowPieTableReit2();
      }
      if (this.activeName == "second") {
        this.$eventBus.$emit("setBreadcrumbs", "市场数据");
        this.isFlagSecond = true;
        this.$refs.second.fnInit("资金流入情况");
        // this.$refs.second.fnGetFundflowPieTableReit2();
      }

      if (this.activeName == "third") {
        this.$eventBus.$emit("setBreadcrumbs", "底层资产");
        this.isFlagThird = true;
        this.$refs.third.fnGetCbtns("资产列表");
      }

      if (this.activeName == "fourth") {
        this.$eventBus.$emit("setBreadcrumbs", "财务报告");
        this.isFlagFourth = true;

        this.$refs.fourth.FnInit();
      }

      if (this.activeName == "fivth") {
        this.$eventBus.$emit("setBreadcrumbs", "估值分析");
        this.isFlagFivth = true;
        this.$refs.fivth.fnGetCbtns("估值概览");
        // this.$refs.fivth.FnInit();
      }
      if (this.activeName == "sixth") {
        this.$eventBus.$emit("setBreadcrumbs", "新闻公告");
        // this.isFlagSixth = true
        this.$refs.sixth.FnInit();
      }
      if (this.activeName == "seventh") {
        this.$eventBus.$emit("setBreadcrumbs", "股东分红");
        this.$refs.seventh.isFlag = true;
        this.$refs.seventh.fnGetCbtnsSep("历史分红");
        this.isFlagSeventh = true;
      }
    },
  },
  components: {
    Overview,
    Marketdata,
    Underlyingasset,
    Financialreports,
    Valuationanalysis,
    Newsannouncement,
    Shareholderdividends,
  },
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
        "--TABPANEHEIGHT": this.TABPANEHEIGHT + "px",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  background-color: var(--bigger-card-bgc);
  height: 100%;
}

// el-tabs
.el-tabs {
  background-color: var(--bigger-card-bgc);
  // padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
}

::v-deep .el-tabs__item {
  color: var(--el_tabs_item_color);
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
}

::v-deep .el-tabs__item:hover {
  color: var(--el_tabs_item_color_active_hover) !important;
}

::v-deep .el-tabs__item.is-active {
  color: var(--el_tabs_item_color_active) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

/*去掉tabs底部的下划线*/
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/*去掉切换时el-tab-pane底部的蓝色下划线*/
::v-deep .el-tabs__active-bar {
  // background-color: #1573fe !important;
  background-color: var(--primary-color) !important;
}

::v-deep .el-tab-pane {
  // background-color: #1573fe !important;
  display: block;
  height: var(--TABPANEHEIGHT);
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

// =============elmentui end ==========================================

.autoArea {
  height: auto;
  width: 100%;
}

.thityCon {
  padding: calc(var(--ROOTFONTFIZE) * (20 / 144));
}
</style>
