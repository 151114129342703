<template>
  <div id="rootchart" class="login_container" :style="cssVars">
    <!-- <div class="pink0">123</div>
    <div class="pink">123</div>
    <div class="pink2">123</div>  -->
    <div class="top">
      <div>
        <div class="title">
          <div class="logoImg">
            <img src="@/assets/logo.png" alt="" />
            <!-- <img src="https://img.js.design/assets/img/6576850c7da7a221644bf179.png#f864441313d760122bf8e9a01604b578" alt=""> -->
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div id="loginArea" class="loginArea">
        <div class="loginArea_tit">
          <div class="loginArea_tit_content">
            <div class="pic_area">
              <img src="@/assets/332211.png" alt="" />
            </div>
          </div>
        </div>
              <transition name="slide-fade">

        <div class="loginArea_main">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="登录" name="first">
              <el-form
                ref="loginFormRef"
                :model="loginForm"
                :rules="loginFormRules"
                label-width="0px"
                class="login_form"
              >
                <el-form-item prop="username">
                  <el-input
                    v-model="loginForm.username"
                    placeholder="请输入账号"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                    v-model="loginForm.password"
                    placeholder="请输入密码"
                    show-password
                  ></el-input>
                </el-form-item>
              </el-form>

              <div class="verificationCode">
                <span>
                  <el-checkbox v-model="checked">记住密码</el-checkbox>
                </span>

                <span> 验证码登录 </span>
              </div>

              <el-button class="loginBTN" @click="Login" type="primary"
                >登 录</el-button
              >

              <div class="loginInfo">
                <span>登录即代表同意</span> <span>《隐私权政策》</span>|
                忘记密码？
              </div>
            </el-tab-pane>

            <el-tab-pane label="注册" name="second">注册</el-tab-pane>
          </el-tabs>
        </div>
                  </transition>

      </div>
    </div>
  
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenRatio: 1,
      flag: false,

      codeImgSrc: "",
      codeImgResult: "",
      url: "",
      token: "",
      // 这是登录表单的数据绑定对象
      loginForm: {
        username: "",
        password: "",
      },
      verifiCode: "",
      // 这是表单的验证规则对象
      loginFormRules: {
        // 验证用户名是否合法
        username: [
          {
            required: true,
            message: "请输入登录名称",
            trigger: "blur",
          },
          // {
          //   min: 3,
          //   max: 10,
          //   message: "长度在 3 到 10 个字符",
          //   trigger: "blur",
          // },
        ],
        // 验证密码是否合法
        password: [
          {
            required: true,
            message: "请输入登录密码",
            trigger: "blur",
          },
          // {
          //   min: 6,
          //   max: 15,
          //   message: "长度在 6 到 15 个字符",
          //   trigger: "blur",
          // },
        ],
      },

      stateJudage: this.$route.query.data,
      checked: false,
      activeName: "second",

      OFFSETWIDTH: 300,
      OFFSETHEIGHT: 200,
      ROOTFONTFIZE: 0,
    };
  },
  beforeCreate() {},
  created() {
    // this.getCsrfToken()
    this.fnGetUnits()

    this.fnGetRatio();

    // this.getCodeImg()
    let obj = {
      name: "first",
    };
    this.handleClick(obj);
  },
  mounted() {
    this.fnGetWidth();
  },
  methods: {
    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById("rootchart");

      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;

      // ROOTFONTFIZE = ROOTFONTFIZE < 1900 ? ROOTFONTFIZE-200 : ROOTFONTFIZE;

      let screenRatio = Number(this.screenRatio) / 100;

      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      return;
      // var element2 = document.getElementById("leftArea");
      // var width2 = element2.offsetWidth;
      // // element2.style.height= (width * 0.77)+'px'
      // element.style.height= (width * 0.67)+'px'
      // element2.style.height= (width2 * 0.77)+'px'
    },

    //获取屏幕缩放比例
    fnGetRatio() {
      function getRatio() {
        var ratio = 0;
        var screen = window.screen;
        var ua = navigator.userAgent.toLowerCase();
        if (window.devicePixelRatio !== undefined) {
          ratio = window.devicePixelRatio;
        } else if (~ua.indexOf("msie")) {
          if (screen.deviceXDPI && screen.logicalXDPI) {
            ratio = screen.deviceXDPI / screen.logicalXDPI;
          }
        } else if (
          window.outerWidth !== undefined &&
          window.innerWidth !== undefined
        ) {
          ratio = window.outerWidth / window.innerWidth;
        }
        if (ratio) {
          ratio = Math.round(ratio * 100);
        }
        return ratio;
      }

      this.screenRatio = getRatio();

      localStorage.setItem("screenRatio", this.screenRatio);
    },

    handleClick(e) {
      this.activeName = e.name;
    },

    async Login() {
      // window.sessionStorage.setItem("activePath", "dashboard");
      // this.$router.push("/home");
      // return;

      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        let username = this.loginForm.username;
        let password = this.loginForm.password;

        const { data } = await this.$https.post(
          // "webapi/auth/customer/login?userName=" +
          "webapi/auth/customer/login?userName=" +
          // "webapi/auth/customer/login?userName=" +

            username +
            "&password=" +
            password
        );

        if (data.code !== 200) return this.$message.error(data.msg);
        this.$message.success("欢迎登录葱花投研！");
        window.sessionStorage.setItem("activePath", "dashboard");
        let token = data.data.access_token
        window.sessionStorage.setItem("token", token);
        
        this.$router.push("/home"); 

        // 1. 将登录成功之后的 token，保存到客户端的 sessionStorage 中
        //   1.1 项目中出了登录之外的其他API接口，必须在登录之后才能访问
        //   1.2 token 只应在当前网站打开期间生效，所以将 token 保存在 sessionStorage 中
        // window.sessionStorage.setItem('token', res.data.token)
        // 2. 通过编程式导航跳转到后台主页，路由地址是 /home
      });
      // const res = await this.$https.post('https://api.rainben.cn/status?_ajax=1')
      // if (res.status !== 200) return this.$message.error(res.statusText)
      // window.location.href = res.data.url
    },

    // 获取财务 - 单位
    async fnGetUnits() {
      const res = await this.$https.get(
        // "webapi/conghua/finTableAssumption/list"
        "webapi/conghua/finTableAssumption/list"
      );

      let data =  res.data


      localStorage.setItem("UNITS", JSON.stringify(data));    
    },


  },
  computed: {
    cssVars() {
      return {
        "--OFFSETWIDTH": this.OFFSETWIDTH + "px",
        "--OFFSETHEIGHT": this.OFFSETHEIGHT + "px",
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
        // "--OFFSETHEIGHT": this.OFFSETHEIGHT,
      };
    },
  },
};
</script>

<style lang="less" scoped>
// .login_container {
//   width: 100vw;
//   height: 100vh;
//   box-sizing: border-box;
//   background-image: url("../assets/bg.jpg");
//   // background: url(@/assets/bg.jpg);
//   background-size: 100% 100%;
//   // background-size: cover;
// }

// .top {
//   padding-top: .1979rem;
//   padding-left: .1979rem;
// }

// .pink0{
//   width:  .625rem;
//   background-color: pink;
// }
// .pink{
//   width:  2.8125rem;
//   background-color: pink;
// }

// .pink2{
//   width:  540px;
//   background-color: pink;
// }

// .title {
//   display: flex;
// }

// .logoImg {
//   width: .9219rem;
//   height: .2708rem;
// }

// .logoImg img {
//   width: 100%;
//   height: 100%;
// }

// .login_form {
//   // width: 360px;
//   margin: .1823rem auto;
// }

// /deep/.el-input__inner {
//   background-color: #f2f2f2 !important;
//   border: 1px solid #dcdfe6 !important;
// }

// .el-button {
//   width: 100%;
// }

// .bottom {
//   display: flex;
//   justify-content: flex-end;
// }

// .loginArea {
//   width: 2.8906rem;
//   // width: 1440 * (555px / 1440) ;
//   height: 3.2917rem;
//   background: #ffffff;
//   border-radius: .1615rem .1615rem .1615rem .1615rem;
//   opacity: 1;
//   border: 1px solid #0e0509;
//   margin-right: .7708rem;
//   margin-top: 1.0833rem;
//   // margin-top: 5%;
//   // margin-top: 1440 * (208px / 1440);
//   box-sizing: border-box;
// }

// .loginArea_tit {
//   padding: .1719rem .026rem;
//   border-radius: .1615rem .1615rem 0px 0px;
//   background: linear-gradient(to right, #e7f1fe, #f2f7ff, #fdfeff);
// }

// .loginArea_tit_content {
//   border-left: .0417rem solid #085ae0;
//   padding-left: .1823rem;
// }

// .pic_area {
//   width: 1.3333rem;
//   height: .1563rem;
// }

// .pic_area img {
//   width: 100%;
//   height: 100%;
// }

// .el-tabs {
//   margin-right: .0104rem;
// }

// .loginArea_main {
//   padding-top: .1406rem;
//   padding-left: .2083rem;
//   height: auto;
// }

// .el-icon-arrow-right {
//   color: #fff;
//   font-size: .0625rem;
//   margin-left: .026rem;
// }

// .login_passwordLogin {
//   margin-top: .1198rem;
//   font-size: .0729rem;
//   font-weight: 400;
//   letter-spacing: 0px;
//   line-height: .0722rem;
//   color: rgba(0, 0, 0, 0.8);
// }

// .password_area {
//   display: flex;
//   justify-content: space-between;
// }

// .password_area span:nth-child(1) {
//   font-size: .0833rem;
//   font-weight: 400;
//   letter-spacing: 0px;
//   line-height: .1016rem;
//   color: rgba(155, 161, 168, 1);
// }

// .password_area span:nth-child(2) {
//   font-size: .0833rem;
//   font-weight: 400;
//   letter-spacing: 0px;
//   line-height: .1016rem;
//   color: rgba(63, 159, 255, 1);
// }

// .btns {
//   margin-top: .1458rem;
// }

// .agree {
//   font-size: .0729rem;
//   font-weight: 400;
//   letter-spacing: 0;
//   color: rgba(194, 199, 204, 1);
//   margin-top: .1354rem;
// }

// // el-tabs
// // el-tabs
// .el-tabs {
//   background-color: #ffffff;
// }

// ::v-deep .el-tabs__item {
//   height: .2604rem;
//   font-size: .151rem;
//   font-family: PingFangSC, PingFang SC;
//   font-weight: bold;
//   color: #bfbfbf;
// }

// ::v-deep .el-tabs__item:hover {
//   color: #000 !important;
// }

// ::v-deep .el-tabs__item.is-active {
//   color: #000 !important;
// }

// /*去掉tabs底部的下划线*/
// ::v-deep .el-tabs__nav-wrap::after {
//   position: static !important;
// }

// /*去掉切换时el-tab-pane底部的蓝色下划线*/
// ::v-deep .el-tabs__active-bar {
//   height: .026rem;
//   background-color: #000 !important;
//   border-radius: .026rem !important;
// }

// .login_form {
//   padding-right: .25rem;
//   padding-left: 0 !important;
//   margin-bottom: 0 !important;
// }

// /deep/.el-input__inner {
//   height: .3125rem;
//   border-radius: .0677rem;
//   margin-left: 0 !important;
//   border: 1px solid #f3f2f2;
//   background-color: #fff !important;
//   font-size: .099rem;
//   font-family: PingFangSC, PingFang SC;
//   font-weight: 400;
// }

// .el-input__icon:after {
//   // width: 50px;
// }

// .verificationCode {
//   margin-top: 0 !important;
//   padding-right: .25rem;
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: .5313rem;
// }

// ::v-deep .el-checkbox__label {
//   font-size: .0833rem;
//   font-family: PingFangSC, PingFang SC;
//   font-weight: 400;
// }

// .verificationCode span:nth-child(2) {
//   font-size: .0833rem;
//   font-family: PingFangSC, PingFang SC;
//   font-weight: 400;
//   color: #5a9df8;
// }

// ::v-deep .el-button {
//   width: 2.4375rem !important;
//   font-size: .1042rem;
//   font-family: PingFangSC, PingFang SC;
//   font-weight: 580;
//   border-radius: .0521rem;
// }

// .loginInfo {
//   font-size: .0833rem;
//   font-family: PingFangSC, PingFang SC;
//   font-weight: 400;
//   color: #666666;
//   text-align: center;
//   padding-top: .1563rem;
// }

// .loginInfo span:nth-child(1) {
//   color: #6d6d6d;
//   font-weight: 700;
// }

// .loginInfo span:nth-child(2) {
//   color: #5a9df8;
//   font-weight: 700;
// }

// 备份

.login_container {
  // width: 100vw;
  height: 100%;
  box-sizing: border-box;
  background-image: url("../assets/bg.jpg");
  // background: url(@/assets/bg.jpg);
  // background-size: contain;
  // background-size: 100% 100%;
  background-size: cover;
  overflow-y: auto;
  // background-repeat: no-repeat;
  // background-color: #1e1e28;
  display: flex;
  flex-direction: column;
}

.top {
  // flex: 2;
  padding-top: calc(var(--ROOTFONTFIZE) * (38 / 144));
  padding-left: calc(var(--ROOTFONTFIZE) * (38 / 144));
}

.title {
  display: flex;
}

.logoImg {
  width: calc(var(--ROOTFONTFIZE) * (177 / 144));
  height: calc(var(--ROOTFONTFIZE) * (52 / 144));
}

.logoImg img {
  width: 100%;
  height: 100%;
}

.login_form {
  // width: 360px;
  margin: calc(var(--ROOTFONTFIZE) * (35 / 144)) auto;
}

/deep/.el-input__inner {
  background-color: #f2f2f2 !important;
  border: 1px solid #dcdfe6 !important;
}

.el-button {
  width: 100%;
}

.bottom {
  // flex: 8;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.loginArea {
  width: calc(var(--ROOTFONTFIZE) * (555 / 144));
  height: calc(var(--ROOTFONTFIZE) * (632 / 144));
  background: #ffffff;
  border-radius: calc(var(--ROOTFONTFIZE) * (31 / 144));
  opacity: 1;
  border: 1px solid #0e0509;
  margin-right: calc(var(--ROOTFONTFIZE) * (145 / 144));
  // margin-top: calc(var(--ROOTFONTFIZE) * (108 / 144)) ;
  // margin-bottom: calc(var(--ROOTFONTFIZE) * (108 / 144)) ;
  box-sizing: border-box;
}

.loginArea_tit {
  padding: calc(var(--ROOTFONTFIZE) * (33 / 144))
    calc(var(--ROOTFONTFIZE) * (5 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (31 / 144))
    calc(var(--ROOTFONTFIZE) * (31 / 144)) 0px 0px;
  background: linear-gradient(to right, #e7f1fe, #f2f7ff, #fdfeff);
}

.loginArea_tit_content {
  border-left: 8px solid #085ae0;
  padding-left: calc(var(--ROOTFONTFIZE) * (35 / 144));
}

.pic_area {
  width: calc(var(--ROOTFONTFIZE) * (256 / 144));
  height: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.pic_area img {
  width: 100%;
  height: 100%;
}

.el-tabs {
  margin-right: calc(var(--ROOTFONTFIZE) * (2 / 144));
}

.loginArea_main {
  padding-top: calc(var(--ROOTFONTFIZE) * (27 / 144));
  padding-left: calc(var(--ROOTFONTFIZE) * (40 / 144));
  height: auto;
}

.el-icon-arrow-right {
  color: #fff;
  font-size: calc(var(--ROOTFONTFIZE) * (12 / 144));
  margin-left: calc(var(--ROOTFONTFIZE) * (5 / 144));
}

.login_passwordLogin {
  margin-top: calc(var(--ROOTFONTFIZE) * (23 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (14 / 144));
  color: rgba(0, 0, 0, 0.8);
}

.password_area {
  display: flex;
  justify-content: space-between;
}

.password_area span:nth-child(1) {
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(155, 161, 168, 1);
}

.password_area span:nth-child(2) {
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(63, 159, 255, 1);
}

.btns {
  margin-top: calc(var(--ROOTFONTFIZE) * (28 / 144));
}

.agree {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0;
  color: rgba(194, 199, 204, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (26 / 144));
}

// el-tabs
// el-tabs
.el-tabs {
  background-color: #ffffff;
}

::v-deep .el-tabs__item {
  height: calc(var(--ROOTFONTFIZE) * (50 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (29 / 144));
  font-family: PingFangSC, PingFang SC;
  font-weight: bold;
  color: #bfbfbf;
}

::v-deep .el-tabs__item:hover {
  color: #000 !important;
}

::v-deep .el-tabs__item.is-active {
  color: #000 !important;
}

/*去掉tabs底部的下划线*/
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/*去掉切换时el-tab-pane底部的蓝色下划线*/
::v-deep .el-tabs__active-bar {
  height: calc(var(--ROOTFONTFIZE) * (5 / 144));
  background-color: #000 !important;
  border-radius: calc(var(--ROOTFONTFIZE) * (5 / 144)) !important;
}

.login_form {
  padding-right: calc(var(--ROOTFONTFIZE) * (48 / 144));
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}

::v-deep .el-input__inner {
  height: calc(var(--ROOTFONTFIZE) * (60 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (13 / 144));
  margin-left: 0 !important;
  border: 1px solid #f3f2f2;
  background-color: #fff !important;
  font-size: calc(var(--ROOTFONTFIZE) * (19 / 144));
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
}

.el-input__icon:after {
  // width: 50px;
}

.verificationCode {
  margin-top: 0 !important;
  padding-right: calc(var(--ROOTFONTFIZE) * (48 / 144));
  display: flex;
  justify-content: space-between;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (102 / 144));
}



  ::v-deep .el-checkbox__label {
    font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
  }

 ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #409eff !important;
  }


.verificationCode span:nth-child(2) {
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #5a9df8;
}

::v-deep .el-button {
  width: calc(var(--ROOTFONTFIZE) * (468 / 144)) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-family: PingFangSC, PingFang SC;
  font-weight: 580;
  border-radius: 10px;
}

.loginInfo {
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #666666;
  text-align: center;
  padding-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.loginInfo span:nth-child(1) {
  color: #6d6d6d;
  font-weight: 700;
}

.loginInfo span:nth-child(2) {
  color: #5a9df8;
  font-weight: 700;
}
</style>
