import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "./assets/css/global.css";
import "./assets/css/element.css";
import "./assets/css/fonts.css";
import "./assets/css/echarts.css";
import "./assets/fonts/iconfont.css";
// import 'lib-flexible/flexible.js'
import axios from "axios";

// import echarts from 'echarts'

import { setCookie, delCookie } from "./assets/cookie";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import VueCookies from "vue-cookies";

import Print from "vue-print-nb";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

Vue.use(Print);

Vue.prototype.$echarts = window.echarts;
Vue.prototype.$Plotly = window.Plotly;

Vue.prototype.$eventBus = new Vue();

Vue.prototype.$CHloading = null;

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "apio";
} else {
  axios.defaults.baseURL = "";
  if (window) {
    window.console.log = function () {};
  }
  // axios.defaults.baseURL = '/'
}

//   axios.defaults.baseURL = ''

// import 'amfe-flexible'
Vue.use(VueCookies);

// 显示进度条功能
NProgress.configure({
  easing: "ease",
  // speed:100,
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});

//当路由进入前
router.beforeEach((to, from, next) => {
  if (to.path === "/login") return next();

  const tokenstr = window.sessionStorage.getItem("token");

  if (!tokenstr) return next("/login");

  // NProgress.start();
  // NProgress.inc();
  next();
});

router.afterEach(() => {
  NProgress.done();
});

Vue.prototype.$cookieStore = {
  delCookie,
  setCookie,
};

// axios.defaults.baseURL='https://erp.rainben.cn/'

// axios.defaults.baseURL = 'https://127.0.0.1:8888/api/private/v1/'
// 挂载axios
Vue.prototype.$https = axios;
axios.defaults.withCredentials = true;

Vue.config.productionTip = false;
// var adc='X-CSRF-TOKEN'
// 请求在到达服务器之前,先会调用use中的这个回调函数来添加请求头信息
axios.interceptors.request.use((config) => {
  let token = window.sessionStorage.getItem("token");
  // if(!token) return this.$router.push("/login");

  config.headers.Authorization = `Bearer ${token}`;
  config.headers.clientid = `e5cd7e4891bf95d1d19206ce24a7b32e`;

  // 为请求头对象,添加token验证的Authorization字段
  // let token=window.sessionStorage.getItem("PHPSESSID")
  // let value= document.cookie.split('=')[1]
  // console.log(value,'value');

  // config.headers.Authorization=window.sessionStorage.getItem("token")
  // config.headers={
  //   'X-CSRF-TOKEN':'aaa'
  // }
  // const res= await this.$https.post(domainApi + '/status?_ajax=1')
  // console.log(res,'res567');
  // console.log(config,'config');
  // config.headers.

  return config;
});

axios.interceptors.response.use((config) => {
  //   console.log(config,'config');
  //    if( config.data &&  config.data.error_code > 50000){
  //      window.alert('您尚无此权限')
  //     }
  // console.log(config,'confiug');
  // if(config.data.error_code == 1000){
  //    let flag=window.confirm('您未登录')
  //    if(flag){
  //       window.location.href=config.data.url
  //    }
  //  }
  return config;
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
