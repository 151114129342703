import { render, staticRenderFns } from "./CButtons_download_single.vue?vue&type=template&id=adc11068&scoped=true&"
import script from "./CButtons_download_single.vue?vue&type=script&lang=js&"
export * from "./CButtons_download_single.vue?vue&type=script&lang=js&"
import style0 from "./CButtons_download_single.vue?vue&type=style&index=0&id=adc11068&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adc11068",
  null
  
)

export default component.exports