<template>
  <div class="container" id="rootchart">
    <!-- 风险相关 -->
    <div class="riskRelatedCard">
      <div class="riskRelatedCard_tit">riskbeta对比</div>
      <div class="riskRelatedCard_ChooseArea">
        <div class="riskRelatedCard_ChooseArea_top">
          <span>
            显示类型
            <CButtonsSeparate
              @Cbtns_sep="fnGetCbtnsSep"
              ref="chart1"
              :disabled="disabled1"
              class="CButtonsSeparate_style"
              :dButtons1="dButtons_separrate"
              :dButtons1_current="dButtons_separrate_current"
            />
          </span>

          <span v-show="!isCODENAME">
            <CSelect
              @CSelect_select_val="fnCSelect_chart3"
              :multiple="false"
              :clearable="false"
              :mini="true"
              :default="select_val_level"
              :options="options_level"
              >显示级别</CSelect
            >
          </span>

          <span v-show="isCODENAME">
            <CCascader
              ref="CCascader2_chart1"
              :checkStrictly="true"
              :multiple="true"
              :isDelete="false"
              takeOut="takeOut"
              :wide="true"
              :collapsetags="true"
              @CCascader_select_val="fnSelect_select_val_chart1_codename"
              :options="CODENAMEDATA"
              >选择个股</CCascader
            >

            <!-- <CSelect
              @CSelect_select_val="fnSelect_select_val_chart1_codename"
              ref="CSelect_1"
              :multiple="true"
              :limit="10"
              :clearable="true"
              :isDelete="true"
              :options="CODENAMEDATA"
              :default="CODENAMEDATA_chart1"
              >选择个股</CSelect
            > -->
          </span>

          <span>
            <CSelect
              @CSelect_select_val="fnCSelect_chart2"
              :multiple="false"
              :mini="true"
              :clearable="false"
              :default="select_val_days"
              :options="options_days"
              >选择天数类型</CSelect
            >
          </span>

          <span v-show="false">
            <CCascader
              ref="CCascader_chart"
              :default="category_cascader_val"
              :checkStrictly="true"
              :multiple="true"
              :isDelete="false"
              :collapsetags="true"
              @CCascader_select_val="fnCCascader_select_val_one"
              :options="category_cascader_options"
              >选择类别</CCascader
            >
          </span>

          <span>
            <CSelect
              @CSelect_select_val="fnCSelect_chart1"
              :multiple="false"
              :clearable="false"
              :auto="true"
              :default="select_val_benchmark"
              :options="options_benchmark"
              >选择benchmark</CSelect
            >
          </span>
        </div>

        <div class="riskRelatedCard_ChooseArea_bottom">
          <span>
            切换类型

            <CButtonsSeparate
              @Cbtns_sep="fnGetCbtnsSep2"
              class="CButtonsSeparate_style"
              :dButtons1="dButtons_separrate2"
              :dButtons1_current="dButtons_separrate2_current"
            />
          </span>
        </div>
      </div>
      <div class="riskRelatedCard_content">
          <!-- v-loading="demaxiyavn"
          element-loading-text="数据量巨大，正在计算中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 1)" -->
        <div
         
          class="chartColumn1_style CHARTS_BGC"
          ref="chartColumn1"
          id="chartColumn1"
          style="width: 100%; height: 450px"
        ></div>
      </div>
    </div>

    <div class="riskRelatedCard">
      <div class="riskRelatedCard_tit">真实波动率对比</div>
      <!-- <div class="riskRelatedCard_ChooseArea">
        <div class="riskRelatedCard_ChooseArea_top realFluctuations">
          <span>
             <CTimeButtons
            class="cTimeButtons_style"
            :default="start_date"
            @CTimeButtons_select_val="fnCTimeButtons_select"
          />
          </span>
          <CCascader
            :default="category_cascader_val2"
            :checkStrictly="true"
            :multiple="true"
            :collapsetags="true"
            @CCascader_select_val="fnCCascader_select_val_two"
            :options="category_cascader_options"
            >选择类别</CCascader
          >

         
        </div>
      </div> -->
      <div class="riskRelatedCard_content">
          <!-- v-loading="loading2"
          element-loading-text="数据量巨大，正在计算中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)" -->
        <div
          class="chartColumn1_style CHARTS_BGC"
          ref="chartColumn2"
          id="chartColumn2"
          style="width: 100%; height: 450px"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import CSelects from "@/components/Basic/CSelects";
import CCategoryRadio from "@/components/Basic/CCategoryRadio";
import CSelect from "@/components/Basic/CSelect";
import CCascader from "@/components/Basic/CCascader";
import CButtonsSeparate from "@/components/Basic/CButtons_separate";
import CTimeButtons from "@/components/Basic/CTimeButtons";

export default {
  data() {
    return {
      MAP_L1: {
        "180101.SZ": "产权类",
        "508000.SH": "产权类",
        "508027.SH": "产权类",
        "508099.SH": "产权类",
        "180102.SZ": "产权类",
        "508021.SH": "产权类",
        "508088.SH": "产权类",
        "180103.SZ": "产权类",
        "508019.SH": "产权类",
        "180501.SZ": "产权类",
        "508058.SH": "产权类",
        "508068.SH": "产权类",
        "508077.SH": "产权类",
        "180301.SZ": "产权类",
        "508056.SH": "产权类",
        "508098.SH": "产权类",
        "180801.SZ": "经营权类",
        "508006.SH": "经营权类",
        "180201.SZ": "经营权类",
        "508001.SH": "经营权类",
        "180202.SZ": "经营权类",
        "508018.SH": "经营权类",
        "508008.SH": "经营权类",
        "508066.SH": "经营权类",
        "508009.SH": "经营权类",
        "180401.SZ": "经营权类",
        "508028.SH": "经营权类",
        "508096.SH": "经营权类",
        "508007.SH": "经营权类",
      },
      MAP_L2: {
        "180101.SZ": "产业园",
        "508000.SH": "产业园",
        "508027.SH": "产业园",
        "508099.SH": "产业园",
        "180102.SZ": "产业园",
        "508021.SH": "产业园",
        "508088.SH": "产业园",
        "180103.SZ": "产业园",
        "508019.SH": "产业园",
        "180501.SZ": "保障性住房",
        "508058.SH": "保障性住房",
        "508068.SH": "保障性住房",
        "508077.SH": "保障性住房",
        "180301.SZ": "物流仓储",
        "508056.SH": "物流仓储",
        "508098.SH": "物流仓储",
        "180801.SZ": "市政生态",
        "508006.SH": "市政生态",
        "180201.SZ": "收费公路",
        "508001.SH": "收费公路",
        "180202.SZ": "收费公路",
        "508018.SH": "收费公路",
        "508008.SH": "收费公路",
        "508066.SH": "收费公路",
        "508009.SH": "收费公路",
        "180401.SZ": "能源",
        "508028.SH": "能源",
        "508096.SH": "能源",
        "508007.SH": "收费公路",
      },

      demaxiyavn: false,
      loading2: false,
      disabled1:false,
      disabled2:false,
      //  ---------------------------------------------------------------------------------------------
      options_benchmark: [
        {
          label: "沪深300",
          value: "沪深300",
        },
        {
          label: "CN10YR",
          value: "CN10YR",
        },
        {
          label: "公用指数",
          value: "公用指数",
        },
        {
          label: "中证红利",
          value: "中证红利",
        },
      ],
      options_benchmark2: [
        {
          value: "CN10YR",
          label: "CN10YR",
        },
        {
          value: "US10YR",
          label: "US10YR",
        },
        {
          value: "JP10YR",
          label: "JP10YR",
        },
        {
          value: "UK10YR",
          label: "UK10YR",
        },
        {
          value: "现货黄金",
          label: "现货黄金",
        },
        {
          value: "沪深300",
          label: "沪深300",
        },
        {
          value: "中证500",
          label: "中证500",
        },
        {
          value: "中证100",
          label: "中证100",
        },
        {
          value: "大宗商品",
          label: "大宗商品",
        },
        {
          value: "公用指数",
          label: "公用指数",
        },

        {
          value: "上证50",
          label: "上证50",
        },
        {
          value: "红利指数",
          label: "红利指数",
        },
        {
          value: "深证红利",
          label: "深证红利",
        },
        {
          value: "中证新能源指数",
          label: "中证新能源指数",
        },
        {
          value: "红利低波100",
          label: "红利低波100",
        },
        {
          value: "中证红利",
          label: "中证红利",
        },
        {
          value: "电力指数",
          label: "电力指数",
        },
        {
          value: "绿色能源",
          label: "绿色能源",
        },
        {
          value: "股息龙头",
          label: "股息龙头",
        },
        {
          value: "全指公用",
          label: "全指公用",
        },

        {
          value: "上证公用",
          label: "上证公用",
        },
        {
          value: "高股息策略",
          label: "高股息策略",
        },
        {
          value: "东证红利低波",
          label: "东证红利低波",
        },
        {
          value: "国企红利",
          label: "国企红利",
        },
        {
          value: "sw_公用事业",
          label: "sw_公用事业",
        },
        {
          value: "国证交通运输行业指数",
          label: "国证交通运输行业指数",
        },
        {
          value: "运输指数",
          label: "运输指数",
        },
        {
          value: "国证物流",
          label: "国证物流",
        },
        {
          value: "sw_高速公路",
          label: "sw_高速公路",
        },
        {
          value: "国证电力公用事业行业指数",
          label: "国证电力公用事业行业指数",
        },

        {
          value: "sw_电力",
          label: "sw_电力",
        },
        {
          value: "sw_物流",
          label: "sw_物流",
        },
        {
          value: "sw_产业地产",
          label: "sw_产业地产",
        },
        {
          value: "sw_水务及水治理",
          label: "sw_水务及水治理",
        },
        {
          value: "sw_环保",
          label: "sw_环保",
        },
        {
          value: "CN1YR",
          label: "CN1YR",
        },
        {
          value: "CN2YR",
          label: "CN2YR",
        },
        {
          value: "CN3YR",
          label: "CN3YR",
        },
        {
          value: "CN5YR",
          label: "CN5YR",
        },
        {
          value: "US1YR",
          label: "US1YR",
        },

        {
          value: "US2YR",
          label: "US2YR",
        },
        {
          value: "US3YR",
          label: "US3YR",
        },
        {
          value: "US5YR",
          label: "US5YR",
        },
        {
          value: "JP3YR",
          label: "JP3YR",
        },
        {
          value: "JP5YR",
          label: "JP5YR",
        },
        {
          value: "现货白银",
          label: "现货白银",
        },
        {
          value: "日经225",
          label: "日经225",
        },
        {
          value: "标普500",
          label: "标普500",
        },
        {
          value: "恒生指数",
          label: "恒生指数",
        },
        {
          value: "道琼斯工业平均",
          label: "道琼斯工业平均",
        },

        {
          value: "cni_风光装备",
          label: "cni_风光装备",
        },
        {
          value: "wi_科技园区指数",
          label: "wi_科技园区指数",
        },
        {
          value: "cj_园区开发(长江)",
          label: "cj_园区开发(长江)",
        },
        {
          value: "wi_房屋租赁指数",
          label: "wi_房屋租赁指数",
        },
        {
          value: "cj_垃圾焚烧(长江)",
          label: "cj_垃圾焚烧(长江)",
        },
        {
          value: "wi_垃圾发电指数",
          label: "wi_垃圾发电指数",
        },
        {
          value: "wi_生物质能指数",
          label: "wi_生物质能指数",
        },
        {
          value: "恒生地产分类指数",
          label: "恒生地产分类指数",
        },
      ],
      // select_val_benchmark: ["公用指数", "沪深300", "CN10YR"],
      select_val_benchmark: "公用指数",

      select_val_days: "20",
      options_days: [
        {
          label: "20",
          value: "20",
        },
        {
          label: "40",
          value: "40",
        },
        {
          label: "60",
          value: "60",
        },
      ],
      select_val_level: "L1",
      options_level: [
        {
          label: "一级",
          value: "L1",
        },
        {
          label: "二级",
          value: "L2",
        },
      ],
      category_cascader_val: ["公募REITs"],
      category_cascader_val2: ["公募REITs"],
      category_cascader_options: [
        {
          value: "产权类",
          label: "产权类",
          children: [
            {
              value: "物流仓储",
              label: "物流仓储",
            },
            {
              value: "产业园",
              label: "产业园",
            },
            {
              value: "保障性住房",
              label: "保障性住房",
            },
            {
              value: "消费",
              label: "消费",
            },
          ],
        },
        {
          value: "经营权类",
          label: "经营权类",
          children: [
            {
              value: "市政生态",
              label: "市政生态",
            },
            {
              value: "能源",
              label: "能源",
            },
            {
              value: "收费公路",
              label: "收费公路",
            },
          ],
        },
        {
          value: "公募REITs",
          label: "公募REITs",
        },
      ],

      category_cascader_options_gongmu: [
        {
          value: "产权类",
          label: "产权类",
          disabled: true,
          children: [
            {
              value: "物流仓储",
              label: "物流仓储",
              disabled: true,
            },
            {
              value: "产业园",
              label: "产业园",
              disabled: true,
            },
            {
              value: "保障性住房",
              label: "保障性住房",
              disabled: true,
            },
            {
              value: "消费",
              label: "消费",
              disabled: true,
            },
          ],
        },
        {
          value: "经营权类",
          label: "经营权类",
          disabled: true,
          children: [
            {
              value: "市政生态",
              label: "市政生态",
              disabled: true,
            },
            {
              value: "能源",
              label: "能源",
              disabled: true,
            },
            {
              value: "收费公路",
              label: "收费公路",
              disabled: true,
            },
          ],
        },
        {
          value: "公募REITs",
          label: "公募REITs",
        },
      ],
      category_cascader_options_nogongmu: [
        {
          value: "产权类",
          label: "产权类",

          children: [
            {
              value: "物流仓储",
              label: "物流仓储",
            },
            {
              value: "产业园",
              label: "产业园",
            },
            {
              value: "保障性住房",
              label: "保障性住房",
            },
            {
              value: "消费",
              label: "消费",
            },
          ],
        },
        {
          value: "经营权类",
          label: "经营权类",
          children: [
            {
              value: "市政生态",
              label: "市政生态",
            },
            {
              value: "能源",
              label: "能源",
            },
            {
              value: "收费公路",
              label: "收费公路",
            },
          ],
        },
        {
          value: "公募REITs",
          label: "公募REITs",
          disabled: true,
        },
      ],
      category_cascader_options_all: [
        {
          value: "产权类",
          label: "产权类",
          children: [
            {
              value: "物流仓储",
              label: "物流仓储",
            },
            {
              value: "产业园",
              label: "产业园",
            },
            {
              value: "保障性住房",
              label: "保障性住房",
            },
            {
              value: "消费",
              label: "消费",
            },
          ],
        },
        {
          value: "经营权类",
          label: "经营权类",
          children: [
            {
              value: "市政生态",
              label: "市政生态",
            },
            {
              value: "能源",
              label: "能源",
            },
            {
              value: "收费公路",
              label: "收费公路",
            },
          ],
        },
        {
          value: "公募REITs",
          label: "公募REITs",
        },
      ],

      dButtons_separrate: ["大类", "个股"],
      dButtons_separrate_current: "大类",

      dButtons_separrate2: ["折现（走势）", "柱状（汇总）"],
      dButtons_separrate2_current: "折现（走势）",

      start_date: "YTD",

      chartColumn1: null,
      chartColumn1_dataTmp: null,
      chartColumn1_pic_type: null,
      chartColumn1_cal_function: null,

      chartColumn2: null,
      chartColumn2_dataTmp: null,
      chartColumn2_pic_type: null,
      chartColumn2_cal_function: null,

      CODENAMEDATA: [],
      CODENAMEDATA_chart1: [],

      isCODENAME: false,
      ROOTFONTFIZE: "",

      isWatch: false,
    };
  },
  created() {
    // this.fnGeRiskAnalysisData();
    // this.fnGeRiskAnalysisData2();
    // this.fnCodeName()
  },
  mounted() {
    window.addEventListener("resize", this.funResize);
    this.fnGetWidth();
  },
  methods: {
    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById("rootchart");
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      window.sessionStorage.setItem("ROOTFONTFIZE", this.ROOTFONTFIZE);

      var chartColumn1 = document.getElementById("chartColumn1");
      chartColumn1.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      var chartColumn2 = document.getElementById("chartColumn2");
      chartColumn2.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      return;
    },

    // 初始化codename
    fnCodeName() {
      let data = this.$store.state.CODENAME;
      // this.CODENAMEDATA = [];
      // for (let i in data) {
      //   let obj = {
      //     value: i,
      //     label: data[i],
      //   };
      //   this.CODENAMEDATA.push(obj);
      // }

      let REITsNAME = JSON.parse(localStorage.getItem("REITsNAME"));
      this.CODENAMEDATA = REITsNAME;
    },

    fnCSelect_chart1(val) {
      this.select_val_benchmark = val;
      this.fnGeRiskAnalysisData();
      this.fnGeRiskAnalysisData2();
    },
    fnCSelect_chart2(val) {
      this.select_val_days = val;
      this.fnGeRiskAnalysisData();
      this.fnGeRiskAnalysisData2();
    },
    fnCSelect_chart3(val) {
      this.select_val_level = val;
      this.fnGeRiskAnalysisData();
      this.fnGeRiskAnalysisData2();
    },

    fnGetCbtnsSep(val) {
      this.demaxiyavn = true
      this.dButtons_separrate_current = val;

      if (val == "个股") {
        this.isCODENAME = true;
        this.initEmptyChart1();
        this.initEmptyChart2();
      } else {
        // this.$refs.CSelect_1.value = [];
        // this.CODENAMEDATA_chart1 = [];
        this.isCODENAME = false;
        this.fnGeRiskAnalysisData();
        this.fnGeRiskAnalysisData2();
      }
    },
    fnGetCbtnsSep2(val) {
      this.dButtons_separrate2_current = val;
      this.fnGeRiskAnalysisData();
      this.fnGeRiskAnalysisData2();
    },

    fnCCascader_select_val_one(val) {
      this.category_cascader_val = val;

      // this.category_cascader_options = []
      if (this.category_cascader_val.length == 0) {
        this.category_cascader_options = this.category_cascader_options_all;
      }

      if (
        this.dButtons_separrate_current == "个股" &&
        this.category_cascader_val.includes("公募REITs") &&
        this.category_cascader_val.length !== 0
      ) {
        this.category_cascader_options = this.category_cascader_options_gongmu;
      }

      if (
        this.dButtons_separrate_current == "个股" &&
        !this.category_cascader_val.includes("公募REITs") &&
        this.category_cascader_val.length !== 0
      ) {
        this.category_cascader_options =
          this.category_cascader_options_nogongmu;
      }
      this.fnGeRiskAnalysisData();
    },

    fnSelect_select_val_chart1_codename(val) {
      val = val.filter((node) => {
        return node.includes(".");
      });
      this.CODENAMEDATA_chart1 = val;
      this.fnGeRiskAnalysisData();
      this.fnGeRiskAnalysisData2();
    },

    async fnGeRiskAnalysisData() {
      // this.$refs.chart1.disabled = true;
      this.disabled1 = true
      let cal_function = "";
      if (this.dButtons_separrate_current == "大类") {
        cal_function = "type";
      } else {
        cal_function = "single";
      }
      let pic_type = "";
      if (this.dButtons_separrate2_current == "折现（走势）") {
        pic_type = "line";
      } else {
        pic_type = "bar";
      }

      let factor = "riskbeta";
      let ben_select = this.select_val_benchmark;
      // let str = ben_select.join("&ben_select=");

      let days = this.select_val_days;
      let level = this.select_val_level;
      let r_type = this.category_cascader_val;
      let str2 = r_type.join("&r_type=");

      const { data } = await this.$https.get(
        "/api/get_risk_analysis_data?factor=" +
          factor +
          "&ben_select=" +
          ben_select +
          "&days=" +
          days +
          "&cal_function=" +
          cal_function +
          "&level=" +
          level +
          "&pic_type=" +
          pic_type +
          "&r_type=" +
          str2
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      let dataTmp = data.data;

      this.chartColumn1_dataTmp = dataTmp;
      this.chartColumn1_pic_type = pic_type;
      this.chartColumn1_cal_function = cal_function;
     
      this.$nextTick(() => {
        this.initChart1();
      });

      return;

      // 1. 获取数组每一项的属性
      let arr1 = [];
      dataTmp.forEach((e) => {
        arr1 = Object.keys(e);
      });

      // 2. 操作
      let arr2 = arr1.map((key) => {
        return dataTmp.map((e) => {
          return e[key];
        });
      });
      this.dRiskAnalysisData_X = arr2[1];

      arr1.splice(0, 2);
      arr2.splice(0, 2);

      let arr3 = [];
      arr2.forEach((node, idx) => {
        let obj = {
          name: arr1[idx],
          dData: node,
        };
        arr3.push(obj);
      });

      let arr4 = Object.entries(this.MAP_L2);

      arr3.map((node) => {
        return arr4.forEach((e) => {
          if (e[0] == node.name) {
            node["cateL2"] = e[1];
            return node;
          }
        });
      });

      this.dRiskAnalysisData_Name = Object.values(this.MAP_L2);

      // 获得了y轴数据
      this.dRiskAnalysisData_Y = arr3;
    },

    initChart1() {
      this.demaxiyavn = true;
      if (this.chartColumn1) {
        this.chartColumn1.dispose();
      }
      this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      this.chartColumn1.clear();


      let dataTmp = this.chartColumn1_dataTmp;
      let pic_type = this.chartColumn1_pic_type;
      let cal_function = this.chartColumn1_cal_function;

      let X = dataTmp.map((node) => {
        return node.tr_date;
      });

      // name
      let Name = Object.keys(dataTmp[0]);
      Name.shift();
      Name.shift();

      let orderData = JSON.parse(localStorage.getItem("ORDER"));
      let TMP = [];
      orderData.forEach((node) => {
        let obj = Name.find((item) => item == node);
        if (obj) {
          TMP.push(obj);
        }
      });
      Name = TMP;

      // let arrtmp = []
      // if(Name.includes('公募REITs')){
      //     arrtmp.push('公募REITs')
      // }
      // if(Name.includes('产权类')){
      //     arrtmp.push('产权类')
      // }
      // if(Name.includes('经营权类')){
      //     arrtmp.push('经营权类')
      // }

      // Name = arrtmp

      // Name为series的系列名称
      console.log(cal_function, "cal_function");
      if (cal_function == "single") {
        let chooseCODENAME = this.CODENAMEDATA_chart1;
        Name = chooseCODENAME;
      }

      // series轴数据
      let Y = [];
      Name.forEach((e) => {
        let obj = {
          name: e,
          data: [],
        };

        dataTmp.forEach((node) => {
          obj.data.push(node[e]);
        });

        Y.push(obj);
      });

      let colors = this.$store.state.colors;
      let colorArr = [];
      // 开始分个股，大类
      if (cal_function == "type") {
        // 颜色
        Name.forEach((e) => {
          colors.forEach((node) => {
            if (e == node.name) {
              colorArr.push(node.value);
            }
          });
        });
      } else {
        // 颜色 名称
        let CODENAME = this.$store.state.CODENAME;
        let MAP_L2 = JSON.parse(localStorage.getItem("MAP_L2"));

        Name.forEach((e) => {
          let obj = MAP_L2.find((item) => {
            return item.name == e;
          });

          let obj2 = colors.find((item) => {
            return item.name == obj.value;
          });

          colorArr.push(obj2.value);
        });

        Name = Name.map((item) => {
          return CODENAME[item];
        });

        Y.forEach((e) => {
          e.name = CODENAME[e.name];
        });
      }

      let typeVal = "line";
      if (pic_type !== "line") {
        typeVal = "bar";
      }


      //0624 暂无关系

      let seriesArr = Y.map((v, index) => {
        return {
          name: v.name,
          data: v.data,
          type: typeVal, // 设置图表类型为 折线图
          symbol: "none",
          cursor: "pointer",
        };
      });

      let texta = this.select_val_benchmark;
      let textb = this.select_val_days;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        title: {
          // 标题设置
          text: texta + "riskbeta" + textb + "日走势对比", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: OPTIONDATA.title.textStyle.fontSize,
          },
          top: this.ROOTFONTFIZE * (20 / 144),
          left: "center",
        },
        grid: {
          top: this.ROOTFONTFIZE * (120 / 144),
          left: this.ROOTFONTFIZE * (35 / 144),
          right: this.ROOTFONTFIZE * (200 / 144),
          bottom: this.ROOTFONTFIZE * (30 / 144),
          containLabel: true,
        },
        color: colorArr,
        legend: {
          show: true,
          icon: "roundRect",
          orient: "vertical",
          right: 50,
          top: "middle",
          align: "left",
          itemGap: this.ROOTFONTFIZE * (15 / 144),
          itemHeight: this.ROOTFONTFIZE * (10 / 144),
          itemWidth: this.ROOTFONTFIZE * (39 / 144),
          padding: [0, 0, 0, 0],
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: OPTIONDATA.legend.textStyle.fontSize,
            // width: 80,
            lineHeight: 20,
            // overflow: "break",
          },
          data: Name,
        },
        tooltip: {
          trigger: "axis",
          confine: true,
          triggerOn: "mousemove|click", // 触发时机, click代表点击, mouseOver代表鼠标移过
          formatter: function (args) {
            let str = "";
            str += args[0].axisValueLabel;
            str += "<br>";
            args.forEach((node) => {
              let val = Number(node.value).toFixed(2);
              str += `${node.marker}${node.seriesName}：${val}`;
              str += "<br>";

              return str;
            });
            return str;
          },
        },
        xAxis: {
          type: "category",
          data: X,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            show: true,
            margin: OPTIONDATA.xAxis.axisLabel.margin,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          type: "value",
          // splitNumber:0.5,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
              args = Number(args).toFixed(num);
              return args;
            },
          },
          splitLine: OPTIONDATA.yAxis.splitLine,
        },
        series: seriesArr,
      };

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
      this.demaxiyavn = false;
        this.disabled1 = false

        // this.$refs.chart1.disabled = false;
      // this.funResize()
    },

    initEmptyChart1() {
      this.chartColumn1.clear();

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        title: {
          // 标题设置
          text: "请先选择个股", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: OPTIONDATA.title.textStyle.fontSize,
          },
          top: this.ROOTFONTFIZE * (20 / 144),
          left: "center",
        },
        grid: {
          top: this.ROOTFONTFIZE * (120 / 144),
          left: this.ROOTFONTFIZE * (35 / 144),
          right: this.ROOTFONTFIZE * (200 / 144),
          bottom: this.ROOTFONTFIZE * (30 / 144),
          containLabel: true,
        },
      };

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
      // this.loading1 = false;
      console.log('empty执行了');
      // this.funResize()
    },

    initEmptyChart2() {
      this.chartColumn2.clear();

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        title: {
          // 标题设置
          text: "请先选择个股", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: OPTIONDATA.title.textStyle.fontSize,
          },
          top: this.ROOTFONTFIZE * (20 / 144),
          left: "center",
        },
        grid: {
          top: this.ROOTFONTFIZE * (120 / 144),
          left: this.ROOTFONTFIZE * (35 / 144),
          right: this.ROOTFONTFIZE * (200 / 144),
          bottom: this.ROOTFONTFIZE * (30 / 144),
          containLabel: true,
        },
      };

      this.chartColumn2.setOption(option);
      this.chartColumn2.resize();
      // this.loading2 = false;
      // this.funResize()
    },

    fnSetOption() {
      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        title: {
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
          },
        },
        legend: {
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
          },
        },

        xAxis: {
          axisLabel: {
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          axisLabel: {
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: OPTIONDATA.yAxis.splitLine,
        },
      };

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
    },

    // =====================================================================================================
    fnCCascader_select_val_two(val) {
      this.category_cascader_val2 = val;
      this.fnGeRiskAnalysisData2();
    },
    fnCTimeButtons_select(val) {
      this.start_date = val;
    },
    async fnGeRiskAnalysisData2() {
      // this.loading2 = true;
      // let factor = 'ATR';
      // let ben_select = "沪深300";
      // let days = 20;
      // let cal_function = "type";
      // let level = "L2";
      // let pic_type = "line";
      // let r_type = this.category_cascader_val2;
      // let str2 = r_type.join("&r_type=");

      let factor = "ATR";

      let cal_function = "";
      if (this.dButtons_separrate_current == "大类") {
        cal_function = "type";
      } else {
        cal_function = "single";
      }

      let pic_type = "";
      if (this.dButtons_separrate2_current == "折现（走势）") {
        pic_type = "line";
      } else {
        pic_type = "bar";
      }

      let ben_select = this.select_val_benchmark;

      let days = this.select_val_days;

      let level = this.select_val_level;

      let r_type = this.category_cascader_val2;
      let str2 = r_type.join("&r_type=");

      const { data } = await this.$https.get(
        "/api/get_risk_analysis_data?factor=" +
          factor +
          "&ben_select=" +
          ben_select +
          "&days=" +
          days +
          "&cal_function=" +
          cal_function +
          "&level=" +
          level +
          "&pic_type=" +
          pic_type +
          "&r_type=" +
          str2
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      let dataTmp = data.data;

      this.chartColumn2_dataTmp = dataTmp;
      this.chartColumn2_pic_type = pic_type;
      this.chartColumn2_cal_function = cal_function;

      this.$nextTick(() => {
        this.initChart2();
      });

      return;
      // 1. 获取数组每一项的属性
      let arr1 = [];
      dataTmp.forEach((e) => {
        arr1 = Object.keys(e);
      });

      // 2. 操作
      let arr2 = arr1.map((key) => {
        return dataTmp.map((e) => {
          return e[key];
        });
      });
      this.dRiskAnalysisData2_X = arr2[1];

      arr1.splice(0, 2);
      arr2.splice(0, 2);

      let arr3 = [];
      arr2.forEach((node, idx) => {
        let obj = {
          name: arr1[idx],
          dData: node,
        };
        arr3.push(obj);
      });

      let arr4 = Object.entries(this.MAP_L2);

      arr3.map((node) => {
        return arr4.forEach((e) => {
          if (e[0] == node.name) {
            node["cateL2"] = e[1];
            return node;
          }
        });
      });

      this.dRiskAnalysisData2_Name = Object.values(this.MAP_L2);

      // 获得了y轴数据
      this.dRiskAnalysisData2_Y = arr3;

      this.initChart2();
    },

    initChart2() {
      this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);
      this.chartColumn2.clear();


      let dataTmp = this.chartColumn2_dataTmp;
      let pic_type = this.chartColumn2_pic_type;
      let cal_function = this.chartColumn2_cal_function;

      let X = dataTmp.map((node) => {
        return node.tr_date;
      });
      // name
      let Name = Object.keys(dataTmp[0]);
      Name.shift();
      Name.shift();

      let orderData = JSON.parse(localStorage.getItem("ORDER"));
      let TMP = [];
      orderData.forEach((node) => {
        let obj = Name.find((item) => item == node);
        if (obj) {
          TMP.push(obj);
        }
      });
      Name = TMP;

      //   let arrtmp = []
      // if(Name.includes('公募REITs')){
      //     arrtmp.push('公募REITs')
      // }
      // if(Name.includes('产权类')){
      //     arrtmp.push('产权类')
      // }
      // if(Name.includes('经营权类')){
      //     arrtmp.push('经营权类')
      // }

      // Name = arrtmp

      // Name为series的系列名称
      if (cal_function == "single") {
        let chooseCODENAME = this.CODENAMEDATA_chart1;
        Name = chooseCODENAME;
      }

      let Y = [];
      Name.forEach((e) => {
        let obj = {
          name: e,
          data: [],
        };

        dataTmp.forEach((node) => {
          obj.data.push(node[e]);
        });

        Y.push(obj);
      });

      let colors = this.$store.state.colors;

      let colorArr = [];

      // 开始分个股，大类
      if (cal_function == "type") {
        Name.forEach((e) => {
          colors.forEach((node) => {
            if (e == node.name) {
              colorArr.push(node.value);
            }
          });
        });
      } else {
        let CODENAME = this.$store.state.CODENAME;
        let MAP_L2 = JSON.parse(localStorage.getItem("MAP_L2"));

        Name.forEach((e) => {
          let obj = MAP_L2.find((item) => {
            return item.name == e;
          });

          let obj2 = colors.find((item) => {
            return item.name == obj.value;
          });
          colorArr.push(obj2.value);
        });

        Name = Name.map((item) => {
          return CODENAME[item];
        });

        Y.map((e) => {
          e.name = CODENAME[e.name];
        });
      }

      let typeVal = "line";
      if (pic_type !== "line") {
        typeVal = "bar";
      }

      let seriesArr = Y.map((v, index) => {
        return {
          name: v.name,
          data: v.data,
          type: typeVal, // 设置图表类型为 折线图
          symbol: "none",
          cursor: "pointer",
        };
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        title: {
          // 标题设置
          text: "ATR走势对比", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: OPTIONDATA.title.textStyle.fontSize,
          },
          top: this.ROOTFONTFIZE * (20 / 144),
          left: "center",
        },
        grid: {
          top: this.ROOTFONTFIZE * (120 / 144),
          left: this.ROOTFONTFIZE * (35 / 144),
          right: this.ROOTFONTFIZE * (200 / 144),
          bottom: this.ROOTFONTFIZE * (30 / 144),
          containLabel: true,
        },
        color: colorArr,
        legend: {
          show: true,
          icon: "roundRect",
          orient: "vertical",
          right: this.ROOTFONTFIZE * (50 / 144),
          top: "middle",
          align: "left",
          itemGap: this.ROOTFONTFIZE * (15 / 144),
          itemHeight: this.ROOTFONTFIZE * (10 / 144),
          itemWidth: this.ROOTFONTFIZE * (39 / 144),
          padding: [0, 0, 0, 0],
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: OPTIONDATA.legend.textStyle.fontSize,
            // width: 80,
            // overflow: "break",
          },
          data: Name,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (args) {
            let str = "";
            str += args[0].axisValueLabel;
            str += "<br>";
            args.forEach((node) => {
              let val = Number(node.value).toFixed(2);
              str += `${node.marker}${node.seriesName}：${val}`;
              str += "<br>";

              return str;
            });
            return str;
          },
        },
        xAxis: {
          type: "category",
          data: X,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            show: true,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
            margin: OPTIONDATA.xAxis.axisLabel.margin,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          scale: true,
          type: "value",
          // splitNumber:0.5,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
              args = Number(args).toFixed(num);
              return args;
            },
          },
          splitLine: OPTIONDATA.yAxis.splitLine,
        },
        series: seriesArr,
      };

      this.chartColumn2.setOption(option);
      this.chartColumn2.resize();
      this.loading2 = false;
      // this.funResize()
    },

    funResize() {
      // this.fnGetWidth();
      this.$nextTick(() => {
        this.chartColumn1.resize();
        this.chartColumn2.resize();
      });
    },

    fnChangeBtn1() {},
  },
  components: {
    CSelects,
    CSelect,
    CCascader,
    CButtonsSeparate,
    CTimeButtons,
    CCategoryRadio,
  },
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      // if(this.isWatch){
      //   console.log('风险相关变色');
      // }
      // return
      this.$nextTick(() => {
        if (isCODENAME) {
          this.initEmptyChart1();
          this.initEmptyChart2();
        } else {
          this.fnSetOption();
          this.initChart2();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
}

.riskRelatedCard {
  background-color: rgba(29, 31, 37, 1);
  background-color: var(--charts_bgc);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  height: auto;
}

.riskRelatedCard2_height {
}

.riskRelatedCard_tit {
  padding-left: calc(var(--ROOTFONTFIZE) * (18 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  // border-left: 5px solid rgba(21, 115, 254, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
}

.riskRelatedCard_ChooseArea {
}

.riskRelatedCard_ChooseArea_top {
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
  padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  display: flex;
}

.riskRelatedCard_ChooseArea_top span {
  // flex: 1;
  // margin-right: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.realFluctuations {
  display: flex;
  // align-items: center;
}

.time {
  margin-top: 0px;
  height: 100%;
  margin-left: calc(var(--ROOTFONTFIZE) * (90 / 144));
}

.riskRelatedCard_ChooseArea_bottom {
  display: flex;
  padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.riskRelatedCard_ChooseArea_top span,
.riskRelatedCard_ChooseArea_bottom span {
  // flex: 1;
  // min-width: 20%;
  // flex: 1;
  display: flex;
  align-items: center;
  color: var(--stock_area_item_color1);
  margin-right: calc(var(--ROOTFONTFIZE) * (50 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}

.CButtonsSeparate_style {
  // margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-left: 20px;
}

.cTimeButtons_style {
  // margin-left: 50px;
}

//============================ echarts=========================
.chartColumn1_style {
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.riskRelatedCard_content {
  padding: 0 calc(var(--ROOTFONTFIZE) * (15 / 144));
}
</style>
