<template>
  <div class="container" id="rootchart" :style="cssVars">
    <div class="CARD MAP">
      <!-- 地图 -->
      <div
        class="map"
        ref="chartColumnMAP"
        id="chartColumnMAP"
        style="width: 100%; height: 600px"
      ></div>
      <!-- 地图 end-->

      <div class="mapOther">
        <div class="title">
          <CButtons
            @Cbtns="fnGetCbtns"
            class="CARD_body_tabs"
            :dButtons1="dButtons1"
            :defaultVal="dButtons1_current" 
          />

          <div class="downloadBtns">
            <!-- <CButtonsDownload
              :dButtonsDownloads="dButtonsDownloads"
            /> -->

            <CButtonsDownloadSingle
              :dButtonsDownloads="dButtonsDownloads2"
              @fnButtondownload="fnButtonDL8_15"
            />
          </div>
        </div>

        <div class="CARD_body_echart" ref="chart8_15">
          <span  @click="fnOpenDialog"  class="iconfont icon-fangda icon-fangda2"></span>
          <div id="chartColumn1" ref="chartColumn1" style="width: 100%; height: 530px"></div>
        </div>
      </div>
    </div>

    <div class="CARD OTHER">
      <div class="CARD_body">
        <div v-show="bButtons1_card1" class="table_area">
          <div class="title2">
            <div class="downloadBtns">
              <!-- <CButtonsDownload
              :dButtonsDownloads="dButtonsDownloads"
              @fnButtondownload="fnButtonDL8_16"
            /> -->

              <CButtonsDownloadSingle
                :dButtonsDownloads="dButtonsDownloads3"
                @fnButtondownload="fnButtonDL8_16"
              />

              <span style="display: none">
                <download-excel
                  id="downloadExcel8_16"
                  :data="dAssetValueByCode"
                  :fields="json_fields"
                  header="底层资产估值变化_表格"
                  name="底层资产估值变化_表格.xls"
                >
                </download-excel>
              </span>
            </div>
          </div>
          <div>
            <el-table
              class="table1"
              :header-cell-style="styleBindFun"
              :data="dAssetValueByCode"
              style="width: 100%"
            >
              <el-table-column prop="index" label="No." align="center" width="100">
              </el-table-column>
              <el-table-column prop="资产名称" label="资产名称" align="left" width="380">
              </el-table-column>
              <el-table-column align="center" prop="资产类型" label="资产类型">
              </el-table-column>
              <el-table-column align="center" prop="所在省份" label="所在省份">
              </el-table-column>

              <el-table-column align="center" prop="所在城市" label="所在城市">
              </el-table-column>
              <el-table-column align="center" prop="用地性质" label="用地性质" width="200">
              </el-table-column>
              <el-table-column align="center" prop="上市时间" label="上市时间">
              </el-table-column>
              <el-table-column
                align="center"
                prop="运营到期时间"
                label="运营到期时间"
              >
              </el-table-column>
              <el-table-column
                align="center"
                width="200"
                prop="最新估值（亿元）"
                label="最新估值（亿元）"
              >
                <template slot-scope="scope">
                  {{
                    (parseInt(Math.abs(scope.row["最新估值（亿元）"])) + "")
                      .length >= 2
                      ? (Number(scope.row["最新估值（亿元）"]) / 10000).toFixed(
                          2
                        )
                      : (Number(scope.row["最新估值（亿元）"]) / 10000).toFixed(
                          2
                        )
                  }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>



     <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div  :style="{ height: height_chart }">
          <div ref="chartColumn1" id="chartColumn1" style="width: 100%; height: 100%"></div>
        </div>
      </el-dialog>
    </CDialog> 

  </div>
</template>

<script>
import CSelects from "@/components/Basic/CSelects";
import CCategoryRadio from "@/components/Basic/CCategoryRadio";
import CButtons from "@/components/Basic/CButtons";
import CTimeButtons from "@/components/Basic/CTimeButtons";
import CButtonsDownload from "@/components/Basic/CButtons_download";
import CDialog from "@/components/Basic/CDialog";

import chinaJSON from "@/assets/json/china.json";

import shanghaiJSON from "@/assets/json/shanghai.json";

import CButtonsDownloadSingle from "@/components/Basic/CButtons_download_single";

import html2canvas from "html2canvas";

import { fnReturnTableStyle,fnDownloadBgc } from "@/utils/util";

export default {
  props: {
    isFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

       sCurrentTheme:this.$store.state.theme == "dark" ? true : false, 
      basis_value: "",
      basis_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      days_value: "",
      days_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      level_value: "",
      level_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      classA_value: "",
      classA_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      classB_value: "",
      classB_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      radio1: "",
      radio2: "",

      dBtn1: ["3M", "6M", "1Y", "YTD", "MAX"],
      activeBtn1: "3M",

      //================ new =================================
      dButtons1: ["资产列表", "运营数据"],
      dButtons1_current: "资产列表",

      dialogVisible: false,
      width_dialog: "80%",
      height_dialog: "73vh",
      height_chart: "71vh",
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: "5vh",


      bButtons1_card1: true,
      bButtons1_card2: false,

      ReLine: ["分位数", "标准差"],
      activeBtn1: "分位数",

      chartColumn1: null,
      chart1_data: null,

      // 成交额及换手率对比
      dDailyAmtTurnoverReit: [],
      chartColumn3: null,
      chartColumn4: null,

      dAssetValueByCode: [],
      dSingleAssetArea: [],

      CODE: "",

      //  地图
      chartColumnMAP: null,

      dButtons2: ["下载图片"],
      dButtons3: ["下载表格"],

      json_fields: {},
      json_fields_data: {},

      dButtonsDownloads: [
        {
          id: "241072",
          name: "下载图片",
        },
        {
          id: "241073",
          name: "下载表格",
        },
      ],

      dButtonsDownloads2: [
        {
          id: "241072",
          name: "下载图片",
        },
      ],

      dButtonsDownloads3: [
        {
          id: "241073",
          name: "下载表格",
        },
      ],

      ROOTFONTFIZE: 0,
    };
  },
  created() {
    //   if(this.isFlag){
    //     this.fnGetCbtns('资产列表')
    //  }
  },
  mounted() {
    this.fnGetWidth()
    window.addEventListener("resize", this.funResize);
  },
  methods: {
    fnGetTheme(){
      this.sCurrentTheme = this.$store.state.theme == "dark" ? true : false
    },
    fnOpenDialog(){
      this.dialogVisible = true
      this.$nextTick(()=>{
        this.initChart1(true)
      })
    },

     fnDropdown(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      }
    },


    funResize() {
      this.fnGetWidth();
      if (this.chartColumnMAP) {
        this.chartColumnMAP.resize();
      }
      if (this.chartColumn1) {
        this.chartColumn1.resize();
      }
    },

    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem("ROOTFONTFIZE");
    },

    // =====================================================================================================
    //  表头方法
    styleBindFun() {
      let val = this.$store.state.theme == "dark" ? true : false;
      return fnReturnTableStyle(val);
    },

    // 下载 start
    fnButtonDL8_15() {
      this.fnButtonDL8_15();
    },

    fnButtonDL8_15() {
      let h = this.$refs.chart8_15.scrollHeight;
      let w = this.$refs.chart8_15.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chart8_15, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "底层资产估值变化"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    fnButtonDL8_16() {
      document.getElementById("downloadExcel8_16").click();
    },

    // 下载 end

    //  1. 表格 1
    async fnGetAssetValueByCode() {
      let code = this.CODE;
      // let code = '508000.SH';

      const { data } = await this.$https.get(
        "/api/asset_value_by_code?code=" + code
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dAssetValueByCode = data.data;

      // 下载 start
      this.json_fields_data = JSON.parse(JSON.stringify(data.data));
      let arr = Object.keys(this.json_fields_data[0]);
      arr.shift();
      arr.pop();
      arr.pop();




      arr.forEach((node) => {
        this.json_fields[node] = node;
      });

      // 下载 end
      this.$nextTick(()=>{
        
        this.initChartMAP();
      })

    },

    //  2. 底层资产估值变化（亿元）
    async fnGetSingleAssetArea() {
      // let code = '508000.SH';
      let code = this.CODE;

      const { data } = await this.$https.get(
        "/api/single_asset_area?code=" + code
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // this.dSingleAssetArea = data.data;
      let DATA = data.data;

      this.chart1_data = DATA;
      this.$nextTick(()=>{
        
        this.initChart1();
      })
    },

    initChart1(val) {

      if(!val){
        this.chartColumn1 = this.$echarts.init(document.querySelectorAll('#chartColumn1')[0]);
      }else{
        this.chartColumn1 = this.$echarts.init(document.querySelectorAll('#chartColumn1')[1]);
      }

      // this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      this.chartColumn1.clear();

      let DATA = this.chart1_data;

      let data = DATA;
      let lineData = data.area_plot;

      let X = lineData.map((node) => {
        return node.rp_date;
      });

      let keys = Object.keys(lineData[0]);
      let str1 = keys[1];
      let str2 = keys[2];
      keys.shift();

      let finallyData = [];
      keys.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };
        data.area_plot.forEach((item) => {
          obj.data.push(item[node]);
        });

        finallyData.push(obj);
      });

      // ---------------------------------

      let Y1 = {
        name: str1,
        data: [],
      };

      lineData.forEach((node) => {
        Y1.data.push(node[str1]);
      });

      let Y2 = {
        name: str2,

        data: [],
      };

      lineData.forEach((node) => {
        Y2.data.push(node[str2]);
      });

      let ipo_date = data.ipo_date;
      let markPointData = ipo_date.map((node) => {
        let obj = {
          yAxis: 0,
          xAxis: node.IPO_date,
          //  字体会重叠
          label: {
            normal: {
              show: true,
              // formatter: `{d|${yIDataPointValue}} {b|bps}`,
              formatter: `${node.type_}`,
              margin: this.ROOTFONTFIZE * (10 / 144),
              position: "right",
              textStyle: {
                color: "#fff",
              },
            },
          },
        };
        return obj;
      });

      let series = finallyData.map((node) => {
        return {
          type: "line",
          name: node.name,
          data: node.data,
          symbol: "none",
          stack: "all",
          itemStyle: {
            // color:'rgba(90, 202, 198, 1)'
          },
          // barWidth: "37px",
          areaStyle: {
            // color:'rgba(90, 202, 198, 1)'
          },
        };
      });

      let obj = {
        name: "IPO标志",
        type: "line",
        symbol: "none",
        markPoint: {
          // 标记的数据，可以是最大值最小值也可以是自定义的坐标
          data: markPointData,
          symbol: "triangle", // 标记图形
          symbolSize: this.ROOTFONTFIZE * (18 / 144),
          symbolOffset: [0, "50%"],
          // 标注点的样式
          itemStyle: {
            color: "rgba(212, 48, 48, 1)", // 标注点颜色
            label: {
              show: true,
            },
          },
        },
      };

      series.push(obj);

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        color: [
          "#5acac6",
          "#598fc8",
          "#00A6FF",
          "#85D907",
          "#BEE5EB",
          "#1B75CF",
          "#1B75CF",
          "#87CEFA",
          "#C8FF00",
          "#FFFF44",
          "#C3E874",
          "#ff461e",
        ],
        title: {
          // 标题设置
          text: "底层资产估值变化", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (16 / 144),
          },
          left: "center",
          top: this.ROOTFONTFIZE * (20 / 144),
        },
        grid: {
          top: keys.length > 5 ? this.ROOTFONTFIZE * (185 / 144) : keys.length > 2 ? this.ROOTFONTFIZE * (150 / 144) : this.ROOTFONTFIZE * (130 / 144),
          bottom: this.ROOTFONTFIZE * (20 / 144),
          left: this.ROOTFONTFIZE * (20 / 144),
          right: this.ROOTFONTFIZE * (60 / 144),
          containLabel: true,
        },
        legend: {
          show: true,
          orient: "horizontal",
          // left:'right',
          left: this.ROOTFONTFIZE * (20 / 144),
          align: "left",
          top: this.ROOTFONTFIZE * (55 / 144),
          // x:50,
          // x2: 150,
          icon: "roundRect",
          itemGap: this.ROOTFONTFIZE * (15 / 144),
          itemWidth: this.ROOTFONTFIZE * (10 / 144),
          itemHeight: this.ROOTFONTFIZE * (10 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
          data: keys,
        },
        tooltip: {
          // 工具提示
          trigger: "axis", // 工具提示的类型 item代表的是每个柱本身, axis代表的是坐标轴
          triggerOn: "mousemove|click", // 触发时机, click代表点击, mouseOver代表鼠标移过
          formatter: function (args) {
            // 文字格式化
            console.log(args, "少女时代西卡");
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node) => {
              str += `${node.marker}${node.seriesName}：${
                node.value ? Number(node.value / 10000).toFixed(1) : 0
              }${node.value ? "" : ""}`;
              str += "<br>";
              return str;
            });
            return str;
          },
        },
        xAxis: {
          type: "category",
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: true,
          },
          axisLabel: {
            // interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: this.ROOTFONTFIZE * (30 / 144),
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          name: "单位：(亿元)",
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          type: "value",
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (OPTIONDATA.yAxis.axisLabel.fontSize / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              args = Number(args) / 10000;
              let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
              args = args.toFixed(num);
              return args;
              //  args =  Number(args) / 100000000
              //  args = args.toFixed(1)+'亿'
              //   return args
            },
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: series,
      };

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
      // this.funResize()
    },

    // 地图
    initChartMAP() {
      this.chartColumnMAP = this.$echarts.init(this.$refs.chartColumnMAP);
      this.chartColumnMAP.clear();

      let data = this.dAssetValueByCode;

      console.log(data,'地图data');

      let colors = this.$store.state.colors;

      let NAME = data.map((node) => {
        return node.资产类型;
      });

      NAME = [...new Set(NAME)];

      let color = [];
      NAME.forEach((e) => {
        let obj = colors.filter((node) => {
          return node.name == e;
        });
        color.push(obj[0].value);
      });
      color.unshift("#fff");

      // 计算每个省市有多少个
      data.forEach((node) => {
        if (node["所在省份"].length == 2) {
          return (node["所在省份"] = node["所在省份"] + "市");
        }
      });
      // foreach 漂亮!!!

      let provinceHave = data.map((node) => {
        return node["所在省份"];
      });
      provinceHave = [...new Set(provinceHave)];

      let mapData = provinceHave.map((node) => {
        let obj = {
          name: node,
          value: 0,
        };
        data.forEach((item) => {
          if (node == item["所在省份"]) {
            obj.value++;
          }
        });
        return obj;
      });

      mapData.forEach((node) => {
        node.name = node.name.slice(0, 2);
      });

      let seriesArr = data.map((v, index) => {
        return {
          name: v["资产类型"],
          data: [[v.lon, v.lat]],
          type: "effectScatter",
          rippleEffect: {
            scale: 1.8, // 设置涟漪动画的缩放比例
          },
          coordinateSystem: "geo",
          symbolSize: this.ROOTFONTFIZE * (10 / 144),
          // symbolSize: 6+(Number(v["最新估值（亿元）"]) / 100)*20,
          // symbolSize: v["最新估值（亿元）"]  / 2,
          cursor: "pointer",
          itemStyle: {
            color: function (val) {
              let name = val.seriesName;
              let obj = colors.filter((node) => {
                return node.name == name;
              });
              return obj[0].value;
            },
          },
        };
      });

      let obj = {
        data: mapData,
        geoIndex: 0,
        type: "map",
        // center:[121.6368,31.220926]
      };
      seriesArr.unshift(obj);

      let centerData = [data[0].lon, data[0].lat];

      // 获取中国地图的矢量数据
      // const ret =  await axios.get('http://10.168.1.124:8890/json/china.json')
      // 1.
      this.$echarts.registerMap("chinaMap", chinaJSON);

         let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        color: color,
        grid: {
          left: 0,
        },
        geo: {
          type: "map",
          map: "chinaMap", // chinaMap需要和registerMap中的第一个参数保持一致
          roam: true, // 设置允许缩放以及拖动的效果
          zoom: 100,
          center: centerData,
          label: {
            show: true, // 展示标签
            textStyle: {
              color: "#333",
              fontSize: this.ROOTFONTFIZE * (16 / 144),
            },
            emphasis: {
              textStyle: {
                color: "#666",
              },
            },
          },
          itemStyle: {
            normal: {
              // borderColor: '#3b3d42',
              // borderWidth: 1,
              areaColor: "#fff",
            },
            emphasis: {
              areaColor: "#d8d8d9",
              // borderWidth: 0,
              // color: 'green'
            },
          },
        },

        tooltip: {
          trigger: "item",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            if (args.seriesType == "map") return;

            args.value[0];

            let obj = data.find((node) => {
              return node.lon == args.value[0] && node.lat == args.value[1];
            });

            let marker1 = '<span style="color:#666666;">资产名称：</span>';
            let marker2 = '<span style="color:#666666;">资产类型：</span>';
            let marker3 = '<span style="color:#666666;">所在省份：</span>';
            let marker4 = '<span style="color:#666666;">所在城市：</span>';
            let marker5 = '<span style="color:#666666;">用地性质：</span>';
            let marker6 = '<span style="color:#666666;">上市时间：</span>';
            let marker7 =
              '<span style="color:#666666;">最新估值（亿元）：</span>';
            let marker8 = '<span style="color:#666666;">运营到期时间：</span>';
            let marker9 = '<span style="color:#666666;">股票代码：</span>';
            let marker10 = '<span style="color:#666666;">股票简称：</span>';

            let str = "";
            str +=
              // str += `${marker}${args.seriesIndex}`;

              str += `<span style=\"font-weight: bolder;\">${obj["资产名称"]}</span>`;
            str += "<br>";

            //  str += `${marker9} <span style=\"color:#787878 ;\">${obj["reit_code"]}</span>`;
            // str += "<br>";

            //  str += `${marker10} <span style=\"color:#787878 ;\">${obj["REITs_简称"]}</span>`;
            // str += "<br>";

            str += `${marker2} <span style=\"color:#787878 ;\">${obj["资产类型"]}</span>`;
            str += "<br>";
            str += `${marker3} <span style=\"color:#787878 ;\">${obj["所在省份"]}</span>`;
            str += "<br>";
            str += `${marker4} <span style=\"color:#787878 ;\">${obj["所在城市"]}</span>`;
            str += "<br>";
            str += `${marker5} <span style=\"color:#787878 ;\">${obj["用地性质"]}</span>`;
            str += "<br>";
            str += `${marker8} <span style=\"color:#787878 ;\">${obj["运营到期时间"]}</span>`;
            str += "<br>";
            str += `${marker7} <span style=\"color:#787878 ;\">${obj["最新估值（亿元）"]}</span>`;
            str += "<br>";
            str += `${marker6} <span style=\"color:#787878 ;\">${obj["上市时间"]}</span>`;

            str += "<br>";

            return str;
          },
        },

        legend: {
          orient: "vertical",
          top: "middle",
          icon: "circle",
          // right: this.ROOTFONTFIZE * (10 / 144),
          right: this.ROOTFONTFIZE * (10 / 144),
          // itemGap: this.ROOTFONTFIZE * (20 / 144),
          itemGap: this.ROOTFONTFIZE * (20 / 144),

          textStyle: {
            // color: "#fff",
            // color: "#7f7f7f",
            color:OPTIONDATA.legend.textStyle.color,
            // fontSize: this.ROOTFONTFIZE * (14 / 144),
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },

          itemStyle: {
            color: "inherit",
          },

          align: "left",
          data: NAME,
        },

        series: seriesArr,

        // visualMap: {
        //   // type: "piecewise",
        //   // orient: "horizontal",
        //   min: 0,
        //   max: 20,
        //   seriesIndex:0,
        //   // itemWidth: 30,
        //   // itemHeight: 10,
        //   inRange: {
        //     color: [
        //       "#ffffff",
        //       "#a4a5a7",
        //     ], // 控制颜色渐变的范围
        //   },
        //   // left: 70,
        //   // bottom: 90,
        //   // text: ["loss", "gain"],
        //   // textStyle: {
        //     // color: "rgba(218, 218, 218, 1)",
        //     // fontSize: 16,
        //     // top: 50,
        //   // },
        //   calculable: true, // 出现滑块
        // },
      };

      this.chartColumnMAP.setOption(option);
      this.chartColumnMAP.resize();
      // this.funResize()
    },

    fnGetCbtns(data) {
      this.CODE = window.sessionStorage.getItem("CURRENTCODE");

      if (data == "资产列表") {
        this.bButtons1_card1 = true;
        this.bButtons1_card2 = false;
        this.dButtons1_current = "资产列表";
        this.CODE = window.sessionStorage.getItem("CURRENTCODE");
        this.fnGetAssetValueByCode();
        this.fnGetSingleAssetArea();
      }

      if (data == "运营数据") {
        this.bButtons1_card1 = false;
        this.bButtons1_card2 = true;
        this.dButtons1_current = "运营数据";
      }
    },
  },
  components: {
    CSelects,
    CCategoryRadio,
    CButtons,
    CTimeButtons,
    CButtonsDownload,
    CDialog,
    CButtonsDownloadSingle,
  },
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.styleBindFun();
       this.fnGetTheme()
        fnDownloadBgc()
      this.$nextTick(()=>{
          this.initChartMAP()
      this.initChart1();
      })

 
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  // background-color: pink;
}

.CARD {
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: var(--charts_bgc);
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  // height: 1000px;
  // margin-right: 30px;
}

.CARD:last-child {
  margin-right: 0px;
  margin-bottom: 0px;
}

.mt {
  margin-top: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.MAP {
  display: flex;
}

.map {
  flex: 5;
  padding: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.mapOther {
  flex: 5;
  padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.OTHER {
}

.CARD_tit {
  padding-left: calc(var(--ROOTFONTFIZE) * (22 / 144));
  // border-left: 5px solid rgba(21, 115, 254, 1);
  font-size: calc(var(--ROOTFONTFIZE) * (36 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (30 / 144));
  // height: 20px;
  color: rgba(255, 255, 255, 1);
}

.CARD_tit2 {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: rgba(255, 255, 255, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.CARD_body {
  padding: 0 calc(var(--ROOTFONTFIZE) * (30 / 144));
  height: 100%;
}

.CARD_body_tabs {
  // margin-top: 30px;
}

.marketValue {
  margin-top: calc(var(--ROOTFONTFIZE) * (35 / 144));
}

.marketValue .value span:nth-child(1) {
  font-size: calc(var(--ROOTFONTFIZE) * (36 / 144));
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);
}

.marketValue .value span:nth-child(2) {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(49, 208, 104, 1);
  margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.marketValue .time {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: calc(var(--ROOTFONTFIZE) * (16 / 144));
  color: rgba(255, 255, 255, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

.CARD_body_echart {
  background: rgba(0, 0, 0, 0.1);
  border: 0.2px solid rgba(135, 135, 135, 1);
  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);
  border-radius: calc(var(--ROOTFONTFIZE) * (5 / 144));
  position: relative;
  // margin-top: 40px;
}

.choose_area {
  margin-top: calc(var(--ROOTFONTFIZE) * (24 / 144));
}

.charts_area {
  display: flex;
}

.choose_area_left {
  flex: 1;
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  height: calc(var(--ROOTFONTFIZE) * (375 / 144));
}

.choose_area_right {
  flex: 1;
  height: calc(var(--ROOTFONTFIZE) * (375 / 144));
}

.table_area {
  // margin-top: 17px;
  // margin-bottom: calc(var(--ROOTFONTFIZE) * (0 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
  height: 100%;
}

.HEIGHT_396 {
  height: calc(var(--ROOTFONTFIZE) * (396 / 144)) !important;
}

.HEIGHT_664 {
  height: calc(var(--ROOTFONTFIZE) * (664 / 144)) !important;
}

.HEIGHT_370 {
  height: calc(var(--ROOTFONTFIZE) * (370 / 144)) !important;
}

.HEIGHT_471 {
  height: calc(var(--ROOTFONTFIZE) * (471 / 144)) !important;
}

.HEIGHT_396 {
  height: calc(var(--ROOTFONTFIZE) * (396 / 144)) !important;
}

.title {
  display: flex;
  justify-content: space-between;
}

.title2 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

::v-deep .el-table {
  border: none !important;
  background-color: var(--table_bgc);
  // height: auto !important;
}

::v-deep .el-table::before {
  background-color: var(--table_bgc);
}

::v-deep .table1 table {
  border: none;
}

::v-deep .el-table__body .el-table__row .el-table__cell {
  background-color: var(--table_bgc);
  color: var(--table_color);
  border-bottom: 1px solid var(--table_brc);
  height: calc(var(--ROOTFONTFIZE) * (60 / 144));
}

::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: var(--table_bgc_hover);
  color: var(--table_color_hover);
}
</style>
