<template>
  <div class="rootArea" id="rootchart" :style="cssVars">
    <div class="chooseArea">
      <span>
        <el-button
          v-for="(item, index) in dButtons1"
          :key="index"
          @click="fnButtons1(item)"
          :class="item == dButtons1_current ? 'checkedBtnStyle' : ''"
          class="noActive"
          size="mini"
        >
          {{ item }}
        </el-button>
      </span>
    </div>

    <div class="main" v-show="bOverview">
      <div class="title">
        资金总体构成数据

        <div class="downloadBtns">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads"
            @fnButtondownload="fnButtonDL5_1"
          />

          <span style="display: none">
            <download-excel
              id="downloadExcel5_1"
              :data="json_fields_pie_data"
              :fields="json_fields_pie"
              header="资金总体构成数据_饼图"
              name="资金总体构成数据_饼图.xls"
            >
            </download-excel>
          </span>

          <span style="display: none">
            <download-excel
              id="downloadExcel5_2"
              :data="json_fields_bar_data"
              :fields="json_fields_bar"
              header="资金总体构成数据_柱状图"
              name="资金总体构成数据_柱状图.xls"
            >
            </download-excel>
          </span>
        </div>

        <!-- <span @click="FnDownload()">
          <i class="el-icon-download"></i>
        </span> -->
      </div>

      <div class="main_choose pl20">
        <span>
          <!-- :multiple="true" -->
          <!-- :isDelete="true" -->
          <!-- :collapsetags="true" -->
          <CCascader
            :checkStrictly="true"
            :multiple="false"
            :default="category_cascader_val_chart12"
            @CCascader_select_val="fnCCascader_select_val_chart12"
            :options="category_cascader_options"
            >选择类别</CCascader
          >
        </span>

        <span class="start_date_area">
          <CunknowButtons
            ref="CunknowButtons1"
            :default="start_date_chart12"
            @CunknowButtons_select_val="fnCTimeButtons_select_val_chart12"
          />
        </span>
      </div>

      <!-- <div class="main_choose">
        <span>
          <CButtonsSeparate
            @Cbtns_sep="fnGetCbtnsSep_chart12"
            :dButtons1="dButtons_separrate_chart12"
            :dButtons1_current="dButtons_separrate_current_chart12"
          />
        </span>
      </div> -->

      <!-- element-loading-background="rgba(0, 0, 0, 0.8)" -->

      <div
        v-loading="loading"
        element-loading-text="数据量巨大，正在计算中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(1, 1, 1, 0.8)"
        ref="content"
      >
        <div class="content">
          <div class="content_left">
            <span
              @click="fnOpenDialog3"
              class="iconfont icon-fangda icon-fangda2"
            ></span>
            <div
              class="canvasArea1"
              ref="chartColumn1"
              style="width: 100%; height: 340px"
            ></div>
            <div class="info deadline_style">数据截止日期：{{ DDL }}</div>
          </div>
          <div class="content_right">
            <div class="InflowIntensity">
              <div class="InflowIntensity_title">
                <span>近{{ start_date_chart12.slice(0, -1) }}日主力净流入</span>
                <span>单位（万元）</span>
              </div>
              <div class="InflowIntensity_body">
                <div class="InflowIntensity_body_top">流入强度</div>
                <div class="InflowIntensity_body_Lines">
                  <div
                    v-for="node in [1, 2, 3, 4, 5, 6]"
                    :key="node"
                    :class="node == AFTER_INDEX ? 'special' : ''"
                  ></div>
                </div>
              </div>
            </div>

            <div
              class="canvasArea2"
              ref="chartColumn6"
              style="width: 100%; height: 215px"
            ></div>
          </div>
        </div>

        <div class="content">
          <div class="content_left tableArea">
            <table>
              <tr>
                <th></th>
                <th>流入（万元）</th>
                <th>流出（万元）</th>
                <th>净流入（万元）</th>
              </tr>

              <tr class="rowArea odd">
                <td>机构</td>
                <td
                  :class="
                    dFundFlowTableLeft_JG[0] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{
                    parseInt(
                      Number(dFundFlowTableLeft_JG[0]) / 10000
                    ).toLocaleString()
                  }}
                </td>

                <!-- <td class="inflowStyle">{{(Number(dFundFlowTableLeft_JG[1]) / 10000).toFixed(2)+'万'}}</td> -->
                <!-- :class="dFundFlowTableLeft_JG[1]>0?'inflowStyle':'outflowStyle'" -->
                <td class="outflowStyle">
                  {{
                    parseInt(
                      Number(dFundFlowTableLeft_JG[1]) / 10000
                    ).toLocaleString()
                  }}
                </td>

                <!-- <td class="outflowStyle">{{(Number(dFundFlowTableLeft_JG[2]) / 10000).toFixed(2)+'万'}}</td> -->
                <td
                  :class="
                    dFundFlowTableLeft_JG[2] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{
                    parseInt(
                      Number(dFundFlowTableLeft_JG[2]) / 10000
                    ).toLocaleString()
                  }}
                </td>
              </tr>

              <tr class="rowArea">
                <td>大单</td>
                <!-- <td class="inflowStyle">{{(Number(dFundFlowTableLeft_DD[0]) / 10000).toLocaleString()}}</td> -->
                <td
                  :class="
                    dFundFlowTableLeft_DD[0] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{
                    parseInt(
                      Number(dFundFlowTableLeft_DD[0]) / 10000
                    ).toLocaleString()
                  }}
                </td>

                <!-- <td class="inflowStyle">{{(Number(dFundFlowTableLeft_DD[1]) / 10000).toFixed(2)+'万'}}</td> -->
                <td class="outflowStyle">
                  {{
                    parseInt(
                      Number(dFundFlowTableLeft_DD[1]) / 10000
                    ).toLocaleString()
                  }}
                </td>

                <!-- <td class="outflowStyle"> -->
                <td
                  :class="
                    dFundFlowTableLeft_DD[2] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{
                    parseInt(
                      Number(dFundFlowTableLeft_DD[2]) / 10000
                    ).toLocaleString()
                  }}
                </td>
              </tr>

              <tr class="rowArea odd">
                <td>中单</td>
                <!-- <td class="inflowStyle">{{dFundFlowTableLeft_ZD[0]}}</td> -->
                <td
                  :class="
                    dFundFlowTableLeft_ZD[0] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{
                    parseInt(
                      Number(dFundFlowTableLeft_ZD[0]) / 10000
                    ).toLocaleString()
                  }}
                </td>
                <!-- <td class="inflowStyle">{{dFundFlowTableLeft_ZD[1]}}</td> -->
                <!-- <td class="inflowStyle">{{(Number(dFundFlowTableLeft_ZD[1]) / 10000).toFixed(2)+'万'}}</td> -->
                <!-- <td class="inflowStyle">{{(Number(dFundFlowTableLeft_ZD[1]) / 10000).toLocaleString()}}</td> -->
                <td class="outflowStyle">
                  {{
                    parseInt(
                      Number(dFundFlowTableLeft_ZD[1]) / 10000
                    ).toLocaleString()
                  }}
                </td>
                <!-- <td class="outflowStyle">{{dFundFlowTableLeft_ZD[2]}}</td> -->
                <!-- <td class="outflowStyle"> -->
                <td
                  :class="
                    dFundFlowTableLeft_ZD[2] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{
                    parseInt(
                      Number(dFundFlowTableLeft_ZD[2]) / 10000
                    ).toLocaleString()
                  }}
                </td>
              </tr>

              <tr class="rowArea">
                <td>小单</td>
                <td
                  :class="
                    dFundFlowTableLeft_XD[0] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{
                    parseInt(
                      Number(dFundFlowTableLeft_XD[0]) / 10000
                    ).toLocaleString()
                  }}
                </td>

                <!-- <td class="inflowStyle">{{(Number(dFundFlowTableLeft_XD[1]) / 10000).toFixed(2)+'万'}}</td> -->
                <td class="outflowStyle">
                  {{
                    parseInt(
                      Number(dFundFlowTableLeft_XD[1]) / 10000
                    ).toLocaleString()
                  }}
                </td>

                <!-- <td class="outflowStyle"> -->
                <td
                  :class="
                    dFundFlowTableLeft_XD[2] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{
                    parseInt(
                      Number(dFundFlowTableLeft_XD[2]) / 10000
                    ).toLocaleString()
                  }}
                </td>
              </tr>
            </table>
          </div>
          <div class="content_right tableArea">
            <table>
              <tr>
                <th>天数</th>
                <th>净流天</th>
                <th>净流额（万元）</th>
                <th>净流率</th>
              </tr>
              <tr class="rowArea odd">
                <td>5</td>
                <td
                  :class="
                    dFundFlowTableRight_ONE[0] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{ dFundFlowTableRight_ONE[0] }}
                </td>
                <td
                  :class="
                    dFundFlowTableRight_ONE[1] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{
                    parseInt(
                      Number(dFundFlowTableRight_ONE[1]) / 10000
                    ).toLocaleString()
                  }}
                </td>
                <td
                  :class="
                    dFundFlowTableRight_ONE[2] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{ Number(dFundFlowTableRight_ONE[2]).toFixed(3) + "%" }}
                </td>
              </tr>

              <tr class="rowArea">
                <td>10</td>
                <td
                  :class="
                    dFundFlowTableRight_TWO[0] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{ dFundFlowTableRight_TWO[0] }}
                </td>
                <td
                  :class="
                    dFundFlowTableRight_TWO[1] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{
                    parseInt(
                      Number(dFundFlowTableRight_TWO[1]) / 10000
                    ).toLocaleString()
                  }}
                </td>

                <td
                  :class="
                    dFundFlowTableRight_TWO[2] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{ Number(dFundFlowTableRight_TWO[2]).toFixed(3) + "%" }}
                </td>
              </tr>

              <tr class="rowArea odd">
                <td>20</td>
                <td
                  :class="
                    dFundFlowTableRight_THREE[0] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{ dFundFlowTableRight_THREE[0] }}
                </td>
                <td
                  :class="
                    dFundFlowTableRight_THREE[1] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{
                    parseInt(
                      Number(dFundFlowTableRight_THREE[1]) / 10000
                    ).toLocaleString()
                  }}
                </td>

                <td
                  :class="
                    dFundFlowTableRight_THREE[2] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{ Number(dFundFlowTableRight_THREE[2]).toFixed(3) + "%" }}
                </td>
              </tr>

              <tr class="rowArea">
                <td>60</td>
                <td
                  :class="
                    dFundFlowTableRight_FOUR[0] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{ dFundFlowTableRight_FOUR[0] }}
                </td>
                <td
                  :class="
                    dFundFlowTableRight_FOUR[1] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{
                    parseInt(
                      Number(dFundFlowTableRight_FOUR[1]) / 10000
                    ).toLocaleString()
                  }}
                </td>

                <td
                  :class="
                    dFundFlowTableRight_FOUR[2] > 0
                      ? 'inflowStyle'
                      : 'outflowStyle'
                  "
                >
                  {{ Number(dFundFlowTableRight_FOUR[2]).toFixed(3) + "%" }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="title secondTit">
        <!-- 总体 公募REITs前5日以来资金流向统计 -->
        近{{ start_date_chart12.slice(0, -1) }}日以来最大流入10支REITs流向统计

        <div class="downloadBtns">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads"
            @fnButtondownload="fnButtonDL5_7"
          />

          <span style="display: none">
            <download-excel
              id="downloadExcel5_10"
              :data="json_fields_bar_below_data"
              :fields="json_fields_bar_below"
              header="近5日以来最大流入10支REITs流向统计"
              name="近5日以来最大流入10支REITs流向统计.xls"
            >
            </download-excel>
          </span>
        </div>
      </div>

      <div class="newAddStyle">
        <CunknowButtons
          id="CunknowButtons1"
          ref="CunknowButtons2"
          :default="start_date_chart12"
          @CunknowButtons_select_val="fnCTimeButtons_select_val_chart12"
        />
      </div>

      <div class="content bar">
        <span
          @click="fnOpenDialog4"
          class="iconfont icon-fangda icon-fangda2"
        ></span>

        <div
          class="canvasArea1"
          ref="chartColumn3"
          style="width: 100%; height: 655px"
        ></div>
        <div class="info deadline_style">数据截止日期：{{ DDL }}</div>
        <div class="info">
          <!-- 数据截止日期：2023-07-07 数据来源：wind 笔者葱花拌豆腐整理 -->
        </div>
      </div>
    </div>

    <div class="main" v-show="bPrimary">
      <div class="title">
        历史资金构成

        <div class="downloadBtns">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads"
            @fnButtondownload="fnButtonDL5_5"
          />

          <span style="display: none">
            <download-excel
              id="downloadExcel5_5"
              :data="json_fields_all_data"
              :fields="json_fields_all"
              header="历史资金构成"
              name="历史资金构成.xls"
            >
            </download-excel>
          </span>

          <!-- <span style="display: none">
            <download-excel
              id="downloadExcel5_5"
              :data="json_fields_left_data"
              :fields="json_fields_left"
              header="历史资金构成_左轴数据"
              name="历史资金构成_左轴数据.xls"
            >
            </download-excel>
          </span> -->

          <span style="display: none">
            <download-excel
              id="downloadExcel5_6"
              :data="json_fields_right_data"
              :fields="json_fields_right"
              header="历史资金构成_右轴数据"
              name="历史资金构成_右轴数据.xls"
            >
            </download-excel>
          </span>

          <span style="display: none">
            <download-excel
              id="downloadExcel5_7"
              :data="json_fields_ipo_data"
              :fields="json_fields_ipo"
              header="历史资金构成_IPO数据"
              name="历史资金构成_IPO数据.xls"
            >
            </download-excel>
          </span>
        </div>
        <!-- <span @click="FnDownload2()">
          <i class="el-icon-download"></i>
        </span> -->
      </div>

      <div class="main_choose pl20">
        <div>
          <!-- 
            :multiple="true"
            :collapsetags="true" -->
          <CCascader
            :checkStrictly="true"
            :default="category_cascader_val_chart3"
            @CCascader_select_val="fnCCascader_select_val_chart3"
            :options="category_cascader_options"
            >选择类别</CCascader
          >
        </div>

        <div class="cselect_area">
          <CSelect
            @CSelect_select_val="fnCSelect_chart3"
            :multiple="false"
            :clearable="false"
            :auto="true"
            :default="select_val_left"
            :options="options_left"
            >左轴占比选项
          </CSelect>
        </div>

        <div>
          <CButtonsSeparate
            @Cbtns_sep="fnGetCbtnsSep_chart3"
            :dButtons1="dButtons_separrate_chart3"
            :dButtons1_current="dButtons_separrate_current_chart3"
          />
        </div>

        <span class="mainforce" v-show="bChecked_chart3">
          只看主力
          <el-checkbox
            @change="fnGetNetOrActiveFlowAnalysis"
            v-model="Checked_chart3"
          ></el-checkbox>
        </span>
      </div>

      <div class="main_choose centerStyle">
        <div>
          <!-- :threeY="true" -->
          <CTimeButtons
            :default="start_date_chart3"
            @CTimeButtons_select_val="fnCTimeButtons_select_val_chart3"
          />
        </div>
      </div>

      <div class="content history">
        <span
          @click="fnOpenDialog"
          class="iconfont icon-fangda icon-fangda2"
        ></span>
        <div
          class="canvasArea1"
          id="chartColumn4"
          ref="chartColumn4"
          style="width: 100%; height: 560px"
        ></div>
      </div>
    </div>

    <div class="main" v-show="bPrimary">
      <div class="title">
        大宗交易

        <div class="downloadBtns">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads"
            @fnButtondownload="fnButtonDL5_6"
          />

          <span style="display: none">
            <download-excel
              id="downloadExcel5_8"
              :data="json_fields_charts_data"
              :fields="json_fields_charts"
              header="大宗交易"
              name="大宗交易.xls"
            >
            </download-excel>
          </span>

          <span style="display: none">
            <download-excel
              id="downloadExcel5_9"
              :data="json_fields_ipo2_data"
              :fields="json_fields_ipo2"
              header="大宗交易_IPO数据"
              name="大宗交易_IPO数据.xls"
            >
            </download-excel>
          </span>
        </div>

        <!-- <span @click="FnDownload3()">
          <i class="el-icon-download"></i>
        </span> -->
      </div>

      <div class="main_choose pl20">
        <span>
          <!-- :multiple="true" -->
          <!-- :collapsetags="true" -->
          <CCascader
            :checkStrictly="true"
            :default="category_cascader_val_chart4"
            @CCascader_select_val="fnCCascader_select_val_chart4"
            :options="category_cascader_options"
            >选择类别</CCascader
          >
        </span>

        <span class="cselect_area">
          <CSelect
            @CSelect_select_val="fnCSelect_chart4"
            :multiple="false"
            :clearable="false"
            :auto="true"
            :default="select_val_Proportion"
            :options="options_Proportion"
            >占比选项
          </CSelect>
        </span>

        <span>
          <CTimeButtons
            :default="start_date_chart4"
            @CTimeButtons_select_val="fnCTimeButtons_select_val_chart4"
          />
        </span>
      </div>

      <div class="content history">
        <span
          @click="fnOpenDialog2"
          class="iconfont icon-fangda icon-fangda2"
        ></span>
        <div
          class="canvasArea1"
          id="chartColumn5"
          ref="chartColumn5"
          style="width: 100%; height: 639px"
        ></div>
      </div>
    </div>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            class="canvasArea1"
            ref="chartColumn4_dialog"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible2"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible2"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown2"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            class="canvasArea1"
            ref="chartColumn5"
            id="chartColumn5"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible3"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible3"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown3"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
           
          <div
            class="canvasArea1"
            ref="chartColumn1_dialog"
            style="width: 100%; height: 100%"
          ></div>
                     
          <div class="info deadline_style">数据截止日期：{{ DDL }}</div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible4"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible4"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown4"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <!-- ref="chartColumn3_dialog" -->
          <div
            class="canvasArea1"
            ref="chartColumn3_dialog"
            style="width: 100%; height: 100%"
          ></div>
          <div class="info deadline_style">数据截止日期：{{ DDL }}</div>
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CSelects from "@/components/Basic/CSelects";
import CSelect from "@/components/Basic/CSelect";
import CCategoryButton from "@/components/Basic/CCategoryButton";
import CButtonsSeparate from "@/components/Basic/CButtons_separate";
import CButtonsDownload from "@/components/Basic/CButtons_download";

import CTimeButtons from "@/components/Basic/CTimeButtons";
import CunknowButtons from "@/components/Basic/CunknowButtons";

import CCascader from "@/components/Basic/CCascader";
import CDialog from "@/components/Basic/CDialog";

import html2canvas from "html2canvas";

import { fnDownloadBgc, fnComputeTime } from "@/utils/util";

export default {
  data() {
    return {
      disnone: false,
      sCurrentTheme: this.$store.state.theme == "dark" ? true : false,
      loading: false,

      // 下载 start
      json_fields_pie: {},
      json_fields_pie_data: [],

      json_fields_table: {},
      json_fields_table_data: [],

      json_fields_bar_below: {},
      json_fields_bar_below_data: [],

      json_fields_bar: {},
      json_fields_bar_data: [],

      json_fields_table2: {},
      json_fields_table2_data: [],

      json_fields_left: {},
      json_fields_left_data: [],

      json_fields_right: {},
      json_fields_right_data: [],

      json_fields_all: {},
      json_fields_all_data: [],

      json_fields_ipo: {},
      json_fields_ipo_data: [],

      json_fields_charts: {},
      json_fields_charts_data: [],

      json_fields_ipo2: {},
      json_fields_ipo2_data: [],

      dButtonsDownloads: [
        {
          id: "241016",
          name: "下载图片",
        },
        {
          id: "241015",
          name: "下载表格",
        },
      ],

      // 下载 end

      dBtn1: ["3M", "6M", "1Y", "YTD", "MAX"],
      activeBtn1: "3M",

      lCategory_value: "",
      lCategory_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      category_value: "",
      category_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      inflow: ["流入", "主动流入"],
      inflow2: ["主力流入", "主动买入"],

      summaryChecked: "",

      // 从头开始第一个
      start_date_chart12: "10D",
      category_cascader_options: [
        {
          value: "产权类",
          label: "产权类",
          children: [
            {
              value: "物流仓储",
              label: "物流仓储",
            },
            {
              value: "产业园",
              label: "产业园",
            },
            {
              value: "保障性住房",
              label: "保障性住房",
            },
            {
              value: "消费",
              label: "消费",
            },
          ],
        },
        {
          value: "经营权类",
          label: "经营权类",
          children: [
            {
              value: "市政生态",
              label: "市政生态",
            },
            {
              value: "能源",
              label: "能源",
            },
            {
              value: "收费公路",
              label: "收费公路",
            },
          ],
        },
        {
          value: "公募REITs",
          label: "公募REITs",
        },
      ],
      category_cascader_val_chart12: "公募REITs",
      dButtons_separrate_chart12: ["流入"],
      dButtons_separrate_current_chart12: "流入",

      start_date_chart3: "3M",
      category_cascader_val_chart3: "公募REITs",
      options_left: [
        {
          label: "流通市值",
          value: "market_cap_float",
        },
        {
          label: "市值",
          value: "market_cap",
        },
        {
          label: "成交额",
          value: "amt",
        },
      ],
      select_val_left: "market_cap_float",
      dButtons_separrate_chart3: ["流入", "主动流入"],
      dButtons_separrate_current_chart3: "流入",
      Checked_chart3: false,
      bChecked_chart3: false,

      start_date_chart4: "YTD",
      category_cascader_val_chart4: "公募REITs",
      options_Proportion: [
        {
          label: "成交量",
          value: "vol",
        },
        {
          label: "成交额",
          value: "amt",
        },
      ],
      select_val_Proportion: "amt",

      chartColumn1: null,
      chartColumn1_dialog: null,

      chartColumn7: null,
      dFundFlowPie: [],

      dFundFlowTableLeft_ZD: [],
      dFundFlowTableLeft_DD: [],
      dFundFlowTableLeft_XD: [],
      dFundFlowTableLeft_JG: [],

      dFundFlowTableRight_ONE: [],
      dFundFlowTableRight_TWO: [],
      dFundFlowTableRight_THREE: [],
      dFundFlowTableRight_FOUR: [],

      dFundFlowTableBar: [],

      chartColumn3: null,
      chartColumn3_dialog: null,

      dFundFlowBar: [],
      dFundFlowBar_X: [],
      dFundFlowBar_X_chart3: [],
      dFundFlowBar_ZD: [],
      dFundFlowBar_DD: [],
      dFundFlowBar_XD: [],
      dFundFlowBar_JG: [],

      chartColumn4: null,
      chartColumn4_dialog: null,

      chart4_data: [],
      dNetOrActiveFlowAnalysis_left: [],
      dNetOrActiveFlowAnalysis_left_X: [],
      dNetOrActiveFlowAnalysis_left_ZLMR: [],
      dNetOrActiveFlowAnalysis_left_ZLJMR: [],
      dNetOrActiveFlowAnalysis_right: [],
      dNetOrActiveFlowAnalysis_IPO: [],

      chartColumn5: null,
      chartColumn5_dataTmp: null,
      chartColumn5_ipo_date: null,

      dBlockTrade: [],
      dBlockTrade_X: [],
      dBlockTrade_SPJ: [],
      dBlockTrade_DZCJE: [],
      dBlockTrade_CJE: [],
      dBlockTrade_MEAN: [],

      chartColumn6: null,

      AFTER_INDEX: 3,
      SPECColor: "rgba(255, 179, 179, 1)",

      dButtons1: ["概览", "主力及大宗"],
      dButtons1_current: "概览",

      bOverview: true,
      bOverview: false,

      DDL: "",

      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,

      width_dialog: "80%",
      height_dialog: "73vh",
      height_chart: "71vh",
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: "5vh",
    };
  },
  created() {
    this.fnButtons1("概览");
  },
  mounted() {
    window.addEventListener("resize", this.funResize);
    this.fnGetWidth();
  },
  methods: {
    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById("rootchart");
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      let OFFSETHEIGHT = element.offsetHeight;
      this.OFFSETHEIGHT = OFFSETHEIGHT;
      // this.TABPANEHEIGHT = OFFSETHEIGHT - 135;

      // var chartColumn1 = document.getElementById("chartColumn1");
      // chartColumn1.style.height = this.ROOTFONTFIZE * (371 / 144) + "px";

      // var chartColumn2 = document.getElementById("chartColumn2");
      // chartColumn2.style.height = this.ROOTFONTFIZE * (360 / 144) + "px";

      // var chartColumn4 = document.getElementById("chartColumn4");
      // chartColumn4.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      // var chartColumn5 = document.getElementById("chartColumn5");
      // chartColumn5.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      // var chartColumn3 = document.getElementById("chartColumn3");
      // chartColumn3.style.height = this.ROOTFONTFIZE * (358 / 144) + "px";

      return;
    },

    // 下载 start
    fnButtonDL5_1(val) {
      if (val == "下载图片") {
        this.FnDownload();
      } else {
        document.getElementById("downloadExcel5_1").click();
        document.getElementById("downloadExcel5_2").click();
      }
    },

    fnButtonDL5_5(val) {
      if (val == "下载图片") {
        this.FnDownload2();
      } else {
        document.getElementById("downloadExcel5_5").click();
        // document.getElementById("downloadExcel5_6").click();
        // document.getElementById("downloadExcel5_7").click();
      }
    },

    fnButtonDL5_6(val) {
      if (val == "下载图片") {
        this.FnDownload3();
      } else {
        document.getElementById("downloadExcel5_8").click();
        document.getElementById("downloadExcel5_9").click();
      }
    },

    fnButtonDL5_7(val) {
      if (val == "下载图片") {
        this.FnDownload4();
      } else {
        document.getElementById("downloadExcel5_10").click();
      }
    },

    // 下载 end

    FnDownload() {
      let h = this.$refs.content.scrollHeight;
      let w = this.$refs.content.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.content, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "资金总体构成数据"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    FnDownload2() {
      let h = this.$refs.chartColumn4.scrollHeight;
      let w = this.$refs.chartColumn4.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn4, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "历史资金构成"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    FnDownload3() {
      let h = this.$refs.chartColumn5.scrollHeight;
      let w = this.$refs.chartColumn5.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn5, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "大宗交易"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    FnDownload4() {
      let h = this.$refs.chartColumn3.scrollHeight;
      let w = this.$refs.chartColumn3.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn3, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "近5日以来最大流入10支REITs流向统计"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == "dark" ? true : false;
    },

    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChart4(true);
      });
    },
    fnOpenDialog2() {
      this.dialogVisible2 = true;
      this.$nextTick(() => {
        this.initChart5(true);
      });
    },
    fnOpenDialog3() {
      this.dialogVisible3 = true;
      this.$nextTick(() => {
        this.initChart1(true);
      });
    },

    fnOpenDialog4() {
      this.dialogVisible4 = true;
      this.$nextTick(() => {
        this.initChart3(true);
      });
    },

    fnDropdown(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      }
    },

    fnDropdown2(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart5(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart5(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart5(true);
        });
      }
    },

    fnDropdown3(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      }
    },

    fnDropdown4(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart3(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart3(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart3(true);
        });
      }
    },

    // ============收益分析第一个echarts============================

    funResize() {
      this.fnGetWidth();

      this.$nextTick(() => {
        this.chartColumn1.resize();
        this.chartColumn6.resize();
        this.chartColumn3.resize();
        this.chartColumn4.resize();
        this.chartColumn5.resize();
      });
    },
    // ===================================
    fnChangeBtn1(val) {
      this.activeBtn1 = val;
    },

    // 从头开始第一个 --------------------------------
    fnCTimeButtons_select_val_chart12(val) {
      this.$refs.CunknowButtons1.activeBtn1 = val;
      this.$refs.CunknowButtons2.activeBtn1 = val;
      this.start_date_chart12 = val;
      this.fnGetFundFlowPieTable();
      this.fnGetNetinflowBar();
    },
    fnCCascader_select_val_chart12(val) {
      this.category_cascader_val_chart12 = val;
      this.fnGetFundFlowPieTable();
      this.fnGetNetinflowBar();
    },
    fnGetCbtnsSep_chart12(val) {
      this.dButtons_separrate_current_chart12 = val;
      this.fnGetFundFlowPieTable();
    },
    async fnGetFundFlowPieTable() {
      this.loading = true;
      let flow_type =
        this.dButtons_separrate_current_chart12 == "流入" ? "normal" : "active";
      let r_type = this.category_cascader_val_chart12;
      // let str = r_type.join("&r_type=");
      let val = this.start_date_chart12 + "";
      val = val.slice(0, -1);
      let start_date = val;

      const { data } = await this.$https.get(
        "/api/fundflow_pie_table?flow_type=" +
          flow_type +
          "&r_type=" +
          r_type +
          "&start_date=" +
          start_date
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 下载 start
      // 1. pie
      this.json_fields_pie_data.push(data.data.pie);
      let pie = Object.keys(this.json_fields_pie_data[0]);
      pie.forEach((node) => {
        this.json_fields_pie[node] = node;
      });

      // 2. table

      // 3.bar_below
      let CODENAME = this.$store.state.CODENAME;
      this.json_fields_bar_below_data = JSON.parse(
        JSON.stringify(data.data.bar_below)
      ); // 深拷贝有效方法
      this.json_fields_bar_below_data = this.json_fields_bar_below_data.map(
        (node) => {
          node["reit_code"] = CODENAME[node["reit_code"]];
          return node;
        }
      );

      let arr = Object.keys(this.json_fields_bar_below_data[0]);
      arr.forEach((node) => {
        if (node == "reit_code") {
          this.json_fields_bar_below["股票名称"] = "reit_code";
        } else {
          this.json_fields_bar_below[node] = node;
        }
      });

      // 下载 end

      let dataTmp0 = data.data.table.data;
      let dataTmp2 = dataTmp0[0];

      let dataTmp3 = dataTmp0[1];

      let dataTmp4 = dataTmp0[2];
      let dataTmp5 = dataTmp0[3];

      // dataTmp2 = dataTmp2.map((node) => {
      //   node = Math.abs(node);
      //   return node;
      // });
      // dataTmp3 = dataTmp3.map((node) => {
      //   node = Math.abs(node);
      //   return node;
      // });
      // dataTmp4 = dataTmp4.map((node) => {
      //   node = Math.abs(node);
      //   return node;
      // });
      // dataTmp5 = dataTmp5.map((node) => {
      //   node = Math.abs(node);
      //   return node;
      // });

      this.dFundFlowTableLeft_ZD = dataTmp2;
      this.dFundFlowTableLeft_DD = dataTmp3;
      this.dFundFlowTableLeft_XD = dataTmp4;
      this.dFundFlowTableLeft_JG = dataTmp5;

      this.dFundFlowPie = data.data.pie;
      this.dFundFlowBar = data.data.bar_below;
      this.dFundFlowBar_X = [];
      this.dFundFlowBar_ZD = [];
      this.dFundFlowBar_DD = [];
      this.dFundFlowBar_XD = [];
      this.dFundFlowBar_JG = [];

      let dataTmp = data.data.bar_below;

      // 排序
      function compare(val1, val2) {
        let v1 =
          Number(Math.abs(val1.中单净买入)) +
          Number(Math.abs(val1.大单净买入)) +
          Number(Math.abs(val1.小单净买入)) +
          Number(Math.abs(val1.机构净买入));
        let v2 =
          Number(Math.abs(val2.中单净买入)) +
          Number(Math.abs(val2.大单净买入)) +
          Number(Math.abs(val2.小单净买入)) +
          Number(Math.abs(val2.机构净买入));

        return v2 - v1;
      }

      dataTmp.sort(compare);

      // 漂亮

      for (let index = 0; index < dataTmp.length; index++) {
        this.dFundFlowBar_X.push(dataTmp[index].reit_code);
        this.dFundFlowBar_ZD.push(dataTmp[index].中单净买入);
        this.dFundFlowBar_DD.push(dataTmp[index].大单净买入);
        this.dFundFlowBar_XD.push(dataTmp[index].小单净买入);
        this.dFundFlowBar_JG.push(dataTmp[index].机构净买入);
      }

      // 设置DDL
      this.DDL = data.data.last_date;
      let arrTmp = this.DDL.split("-");
      this.DDL = arrTmp[0] + "年" + arrTmp[1] + "月" + arrTmp[2] + "日";

      this.dFundFlowBar_X_chart3 = this.dFundFlowBar_X.splice(0, 15);

      this.$nextTick(() => {
        this.initChart1();
        this.initChart3();
      });
    },
    formatDate() {
      var date = new Date();
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " ";
    },
    // 赋值表格
    async fnSetTable() {
      return;
      let flow_type = "normal";
      let r_type = "公募REITs";
      let start_date = 10;

      const { data } = await this.$https.get(
        "/api/fundflow_pie_table?flow_type=" +
          flow_type +
          "&r_type=" +
          r_type +
          "&start_date=" +
          start_date
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      let dataTmp = data.data.table.data;
      let dataTmp2 = dataTmp[0];

      let dataTmp3 = dataTmp[1];

      let dataTmp4 = dataTmp[2];
      let dataTmp5 = dataTmp[3];

      dataTmp2 = dataTmp2.map((node) => {
        node = Math.abs(node);
        return node;
      });
      dataTmp3 = dataTmp3.map((node) => {
        node = Math.abs(node);
        return node;
      });
      dataTmp4 = dataTmp4.map((node) => {
        node = Math.abs(node);
        return node;
      });
      dataTmp5 = dataTmp5.map((node) => {
        node = Math.abs(node);
        return node;
      });

      this.dFundFlowTableLeft_ZD = dataTmp2;
      this.dFundFlowTableLeft_DD = dataTmp3;
      this.dFundFlowTableLeft_XD = dataTmp4;
      this.dFundFlowTableLeft_JG = dataTmp5;
    },

    initChart1(val) {
      if (!val) {
        this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      } else {
        this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1_dialog);
      }

      // this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);

      let dFundFlowPie = this.dFundFlowPie;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        tooltip: {
          trigger: "item",
          formatter: function (args) {
            let str = "";
            let name = args.name;
            //               str += `${name}`;
            // str += "<br>";
            str += `${args.marker}${name}：${args.percent}%`;
            return str;
          },
        },
        legend: {
          top: "middle",
          right: 80,
          itemGap: 18,
          itemWidth: 16,
          itemHeight: 16,
          orient: "vertical",
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 14,
          },
        },
        color: [
          "#870000",
          "#ae0101",
          "#f53434",
          "#ff8181",
          "#77ea7a",
          "#27b429",
          "#0c7e0c",
          "#053d05",
        ],
        series: [
          {
            type: "pie",
            radius: ["50%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
              // color: OPTIONDATA.legend.textStyle.color,
              color: "#ffffff",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: dFundFlowPie["机构流入"],
                name: "机构流入",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },
              {
                value: dFundFlowPie["大单流入"],
                name: "大单流入",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },

              {
                value: dFundFlowPie["中单流入"],
                name: "中单流入",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },

              {
                value: dFundFlowPie["小单流入"],
                name: "小单流入",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },

              {
                value: dFundFlowPie["小单流出"],
                name: "小单流出",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },

              {
                value: dFundFlowPie["中单流出"],
                name: "中单流出",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },

              {
                value: dFundFlowPie["大单流出"],
                name: "大单流出",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },

              {
                value: dFundFlowPie["机构流出"],
                name: "机构流出",
                label: {
                  normal: {
                    show: true,
                    position: "inner",
                    formatter: "{d}%",
                  },
                },
              },
            ],
          },
        ],
      };

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
      this.loading = false;

      // this.funResize()
    },

    initChart3(val) {
      if (!val) {
        this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      } else {
        this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3_dialog);
      }

      // this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      this.chartColumn3.clear();

      let dFundFlowBar_X = this.dFundFlowBar_X_chart3;
      let dFundFlowBar_ZD = this.dFundFlowBar_ZD;
      let dFundFlowBar_DD = this.dFundFlowBar_DD;
      let dFundFlowBar_XD = this.dFundFlowBar_XD;
      let dFundFlowBar_JG = this.dFundFlowBar_JG;
      let CODENAME = this.$store.state.CODENAME;
      let MAP = CODENAME;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        // title: {
        //   // 标题设置
        //   text: "总体 公募REITs 前5日以来资金流向统计（百万）", // 标题文字
        //   textStyle: {
        //     // 标题文字样式设置
        //     color: "rgba(218, 218, 218, 1)",
        //     fontSize: 16,
        //   },
        //   left: "center",
        //   top: 20,
        // },

        legend: {
          show: true,
          orient: "horizontal",
          top: 50,
          left: 30,
          align: "left",
          icon: "roundRect",
          itemGap: 30,
          itemHeight: 12,
          itemWidth: 12,
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 14,
          },
          data: [
            {
              name: "机构",
              icon: "circle",
            },
            {
              name: "大单",
              icon: "circle",
            },
            {
              name: "中单",
              icon: "circle",
            },
            {
              name: "小单",
              icon: "circle",
            },
          ],
        },

        grid: {
          left: 30,
          bottom: 38,
          right: 20,
          top: 100,
          // height: "90%",
          // width: "95%",
          containLabel: true,
        },

        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${CODENAME[args[0].axisValueLabel]}`;
            args.forEach((node) => {
              let val = Number(node.value).toFixed(1);
              str += "<br>";
              str += `${node.marker}${node.seriesName}：${val}`;

              return str;
            });
            return str;
          },
        },

        xAxis: {
          type: "category",
          data: dFundFlowBar_X,
          axisLine: {
            show: false,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
            formatter: function (val) {
              for (let i in MAP) {
                if (val == i) return MAP[i];
              }
            },
          },
        },
        yAxis: {
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            // formatter: "{value}%", //y轴百分比
            formatter: function (val) {
              if (val == 0) {
                return 0;
              } else {
                let num =
                  (parseInt(Math.abs(Number(val) / 1000000)) + "").length >= 2
                    ? 1
                    : 2;

                return (Number(val) / 1000000).toFixed(num);
              }
            },
          },
          splitLine: OPTIONDATA.yAxis.splitLine,
        },

        series: [
          {
            name: "机构",
            type: "bar",
            stack: "all",
            data: dFundFlowBar_JG,
            // itemStyle: {
            //   color: function (params) {
            //     let name = params.name;
            //     let obj = dDailyReturnCompare.find((item) => {
            //       return item.index === name;
            //     });
            //     let obj2 = {};
            //     if (obj.index === "公募REITs") {
            //       obj2 = colors.find((item) => {
            //         return item.name === "公募REITs";
            //       });
            //     } else {
            //       obj2 = colors.find((item) => {
            //         return item.name === obj.ben_type;
            //       });
            //     }
            //     return obj2.value;
            //   },

            //   barBorderRadius: function (params) {
            //     // return [30, 30, 0, 0];
            //   },
            // },
            barWidth: "38px",
            itemStyle: {
              color: "#3151d3",
            },
          },
          {
            name: "大单",
            type: "bar",
            stack: "all",
            data: dFundFlowBar_DD,
            itemStyle: {
              color: "#5e63e6",
            },

            barWidth: "38px",
          },
          {
            name: "中单",
            type: "bar",
            stack: "all",
            data: dFundFlowBar_ZD,
            itemStyle: {
              color: "#608fe5",
            },

            symbol: "circle",
            symbolSize: 8,

            barWidth: "38px",
          },
          {
            name: "小单",
            type: "bar",
            stack: "all",
            data: dFundFlowBar_XD,
            itemStyle: {
              color: "#61bae3",
            },

            symbol: "circle",
            symbolSize: 8,

            barWidth: "38px",
          },
        ],
      };

      this.chartColumn3.setOption(option);
      this.chartColumn3.resize();
      // this.funResize()
    },

    fnCTimeButtons_select_val_chart3(val) {
      this.start_date_chart3 = val;
      this.fnGetNetOrActiveFlowAnalysis();
    },
    fnCCascader_select_val_chart3(val) {
      this.category_cascader_val_chart3 = val;
      this.fnGetNetOrActiveFlowAnalysis();
    },
    fnCSelect_chart3(val) {
      this.select_val_left = val;
      this.fnGetNetOrActiveFlowAnalysis();
    },
    fnGetCbtnsSep_chart3(val) {
      if (val == "主动流入") {
        this.bChecked_chart3 = true;
      } else {
        this.bChecked_chart3 = false;
      }
      this.dButtons_separrate_current_chart3 = val;
      this.fnGetNetOrActiveFlowAnalysis();
    },

    async fnGetNetOrActiveFlowAnalysis() {
      let r_type = this.category_cascader_val_chart3;
      // let str = r_type.join("&r_type=");
      let start_date =
        this.start_date_chart3 == "MAX" ? "max" : this.start_date_chart3;
      let base_factor = this.select_val_left;
      let flow_type =
        this.dButtons_separrate_current_chart3 == "主动流入"
          ? "active"
          : "normal";
      let only_main = this.Checked_chart3 ? 1 : 0;

      const { data } = await this.$https.get(
        "/api/net_or_active_flow_analysis?r_type=" +
          r_type +
          "&start_date=" +
          start_date +
          "&base_factor=" +
          base_factor +
          "&flow_type=" +
          flow_type +
          "&only_main=" +
          only_main
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 下载 start

      this.json_fields_left = {};
      this.json_fields_right = {};
      this.json_fields_ipo = {};
      this.json_fields_left_data = [];
      this.json_fields_right_data = [];
      this.json_fields_ipo_data = [];

      // 1. left
      this.json_fields_left_data = data.data.left;
      let left = Object.keys(this.json_fields_left_data[0]);
      left.forEach((node) => {
        if (node == "tr_date") {
          this.json_fields_left["日期"] = "tr_date";
        } else {
          this.json_fields_left[node] = node;
        }
      });

      this.json_fields_right_data = data.data.right;
      let right = Object.keys(this.json_fields_right_data[0]);
      right.forEach((node) => {
        if (node == "tr_date") {
          this.json_fields_right["日期"] = "tr_date";
        } else {
          this.json_fields_right[node] = node;
        }
      });

      // 左轴右轴合并 start
      let leftX = this.json_fields_left_data.map((node) => node.tr_date);
      let rightX = this.json_fields_right_data.map((node) => node.tr_date);
      let allX = [...leftX, ...rightX];
      allX = [...new Set(allX)];

      // 键
      let leftkeys = Object.keys(this.json_fields_left);
      let rightkeys = Object.keys(this.json_fields_right);

      leftkeys = leftkeys.filter((node) => node !== "日期");
      rightkeys = rightkeys.filter((node) => node !== "日期");

      let ALLDATA = [];
      allX.forEach((node) => {
        let obj = {
          tr_date: node,
        };
        let left = this.json_fields_left_data.find((item) => {
          return item["tr_date"] == node;
        });

        if (left) {
          leftkeys.forEach((ele) => {
            obj[ele] = left[ele];
          });
        } else {
          leftkeys.forEach((ele) => {
            obj[ele] = "";
          });
        }

        let right = this.json_fields_right_data.find((item) => {
          return item["tr_date"] == node;
        });

        if (right) {
          rightkeys.forEach((ele) => {
            obj[ele] = right[ele];
          });
        } else {
          rightkeys.forEach((ele) => {
            obj[ele] = "";
          });
        }
        ALLDATA.push(obj);
      });

      this.json_fields_all_data = [];
      this.json_fields_all = {};

      this.json_fields_all_data = ALLDATA;
      Object.keys(ALLDATA[0]).forEach((node) => {
        if (node == "tr_date") {
          this.json_fields_all["日期"] = "tr_date";
        } else {
          this.json_fields_all[node] = node;
        }
      });

      // 时间筛选
      let levelName = window.sessionStorage.getItem("levelName");
      if (levelName == "普通客户") {
        let result = fnComputeTime("3M");
        this.json_fields_all_data = this.json_fields_all_data.filter((node) => {
          return node["tr_date"] >= result;
        });
      } else {
        let result = fnComputeTime("6M");
        this.json_fields_all_data = this.json_fields_all_data.filter((node) => {
          return node["tr_date"] >= result;
        });
      }

      // 左轴右轴合并 end

      // ipo
      this.json_fields_ipo_data = data.data.ipo_date;
      this.json_fields_ipo = {
        IPO日期: "IPO_date",
        代码: "reit_code",
        名称: "REITs_name",
      };

      // 下载 end

      this.dNetOrActiveFlowAnalysis_left = data.data.left;

      this.dNetOrActiveFlowAnalysis_left_X = [];
      this.dNetOrActiveFlowAnalysis_left_ZLMR = [];
      this.dNetOrActiveFlowAnalysis_left_ZLJMR = [];

      let dataTmp = data.data.left;
      let dataTmp2 = data.data.right;

      for (let index = 0; index < dataTmp.length; index++) {
        this.dNetOrActiveFlowAnalysis_left_X.push(dataTmp[index].tr_date);
      }

      this.dNetOrActiveFlowAnalysis_right = [];

      this.dNetOrActiveFlowAnalysis_left_X.forEach((node) => {
        let obj = dataTmp2.find((item) => {
          return item["tr_date"] == node && item[r_type] !== "";
        });

        if (obj) {
          this.dNetOrActiveFlowAnalysis_right.push(obj[r_type]);
        } else {
          this.dNetOrActiveFlowAnalysis_right.push("");
        }
      });

      // 赋值IPO
      this.dNetOrActiveFlowAnalysis_IPO = data.data.ipo_date;
      this.chart4_data = dataTmp;
      this.$nextTick(() => {
        this.initChart4();
      });
    },

    initChart4(val) {
      if (!val) {
        this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      } else {
        this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4_dialog);
      }

      // this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      this.chartColumn4.clear();

      let dataTmp = this.chart4_data;

      // left值
      let arr3 = Object.keys(dataTmp[0]);
      arr3.splice(0, 1);

      let sARR = [];
      arr3.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };
        dataTmp.forEach((e) => {
          obj.data.push(e[node]);
        });

        sARR.push(obj);
      });

      let dNetOrActiveFlowAnalysis_left_X =
        this.dNetOrActiveFlowAnalysis_left_X;

      let dNetOrActiveFlowAnalysis_left_ZLMR =
        this.dNetOrActiveFlowAnalysis_left_ZLMR;

      let dNetOrActiveFlowAnalysis_left_ZLJMR =
        this.dNetOrActiveFlowAnalysis_left_ZLJMR;

      let dNetOrActiveFlowAnalysis_right = this.dNetOrActiveFlowAnalysis_right;

      let dNetOrActiveFlowAnalysis_IPO = this.dNetOrActiveFlowAnalysis_IPO;

      let NAME = [];
      if (this.Checked_chart3) {
        NAME.push("buyamt");
        NAME.push("netbuyamt");
      } else {
        NAME.push("主力买入");
        NAME.push("主力净买入");
      }

      if (!dNetOrActiveFlowAnalysis_left_ZLMR[0]) {
        NAME = [];
      }

      arr3 = arr3.map((node) => {
        return node + "（左轴）";
      });

      let arrTMP = [...sARR[0].data, ...sARR[1].data];

      let MIN_Y = Math.min(...arrTMP);

      // IPO
      let arr1 = dNetOrActiveFlowAnalysis_IPO.map((node) => {
        return node.IPO_date;
      });

      arr1 = [...new Set(arr1)];

      let markPointData = arr1.map((node) => {
        let obj = {
          yAxis: MIN_Y,
          xAxis: node,
        };
        return obj;
      });

      let texta = this.start_date_chart3;

      switch (texta) {
        case "3M":
          texta = "3个月以来";
          break;
        case "YTD":
          texta = "今年以来";
          break;

        case "6M":
          texta = "6个月以来";
          break;

        case "1Y":
          texta = "1年以来";

          break;
        case "3Y":
          texta = "3年以来";
          break;

        case "MAX":
          texta = "上市以来";
          break;
      }

      let textb = this.category_cascader_val_chart3;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;
      let lightOpacity = this.$store.state.lightOpacity;

      let option = {
        title: {
          // 标题设置
          text: texta + textb + "走势与资金净流入关系", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 16,
          },
          left: "center",
          top: 20,
        },

        grid: {
          top: 100,
          bottom: 20,
          right: 60,
          left: 30,
          containLabel: true,
        },
        legend: [
          {
            show: true,
            itemWidth: 12,
            itemHeight: 12,
            data: [
              {
                name: arr3[0],
                icon: "circle",
                // color:'rgba(56, 56, 118, 1)'
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 35,
            y: 50,
          },

          {
            show: true,
            itemWidth: 12,
            itemHeight: 12,
            data: [
              {
                name: arr3[1],
                icon: "circle",
                // color:'rgba(56, 56, 118, 1)'
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 200,
            y: 50,
          },

          {
            show: true,
            itemWidth: 35,
            itemHeight: 3,
            data: [
              {
                name: textb + "走势指数（右轴）",
                icon: "roundRect",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 380,
            y: 50,
          },

          {
            show: true,
            itemWidth: 25,
            itemHeight: 12,
            data: [
              {
                name: "IPO标志",
                icon: "triangle",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 630,
            y: 50,
          },
        ],

        tooltip: {
          trigger: "axis",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              if (idx < 2) {
                let val = (Number(node.value) * 100).toFixed(2);
                str += `${node.marker}${node.seriesName}：${val}%`;
              } else {
                if (!node.value) {
                } else {
                  let num =
                    (parseInt(Math.abs(node.value)) + "").length >= 2 ? 1 : 2;
                  let val = Number(node.value).toFixed(num);
                  str += `${node.marker}${node.seriesName}：${val}`;
                }
              }
              str += "<br>";
            });

            return str;
          },
          // axisPointer: {
          //   type: "line",
          //   z: 0,
          //   lineStyle: {
          //     color: "#2D3443",
          //   },
          // },
          // formatter: function (args) {
          //   console.log(args,'args123');
          //   args =  args.map(node=>{
          //     node.value = Number(node.value) * 100
          //     node.value = node.value.toFixed(1)+'%';
          //     return node.value;
          // })

          //   return args

          // },
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          splitNumber: 4,
          axisLine: {
            show: true,
            onZero: false,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            rotate: 15,
            margin: 35,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dNetOrActiveFlowAnalysis_left_X,
          // data: ['1','2','3','4'],
        },
        yAxis: [
          {
            // scale: true,
            type: "value",
            min: MIN_Y,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                args = Number(args) * 100;
                let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
                args = args.toFixed(num) + "%";
                return args;
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },
          {
            type: "value",

            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (args == 0) {
                  return 0;
                } else {
                  let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
                  return Number(args).toFixed(num);
                }
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },
        ],

        series: [
          {
            name: sARR[0].name + "（左轴）",
            data: sARR[0].data,
            type: "line",
            smooth: false,
            itemStyle: {
              color: "#4c4cab",
            },
            symbol: "none",
            areaStyle: {
              color: "#4c4cab",
              opacity: theme == "dark" ? 0.5 : lightOpacity,
            },
          },
          {
            name: sARR[1].name + "（左轴）",
            data: sARR[1].data,
            type: "line",
            smooth: false,
            itemStyle: {
              color: "#4da8a5",
            },
            symbol: "none",
            areaStyle: {
              color: "#4da8a5",
              opacity: theme == "dark" ? 0.5 : lightOpacity,
            },
          },
          {
            yAxisIndex: 1,
            name: textb + "走势指数（右轴）",
            data: dNetOrActiveFlowAnalysis_right,
            type: "line",
            smooth: false,
            itemStyle: {
              color: "#d43030",
            },
            symbol: "none",
          },

          {
            name: "IPO标志",
            type: "line",
            symbol: "none",
            itemStyle: {
              // color: "#cccccc",
              color: "#d43030",
            },

            markPoint: {
              // 标记的数据，可以是最大值最小值也可以是自定义的坐标
              data: markPointData,
              symbol: "triangle", // 标记图形
              symbolSize: 18,
              symbolOffset: [0, "50%"],
              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: true,
                  // color: "#333",
                  color: OPTIONDATA.emphasis.label.color,
                  // shadowColor:'#e0e0e0',
                  // shadowBlur : 5,
                  position: "top",
                  // backgroundColor: "Transparent",
                  // backgroundColor: "#fff",
                  backgroundColor: OPTIONDATA.emphasis.label.backgroundColor,
                  align: "center",
                  padding: [10, 10],
                  borderRadius: 5,
                  lineHeight: 20,
                  fontSize: 14,
                  // width:90,height:90,
                  formatter: function (val) {
                    let name = val.name;
                    let arr = dNetOrActiveFlowAnalysis_IPO.filter((node) => {
                      return node.IPO_date == name;
                    });

                    let str = name;
                    //  str += '\n'
                    arr.forEach((node) => {
                      str += "\n";
                      str += "IPO:  " + node.REITs_name;
                    });
                    return str;
                  },
                },
              },

              // 标注点的样式
              itemStyle: {
                // color: "rgba(204, 204, 204, 1)", // 标注点颜色
                color: "#d43030",
                label: {
                  show: true,
                },
              },
            },
          },
        ],
      };

      this.chartColumn4.setOption(option);
      this.chartColumn4.resize();
      // this.funResize()
    },

    fnCTimeButtons_select_val_chart4(val) {
      this.start_date_chart4 = val;
      this.fnGetBlockTrade();
    },
    fnCCascader_select_val_chart4(val) {
      this.category_cascader_val_chart4 = val;
      this.fnGetBlockTrade();
    },
    fnCSelect_chart4(val) {
      this.select_val_Proportion = val;
      this.fnGetBlockTrade();
    },

    async fnGetBlockTrade() {
      let r_type = this.category_cascader_val_chart4;
      // let str = r_type.join("&r_type=");
      let start_date = this.start_date_chart4;
      let factor_ = this.select_val_Proportion;

      const { data } = await this.$https.get(
        "/api/block_trade?r_type=" +
          r_type +
          "&start_date=" +
          start_date +
          "&factor_=" +
          factor_
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 下载 start

      this.json_fields_charts = {};
      this.json_fields_ipo2 = {};
      this.json_fields_charts_data = [];
      this.json_fields_ipo2_data = [];

      // 1. left
      this.json_fields_charts_data = data.data.charts;
      let charts = Object.keys(this.json_fields_charts_data[0]);
      charts.forEach((node) => {
        if (node == "tr_date") {
          this.json_fields_charts["日期"] = "tr_date";
        } else {
          this.json_fields_charts[node] = node;
        }
      });

      this.json_fields_ipo2_data = data.data.ipo_date;
      this.json_fields_ipo2 = {
        IPO日期: "IPO_date",
        代码: "reit_code",
        名称: "REITs_name",
      };


      console.log(this.json_fields_ipo2_data,'json_fields_ipo2_data');

      // 时间筛选
      let levelName = window.sessionStorage.getItem("levelName");
       if (levelName == "普通客户") {
          let result = fnComputeTime("3M");
          this.json_fields_charts_data = this.json_fields_charts_data.filter((node) => {
          return node["tr_date"] >= result;
          });

            this.json_fields_ipo2_data = this.json_fields_ipo2_data.filter((node) => {
          return node["IPO_date"] >= result;
          });

       }else{
          let result = fnComputeTime("6M");
          this.json_fields_charts_data = this.json_fields_charts_data.filter((node) => {
          return node["tr_date"] >= result;
        });

          this.json_fields_ipo2_data = this.json_fields_ipo2_data.filter((node) => {
          return node["IPO_date"] >= result;
        });
       }


      // 下载 end

      this.dBlockTrade = data.data.charts;
      this.dBlockTrade_X = [];
      this.dBlockTrade_SPJ = [];
      this.dBlockTrade_DZCJE = [];
      this.dBlockTrade_CJE = [];

      let dataTmp = data.data.charts;

      let ipo_date = data.data.ipo_date;

      this.dBlockTrade_MEAN = data.data.mean;

      this.chartColumn5_dataTmp = dataTmp;
      this.chartColumn5_ipo_date = ipo_date;
      this.$nextTick(() => {
        this.initChart5();
      });
    },

    initChart5(val) {
      if (!val) {
        this.chartColumn5 = this.$echarts.init(
          document.querySelectorAll("#chartColumn5")[0]
        );
      } else {
        this.chartColumn5 = this.$echarts.init(
          document.querySelectorAll("#chartColumn5")[1]
        );
      }

      // this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5);
      this.chartColumn5.clear();

      let dataTmp = this.chartColumn5_dataTmp;
      let ipo_date = this.chartColumn5_ipo_date;

      let dBlockTrade_X = dataTmp.map((node) => {
        return node.tr_date;
      });

      let dBlockTrade_MEAN = this.dBlockTrade_MEAN;

      let NAME = Object.keys(dataTmp[0]);
      NAME.shift();

      let FINALLY = [];
      NAME.forEach((node, idx) => {
        let obj = {
          name: idx < 2 ? node + "（左轴）" : node + "（右轴）",
          data: [],
        };
        dataTmp.forEach((e) => {
          obj.data.push(e[node]);
        });

        FINALLY.push(obj);
      });

      NAME = NAME.map((node, idx) => {
        return idx < 2 ? node + "（左轴）" : node + "（右轴）";
      });

      // ipo_date - start

      let markPointData = ipo_date.map((node) => {
        return {
          xAxis: node.IPO_date,
          yAxis: 0,
        };
      });

      // ipo_date - end

      let texta = this.start_date_chart4;

      switch (texta) {
        case "3M":
          texta = "3个月以来";
          break;
        case "YTD":
          texta = "今年以来";
          break;

        case "6M":
          texta = "6个月以来";
          break;

        case "1Y":
          texta = "1年以来";

          break;
        case "3Y":
          texta = "3年以来";
          break;

        case "MAX":
          texta = "上市以来";
          break;
      }

      let textb = this.select_val_Proportion == "amt" ? "成交额" : "成交量";

      let textc = this.category_cascader_val_chart4;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;
      let lightOpacity = this.$store.state.lightOpacity;

      let option = {
        title: {
          // 标题设置
          text: texta + textc + "大宗交易与" + textb + "关系", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 16,
          },
          left: "center",
          top: 20,
        },

        grid: {
          top: 120,
          bottom: 30,
          right: 35,
          left: 30,
          containLabel: true,
        },
        legend: [
          {
            show: true,
            itemWidth: 12,
            itemHeight: 12,
            data: [
              {
                name: NAME[1],
                icon: "circle",
                // color:'rgba(56, 56, 118, 1)'
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 35,
            y: 50,
          },

          {
            show: true,
            itemWidth: 12,
            itemHeight: 12,
            data: [
              {
                name: NAME[0],
                icon: "circle",
                // color:'rgba(56, 56, 118, 1)'
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 170,
            y: 50,
          },

          {
            show: true,
            itemWidth: 35,
            itemHeight: 3,
            data: [
              {
                name: NAME[2],
                icon: "roundRect",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 330,
            y: 50,
          },

          {
            show: true,
            itemWidth: 25,
            itemHeight: 12,
            data: [
              {
                name: "IPO标志",
                icon: "triangle",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            itemStyle: {
              color: "#d43030",
            },
            left: 560,
            y: 50,
          },
        ],

        tooltip: {
          trigger: "axis",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, index) => {
              let val = 0;
              if (node.seriesIndex > 1) {
                let value = node.value;
                value = Number(value) * 100;
                let num = (parseInt(Math.abs(value)) + "").length >= 2 ? 1 : 2;

                val = Number(value).toFixed(num) + "%";
              } else {
                val = Number(node.value) / 1000000;
                val = val > 10 ? val.toFixed(1) : val.toFixed(2);
              }
              str += `${node.marker}${node.seriesName}：${val}`;
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            rotate: 15,
            margin: 35,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dBlockTrade_X,
        },

        yAxis: [
          {
            name: "单位（百万）",
            nameTextStyle: {
              color: OPTIONDATA.yAxis.nameTextStyle.color,
              fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
            },
            nameGap: OPTIONDATA.yAxis.nameGap,
            // scale: true,
            type: "value",
            // min:MIN_Y,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (args == 0) {
                  return 0;
                } else {
                  let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
                  return (Number(args) / 1000000).toFixed(num);
                }
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },

          {
            name: "单位（%）",
            nameTextStyle: {
              color: OPTIONDATA.yAxis.nameTextStyle.color,
              fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
            },
            nameGap: OPTIONDATA.yAxis.nameGap,
            type: "value",

            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (args == 0) {
                  return 0;
                } else {
                  args = Number(args) * 100;
                  let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
                  return Number(args).toFixed(num) + "%";
                }
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },
        ],

        series: [
          {
            name: FINALLY[0].name,
            data: FINALLY[0].data,
            yAxisIndex: 0,
            type: "line",
            smooth: false,
            z: 2,
            itemStyle: {
              color: "rgba(90, 202, 198, 1)",
            },
            symbol: "none",
            areaStyle: {
              color: "rgba(90, 202, 198, 1)",
              opacity: theme == "dark" ? 0.5 : lightOpacity,
            },
            // itemStyle: {
            //   color: "rgba(86, 84, 202, 1)",
            // },
            // symbol: "none",
            // areaStyle: {
            //   color: "rgba(86, 84, 202, 1)",
            //   opacity: 0.5,
            // },
          },

          {
            name: FINALLY[1].name,
            data: FINALLY[1].data,
            yAxisIndex: 0,
            type: "line",
            smooth: false,
            z: 1,
            itemStyle: {
              color: "rgba(86, 84, 202, 1)",
            },
            symbol: "none",
            areaStyle: {
              color: "rgba(86, 84, 202, 1)",
              opacity: theme == "dark" ? 0.5 : lightOpacity,
            },
          },

          {
            name: FINALLY[2].name,
            data: FINALLY[2].data,
            yAxisIndex: 1,
            type: "line",
            smooth: false,
            itemStyle: {
              color: "#e93434",
            },
            lineStyle: {
              width: 3,
            },

            symbol: "none",
          },

          {
            yAxisIndex: 1,
            type: "line",
            smooth: false,
            itemStyle: {
              color: "rgba(86, 84, 202, 1)",
            },
            markLine: {
              symbol: ["none", "none"],

              data: [
                {
                  yAxis: dBlockTrade_MEAN,
                },
              ],
              lineStyle: {
                type: "solid",
                // color: "#fff",
                color: theme == "dark" ? "#fff" : "#878787",
                width: 1,
              },

              label: {
                show: true,
                position: "insideEndTop",
                // padding: [0, 0, 0,0],
                fontSize: 16,
                formatter: function (args) {
                  let val = dBlockTrade_MEAN;
                  val = val * 100;
                  val = val.toFixed(2);
                  val = val + "%";
                  return `{title|大宗平均折扣率: }  {value|${val}} `;
                },
                rich: {
                  title: {
                    color: theme == "dark" ? "#b6b6b6" : "#5a4f4f",
                    fontSize: 14,
                  },
                  value: {
                    color: theme == "dark" ? "#fff" : "#5a4f4f",
                    fontSize: 16,
                    fontWeight: "bolder",
                  },
                },
              },
            },
            symbol: "none",
          },

          {
            name: "IPO标志",
            type: "line",
            symbol: "none",
            markPoint: {
              // 标记的数据，可以是最大值最小值也可以是自定义的坐标
              data: markPointData,
              symbol: "triangle", // 标记图形
              symbolSize: 18,
              symbolOffset: [0, "50%"],
              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: true,
                  color: OPTIONDATA.emphasis.label.color,
                  position: "top",
                  // backgroundColor: "Transparent",
                  backgroundColor: OPTIONDATA.emphasis.label.backgroundColor,
                  align: "center",
                  padding: [10, 10],
                  borderRadius: 5,
                  lineHeight: 20,
                  fontSize: 14,
                  // width:90,height:90,
                  formatter: function (val) {
                    let name = val.name;
                    let arr = ipo_date.filter((node) => {
                      return node.IPO_date == name;
                    });

                    // let str = ''
                    let str = name;

                    arr.forEach((node) => {
                      str += "\n";
                      str += "IPO:  " + node.REITs_name;
                    });
                    return str;
                  },
                },
              },

              // 标注点的样式
              itemStyle: {
                // color: "rgba(204, 204, 204, 1)", // 标注点颜色
                color: "#d43030",
                label: {
                  show: true,
                },
              },
            },
          },
        ],
      };

      this.chartColumn5.setOption(option);
      this.chartColumn5.resize();
      // this.funResize()
    },

    async fnGetNetinflowBar() {
      // let r_type = "公募REITs";
      // let start_date = 5;
      let r_type = this.category_cascader_val_chart12;
      // let str = r_type.join("&r_type=");
      let val = this.start_date_chart12 + "";
      val = val.slice(0, -1);
      let start_date = val;

      const { data } = await this.$https.get(
        "/api/netinflow_bar?r_type=" + r_type + "&start_date=" + start_date
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 下载 start
      // 1. bar
      this.json_fields_bar_data = data.data.bar;
      this.json_fields_bar = {
        日期: "tr_date",
        流入强度: "data",
      };

      // 2. table

      let condition = data.data.condition;

      let num = Number(condition) / (1 / 6);
      this.AFTER_INDEX = Math.ceil(num);

      this.dFundFlowTableBar = data.data.bar;

      let dataTmp = data.data.table.data;
      let dataTmp2 = dataTmp[0];
      let dataTmp3 = dataTmp[1];
      let dataTmp4 = dataTmp[2];
      let dataTmp5 = dataTmp[3];
      this.dFundFlowTableRight_ONE = dataTmp2;
      this.dFundFlowTableRight_TWO = dataTmp3;
      this.dFundFlowTableRight_THREE = dataTmp4;
      this.dFundFlowTableRight_FOUR = dataTmp5;
      this.$nextTick(() => {
        this.initChart6();
        this.fnSetSPEColor();
      });
    },

    // 三角形颜色
    fnSetSPEColor() {
      let arr = this.$store.state.SPEColor;
      this.SPECColor = arr[this.AFTER_INDEX - 1];
    },

    initChart6() {
      this.chartColumn6 = this.$echarts.init(this.$refs.chartColumn6);
      let data = this.dFundFlowTableBar;
      let X = data.map((node) => {
        return node.tr_date;
      });

      let Y = data.map((node) => {
        return node.data;
      });

      X = X.map((node) => {
        let arr1 = node.split("-");
        arr1.shift();
        let str2 = arr1.join("-");
        return str2;
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        grid: {
          //方法 2
          left: 50,
          right: 50,
          bottom: 80,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: X,
          axisLine: {
            show: false,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            show: true,
            margin: 30,
            showMaxLabel: true,
            fontSize: 14,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
          },
          axisLabel: {
            fontSize: 12,
            show: false,
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            type: "bar",
            data: Y.map((node) => {
              return {
                value: node,
                label: {
                  show: true,
                  position: node > 0 ? "top" : "bottom",
                  fontSize: 14,
                  formatter: function (value) {
                    let val = value.value;
                    val = val / 10000;

                    if (val > 10 && val < 100) {
                      val = Number(val).toFixed(1);
                      return val;
                    }

                    if (Number(val) > 100) {
                      val = parseInt(Number(val));
                      return val;
                    }

                    if (Number(val) < 10 && Number(val) > 0) {
                      val = Number(val).toFixed(2);
                      return val;
                    }

                    if (Number(val) < 0 && Number(val) > -10) {
                      val = Number(val).toFixed(2);
                      return val;
                    }

                    if (Number(val) < -10 && Number(val) > -100) {
                      val = Number(val).toFixed(1);
                      return val;
                    }

                    if (Number(val) < -100) {
                      val = parseInt(Number(val));
                      return val;
                    }
                  },
                  color:
                    node > 0 ? "rgba(250, 40, 50, 1)" : "rgba(22, 188, 80, 1)",
                  fontSize: 12,
                },
              };
            }),
            // barWidth: "35px",
            markLine: {
              symbol: ["none", "none"], // none为标线两端的样式为无，可更改
              data: [
                {
                  yAxis: 0,
                },
              ],
              label: {
                show: false,
              },
              lineStyle: {
                type: "solid",
                color: "#6e7079",
              },
            },

            itemStyle: {
              normal: {
                color: function (val) {
                  let value = val.value;
                  if (value > 0) {
                    return "rgba(250, 40, 50, 1)";
                  } else {
                    return "rgba(22, 188, 80, 1)";
                  }
                },
              },
            },
          },
        ],
      };

      this.chartColumn6.setOption(option);
      this.chartColumn6.resize();
      // this.funResize()
    },

    // 4.fnButtons1
    fnButtons1(val) {
      this.dButtons1_current = val;

      if (this.dButtons1_current == "概览") {
        this.bOverview = true;
        this.bPrimary = false;
        this.fnGetFundFlowPieTable();
        this.fnGetNetinflowBar();
      }

      if (this.dButtons1_current == "主力及大宗") {
        this.bOverview = false;
        this.bPrimary = true;
        this.fnGetNetOrActiveFlowAnalysis();
        this.fnGetBlockTrade();
      }
    },
  },
  components: {
    CSelects,
    CCategoryButton,
    CSelect,
    CButtonsSeparate,
    CTimeButtons,
    CunknowButtons,
    CButtonsDownload,
    CCascader,
    CDialog,
  },
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
        "--specialColor": this.SPECColor,
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
      fnDownloadBgc();
      this.$nextTick(() => {
        if (this.bOverview) {
          this.initChart1();
          this.initChart6();
          this.initChart3();
        }

        if (this.bPrimary) {
          this.initChart4();
          this.initChart5();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.rootArea {
  padding-bottom: 20px;
}

.main {
  background-color: #1d1f25;
  background-color: #3b3d42;
  background-color: var(--charts_bgc);
  padding-top: 15px;
  border-radius: 20px;
  margin-top: 30px;

  padding-bottom: 20px;
}

.chooseArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chooseArea .el-button {
  font-size: 14px;
  font-weight: 400;
  // width: 96px;
  height: 36px;
  // margin-left: 10px;
}

.el-icon-download {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  float: right;
}

.title {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 28px;
  color: var(--chart_title);
  border-left: 5px solid rgba(21, 115, 254, 1);
  border-left: 5px solid var(--primary-color);
  padding-left: 13px;
  padding-right: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top: 15px;
}

.main_choose {
  margin-top: 25px;
  display: flex;
  align-items: center;
  position: relative;
}

.pl20 {
  padding-left: 20px;
}

.centerStyle {
  display: flex;
  justify-content: center;
}

.main_choose span {
  display: flex;
  align-items: center;
}

.cselect_area {
  margin-left: 50px;
  margin-right: 50px;
}

.mainforce {
  color: var(--Select-color2);
  margin-left: 50px;
  font-size: 14px;
}

.btnArea {
  margin-right: 40px;
}

.colorCategory {
  margin-left: 39px;
}

.summary {
  margin-left: 120px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  width: 52px;
  display: flex;
  justify-content: space-between;
}

.average {
  margin-left: 120px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  width: 92px;
  display: flex;
  justify-content: space-between;
}

// .width20{
//   width: 27%;
// }

// .mobility span:nth-child(1){
//   margin-left: 0px ;
// }

.category {
  margin-left: 50px;
}

// echarts
.content {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 20px;
  position: relative;
}

.content_left,
.content_right {
  width: 49%;
  height: 375px;
  box-sizing: border-box;
  background-color: #1a1c21;
  background-color: var(--charts_bgc);
  border-radius: 10px;
  border: 1px solid var(--charts_brc);
  position: relative;
}

.info {
  padding-left: 13px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: #77787a;
}

.secondTit {
  margin-top: 55px;
}

.bar {
  display: flex;
  flex-direction: column;
  // background-color: #1a1c21;
  background-color: var(--charts_bgc);
  border: 1px solid #333439;
  border: 1px solid var(--charts_brc);
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 35px;
  margin-right: 35px;
  padding-bottom: 12px;
}

.history {
  background-color: #1a1c21;
  background-color: var(--charts_bgc);
  border: 1px solid #333439;
  border: 1px solid var(--charts_brc);
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

// table start
.tableArea {
  height: 300px;
  border: none !important;
}

table {
  width: 100%;
  height: 100%;
  border: none;
}

tr:nth-child(1) {
  // height: 50px;
  background-color: var(--table3_th_bgc);
}

tr:nth-child(1) th {
  font-size: 16px;
  color: var(--table3_th_color);
  font-weight: 700;
  border-bottom: 1px solid var(--table3_th_brc_bottom);
  // border-top: 2px solid var(--table3_th_brc_top);
}

.rowArea {
  // background-color: var(--table2_th_bgc);
  background-color: var(--table_bgc);
  color: var(--table_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  border-bottom: 1px solid var(--table3_th_brc_bottom);
}

.rowArea:hover {
  background-color: var(--table_bgc_hover);
  color: var(--table_color_hover);
  cursor: pointer;
}

.odd {
  // background-color: var(--table2_odd_bgc);
  // border-top: 1px solid var(--table2_odd_brc);
  // border-bottom: 1px solid var(--table2_odd_brc);
}

.rowArea td:nth-child(1) {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--stock_area_item_color1);
}

.inflowStyle {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: rgba(227, 37, 47, 1);
}

.outflowStyle {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #16a148;
}

// table end

.canvasArea2 {
  padding: 0 80px;
  box-sizing: border-box;
}

.InflowIntensity {
  margin-bottom: 60px;
}

.InflowIntensity_title {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.27px;
  margin-top: 10px;
  // color: rgba(218, 218, 218, 1);
  color: var(--stock_area_item_color2);
  position: relative;
}

.InflowIntensity_title span:nth-child(2) {
  position: absolute;
  right: 20px;
}

.InflowIntensity_body {
  padding: 0 80px;
}

.InflowIntensity_body_top {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 15px;
  margin-bottom: 20px;
}

.InflowIntensity_body_Lines {
  display: flex;
}

.InflowIntensity_body_Lines div {
  flex: 1;
  height: 12px;
  margin-right: 10px;
}

.InflowIntensity_body_Lines div:nth-child(1) {
  background-color: rgba(0, 175, 65, 1);
  border-radius: 5px 0 0 5px;
  position: relative;
}

.InflowIntensity_body_Lines div:nth-child(2) {
  background-color: rgba(102, 207, 141, 1);
  position: relative;
}

.InflowIntensity_body_Lines div:nth-child(3) {
  position: relative;
  background-color: rgba(179, 231, 198, 1);
}

.InflowIntensity_body_Lines div:nth-child(4) {
  background-color: rgba(255, 179, 179, 1);
  position: relative;
}

.InflowIntensity_body_Lines div:nth-child(5) {
  background-color: rgba(255, 102, 102, 1);
  position: relative;
}

.InflowIntensity_body_Lines div:nth-child(6) {
  background-color: rgba(255, 0, 0, 1);
  border-radius: 0 5px 5px 0;
  position: relative;
}

.special:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  transform: translateY(5px);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  // border-bottom: 10px solid red;
  // border-bottom: 16px solid rgba(255, 179, 179, 1);
  border-bottom: 16px solid var(--specialColor);
}

// 切换按钮  start --------------------------------------------
.chooseArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chooseArea .el-button {
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  // margin-left: 10px;
}

.chooseArea .noActive {
  // background-color: var(--Btn-bgc2);
  // color: var(--Select-color2);
  // border: 0.98px solid rgba(255, 255, 255, 0.2);

  background-color: var(--Btn-bgc2);
  color: var(--stock_area_item_color1);
  border: 1px solid var(--Btn-borderColor);
}

.checkedBtnStyle {
  // background-color: #1573fe !important;
  // color: #ffffff !important;
  // border: 0.98px solid #1573fe !important;

  background-color: var(--primary-color) !important;
  color: var(--Btn-color) !important;
  border: 0.98px solid var(--primary-color) !important;
}

.chooseArea .el-button:hover {
  background-color: var(--Btn-bgc_hover);
  color: var(--Btn-color2);
  border: 1px solid var(--primary-color) !important;
}

// 切换按钮  end --------------------------------------------
.start_date_area {
  margin-left: 20px;
  margin-right: 50px;
}

.newAddStyle {
  padding-top: 20px;
  padding-left: 20px;
}
</style>
