<template>
  <div class="home" id="rootchart" :style="cssVars">
    <div class="title">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="市场走势" name="first">
          <div class="overall">
            <div class="chart_title">总体市场走势</div>
            <el-row :gutter="0">
              <el-col :xs="24" :sm="24" :md="8" :lg="14">
                <div class="btnArea ml80">
                  <CTimeButtons
                    :default="start_date_chart1And2"
                    @CTimeButtons_select_val="
                      fnCTimeButtons_select_val_chart1And2
                    "
                  />
                </div>

                <!-- element-loading-background="rgba(247, 247, 247, 0.6)" -->
                <div
                  v-loading="loading1"
                  element-loading-text="数据量巨大，正在计算中..."
                  element-loading-spinner="el-icon-loading"
                  class="chart_content CHARTS_BGC chart1"
                >
                  <div class="chart1_content_top">
                    <div class="chart1_content_top_tit">REITS及对标指数</div>
                    <div
                      id="chartColumn1"
                      ref="chartColumn1"
                      style="width: 100%; height: 480px"
                    ></div>
                  </div>
                  <div class="chart1_content_bottom">
                    <div class="deadline_style">
                      数据截止日期：{{ sReitsBenIndexDDL }}
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="16" :lg="10">
                <div class="btnArea p10">
                  <!-- <CSelectBench
                    @CSelect_select_val="fnCSelect_chart1And2"
                    :multiple="true"
                    :limit="3"
                    ref="chooseBenchmark"
                    :clearable="false"
                    :wide="true"
                    :collapse="true"
                    :default="select_val_benchmark"
                    :options="options_benchmark"
                    >选择benchmark</CSelectBench
                  > -->

                  <!-- :checkStrictly="true" -->
                  <CCascader2
                    @CCascader_select_val="fnCCascader_select_val_one"
                    :default="select_val_benchmark"
                    ref="CCascader_chart1"
                    :multiple="true"
                    :isDelete="false"
                    takeOut="takeOut2"
                    :collapsetags="true"
                    :options="options_benchmark2"
                    >选择benchmark</CCascader2
                  >
                </div>

                <div
                  v-loading="loading1"
                  element-loading-text="数据量巨大，正在计算中..."
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 1)"
                  class="chart_content CHARTS_BGC chart2"
                >
                  <span
                    @click="fnOpenDialog"
                    class="iconfont icon-fangda icon-fangda2"
                  ></span>

                  <div
                    class="canvasArea2"
                    id= "chartColumn2"
                    ref="chartColumn2"
                    style="width: 100%; height: 500px"
                  ></div>
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="overall2">
            <div class="chart_title">分版块走势</div>
            <div class="btnArea btnArea2">
              <div class="chooseLev">
                <CSelect
                  @CSelect_select_val="fnCSelect_chart3And4And5"
                  :multiple="false"
                  :mini="true"
                  :clearable="false"
                  :default="select_val_level"
                  :options="options_level"
                  >选择层级</CSelect
                >
              </div>

              <div class="CTimeButtonStyle">
                <CTimeButtons
                  :default="start_date_chart3And4And5"
                  @CTimeButtons_select_val="
                    fnCTimeButtons_select_val_chart3And4And5
                  "
                />
              </div>
            </div>
            <el-row :gutter="0">
              <el-col :xs="24" :sm="24" :md="8" :lg="16">
                <div
                  v-loading="loading3"
                  element-loading-text="数据量巨大，正在计算中..."
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                  class="chart_content CHARTS_BGC"
                >
                  <div class="chart1_content_top">
                    <div class="chart1_content_top_tit">REITS及对标指数</div>
                    <div
                      class="canvasArea"
                      id="chartColumn3"
                      ref="chartColumn3"
                      style="width: 100%; height: 480px"
                    ></div>
                  </div>
                  <div class="chart1_content_bottom">
                    <div class="deadline_style">
                      数据截止日期：{{ sOnlyReitsIndexDDL }}
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="16" :lg="8" class="overall2Right">
                <div
                  v-loading="loading3"
                  element-loading-text="数据量巨大，正在计算中..."
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                  class="chart_content CHARTS_BGC chart4"
                >
                  <div
                    class="canvasArea"
                    ref="chartColumn4"
                    style="width: 100%; height: 100%"
                  ></div>
                </div>

                <div
                  v-loading="loading3"
                  element-loading-text="数据量巨大，正在计算中..."
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                  class="chart_content CHARTS_BGC chart5"
                >
                  <div class="chart5_tit">
                    {{ chartColumn5_title }}板块走势热力图
                  </div>
                  <div
                    class="canvasArea"
                    ref="chartColumn5"
                    style="width: 100%; height: 100%"
                  ></div>
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="overall2 HEIGHTAUTO">
            <div class="chart_title chart3_title">个股走势及排名</div>
            <div class="btnArea">
              <CTimeButtons
                :default="start_date_chart6"
                @CTimeButtons_select_val="fnCTimeButtons_select_val_chart6"
              />
            </div>
            <!-- <div class="title_chart6">单支公募REITs收益指数表现</div> -->
            <div
              v-loading="loading2"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 1)"
              class="echarts_AREA"
            >
              <div
                class="canvasArea3"
                v-for="(node, index) in dCalSingleReitIndex_Ys"
                :key="index"
                :id="node.name"
                style="height: 300px"
              ></div>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="收益分析" name="second">
          <BenefitAnalysis ref="second" />
        </el-tab-pane>

        <el-tab-pane label="风险相关" name="third">
          <RiskRelated ref="third" />
        </el-tab-pane>
        <el-tab-pane label="流动性分析" name="fourth">
            <Mobility ref="fourth" />
        </el-tab-pane>
        <el-tab-pane label="资金数据" name="fivth">
          <FinancialData ref="fivth" />
        </el-tab-pane>
        <!-- <el-tab-pane label="测试" name="sixth">
          <Test />
        </el-tab-pane> -->
      </el-tabs>
    </div>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div
          v-loading="loading1"
          element-loading-text="数据量巨大，正在计算中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 1)"
          class="chart_content CHARTS_BGC chart2 bn"
          :style="{ height: height_chart }"
        >
          <div
            class="canvasArea2"
            ref="chartColumn2_dialog"
            style="width: 100%; height: 100%"
          ></div>
        </div>
        <!-- <div class="charts5 charts6Height">
          <div class="chartTitle">分红\股息率</div>
          <div class="chart5Content">
            <div
              id="chartColumn4"
              ref="chartColumn4"
              style="width: 100%; height: 100%"
            ></div>
            <div class="chart5Info">年化波动率</div>
          </div>
        </div> -->
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import RiskRelated from "@/components/MarketDynamics/RiskRelated";
import Mobility from "@/components/MarketDynamics/Mobility";
import FinancialData from "@/components/MarketDynamics/FinancialData";
import BenefitAnalysis from "@/components/MarketDynamics/BenefitAnalysis";

import Test from "@/components/MarketDynamics/test";
import CTimeButtons from "@/components/Basic/CTimeButtons";
import CSelect from "@/components/Basic/CSelect";
import CSelectBench from "@/components/Basic/CSelectBench";
import CCascader from "@/components/Basic/CCascader";
import CCascader2 from "@/components/Basic/CCascader2";

import benchmarkData from "@/assets/json/benchmark.js";

import CDialog from "@/components/Basic/CDialog";

import { fnGetMuchDecimals } from "@/utils/util";

export default {
  data() {
    return {
      MAP: {
        "180101.SZ": "招蛇产园",
        "508000.SH": "张江光大",
        "508027.SH": "东吴苏园",
        "508099.SH": "建信中关村",
        "180102.SZ": "华夏合肥高新",
        "508021.SH": "国君临港",
        "508088.SH": "国君东久",
        "180103.SZ": "华夏和达高科",
        "508019.SH": "中金湖北科投",
        "180501.SZ": "深圳红土安居",
        "508058.SH": "中金厦门安居",
        "508068.SH": "华夏北京保障房",
        "508077.SH": "华夏华润有巢",
        "180301.SZ": "红土盐港",
        "508056.SH": "GLP仓储",
        "508098.SH": "嘉实京东",
        "180801.SZ": "首钢绿能",
        "508006.SH": "首创水务",
        "180201.SZ": "广州广河",
        "508001.SH": "沪杭甬高速",
        "180202.SZ": "越秀高速",
        "508018.SH": "中交公路",
        "508008.SH": "国金中铁建",
        "508066.SH": "江苏交控",
        "508009.SH": "安徽交控",
        "180401.SZ": "深圳能源",
        "508028.SH": "国电投",
        "508096.SH": "中航京能",
        "508007.SH": "中金山东高速",
        "508031.SH": "国君宽庭",
      },

      dialogVisible: false,
      height_dialog: "73vh",
      height_chart: "72vh",
      width_dialog: "80%",
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: "5vh",

      category_cascader_options: this.$store.state.category_cascader_options,

      category_cascader_options2: [
        {
          value: "产权类",
          label: "产权类",
          children: [
            {
              value: "物流仓储",
              label: "物流仓储",
            },
            {
              value: "产业园",
              label: "产业园",
            },
            {
              value: "保障性住房",
              label: "保障性住房",
            },
            {
              value: "消费",
              label: "消费",
            },
          ],
        },
        {
          value: "经营权类",
          label: "经营权类",
          children: [
            {
              value: "市政生态",
              label: "市政生态",
            },
            {
              value: "能源",
              label: "能源",
            },
            {
              value: "收费公路",
              label: "收费公路",
            },
          ],
        },
        {
          value: "公募REITs",
          label: "公募REITs",
        },
      ],

      // -----------分割线---------------

      chartColumn2: null,
      chartColumn2_dialog: null,

      // -----------分割线---------------
      chartColumn3: null,
      chart3_option:null,
      chart3_data: [],

      // -----------分割线---------------

      chartColumn4: null,

      // -----------分割线---------------

      chartColumn5: null,
      chartColumn5_title: "",

      // -----------分割线---------------
      chartColumn6: null,

      // -----------分割线---------------
      chartColumn7: null,

      // -----------分割线---------------
      dDailyTotalMv: [],
      dDailyTotalMv_X: [],
      dDailyTotalMv_y: [],

      // -----------分割线---------------
      dDailyReturnCompare: [],
      ddDailyReturnCompare_X: [],
      ddDailyReturnCompare_y: [],
      ddDailyReturnCompare_Z: [],
      sDailyReturnCompareDDL: "",

      // -----------分割线---------------
      dDailyReturnVolatility_yield: [],
      dDailyReturnVolatility_yield_X: [],
      dDailyReturnVolatility_yield_Y: [],

      // -----------分割线---------------
      dDailyReturnVolatility_return: [],

      // -----------分割线---------------
      dDailyReturnYieldSpread: [],
      dDailyReturnYieldSpread_X: [],
      dDailyReturnYieldSpread_CQ: [],
      dDailyReturnYieldSpread_CN: [],
      dDailyReturnYieldSpread_YI: [],
      dDailyReturnYieldSpread_1yr: [],

      // -----------分割线---------------
      dTop: [],

      // =========================================

      // activeName: "second",
      activeName: "first",

      dBtn1: ["3M", "6M", "1Y", "YTD", "MAX"],

      //
      chartColumn1: null,
      chart1Tmp: null,
      dReitsBenIndex: [],
      dReitsBenIndex_X: [],
      dReitsBenIndex_GM: [],
      dReitsBenIndex_HS: [],
      dReitsBenIndex_GY: [],
      dReitsBenIndex_CN: [],
      sReitsBenIndexDDL: "",

      //
      valChooseBenchMark: "",
      dBenchMark: [
        {
          value: "CN10YR",
          label: "CN10YR",
        },
        {
          value: "US10YR",
          label: "US10YR",
        },
        {
          value: "JP10YR",
          label: "JP10YR",
        },
        {
          value: "UK10YR",
          label: "UK10YR",
        },
        {
          value: "现货黄金",
          label: "现货黄金",
        },
        {
          value: "沪深300",
          label: "沪深300",
        },
        {
          value: "中证500",
          label: "中证500",
        },
        {
          value: "中证100",
          label: "中证100",
        },
        {
          value: "大宗商品",
          label: "大宗商品",
        },
        {
          value: "公用指数",
          label: "公用指数",
        },

        {
          value: "上证50",
          label: "上证50",
        },
        {
          value: "红利指数",
          label: "红利指数",
        },
        {
          value: "深证红利",
          label: "深证红利",
        },
        {
          value: "中证新能源指数",
          label: "中证新能源指数",
        },
        {
          value: "红利低波100",
          label: "红利低波100",
        },
        {
          value: "中证红利",
          label: "中证红利",
        },
        {
          value: "电力指数",
          label: "电力指数",
        },
        {
          value: "绿色能源",
          label: "绿色能源",
        },
        {
          value: "股息龙头",
          label: "股息龙头",
        },
        {
          value: "全指公用",
          label: "全指公用",
        },

        {
          value: "上证公用",
          label: "上证公用",
        },
        {
          value: "高股息策略",
          label: "高股息策略",
        },
        {
          value: "东证红利低波",
          label: "东证红利低波",
        },
        {
          value: "国企红利",
          label: "国企红利",
        },
        {
          value: "sw_公用事业",
          label: "sw_公用事业",
        },
        {
          value: "国证交通运输行业指数",
          label: "国证交通运输行业指数",
        },
        {
          value: "运输指数",
          label: "运输指数",
        },
        {
          value: "国证物流",
          label: "国证物流",
        },
        {
          value: "sw_高速公路",
          label: "sw_高速公路",
        },
        {
          value: "国证电力公用事业行业指数",
          label: "国证电力公用事业行业指数",
        },

        {
          value: "sw_电力",
          label: "sw_电力",
        },
        {
          value: "sw_物流",
          label: "sw_物流",
        },
        {
          value: "sw_产业地产",
          label: "sw_产业地产",
        },
        {
          value: "sw_水务及水治理",
          label: "sw_水务及水治理",
        },
        {
          value: "sw_环保",
          label: "sw_环保",
        },
        {
          value: "CN1YR",
          label: "CN1YR",
        },
        {
          value: "CN2YR",
          label: "CN2YR",
        },
        {
          value: "CN3YR",
          label: "CN3YR",
        },
        {
          value: "CN5YR",
          label: "CN5YR",
        },
        {
          value: "US1YR",
          label: "US1YR",
        },

        {
          value: "US2YR",
          label: "US2YR",
        },
        {
          value: "US3YR",
          label: "US3YR",
        },
        {
          value: "US5YR",
          label: "US5YR",
        },
        {
          value: "JP3YR",
          label: "JP3YR",
        },
        {
          value: "JP5YR",
          label: "JP5YR",
        },
        {
          value: "现货白银",
          label: "现货白银",
        },
        {
          value: "日经225",
          label: "日经225",
        },
        {
          value: "标普500",
          label: "标普500",
        },
        {
          value: "恒生指数",
          label: "恒生指数",
        },
        {
          value: "道琼斯工业平均",
          label: "道琼斯工业平均",
        },

        {
          value: "cni_风光装备",
          label: "cni_风光装备",
        },
        {
          value: "wi_科技园区指数",
          label: "wi_科技园区指数",
        },
        {
          value: "cj_园区开发(长江)",
          label: "cj_园区开发(长江)",
        },
        {
          value: "wi_房屋租赁指数",
          label: "wi_房屋租赁指数",
        },
        {
          value: "cj_垃圾焚烧(长江)",
          label: "cj_垃圾焚烧(长江)",
        },
        {
          value: "wi_垃圾发电指数",
          label: "wi_垃圾发电指数",
        },
        {
          value: "wi_生物质能指数",
          label: "wi_生物质能指数",
        },
        {
          value: "恒生地产分类指数",
          label: "恒生地产分类指数",
        },
      ],

      dGetReitsAndBenCorr: [],
      dGetReitsAndBenCorr_data: [],
      dGetReitsAndBenCorrColumns: [],

      // -------------------------
      optionsLev: [
        {
          value: "一级",
          label: "一级",
        },
      ],
      valueLev: "",
      dOnlyReitsIndex: [],
      dOnlyReitsIndex_X: [],
      dOnlyReitsIndex_GM: [],
      dOnlyReitsIndex_CQ: [],
      dOnlyReitsIndex_JY: [],
      sOnlyReitsIndexDDL: "",

      // -------------------------
      dStackingReturnData: [],
      dStackingReturnData_X: [],
      dStackingReturnData_JG: [],
      dStackingReturnData_FH: [],
      dStackingReturnData_ZT: [],

      // -------------------------
      dCapsizeReturn: [],
      dCapsizeReturn_data: [],
      dCapsizeReturn_arr1: [],
      dCapsizeReturn_minval: [],
      dCapsizeReturn_maxval: [],

      // -------------------------
      dCalSingleReitIndex: [],
      dCalSingleReitIndex_X: [],
      dCalSingleReitIndex_Ys: [],
      chart6_arrX: [],
      // chartColumn
      chartColumn2_1: null,
      dDiffTimeReturn: [],
      dDiffTimeReturn_X: [],
      dDiffTimeReturn_1Y: [],
      dDiffTimeReturn_2Y: [],
      dDiffTimeReturn_3M: [],
      dDiffTimeReturn_3Y: [],
      dDiffTimeReturn_YTD: [],
      dDiffTimeReturn_max: [],
      dDiffTimeReturnDDL: "",

      // chartColumn
      chartColumn2_2: null,
      dStackingReturnData2: [],
      dStackingReturnData2_X: [],
      dStackingReturnData2_JG: [],
      dStackingReturnData2_FH: [],
      dStackingReturnData2_ZT: [],

      // chartColumn
      chartColumn2_3: null,
      dReitsDetailRiskReturnScatter: [],

      isRiskRelated: false,
      isMobility: false,

      // chart1和2
      start_date_chart1And2: "YTD",
      options_benchmark: JSON.parse(localStorage.getItem("BENCHMARKNAME2")),
      options_benchmark2: null,
      // select_val_benchmark: ["中证红利", "沪深300", "CN10YR"],
      select_val_benchmark: [
        ["equity", "中证红利"],
        ["equity", "沪深300"],
        ["bond", "CN10YR"],
      ],

      // chart3和4和5
      start_date_chart3And4And5: "YTD",
      options_level: [
        {
          label: "一级",
          value: "L1",
        },
        {
          label: "二级",
          value: "L2",
        },
        {
          label: "全部",
          value: "all",
        },
      ],
      select_val_level: "L1",

      // chart6
      start_date_chart6: "YTD",

      TABPANEHEIGHT: 0,
      ROOTFONTFIZE: 0,

      // 遮罩层
      loading1: false,
      loading2: false,
      loading3: false,
    };
  },

  created() {
    
    // this.fnMethods("市场走势");
    this.fnInitBenchMark();
    // this.fnGetBenchMark()
    this.$nextTick(()=>{
    let name2 = localStorage.getItem('activeName')
    this.handleClick({name:name2})
    })
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener("resize", this.funResize);
  },
  methods: {
    // 初始化benchmark
    fnInitBenchMark() {
      let arr1 = JSON.parse(localStorage.getItem("BENCHMARKNAME2"));
      let arr2 = JSON.parse(localStorage.getItem("BENCHMARKNAMEBENTYPE"));

      let arr3 = arr2.map((node) => {
        return node.benType;
      });

      arr3 = [...new Set(arr3)];

      localStorage.setItem("BENTYPE", JSON.stringify(arr3));

      let arr4 = arr3.map((node) => {
        let obj = {
          value: node,
          label: node,
          children: [],
        };

        return obj;
      });

      arr1.forEach((node) => {
        let obj = arr2.find((item) => item.benName == node.value);
        if (obj) {
          let bentype = obj.benType;
          arr4.forEach((ele) => {
            if (ele.value == bentype) {
              ele.children.push(node);
            }
          });
        }
      });

      this.options_benchmark2 = arr4;
      this.fnNot();
    },

    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById("rootchart");
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;

      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      window.sessionStorage.setItem("ROOTFONTFIZE", this.ROOTFONTFIZE);

      let OFFSETHEIGHT = element.offsetHeight;
      this.TABPANEHEIGHT = OFFSETHEIGHT - 135;

      var chartColumn1 = document.getElementById("chartColumn1");
      chartColumn1.style.height = this.ROOTFONTFIZE * (480 / 144) + "px";

      var chartColumn2 = document.getElementById("chartColumn2");
      chartColumn2.style.height = this.ROOTFONTFIZE * (500 / 144) + "px";

      var chartColumn3 = document.getElementById("chartColumn3");
      chartColumn3.style.height = this.ROOTFONTFIZE * (388 / 144) + "px";

      return;
    },

    // ============= 特殊方法 =============================
    // 1.获取benchmark
    async fnGetBenchMark() {
      // const { data } = await this.$https.get(
      //   "http://conghua.uii.net:18777webapi/conghua/benchmarkData/list"
      // );
      // if (data.code !== 200) return this.$message.error(data.msg);
      return;

      let benckmark = benchmarkData.map((node) => {
        return node.benName;
      });

      benckmark = [...new Set(benckmark)];

      this.dBenchMark = benckmark.map((node) => {
        let obj = {
          value: node,
          label: node,
        };
        return obj;
      });
    },

    // ==========================================

    // --------------------------------------------------------------

    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChart2('宽高一');
      });
      // this.fnGetReitsAndBenCorr()
    },

    fnDropdown(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart2('宽高一');
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart2('宽高二');
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart2('宽高三');
        });
      }
    },

    // chart1和2
    fnCTimeButtons_select_val_chart1And2(val) {
      this.start_date_chart1And2 = val;
      this.fnGetReitsBenIndex();
      this.fnGetReitsAndBenCorr();
    },
    fnCSelect_chart1And2(val) {
      if (!val[0]) {
        return this.$message.error("值不能为空");
      } else {
        this.select_val_benchmark = val;
        this.$refs.chooseBenchmark.value = this.select_val_benchmark;

        this.fnGetReitsBenIndex();
        this.fnGetReitsAndBenCorr();
      }
    },

    fnCCascader_select_val_one(val) {
      let arr = JSON.parse(localStorage.getItem("BENTYPE"));
      let data = val.filter((node) => {
        return !arr.includes(node);
      });
      this.select_val_benchmark = data;
      this.fnNot();

      this.fnGetReitsBenIndex(true);
      this.fnGetReitsAndBenCorr(true);
    },

    async fnGetReitsBenIndex(val) {
      this.loading1 = true;
      // 待处理  要在这里做个判断
      // let ben_select = ["公用指数", "沪深300", "CN10YR"];
      // let start_date = "3M";
      let ben_select = null;
      if (typeof(this.select_val_benchmark[0]) !== 'string') {
        // 修改一下数组 start
        ben_select = this.select_val_benchmark.map((node) => {
          return node[1];
        });
        // 修改一下数组 end
      } else {
        ben_select = this.select_val_benchmark;
      }

      let str = ben_select.join("&ben_select=");

      let start_date = this.start_date_chart1And2;
      start_date = start_date == "MAX" ? "max" : start_date;

      const { data } = await this.$https.get(
        "/api/get_reits_ben_index?ben_select=" +
          str +
          "&start_date=" +
          start_date
      );
      if (data.code !== 200) return this.$message.error(data.msg);

      this.dReitsBenIndex = data.data;
      this.dReitsBenIndex_X = [];
      this.dReitsBenIndex_GM = [];
      this.dReitsBenIndex_HS = [];
      this.dReitsBenIndex_GY = [];
      this.dReitsBenIndex_CN = [];

      let dataTmp = data.data;

      for (let index = 0; index < dataTmp.length; index++) {
        this.dReitsBenIndex_X.push(dataTmp[index].tr_date);
        this.dReitsBenIndex_GM.push(dataTmp[index].公募REITs);
        this.dReitsBenIndex_HS.push(dataTmp[index].沪深300);
        this.dReitsBenIndex_GY.push(dataTmp[index].公用指数);
        this.dReitsBenIndex_CN.push(dataTmp[index].CN10YR);
      }

      this.sReitsBenIndexDDL =
        this.dReitsBenIndex_X[this.dReitsBenIndex_X.length - 1];

      let arr = this.sReitsBenIndexDDL.split("-");
      this.sReitsBenIndexDDL = arr[0] + "年" + arr[1] + "月" + arr[2] + "日";

      this.chart1Tmp = dataTmp;
      this.$nextTick(()=>{
        this.initChart1();
      })
    },

    initChart1() {
      // 步骤1：获取x,y数据
      // 步骤2：调试
      this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      this.chartColumn1.clear();


      let dataTmp = this.chart1Tmp;
      // X
      let dReitsBenIndex_X = dataTmp.map((node) => {
        return node.tr_date;
      });

      // Y
      let Y1 = Object.keys(dataTmp[0]);
      Y1.shift();

      // let colors = [
      //   {
      //     name: "公募REITs",
      //     value: "rgba(90, 232, 237, 1)",
      //   },
      //   {
      //     name: "CN10YR",
      //     value: "rgba(170, 171, 174, 1)",
      //   },
      //   {
      //     name: "沪深300",
      //     value: "rgba(255, 87, 51, 1)",
      //   },
      //   {
      //     name: "公用指数",
      //     value: "rgba(242, 209, 201, 1)",
      //   },
      // ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      

      let colors = [
        // "rgba(90, 232, 237, 1)",
        "rgba(255, 87, 51, 1)",
        theme == "dark" ? "rgba(242, 209, 201, 1)" : "#a84d38",
        // "",
        "rgba(170, 171, 174, 1)",
      ];
      colors.unshift(this.$store.state.colors.find(node=>node.name == '公募REITs').value)

      let colorArr = [];
      Y1.forEach((e, idx) => {
        colorArr.push(colors[idx]);
      });

      let FINALLY = [];
      let minData = [];
      Y1.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };

        dataTmp.forEach((e) => {
          obj.data.push(e[node]);
        });

        minData.push(Math.min(...obj.data));

        FINALLY.push(obj);
      });

      let min = Math.min(...minData) - 0.2;
      min = min.toFixed(1);

      let seriesArr = FINALLY.map((node) => {
        return {
          name: node.name,
          data: node.data,
          type: "line",
          smooth: false,
          itemStyle: {
            // color: "#5ae8ed",
          },
          symbol: "none",

          label: {
            show: false,
          },
        };
      });

      let texta = this.start_date_chart1And2;

      switch (texta) {
        case "3M":
          texta = "3个月以来";
          break;
        case "YTD":
          texta = "今年以来";
          break;

        case "6M":
          texta = "6个月以来";
          break;

        case "1Y":
          texta = "1年以来";

          break;
        case "3Y":
          texta = "3年以来";
          break;

        case "MAX":
          texta = "上市以来";
          break;
      }

      let option1 = {
        color: colorArr,
        title: {
          // 标题设置
          text: texta + " 公募REITs及主要对标指数走势对比", // 标题文字
          textStyle: {
            color: OPTIONDATA.title.textStyle.color,
            fontSize:
              this.ROOTFONTFIZE * (OPTIONDATA.title.textStyle.fontSize / 144),
          },
          top: this.ROOTFONTFIZE * (OPTIONDATA.title.top / 144),
        },

        legend: {
          orient: "vertical",
          right: this.ROOTFONTFIZE * (OPTIONDATA.legend.right / 144),
          top: "middle",
          align: "left",
          // x:50,
          // x2: 150,
          itemGap: this.ROOTFONTFIZE * (20 / 144),
          itemHeight: this.ROOTFONTFIZE * (5 / 144),
          textStyle: {
            fontSize:
              this.ROOTFONTFIZE * (OPTIONDATA.legend.textStyle.fontSize / 144),
            width: 80,
            color: OPTIONDATA.legend.textStyle.color,
            overflow: "break",
          },
          data: Y1,
        },
        tooltip: {
          formatter: function (args) {
            let str = "";
            str += args[0].axisValueLabel;
            str += "<br>";
            args.forEach((node) => {
              let val = Number(node.value).toFixed(2);
              str += `${node.marker}${node.seriesName}：${val}`;
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        grid: {
          left: "left",

          bottom: this.ROOTFONTFIZE * (OPTIONDATA.grid.bottom / 144),
          right: this.ROOTFONTFIZE * (120 / 144),
          containLabel: true,
        },

        xAxis: {
          splitNumber: 4,
          data: dReitsBenIndex_X,
          axisLabel: {
            fontSize:
              this.ROOTFONTFIZE * (OPTIONDATA.xAxis.axisLabel.fontSize / 144),
            show: true,
            showMaxLabel: true,
            margin:
              this.ROOTFONTFIZE * (OPTIONDATA.xAxis.axisLabel.margin / 144),
            // color: "rgba(218, 218, 218, 1)",
            // color: '#6e7079'
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
          // data: ['1','2','3','4'],
        },
        yAxis: {
          min: min,
          axisLabel: {
            fontSize:
              this.ROOTFONTFIZE * (OPTIONDATA.yAxis.axisLabel.fontSize / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              args = Number(args).toFixed(2);
              return args;
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.yAxis.splitLine.lineStyle.color,
            },
          },
        },

        series: seriesArr,
      };

      let basicOption = this.$store.state.basicOption;

      this.chartColumn1.setOption(basicOption);
      this.chartColumn1.setOption(option1);

      this.chartColumn1.resize();
      this.loading1 = false;
      // this.funResize()
    },

    // -------------------------------------------------------

    async fnGetReitsAndBenCorr(val) {
      let ben_select = null;
      if (typeof(this.select_val_benchmark[0]) !== 'string') {
        // 修改一下数组 start
        ben_select = this.select_val_benchmark.map((node) => {
          return node[1];
        });
        // 修改一下数组 end
      } else {
        ben_select = this.select_val_benchmark;
      }
      // let ben_select = this.select_val_benchmark;
      let str = ben_select.join("&ben_select=");

      let start_date = this.start_date_chart1And2;
      start_date = start_date == "MAX" ? "max" : start_date;

      const { data } = await this.$https.get(
        "/api/get_reits_and_ben_corr?ben_select=" +
          str +
          "&start_date=" +
          start_date
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dGetReitsAndBenCorr = data.data;

      // 转换热力图数据
      let data2 = this.dGetReitsAndBenCorr.data;
      this.dGetReitsAndBenCorr_data = [];

      // for (let i = 0; i < data2.length; i++) {
      //   for (let j = 0; j < data2[i].length; j++) {
      //     let arr1 = [];
      //     arr1[0] = i;
      //     arr1[1] = j;
      //     arr1[2] = data2[i][j].toFixed(2);
      //     console.log(arr1,'arr1');

      //     this.dGetReitsAndBenCorr_data.push(arr1);
      //   }
      // }

      for (let i = 0; i < data2.length; i++) {
        for (let j = data2[i].length - 1; j >= 0; j--) {
          let arr1 = [];
          arr1[0] = i;
          arr1[1] = data2[i].length - 1 - j;
          arr1[2] = data2[i][j].toFixed(2);

          this.dGetReitsAndBenCorr_data.push(arr1);
        }
      }

      let columns = this.dGetReitsAndBenCorr.columns.reverse();

      // if(columns.indexOf('产权类')>0){
      //   columns.splice(columns.indexOf('产权类'),1)
      //   columns.unshift('产权类')
      // }

      this.dGetReitsAndBenCorrColumns = columns;
      this.$nextTick(()=>{
        this.initChart2();
      })
    },

    initChart2(val) {
      let widthHeight = null 
      let widthWidth = null 
      if (!val) {
        widthHeight = 320
        widthWidth = 320

        this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);
      } else {
        if(val == '宽高一'){
           widthHeight = 470
           widthWidth = 1200

        }else if(val == '宽高二'){
           widthHeight = 470
           widthWidth = 470

        }else{
           widthHeight = 250
           widthWidth = 1200
        }
        this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2_dialog);
      }
      this.chartColumn2.clear();

      // this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2_dialog);
      let dGetReitsAndBenCorr = this.dGetReitsAndBenCorr;
      let dGetReitsAndBenCorr_data = this.dGetReitsAndBenCorr_data;
      let yData = this.dGetReitsAndBenCorrColumns;

      let options_benchmark = this.options_benchmark;

      let arr  = dGetReitsAndBenCorr_data.map(node=>{
        return node[node.length-1]
      })
      let min = Math.min(...arr)
      let max = Math.max(...arr)

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let texta = this.start_date_chart1And2;

      switch (texta) {
        case "3M":
          texta = "3个月以来";
          break;
        case "YTD":
          texta = "今年以来";
          break;

        case "6M":
          texta = "6个月以来";
          break;

        case "1Y":
          texta = "1年以来";

          break;
        case "3Y":
          texta = "3年以来";
          break;

        case "MAX":
          texta = "上市以来";
          break;
      }


      let option = {
        title: {
          // 标题设置
          text: texta + " 公募REITs及主要对标指数走势对比", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            // 标题文字样式设置
            fontSize:
              this.ROOTFONTFIZE * (OPTIONDATA.title.textStyle.fontSize / 144),
          },
          left: "center",
          top: this.ROOTFONTFIZE * (OPTIONDATA.title.top / 144),
        },
        grid: {
          // 320
          width: this.ROOTFONTFIZE * (widthWidth / 144) + "px",
          height: this.ROOTFONTFIZE * (widthHeight / 144) + "px",
          //  width: this.ROOTFONTFIZE * (250 / 144) + "px",
          // height: this.ROOTFONTFIZE * (250 / 144) + "px",
          top: this.ROOTFONTFIZE * (55 / 144),
          // right: this.ROOTFONTFIZE * (65 / 144)+'px',
          left: "center",
          bottom: this.ROOTFONTFIZE * (90 / 144),
          // containLabel:true
          // left: -10
        },

        xAxis: {
          data: dGetReitsAndBenCorr.index,
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            rotate: 90,
            // formatter:function(val){
            //   return val.split("").join('\n')
            // },
            formatter: function (val) {
              let flag = options_benchmark.some((node) => {
                return node.value == val;
              });

              if (flag || val == "公募REITs") {
                return val;
              } else {
                return val + "REITS";
              }
            },
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          type: "category",
          data: yData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: OPTIONDATA.yAxis.axisLabel.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            margin: this.ROOTFONTFIZE * (115 / 144),
            textStyle: {
              align: "left",
            },
            formatter: function (val) {
              let flag = options_benchmark.some((node) => {
                return node.value == val;
              });

              if (flag || val == "公募REITs") {
                return val;
              } else {
                return val + "REITS";
              }
            },
          },
        },
        visualMap: {
          min: min,
          max: max,
          calculable: false,
          orient: "vertical",
          text:[max.toFixed(fnGetMuchDecimals(max)),min.toFixed(fnGetMuchDecimals(min))],
          top: this.ROOTFONTFIZE * (50 / 144),
          right: "3%",
          inRange: {
            color: ["#417f9c", "#eae8e8", "#ca6d55"],
          },
          itemHeight: this.ROOTFONTFIZE * (widthHeight / 144),
          textStyle: {
            color: "#fff",
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          formatter: function (val) {
            let num = (parseInt(Math.abs(val)) + "").length >= 2 ? 1 : 2;
            val = val.toFixed(num);
            return val;
          },
        },
        series: [
          {
            type: "heatmap",
            data: dGetReitsAndBenCorr_data,
            label: {
              show: true,
              color: "#fff",
              fontSize: this.ROOTFONTFIZE * (10 / 144),
            },
            // 强调
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              label: {
                fontSize: this.ROOTFONTFIZE * (14 / 144),
                fontWeight: "bold",
              },
            },
          },
        ],
      };

      this.chartColumn2.setOption(option);
      this.chartColumn2.resize();
    },

    // ------------------------------------------------------------------------------------------

    // chart3和4和5
    fnCTimeButtons_select_val_chart3And4And5(val) {
      this.start_date_chart3And4And5 = val;
      this.fnGetOnlyReitsIndex();
      this.fnGetCapsizeReturn()
      
    },
    fnCSelect_chart3And4And5(val) {
      this.select_val_level = val;
      this.fnGetOnlyReitsIndex();
    },

    async fnGetOnlyReitsIndex() {
      // 待处理  要在这里做个判断
      // let ben_select = ["公用指数", "沪深300", "CN10YR"];
      let start_date = this.start_date_chart3And4And5;
      start_date = start_date == "MAX" ? "max" : start_date;

      let level = this.select_val_level;

      const { data } = await this.$https.get(
        "/api/get_only_reits_index?start_date=" + start_date + "&level=" + level
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.chart3_data = data.data;

      this.initChart3_2();
      this.fnGetStackingReturnData();
    },

    initChart3_2() {
      this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      this.chartColumn3.clear();

      

      let data = this.chart3_data
    
      // let data = this.chart3_data.slice();
      console.log(this.chart3_data,'基克拉');

      let X = data.map((node) => {
        return node["tr_date"];
      });
      console.log(X,'X');
      this.sOnlyReitsIndexDDL = X[X.length - 1];
      console.log(this.sOnlyReitsIndexDDL,'孤影着');


      let arr = this.sOnlyReitsIndexDDL.split("-");
      this.sOnlyReitsIndexDDL = arr[0] + "年" + arr[1] + "月" + arr[2] + "日";

      let Y = data.map((node) => {
        delete node["tr_date"];
        // let item = {...node}
        // delete item['tr_date']
        return node;  
      });

      // console.log(Y,'天使不流泪');


      let data2 = Object.keys(Y[0]);

      // let data2 = ['产权类', '经营权类', '公募REITs']

      console.log(data2,'dara2');

      // data2 = ['公募REITs', '产权类', '保障性住房',  '市政生态', '收费公路', '物流仓储', '经营权类', '能源']

      let datatmp = [];
      let datatmp2 = [];
      let datatmp3 = [];
      let DATA = data2;

      if (this.select_val_level == "all" || this.select_val_level == "L2") {
        let JYQL = [];
        let CQL = [];

        data2.forEach((node) => {
          let obj = null;
          this.category_cascader_options.forEach((e) => {
            let val = e.data.includes(node);
            if (val) {
              obj = e;
            }
          });

          if (obj && obj.name == "产权类") {
            CQL.push(node);
          }

          if (obj && obj.name == "经营权类") {
            JYQL.push(node);
          }
        });

        if (data2.indexOf("公募REITs") > 0) {
          datatmp3.push("公募REITs");
        }

        if (data2.indexOf("产权类") > 0) {
          datatmp3.push("产权类");
        }

        if (data2.indexOf("经营权类") > 0) {
          datatmp3.push("经营权类");
        }

        datatmp3 = [...datatmp3, ...CQL];
        datatmp3 = [...datatmp3, ...JYQL];

        // datatmp3 = [...datatmp3, ...datatmp2];
        // datatmp3 = [...datatmp3, ...datatmp];

        DATA = datatmp3;
      } else {
        //  let JYQL = [];
        // let CQL = [];
        //       data2.forEach(node=>{
        //          this.category_cascader_options.forEach((e,idx)=>{
        //                if(e.name == node || e.data.includes(node)){
        //                  if(!idx){
        //                    CQL.push(node)
        //                  }else{
        //                    JYQL.push(node)
        //                  }
        //                }
        //       })
        //       })

        //       if (data2.indexOf("公募REITs") > 0) {
        //             DATA.push("公募REITs");
        //        }
        //    DATA = [...DATA,...CQL];
        //    DATA = [...DATA,...JYQL];

        DATA = ["公募REITs", "产权类", "经营权类"];
      }

      let dataFINALY = [];
      DATA.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };
        Y.forEach((e) => {
          obj.data.push(e[node]);
        });
        dataFINALY.push(obj);
      });

      let NAME = dataFINALY.map((node) => {
        return node.name;
      });

      let colors = this.$store.state.colors;

      let colorArr = [];
      NAME.forEach((e) => {
        colors.forEach((node) => {
          if (e == node.name) {
            colorArr.push(node.value);
          }
        });
      });

      let seriesArr = dataFINALY.map((node) => {
        return {
          name: node.name,
          data: node.data,
          type: "line",
          smooth: false,
          symbol: "none",
          label: {
            show: false,
            position: "top",
          },
        };
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let texta = this.start_date_chart3And4And5;

      switch (texta) {
        case "3M":
          texta = "3个月以来";
          break;
        case "YTD":
          texta = "今年以来";
          break;

        case "6M":
          texta = "6个月以来";
          break;

        case "1Y":
          texta = "1年以来";

          break;
        case "3Y":
          texta = "3年以来";
          break;

        case "MAX":
          texta = "上市以来";
          break;
      }

      this.chartColumn5_title = texta;

      let option = {
        color: colorArr,
        title: {
          // 标题设置
          text: texta + " 公募REITs及主要对标指数走势对比", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize:
              this.ROOTFONTFIZE * (OPTIONDATA.title.textStyle.fontSize / 144),
          },
          left: "center",
          top: this.ROOTFONTFIZE * (OPTIONDATA.title.top / 144),
        },

        tooltip: {
          trigger: "axis",
          formatter: function (args) {
            let str = "";
            str += args[0].axisValueLabel;
            str += "<br>";
            args.forEach((node) => {
              let val = Number(node.value).toFixed(2);
              str += `${node.marker}${node.seriesName}：${val}`;
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        grid: {
          left: "left",
          bottom: this.ROOTFONTFIZE * (OPTIONDATA.grid.bottom / 144),
          // bottom: 0,
          right: this.ROOTFONTFIZE * (120 / 144),
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          //  interval: "2",  ???
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize:
              this.ROOTFONTFIZE * (OPTIONDATA.xAxis.axisLabel.fontSize / 144),
            show: true,
            showMaxLabel: true,
            margin:
              this.ROOTFONTFIZE * (OPTIONDATA.xAxis.axisLabel.margin / 144),
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
          data: X,
        },
        yAxis: {
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize:
              this.ROOTFONTFIZE * (OPTIONDATA.yAxis.axisLabel.fontSize / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              args = Number(args).toFixed(2);
              return args;
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.yAxis.splitLine.lineStyle.color,
            },
          },
        },
        legend: {
          show: true,
          icon: "roundRect",
          orient: "vertical",
          right: this.ROOTFONTFIZE * (10 / 144),
          top: "middle",
          align: "left",
          itemGap: this.ROOTFONTFIZE * (10 / 144),
          itemHeight: this.ROOTFONTFIZE * (5 / 144),
          padding: [0, 0, 0, 0],
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize:
              this.ROOTFONTFIZE * (OPTIONDATA.legend.textStyle.fontSize / 144),
          },
          data: NAME,
        },
        series: seriesArr,
      };

      this.chart3_option = option
      this.chartColumn3.setOption(option);
      this.chartColumn3.resize();
    },

    fnSetOption(){
         let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        title:{
          textStyle : {
            color: OPTIONDATA.title.textStyle.color,
          }
        },
        xAxis:{
          axisLabel:{
            color: OPTIONDATA.xAxis.axisLabel.color,
          }
        },
        yAxis: {
          axisLabel: {
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            lineStyle: {
              color: OPTIONDATA.yAxis.splitLine.lineStyle.color,
            },
          },
        },
         legend: {
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
          },
        },
      }

       this.chartColumn3.setOption(option);
      this.chartColumn3.resize();

    },

    // ---------------------------------------------------------------------------

    async fnGetStackingReturnData() {
      let val = this.chart3_data;
      this.loading3 = true;
      let r_type_ = Object.keys(val[0]);
      // let r_type_ = ['产权类', '经营权类', '公募REITs'];
      let annualize = "1";
      let cal_function = "type";
      let str = r_type_.join("&r_type_=");
      // let r_type_ = "公募REITs";

      let freq_type = this.start_date_chart3And4And5;
      freq_type = freq_type == "MAX" ? "max" : freq_type;

      let codes = "";

      const { data } = await this.$https.get(
        "/api/stacking_return_data?annualize=" +
          annualize +
          "&cal_function=" +
          cal_function +
          "&codes=" +
          codes +
          "&r_type_=" +
          str +
          "&freq_type=" +
          freq_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dStackingReturnData = data.data;
      this.dStackingReturnData_X = [];
      this.dStackingReturnData_JG = [];
      this.dStackingReturnData_FH = [];
      this.dStackingReturnData_ZT = [];

      let dataTmp = data.data;

      for (let index = 0; index < dataTmp.length; index++) {
        this.dStackingReturnData_X.push(dataTmp[index].reit_code);
        this.dStackingReturnData_JG.push(dataTmp[index].价格回报);
        this.dStackingReturnData_FH.push(dataTmp[index].分红回报);
        this.dStackingReturnData_ZT.push(dataTmp[index].总体回报);
      }

      this.dStackingReturnData_JG = this.dStackingReturnData_JG.map((node) => {
        let val = node * 100;
        return val.toFixed(1);
      });

      this.dStackingReturnData_FH = this.dStackingReturnData_FH.map((node) => {
        let val = node * 100;
        return val.toFixed(1);
      });

      this.dStackingReturnData_ZT = this.dStackingReturnData_ZT.map((node) => {
        let val = node * 100;
        return val.toFixed(1);
      });
      this.$nextTick(()=>{
        this.initChart4();
      })
    },

    initChart4() {
      this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      this.chartColumn4.clear();

      let dStackingReturnData_X = this.dStackingReturnData_X;
      // dStackingReturnData_X = dStackingReturnData_X.slice(0, 3);
      let dStackingReturnData_JG = this.dStackingReturnData_JG;
      let dStackingReturnData_FH = this.dStackingReturnData_FH;
      let dStackingReturnData_ZT = this.dStackingReturnData_ZT;

      let sum1 = 0;
      let sum2 = 0;
      let sum3 = 0;
      dStackingReturnData_X.forEach((node, idx) => {
        if (idx == 0) {
          sum1 += Number(dStackingReturnData_JG[idx]);
          sum1 += Number(dStackingReturnData_FH[idx]);
        }

        if (idx == 1) {
          sum2 += Number(dStackingReturnData_JG[idx]);
          sum2 += Number(dStackingReturnData_FH[idx]);
        }

        if (idx == 2) {
          sum3 += Number(dStackingReturnData_JG[idx]);
          sum3 += Number(dStackingReturnData_FH[idx]);
        }
      });

      let sum = [sum1, sum2, sum3];

      let min = Math.min(...sum);
      let max = Math.max(...sum);

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option4 = {
        legend: {
          show: true,
          orient: "horizontal",
          top: this.ROOTFONTFIZE * (15 / 144),
          left: this.ROOTFONTFIZE * (20 / 144),
          align: "left",
          icon: "roundRect",
          itemGap: this.ROOTFONTFIZE * (20 / 144),
          itemHeight: this.ROOTFONTFIZE * (12 / 144),
          itemWidth: this.ROOTFONTFIZE * (12 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
          data: [
            {
              name: "价格回报",
              icon: "roundrect",
            },
            {
              name: "分红回报",
              icon: "roundrect",
            },
            {
              name: "总体回报",
              icon: "circle",
            },
          ],
        },

        grid: {
          left: this.ROOTFONTFIZE * (10 / 144),
          bottom: this.ROOTFONTFIZE * (10 / 144),
          right: this.ROOTFONTFIZE * (10 / 144),
          top: this.ROOTFONTFIZE * (50 / 144),
          width: "95%",
          containLabel: true,
        },
        tooltip: {
          // 工具提示
          // trigger:'item',
          trigger: "axis", // 工具提示的类型 item代表的是每个柱本身, axis代表的是坐标轴
          triggerOn: "mousemove|click", // 触发时机, click代表点击, mouseOver代表鼠标移过
          formatter: function (args) {
            let str = "";
            str += args[0].axisValueLabel;
            str += "<br>";
            args.forEach((node) => {
              let val = Number(node.value);
              let num = fnGetMuchDecimals(val);
              val = val.toFixed(num);
              str += `${node.marker}${node.seriesName}：${val}%`;
              str += "<br>";

              return str;
            });
            return str;

            // let str = "";
            // str += `YTD回报率：`;
            // str += "<br>";
            // str += `${args[0].marker}${args[0].name}：${args[0].data}%`;

            // return str;
          },
        },
        xAxis: {
          type: "category",
          data: dStackingReturnData_X,
          axisLine: {
            show: false,
            onZero: true, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            showMaxLabel: true,
            margin: 20,
            color: OPTIONDATA.xAxis.axisLabel.color,

            // formatter: function (value) {
            //   var str = "";
            //   var num = 2; //每行显示字数
            //   var valLength = value.length; //该项x轴字数
            //   var rowNum = Math.ceil(valLength / num); // 行数

            //   if (rowNum > 1) {
            //     for (var i = 0; i < rowNum; i++) {
            //       var temp = "";
            //       var start = i * num
            //       var end = start + num;

            //       temp = value.substring(start, end) + "\n";
            //       str += temp;
            //     }
            //     return str;
            //   } else {
            //     return value;
            //   }
            // },
          },
        },
        yAxis: {
          // scale: true,
          type: "value",
          // min: -20,
          // max: 20,
          interval: 5,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (12 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            // formatter: "{value}%", //y轴百分比
            formatter: function (val) {
              return val.toFixed(1) + "%";
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.yAxis.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            name: "价格回报",
            type: "bar",
            stack: "all",
            data: dStackingReturnData_JG,
            // barWidth: "60px",
            itemStyle: {
              color: "#5acac6",
            },
            label: {
              show: true,
              position: "insideBottom",
              fontSize: this.ROOTFONTFIZE * (12 / 144),
              color: "#fff",
              formatter: function (value) {
                let val = value.value;
                let num = fnGetMuchDecimals(val);
                val = Number(val).toFixed(num);
                return val + "%";
              },
            },
            markLine: {
              symbol: ["none", "none"], // none为标线两端的样式为无，可更改
              data: [
                {
                  yAxis: 0,
                },
              ],
              label: {
                show: false,
              },
              lineStyle: {
                type: "solid",
                color: "#6e7079",
              },
            },
          },
          {
            name: "分红回报",
            type: "bar",
            stack: "all",
            data: dStackingReturnData_FH,
            itemStyle: {
              color: "#5e5ce6",
            },

            // barWidth: this.ROOTFONTFIZE * (14 / 144)+'px',

            label: {
              show: true,
              position: "top",
              fontSize: this.ROOTFONTFIZE * (12 / 144),
              color: OPTIONDATA.title.textStyle.color,
              formatter: function (value) {
                let val = value.value;
                let num = fnGetMuchDecimals(val);
                val = Number(val).toFixed(num);
                if (val == 0) {
                  return " ";
                } else {
                  return val + "%";
                }
              },
            },
          },
          {
            name: "总体回报",
            type: "line",
            data: dStackingReturnData_ZT,
            itemStyle: {
              show: false,
              width: 0,
              // color: "#ff7000",
              color: "#ff5028",
              opacity: 0.7,
            },
            lineStyle: {
              opacity: 0,
            },
            symbol: "circle",
            symbolSize: 8,

            // barWidth: "60px",

            label: {
              show: true,
              position: "right",
              fontSize: this.ROOTFONTFIZE * (12 / 144),
              backgroundColor: "#ff5028",
              color: "rgba(255, 255, 255)",
              padding: 3,
              borderRadius: 3,
              formatter: function (value) {
                let val = value.value;
                let num = fnGetMuchDecimals(val);
                val = Number(val).toFixed(num);
                return val + "%";
              },
            },
          },
        ],
      };

      this.chartColumn4.setOption(option4);
      this.chartColumn4.resize();
      this.loading3 = false;
    },

    // ---------------------------------------------------------------------------

    async fnGetCapsizeReturn() {
      this.loading3 = true
      let freq_type = this.start_date_chart3And4And5;
      if (freq_type == "MAX") return

      freq_type = freq_type == "MAX" ? "max" : freq_type;

      // switch (this.start_date_chart3And4And5) {
      //   case "3M":
      //     this.chartColumn5_title = "3个月以来";
      //     break;
      //   case "YTD":
      //     this.chartColumn5_title = "今年以来";
      //     break;

      //   case "6M":
      //     this.chartColumn5_title = "6个月以来";
      //     break;

      //   case "1Y":
      //     this.chartColumn5_title = "1年以来";

      //     break;
      //   case "3Y":
      //     this.chartColumn5_title = "3年以来";
      //     break;

      //   case "MAX":
      //     this.chartColumn5_title = "上市以来";
      //     break;
      // }

      const { data } = await this.$https.get(
        "/api/capsize_return?freq_type=" + freq_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dCapsizeReturn = data.data;

      // 转换热力图数据
      let data2 = this.dCapsizeReturn.data;
      data2.reverse();
      this.dCapsizeReturn_data = [];

      for (let i = 0; i < data2.length; i++) {
        for (let j = 0; j < data2[i].length; j++) {
          let arr1 = [];
          arr1[0] = j;
          arr1[1] = i;
          arr1[2] = data2[i][j].toFixed(3);

          this.dCapsizeReturn_data.push(arr1);
        }
      }

      let arr1 = [];
      this.dCapsizeReturn_data.forEach((node) => {
        arr1.push(node[2]);
      });

      arr1 = arr1.map((node) => {
        return Number(node);
      });

      let minval = Math.min(...arr1);
      let maxval = Math.max(...arr1);

      this.dCapsizeReturn_arr1 = arr1;
      this.dCapsizeReturn_minval = minval;
      this.dCapsizeReturn_maxval = maxval;
      this.$nextTick(()=>{
        this.initChart5();
      })
    },

    initChart5() {
      this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5);

      let dCapsizeReturn = this.dCapsizeReturn;
      let dCapsizeReturn_data = this.dCapsizeReturn_data;

      console.log(dCapsizeReturn_data,'dCapsizeReturn_data迪丽热巴');

      let arr1 = this.dCapsizeReturn_arr1;
      let minval = this.dCapsizeReturn_minval;
      let maxval = this.dCapsizeReturn_maxval;

      let obj = dCapsizeReturn.index;
      let arrTmp1 = Object.values(obj);
      let dCapsizeReturn_index = arrTmp1.reverse();

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        // tooltip: {
        //   position: "top",
        // },

        grid: {
          width: this.ROOTFONTFIZE * (160 / 144) + "px",
          height: this.ROOTFONTFIZE * (160 / 144) + "px",
          top: this.ROOTFONTFIZE * (35 / 144),
          left: "center",
        },
        xAxis: {
          type: "category",
          data: dCapsizeReturn.columns,
          position: "top",

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: this.ROOTFONTFIZE * (12 / 144),
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          type: "category",
          data: dCapsizeReturn_index,
          axisLine: {
            show: true,
            lineStyle: {
              color: OPTIONDATA.yAxis.axisLine.lineStyle.color,
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: OPTIONDATA.yAxis.axisLabel.color,

            fontSize: this.ROOTFONTFIZE * (12 / 144),
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "solid",
              color: OPTIONDATA.yAxis.axisLine.lineStyle.color,
            },
          },
        },
        visualMap: {
          min: minval,
          max: maxval,
          top: this.ROOTFONTFIZE * (35 / 144),
          right: "20%",
          orient: "vertical",
          calculable: false,
          inRange: {
            // color: ["#63e6e2", "#6088e5"],
            color: ["#fefffe", "rgba(90, 232, 237, 1)"],
            // color: ["#46ed81"],
          },

          textStyle: {
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          formatter: function (value) {
            let val = Number(value) * 100;
            let num = (parseInt(Math.abs(val)) + "").length >= 2 ? 1 : 2;
            val = val.toFixed(num);
            return val + "%";
          },

          // bottom: "15%",
        },

        series: [
          {
            name: "Punch Card",
            type: "heatmap",
            data: dCapsizeReturn_data,
            label: {
              show: true,
              color: "#333",
              formatter: function (param) {
                let val = param.value[2] * 100;
                let num = (parseInt(Math.abs(val)) + "").length >= 2 ? 1 : 2;
                val = val.toFixed(num) + "%";

                return val;
              },
            },
            // 强调
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              label: {
                fontSize: this.ROOTFONTFIZE * (14 / 144),
                fontWeight: "bold",
              },
            },
          },
        ],
      };

      this.chartColumn5.setOption(option);
      this.chartColumn5.resize();
    },

    // 第六个 -------------------------------------------------------------------------
    // 步骤1：在模板中，使用v-for循环遍历item列表，并为每个item渲染一个包含唯一id的图表容器：
    // 步骤2：在Vue组件的data选项中定义一个数组，用于存储每个item的echarts实例：
    // 步骤3： 在mounted钩子函数 (或者定义的函数中) 中，使用v-for循环遍历您的item列表，并为每个item创建一个独立的echarts实例。在创建实例时，您可以将实例添加到echartsInstances数组中：

    fnCTimeButtons_select_val_chart6(val) {
      this.start_date_chart6 = val;
      this.fnGetCalSingleReitIndex();
    },
    async fnGetCalSingleReitIndex() {
      this.loading2 = true;
      // 待处理  要在这里做个判断
      // let ben_select = ["公用指数", "沪深300", "CN10YR"];
      let start_date = this.start_date_chart6;
      start_date = start_date == "MAX" ? "max" : start_date;

      const { data } = await this.$https.get(
        "/api/cal_single_reit_index?start_date=" + start_date
      );
      if (data.code !== 200) return this.$message.error(data.msg);

      this.dCalSingleReitIndex = data.data;
      let dataTmp = data.data;

      // 步骤1：提取x轴数据
      let arrX = [];
      dataTmp.forEach((node) => {
        arrX.push(node.tr_date);
      });

      // 步骤2：提取值作为y轴数据，组成一组数据
      // let arr2 = Object.keys(dataTmp[0]);
      // arr2.shift(); // 改变一下这个顺序即可
      let arr2 = Object.entries(dataTmp[dataTmp.length - 1]);
      arr2.shift();
      function compare(val1, val2) {
        return val2[1] - val1[1];
      }
      arr2.sort(compare);
      arr2 = arr2.map((node) => {
        return node[0];
      });

      let FINALLYDATA = [];
      arr2.forEach((node) => {
        let obj = {
          key: node,
          data: [],
        };
        arrX.forEach((item) => {
          dataTmp.forEach((e) => {
            if (e.tr_date == item) {
              obj.data.push(e[node]);
            }
          });
        });

        FINALLYDATA.push(obj);
      });

      let FINALLYDATA_AFTER = [];
      arr2.forEach((node) => {
        let obj = {
          name: node,
          value: FINALLYDATA,
        };
        FINALLYDATA_AFTER.push(obj);
      });

      // FINALLYDATA_AFTER = FINALLYDATA_AFTER.slice(0, 20);

      // this.dCalSingleReitIndex_Ys = arr3;
      this.dCalSingleReitIndex_Ys = [];
      this.dCalSingleReitIndex_Ys = FINALLYDATA_AFTER;

      this.chart6_arrX = arrX;
        this.initChart6();
    },

    initChart6() {
      let FINALLYDATA_AFTER = this.dCalSingleReitIndex_Ys;
      let arrX = this.chart6_arrX;

      let MAP_L2 = this.$store.state.MAP_L2;
      let COLORSARRAY = this.$store.state.colors;
      //
      let CODELIST = this.$store.state.CODELIST;

      let theme = this.$store.state.theme;

      setTimeout(() => {
        FINALLYDATA_AFTER.forEach((item, index) => {
          this.$nextTick(()=>{

          const chartInstance = echarts.init(
            document.getElementById(item.name)
          );
          chartInstance.clear();

          let seriesArr = item.value.map((node) => {
            return {
              name: node.key,
              data: node.data,
              type: "line",
              smooth: false,
              symbol: "none",
              tooltip: {
                show: node.key == item.name ? true : false,
              },
            };
          });

          let colors = [];
          item.value.forEach((node, idx) => {
            if (item.name == node.key) {
              let valobj = CODELIST.filter((item2) => {
                return item2.reitCode == node.key;
              });
              let val = valobj[0].reitsTypeL2;
              let obj = COLORSARRAY.filter((node) => {
                return node.name == val;
              });

              colors.push(obj[0].value);
            } else {
              if (theme == "dark") {
                colors.push("rgba(118, 118, 118, 0.1)");
              } else {
                colors.push("rgba(196, 196, 196, 0.1)");
              }
            }
          });

          // let text = this.MAP[item.name];
          let MAP = CODELIST.filter((node) => {
            return node.reitCode == item.name;
          });
          let text = MAP[0].reitsName;
          let CODENAME = this.$store.state.CODENAME;

          let OPTIONDATA =
            this.$store.state.theme == "dark"
              ? this.$store.state.OPTIONDATA
              : this.$store.state.OPTIONDATA_light;

          let option = {
            tooltip: {
              trigger: "axis",
              confine: true,
              triggerOn: "mousemove|click", // 触发时机, click代表点击, mouseOver代表鼠标移过
              formatter: function (val) {
                let str = "";
                val.forEach((node) => {
                  let val = Number(node.value).toFixed(2);

                  str += `${node.axisValueLabel}`;
                  str += "<br>";
                  // str += `${node.marker}${node.seriesName}:   ${val}`;
                  str += `${node.marker}${CODENAME[node.seriesName]}:   ${val}`;
                });

                return str;
              },
            },
            title: {
              text: text, // 标题文字
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
                fontSize: this.ROOTFONTFIZE * (16 / 144),
              },
              top: this.ROOTFONTFIZE * (20 / 144),
              left: this.ROOTFONTFIZE * (20 / 144),
            },
            color: colors,
            xAxis: {
              type: "category",
              data: arrX,
              axisTick: {
                show: false,
              },
              axisLine: {
                show: true,
              },
              axisLabel: {
                show: false,
              },
            },
            yAxis: {
              type: "value",
              axisLine: {
                show: true,
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: true,
                color: OPTIONDATA.xAxis.axisLabel.color,
                fontSize: this.ROOTFONTFIZE * (14 / 144),
              },
            },
            series: seriesArr,
          };

          chartInstance.setOption(option);
          this.loading2 = false;
          })
        });

      }, 500);
    },

    // ---------------------------------------------------------------------------

    funResize() {
      // this.fnGetWidth();

      this.$nextTick(()=>{
          this.chartColumn1.resize();
          this.chartColumn2.resize();
          this.chartColumn4.resize();
          this.chartColumn5.resize();
          this.chartColumn3.resize();
      })
    
    },

    // ====================================

    handleClick(e) {
      this.$refs.fourth.fnDispose()
      // this.$refs.fourth.fnDispose()
      this.activeName = e.name;
      // window.sessionStorage.setItem('BreadcrumbsName', this.activeName);
      // this.$store.commit('fnSetBreadcrumbs','新民')
      localStorage.setItem('activeName',this.activeName)
      this.fnMethods(e.label);

      //  this.funResize()
    },
    fnMethods(data) {
      this.$eventBus.$emit("setBreadcrumbs", data?data:'市场走势');
      console.log(this.activeName,'天使');
      if (this.activeName == "first") {
        this.fnGetReitsBenIndex();
        this.fnGetReitsAndBenCorr();
        this.fnGetOnlyReitsIndex();
        this.fnGetCapsizeReturn();
        // this.fnGetStackingReturnData();
        this.fnGetCalSingleReitIndex();
        // setTimeout(() => {
        //   this.initChart1();
        //   this.initChart2();
        //   this.initChart3();
        //   this.initChart4();
        //   this.initChart5();
        // }, 0);
      }

      if (this.activeName == "second") {
        this.$refs.second.fnGetDiffTimeReturn();
        this.$refs.second.fnGetStackingReturnData2();
        this.$refs.second.fnGetReitsDetailRiskReturnScatter();
        // this.$refs.second.fnCodeName();

      }

      if (this.activeName == "third") {
        this.$refs.third.fnGeRiskAnalysisData();
        this.$refs.third.fnGeRiskAnalysisData2();
        this.$refs.third.fnCodeName();

      }

      if (this.activeName == "fourth") {
        this.$refs.fourth.fnButtons1("概览");
      }

      if (this.activeName == "fivth") {
        this.$refs.fivth.fnButtons1("概览");

      }
    },

    // 禁止选择
    fnNot() {
      let value = null;
      if (typeof this.select_val_benchmark[0] == "string") {
        value = this.select_val_benchmark;
      } else {
        value = this.select_val_benchmark.map((node) => {
          return node[1];
        });
      }

      // 3种情况
      // 1. 等于3
      if (this.select_val_benchmark.length == 3) {
        this.options_benchmark2 = this.options_benchmark2.map((node) => {
          node.children.forEach((ele) => {
            let obj = value.find((item) => {
              return item == ele.value;
            });
            if (!obj) {
              ele["disabled"] = true;
            } else {
              ele["disabled"] = false;
            }
          });
          return node;
        });
      }
      // 2. 大于1小于3
      if (
        this.select_val_benchmark.length < 3 &&
        this.select_val_benchmark.length > 1
      ) {
        this.options_benchmark2 = this.options_benchmark2.map((node) => {
          node.children.forEach((ele) => {
            ele["disabled"] = false;
          });
          return node;
        });
      }
      // 3. 等于1
      if (this.select_val_benchmark.length == 1) {
        this.options_benchmark2 = this.options_benchmark2.map((node) => {
          node.children.forEach((ele) => {
            let obj = value.find((item) => {
              return item == ele.value;
            });
            if (obj) {
              ele["disabled"] = true;
            } else {
              ele["disabled"] = false;
            }
          });
          return node;
        });
      }
    },
  },
  components: {
    RiskRelated,
    Mobility,
    FinancialData,
    BenefitAnalysis,
    Test,
    CTimeButtons,
    CSelect,
    CDialog,
    CSelectBench,
    CCascader2,
  },
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
        "--TABPANEHEIGHT": this.TABPANEHEIGHT + "px",
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      
      // this.fnGetReitsBenIndex();
      // this.fnGetReitsAndBenCorr();
      // this.fnGetOnlyReitsIndex();
      // this.fnGetStackingReturnData();
      // this.fnGetCapsizeReturn();
      // this.fnGetCalSingleReitIndex()
      // this.initChart6();
      this.$nextTick(() => {
        this.initChart1();
        this.initChart2();
        this.initChart4();
        this.initChart5();
        this.initChart6()

        // this.fnGetOnlyReitsIndex()
         this.fnSetOption();

        if (this.activeName == "first") {
          //  this.initChart4();
          //  this.initChart5();
        }

        // this.initChart1()
        // this.initChart2()
        // this.initChart3_2()
        // this.initChart4()
        // this.initChart5()
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  background-color: var(--bigger-card-bgc);
  height: 100%;
  // padding-top: 10px;
  // padding-left: 20px;
  // padding-right: calc(var(--ROOTFONTFIZE) * (25 / 144));
}

// .main {
//   background-color: #1d1f25;
//   height: 90%;
// }

// el-tabs
.el-tabs {
  // background-color: #141518;
  background-color: var(--bigger-card-bgc);
  // padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));

  // background-color: pink;
}

.overall {
  // background-color: #1d1f25;
  // background-color: #3b3d42;
  background-color: var(--charts_bgc);
  height: auto;
  // margin-top: calc(var(--ROOTFONTFIZE) * (5 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (39 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (16 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  box-sizing: border-box;
}

.overall2 {
  // background-color: #1d1f25;
  // background-color: #3b3d42;
  background-color: var(--charts_bgc);

  height: auto;
  margin-top: calc(var(--ROOTFONTFIZE) * (5 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (16 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  box-sizing: border-box;
}

.overall3 {
  // background-color: #1d1f25;
  // background-color: #3b3d42;
  background-color: var(--charts_bgc);

  height: calc(var(--ROOTFONTFIZE) * (673 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (5 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (39 / 144));
  color: #fff;
  box-sizing: border-box;
}

::v-deep .el-tabs__item {
  color: var(--el_tabs_item_color);
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
}

::v-deep .el-tabs__item:hover {
  color: var(--el_tabs_item_color_active_hover) !important;
}

::v-deep .el-tabs__item.is-active {
  color: var(--el_tabs_item_color_active) !important;
  font-size: 18px;
}

/*去掉tabs底部的下划线*/
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/*去掉切换时el-tab-pane底部的蓝色下划线*/
::v-deep .el-tabs__active-bar {
  // background-color: #1573fe !important;
  background-color: var(--primary-color) !important;
}

::v-deep .el-tab-pane {
  // background-color: #1573fe !important;
  display: block;
  // height: 1040px;
  // width: 100%;
  height: var(--TABPANEHEIGHT);
  overflow-y: auto;
  box-sizing: border-box;

  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

// -----------------------------------------------------------------------------------------------
.chart_title {
  color: var(--chart_title);
  font-size: calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-weight: 700;
  // border-left: 5px solid #1573fe;
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  padding-left: 18px;
  padding-left: calc(var(--ROOTFONTFIZE) * (18 / 144));
  height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  display: flex;
  align-items: center;
}

.chart_title span {
  color: #1573fe;
}

.chart3_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btnArea {
  margin-top: calc(var(--ROOTFONTFIZE) * (27 / 144));
  display: flex;
  justify-content: center;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-left: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

.ml80 {
  margin-left: calc(var(--ROOTFONTFIZE) * (80 / 144));
}

.p10 {
  padding: calc(var(--ROOTFONTFIZE) * (0 / 144))
    calc(var(--ROOTFONTFIZE) * (20 / 144));
  justify-content: start;
}

.btnArea2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  position: relative;
  // padding-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.btnArea6 {
  display: flex;
  justify-content: space-between;
  padding-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.CTimeButtonStyle {
  margin-left: 20px;
  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  // margin: 0 auto;
}

.canvasArea {
  width: 100%;
  height: 100%;
}

.canvasArea2 {
  // width: 100%;
  height: calc(var(--ROOTFONTFIZE) * (600 / 144));
}

.HEIGHTAUTO {
  height: auto;
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.echarts_AREA {
  padding-left: calc(var(--ROOTFONTFIZE) * (50 / 144));
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  // justify-content: space-between;
  // height: 800px;
  // overflow-y: scroll;
}

.canvasArea3 {
  width: 16%;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  // border: 1px solid #3e3f43;
  border: none;
  // border-radius: 20px;
}

.chart_content {
  margin-left: calc(var(--ROOTFONTFIZE) * (18 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  position: relative;
}

.bn{
  border: none;
}

.chart1,
.chart2 {
  height: calc(var(--ROOTFONTFIZE) * (520 / 144));
}

.chart3 {
  height: calc(var(--ROOTFONTFIZE) * (520 / 144));
}

.chart1_content_top {
  display: flex;
}

.overall2Right{
}

.chart1_content_top_tit {
  writing-mode: vertical-rl;
  text-align: center;
  color: rgba(140, 140, 140, 1);
  color: rgba(218, 218, 218, 1);
  color: var(--stock_area_item_color2);
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  margin: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.chart1_content_bottom {
  padding-left: calc(var(--ROOTFONTFIZE) * (50 / 144));
  color: rgba(158, 158, 158, 1);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}

.chart4 {
  height: calc(var(--ROOTFONTFIZE) * (240 / 144));
}

.chart5 {
  height: calc(var(--ROOTFONTFIZE) * (210 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (8 / 144));
}

.chart5_tit {
  float: left;
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: var(--stock_area_item_color2);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 600;
}

.chart6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overall3_top {
  display: flex;
  justify-content: space-between;
}

.overall3_tit {
  text-align: center;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (16 / 144));
  color: rgba(218, 218, 218, 1);
  margin: calc(var(--ROOTFONTFIZE) * (30 / 144)) 0
    calc(var(--ROOTFONTFIZE) * (22 / 144)) 0;
}

.overall3_bottom .overall3_bottom_fist,
.overall3_bottom .overall3_bottom_second {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding: 0 calc(var(--ROOTFONTFIZE) * (70 / 144));
}

.chart_area {
  flex: 1;
  // width: 33%;
  // width: 308px;
  height: calc(var(--ROOTFONTFIZE) * (247 / 144));
  background-color: #1a1c21;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border: 0.21px solid rgba(135, 135, 135, 1);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.chart6_name {
  // padding-bottom: 5px;
  padding-left: calc(var(--ROOTFONTFIZE) * (25 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (13 / 144));
  color: rgba(255, 255, 255, 1);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}

// ----------------收益分析---------------------------------------------------------------------------------------------------------

.income {
}

.income_card1,
.income_card2 {
  height: 474px;
  border-radius: 20px;
  padding-right: 38px;
  padding-top: 15px;
  background-color: #1d1f25;
  margin-bottom: 45px;
}

.income_card2_820 {
  height: 820px;
}

.income_card1_tit,
.income_card2_tit {
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-left: 5px solid #1573fe;
  padding-left: 13px;
}

.income_card1_tit_left,
.income_card2_tit_left {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
}

.el-icon-download {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

.income_card1_con_left {
  position: relative;
}

.income_card1_con_right,
.income_card2_con_right {
  padding-left: 60px;
}

.setArea1 {
}

.setArea1_item,
.setArea2_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.setArea1_item_left,
.setArea2_item_left {
  flex: 3;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 1);
}

.setArea1_item_right,
.setArea2_item_right {
  flex: 7;
  display: flex;
  justify-content: end;
}

.setArea1_item_right .el-checkbox,
.setArea2_item_right .el-checkbox {
  margin-left: 35px;
  color: #a5a6a9;
}

.moreYear {
  display: flex;
  justify-content: start;
  width: 100%;
}

.bar {
  width: 300px;
  width: 255px;
}

.sDiffTimeReturnDDL {
  color: rgba(158, 158, 158, 1);
  position: absolute;
  bottom: 30;
  left: 20;
}

.chartColumn2_2_style {
}

.chartColumn2_1_style,
.chartColumn2_2_style_pack {
  border: 0.2px solid #2a2c31;
  border-radius: 10px;
  margin: 15px;
  background-color: #1a1c21;
  display: flex;
  justify-content: center;
  position: relative;
}

.income_card2_con {
}

.income_card2_con_left {
}

.income_card2_btn {
  margin-top: 9px;
}

.title_chart6 {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.32px;
  color: rgba(218, 218, 218, 1);
  text-align: center;
  margin-top: 60px;
  margin-bottom: 50px;
}

// /-------------elmentui部分----------------------------------------------------------------------

// el-select
// .el-select {
//   margin-right: 27px;
//   margin-left: 10px;
//   border: 1px solid rgba(49, 53, 63, 1);
//   border-radius: 5px;
//   // padding: 0 !important;
//   width: 300px;
//   height: 28px;
//   padding-right: 10px !important;
// }

// ::v-deep .el-input__inner {
//   background-color: rgba(0, 0, 255, 0) !important;
//   border: none;
//   height: 24px !important;
//   padding: 0 !important;
//   margin: 0 !important;
//   display: flex !important;
//   align-items: center !important;
// }

// ::v-deep .el-select__tags-text {
//   display: flex !important;
//   align-items: center !important;
// }

// ::v-deep .el-input__suffix {
//   display: flex !important;
//   align-items: center !important;
// }

// .chooseLev {
// }

// ::v-deep .chooseLev .el-select {
//   width: 85px !important;
// }

// ::v-deep .chooseLev .el-input__inner {
//   color: #969696 !important;
// }

// ::v-deep .setArea1_tabs.el-tabs,
// ::v-deep .setArea2_tabs.el-tabs {
//   // background-color: rgba(0, 0, 255, 0) !important;
//   background-color: pink !important;
// }

// ::v-deep .setArea1_tabs.el-tabs__item,
// ::v-deep .setArea2_tabs.el-tabs__item {
//   color: rgba(255, 255, 255, 0.65);
//   color: #1573fe;
//   font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
//   font-weight: 700 !important;
//   letter-spacing: 0px;
// }
</style>
