<template>
  <div class="container" id="rootchart" :style="cssVars">
    <div class="CARD" v-show="true">
      <div class="CARD_tit">
        {{ STOCKNAME }} | <span class="CARD_tit_code">{{ CODE }}</span>
      </div>
      <div class="CARD_body">
        <div class="choose_area">
          <CButtons
            @Cbtns="fnGetCbtns"
            :dButtons1="dButtons1"
            :defaultVal="dButtons1_current"
          />
        </div>

        <div v-show="bButtons1_card1">
          <div class="assistantTitle">
            估值概览
            <div class="downloadBtns">
              <CButtonsDownload
                :dButtonsDownloads="dButtonsDownloads"
                @fnButtondownload="fnButtonDL8_2"
              />

              <span style="display: none">
                <download-excel
                  id="downloadExcel8_10"
                  :data="json_fields_bar_data"
                  :fields="json_fields_bar"
                  header="同类型资产对比"
                  name="同类型资产对比.xls"
                >
                </download-excel>
              </span>
            </div>
          </div>

          <div class="charts_area3 MB50">
            <div class="left CARD_body_echart HEIGHT_293">
              <div class="charts_area_tit">目前估值水平</div>
              <div
                class="canvasArea"
                ref="chartColumn1"
                style="width: 100%; height: 253px"
              ></div>
            </div>
            <div class="right CARD_body_echart HEIGHT_293">
              <div class="contrast_area">
                <span
                  @click="fnOpenDialog2"
                  class="iconfont icon-fangda icon-fangda2"
                ></span>

                <div class="charts_area_tit">
                  同类型资产对比

                  <span>
                    <CSelect
                      @CSelect_select_val="fnSelect_select_val_chart1"
                      class="target_style"
                      :clearable="false"
                      :auto="true"
                      :options="category_options_valuationTable"
                      :default="category_options_valuationTable_chart1"
                      >选择估值指标</CSelect
                    >
                  </span>
                </div>

                <div class="contrast_area_content">
                  <div
                    class="canvasArea"
                    ref="chartColumn5"
                    style="width: 100%; height: 253px"
                  ></div>
                </div>

                <!-- <div class="contrast_area_content">
                  <div class="contrast_area_content_item">

                    <div class="contrast_area_main">
                      <div class="contrast_area_main_bar" :style="cssVars">
                        <div class="red_area"></div>
                        <div class="contrast_area_main_bar_info">
                          {{ last.toFixed(2) }}
                        </div>
                      </div>
                    </div>
                    <div class="contrast_bar_name">张江光大</div>

                  </div>
                </div> -->
              </div>
            </div>
          </div>

          <div class="assistantTitle">估值bands</div>

          <div class="chooseTarget">
            <span>
              <CSelect
                :auto="true"
                :clearable="false"
                @CSelect_select_val="fnCSelect_chart4"
                :default="select_val_chart4"
                :options="category_options_valuationTable"
                >选择估值指标</CSelect
              >
            </span>

            <span
              @click="fnOpenDialog"
              class="iconfont icon-fangda icon-fangda2"
            ></span>

            <!-- <span>
               <CCascader
               class="CCascader_style"
              @CCascader_select_val="fnCCascader_select_val_4"
              :default="r_type_chart4"
              :options="category_cascader_valuationTable"
              >选择大类</CCascader
            >
            </span> -->

            <!-- <span class="CTimeButtons_style"><CTimeButtons /></span> -->
            <!-- <span class="REFERENCE_LINE">
              <CButtonsSeparate
                class="CButtonsSeparate_style"
                :dButtons1="dButtons_separrate2"
                :dButtons1_current="dButtons_separrate_current2"
              />
            </span> -->
          </div>

          <div class="charts_area HEIGHT_402">
            <div
              v-loading="loading1"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 1)"
              ref="chartColumn4"
              class="AREA_STYLE"
              style="width: 100%; height: 402px"
            ></div>
          </div>
        </div>

        <div v-show="bButtons1_card2" ref="content">
          <div class="CARD_tit subtitle">
            历史估值

            <div class="downloadBtns">
              <CButtonsDownload
                :dButtonsDownloads="dButtonsDownloads"
                @fnButtondownload="fnButtonDL8_1"
              />

              <span style="display: none">
                <download-excel
                  id="downloadExcel8_1"
                  :data="json_fields_data1_data"
                  :fields="json_fields_data1"
                  header="历史估值"
                  name="历史估值.xls"
                >
                </download-excel>
              </span>

              <span style="display: none">
                <download-excel
                  id="downloadExcel8_2"
                  :data="json_fields_pic1_lines_data"
                  :fields="json_fields_pic1_lines"
                  header="历史估值_参考线"
                  name="历史估值_参考线.xls"
                >
                </download-excel>
              </span>
            </div>

            <!-- <span @click="FnDownload()">
              <i class="el-icon-download"></i>
            </span> -->
          </div>

          <div class="chooseTarget">
            <span>
              <CSelect
                @CSelect_select_val="fnCSelect_chart2"
                :default="select_val_chart2"
                :clearable="false"
                :auto="true"
                :options="category_options_valuationTable"
                >选择估值指标</CSelect
              >
            </span>

            <span class="REFERENCE_LINE">
              参考线
              <CButtonsSeparate
                @Cbtns_sep="fnCbtns_sep_chart2"
                class="CButtonsSeparate_style"
                :dButtons1="dButtons_separrate"
                :dButtons1_current="dButtons_separrate_current_chart2"
              />
            </span>

            <span class="time_area">
              <CTimeButtons
                :default="start_date_chart2"
                @CTimeButtons_select_val="fnCTimeButtons_select_val_chart2"
              />
            </span>
          </div>

          <div class="charts_area HEIGHT_434">
            <span
              @click="fnOpenDialog3"
              class="iconfont icon-fangda icon-fangda2"
            ></span>

            <div
              v-loading="loading2"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 1)"
              ref="chartColumn2"
              style="width: 100%; height: 434px"
            ></div>
          </div>

          <div class="CARD_tit subtitle mt_30">
            历史估值对应未来{{ scatter_future_year }}收益率统计
          </div>
          <div class="rowStyle">
            <div class="rowStyle_left1">
              <CotherButtons
                :default="start_date_scatter"
                @CotherButtons_select_val="CotherButtons_select_val"
              />
            </div>

            <div class="rowStyle_right1"></div>
          </div>

          <div class="rowStyle">
            <div class="rowStyle_left HEIGHT_370">
              <div
                ref="chartColumn_scatter"
                style="width: 100%; height: 370px"
              ></div>
            </div>

            <div class="rowStyle_right HEIGHT_370">
              <div ref="chartColumn3" style="width: 100%; height: 370px"></div>
            </div>
          </div>

          <div class="illustrate">
            <div>说明</div>
            <div>
              1.市盈率=成分股当日总市值总计/成分股净利润(TTM)总计，当成分股净利润(TTM)为负时，市盈率为0
            </div>
            <div>
              2.加权指标是指按照指数加权方式计算的指标，部分指数没有权重数据则没有相关指标
            </div>
            <div>
              3.分位点为将某一个数值在其所在的数组中的分布点，对于估值分布来说就是“当前估值在历史中出于什么样的位置”
            </div>
            <div>
              4.机会值、中位数以及危险值分别对应了20%、50%、80%三个分位点
            </div>
            <div>
              5.历史估值对应未来1年收益率的选取了过去10年内120个估值点以及每个估值点对应的未来1年的指数涨跌幅。用以描述估值高低与未来涨跌幅是否存在相关关系
            </div>
          </div>
        </div>
      </div>
    </div>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            v-loading="loading1"
            element-loading-text="数据量巨大，正在计算中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 1)"
            ref="chartColumn4_dialog"
            class="AREA_STYLE"
            style="width: 99%; height: 100%"
          ></div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible2"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible2"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown2"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            class="canvasArea"
            ref="chartColumn5_dialog"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible3"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible3"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown3"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            v-loading="loading2"
            element-loading-text="数据量巨大，正在计算中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 1)"
            ref="chartColumn2_dialog"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CSelects from "@/components/Basic/CSelects";
import CCascader from "@/components/Basic/CCascader";
import CSelect from "@/components/Basic/CSelect";
import CCategoryRadio from "@/components/Basic/CCategoryRadio";
import CButtons from "@/components/Basic/CButtons";
import CTimeButtons from "@/components/Basic/CTimeButtons";
import CotherButtons from "@/components/Basic/CotherButtons";

import CButtonsSeparate from "@/components/Basic/CButtons_separate";
import CButtonsDownload from "@/components/Basic/CButtons_download";

import ecStat from "echarts-stat";

import html2canvas from "html2canvas";

import CDialog from "@/components/Basic/CDialog";

import { fnDownloadBgc, fnGetMuchDecimals, fnComputeTime } from "@/utils/util";

export default {
  props: {
    isFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // CODE: window.sessionStorage.getItem("CURRENTCODE"),
      // STOCKNAME: window.sessionStorage.getItem("CURRENTNAME"),
      sCurrentTheme: this.$store.state.theme == "dark" ? true : false,
      ROOTFONTFIZE: 0,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,

      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      width_dialog: "80%",
      height_dialog: "73vh",
      height_chart: "71vh",
      top_dialog: "5vh",

      CODE: "",
      STOCKNAME: "",

      loading1: false,
      loading2: false,

      basis_value: "",
      basis_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      days_value: "",
      days_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      level_value: "",
      level_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      classA_value: "",
      classA_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      classB_value: "",
      classB_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      radio1: "",
      radio2: "",

      dBtn1: ["3M", "6M", "1Y", "YTD", "MAX"],
      activeBtn1: "3M",

      category_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      //================ new =================================
      dButtons_separrate: ["分位数", "标准差"],
      dButtons_separrate_current: "分位数",

      dButtons1: ["估值概览", "历史估值"],
      dButtons1_current: "估值概览",

      dButtons_separrate2: ["平滑", "阶梯"],
      dButtons_separrate_current2: "平滑",

      bButtons1_card1: true,
      bButtons1_card2: false,

      ReLine: ["分位数", "标准差"],
      activeBtn1: "分位数",

      chartColumn1: null,
      chartColumn5: null,
      chartColumn5_dialog: null,
      chart5_X: null,
      chart5_Y_basic: null,
      chart5_Y_show: null,
      chart5_red: null,
      chart5_markPointData: null,
      chart5_DATA: null,

      chartColumn2: null,
      chartColumn2_dialog: null,
      chart2_data: null,

      // 成交额及换手率对比
      dDailyAmtTurnoverReit: [],
      chartColumn3: null,
      chart3_data: null,

      chartColumn4: null,
      chartColumn4_dialog: null,
      chart4_data: null,
      chartColumn_scatter: null,
      chartColumn_scatter_last_red_line2: null,
      chartColumn_scatter_arr1: null,
      chartColumn_scatter_arr2: null,

      dCurrentValuationReit: [],

      val3: "",
      last: "",

      // category_options_valuationTable: this.$store.state.valuationTable2,
      category_options_valuationTable: this.$store.state.valuationTable3,

      category_options_valuationTable_chart1: "PB",
      select_val_chart2: "PB",
      start_date_chart2: "YTD",
      start_date_scatter: "1M",
      dButtons_separrate_current_chart2: "分位数",

      // chart4
      select_val_chart4: "PB",
      category_cascader_valuationTable: [
        {
          value: "产权类",
          label: "产权类",
          children: [
            {
              value: "物流仓储",
              label: "物流仓储",
            },
            {
              value: "产业园",
              label: "产业园",
            },
            {
              value: "保障性住房",
              label: "保障性住房",
            },
            {
              value: "消费",
              label: "消费",
            },
          ],
        },
        {
          value: "经营权类",
          label: "经营权类",
          children: [
            {
              value: "市政生态",
              label: "市政生态",
            },
            {
              value: "能源",
              label: "能源",
            },
            {
              value: "收费公路",
              label: "收费公路",
            },
          ],
        },
        {
          value: "公募REITs",
          label: "公募REITs",
        },
      ],
      r_type_chart4: ["公募REITs"],

      // 下载 start
      json_fields_data1: {},
      json_fields_data1_data: [],

      json_fields_pic1_lines: {},
      json_fields_pic1_lines_data: [],

      json_fields_bar: {},
      json_fields_bar_data: [],

      dButtonsDownloads: [
        {
          id: "241078",
          name: "下载图片",
        },
        {
          id: "241079",
          name: "下载表格",
        },
      ],

      scatter_future_year: "",

      // 下载 end
    };
  },
  created() {
    // this.FnInit()
    // if(this.isFlag){
    //   this.fnGetCbtns("估值概览");
    //   }
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener("resize", this.funResize);
    // this.funResize();
  },
  computed: {
    cssVars() {
      return {
        "--val3": this.val3,
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
      fnDownloadBgc();

      this.$nextTick(() => {
        if (this.bButtons1_card1) {
          this.initChart1();
          this.initChart5();
          this.initChart4();
        } else {
          this.initChart2();
          this.initChart3();
          this.initChart_scatter();
        }
      });

      // this.initChart();
      // this.initChart2();
      // this.initChart3();
      // this.initChart4();
      // this.initChart5();
    },
  },

  methods: {
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == "dark" ? true : false;
    },

    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChart4(true);
      });
    },

    fnDropdown(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      }
    },

    fnOpenDialog2() {
      this.dialogVisible2 = true;
      this.$nextTick(() => {
        this.initChart5(true);
      });
    },

    fnDropdown2(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart5(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart5(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart5(true);
        });
      }
    },

    fnOpenDialog3() {
      this.dialogVisible3 = true;
      this.$nextTick(() => {
        this.initChart2(true);
      });
    },

    fnDropdown3(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart2(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart2(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart2(true);
        });
      }
    },

    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem("ROOTFONTFIZE");
    },

    funResize() {
      this.fnGetWidth();
      if (this.chartColumn1) {
        this.chartColumn1.resize();
      }
      if (this.chartColumn5) {
        this.chartColumn5.resize();
      }
      if (this.chartColumn4) {
        this.chartColumn4.resize();
      }
      if (this.chartColumn2) {
        this.chartColumn2.resize();
      }
      if (this.chartColumn_scatter) {
        this.$nextTick(() => {
          this.initChart_scatter();
        });
        // this.chartColumn_scatter.resize();
      }

      if (this.chartColumn3) {
        this.chartColumn3.resize();
      }
    },

    // 下载 start

    fnButtonDL8_1(val) {
      if (val == "下载图片") {
        this.FnDownload8_1();
      } else {
        document.getElementById("downloadExcel8_1").click();
        document.getElementById("downloadExcel8_2").click();
      }
    },

    FnDownload8_1() {
      let h = this.$refs.chartColumn2.scrollHeight;
      let w = this.$refs.chartColumn2.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn2, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "历史估值"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    fnButtonDL8_2(val) {
      if (val == "下载图片") {
        this.FnDownload8_2();
      } else {
        document.getElementById("downloadExcel8_10").click();
      }
    },

    FnDownload8_2() {
      let h = this.$refs.chartColumn5.scrollHeight;
      let w = this.$refs.chartColumn5.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn5, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "同类型资产对比"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    // 下载 end

    // FnInit(){
    //   this.CODE = window.sessionStorage.getItem("CURRENTCODE")
    //   this.STOCKNAME = window.sessionStorage.getItem("CURRENTNAME")
    // },

    // =====================================================================================================

    //  2. 目前估值水平
    async fnGetCurrentValuationReit() {
      let code = this.CODE;
      const { data } = await this.$https.get(
        "/api/current_valuation_reit?code=" + code
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dCurrentValuationReit = data.data;
      this.$nextTick(() => {
        this.initChart1();
      });
    },

    initChart1() {
      this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      this.chartColumn1.clear();

      let dCurrentValuationReit = this.dCurrentValuationReit[0];
      delete dCurrentValuationReit.PB_ttm;
      delete dCurrentValuationReit.div_yield_ttm;
      let arr1 = Object.entries(dCurrentValuationReit).reverse();
      let X = arr1.map((node) => {
        return node[0];
      });

      X = X.map((node) => {
        return node.slice(0, -4);
      });

      let Y = arr1.map((node) => {
        return node[1];
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        grid: {
          top: this.ROOTFONTFIZE * (30 / 144),
          bottom: this.ROOTFONTFIZE * (30 / 144),
          left: this.ROOTFONTFIZE * (20 / 144),
          right: this.ROOTFONTFIZE * (20 / 144),
          containLabel: true,
        },
        // tooltip: {
        //   // 工具提示
        //   // trigger:'item',
        //   trigger: "axis", // 工具提示的类型 item代表的是每个柱本身, axis代表的是坐标轴
        //   triggerOn: "click", // 触发时机, click代表点击, mouseOver代表鼠标移过
        //   formatter: function (args) {
        //     // 文字格式化
        //     return args[0].name + "的YTD回报率：" + args[0].data + "%";
        //   },
        // },
        xAxis: {
          type: "category",
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: this.ROOTFONTFIZE * (10 / 144),
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          // type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (12 / 144),
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            type: "bar",
            data: Y,
            itemStyle: {
              normal: {
                color: function (params) {
                  let name = params.name;
                  if (name == "PNAV") {
                    return "rgba(90, 202, 198, 1)";
                  }
                  if (name == "PFFO") {
                    return "rgba(89, 143, 200, 1)";
                  }
                  if (name == "PCFO") {
                    return "rgba(89, 143, 200, 1)";
                  }
                  if (name == "EV/EBITDA") {
                    return "rgba(86, 84, 202, 1)";
                  }
                },
              },
            },

            // barWidth: "35px",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
                fontSize: this.ROOTFONTFIZE * (14 / 144),
              },
              formatter: function (val) {
                return val.value.toFixed(1);
              },
            },
          },
        ],
      };

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
      // this.funResize()
    },

    //  3. 同类型资产对比

    fnSelect_select_val_chart1(val) {
      this.category_options_valuationTable_chart1 = val;
      this.fnGeturrentValuationReitCompare();
    },

    async fnGeturrentValuationReitCompare() {
      let code = this.CODE;
      let valuation = this.category_options_valuationTable_chart1;

      const { data } = await this.$https.get(
        "/api/current_valuation_reit_compare?code=" +
          code +
          "&valuation=" +
          valuation
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 下载 start
      let obj = JSON.parse(JSON.stringify(data.data.bar));

      this.json_fields_bar_data = [];
      for (let i in obj) {
        let o = {};
        o[i] = obj[i];
        this.json_fields_bar_data.push(o);
      }

      this.json_fields_bar_data = this.json_fields_bar_data.map((node) => {
        let o = {};
        let name = Object.keys(node);
        o["股票名称"] = name[0];
        o["last"] = node[name[0]]["last"];
        o["max"] = node[name[0]]["max"];
        o["min"] = node[name[0]]["min"];
        return o;
      });
      let tmp = Object.keys(this.json_fields_bar_data[0]);
      tmp.forEach((node) => {
        this.json_fields_bar[node] = node;
      });

      // 漂亮！！！

      // 下载 end

      let DATA = data.data;
      let red = data.data.red;

      // 排序
      let BAR = DATA.bar;
      let BARARR = Object.entries(BAR);

      function fnSort(a, b) {
        return b[1].last - a[1].last;
      }
      BARARR.sort(fnSort);

      let index = BARARR.findIndex((node) => {
        return node[0] == red;
      });

      let arr = BARARR.splice(index, 1);
      BARARR.push(arr[0]);

      let X = BARARR.map((node) => {
        return node[0];
      });

      let YDATA = BARARR.map((node) => {
        return node[1];
      });

      let Y_basic = YDATA.map((node) => {
        return node.min;
      });

      let Y_show = YDATA.map((node) => {
        if (node.min < 0) {
          return node.max;
        } else {
          return Number(node.max) - Number(node.min);
        }
      });

      let Y_last = YDATA.map((node) => {
        return node.last;
      });
      let markPointData = [];

      X.forEach((node, idx) => {
        let obj = {
          yAxis: Y_last[idx],
          xAxis: node,
          last:
            this.category_options_valuationTable_chart1 == "div_yield_ttm"
              ? (Number(Y_last[idx]) * 100).toFixed(2) + "%"
              : Y_last[idx].toFixed(2),
        };
        markPointData.push(obj);
      });

      this.chart5_X = X;
      this.chart5_Y_basic = Y_basic;
      this.chart5_Y_show = Y_show;
      this.chart5_red = red;
      this.chart5_markPointData = markPointData;
      this.chart5_DATA = DATA;
      this.$nextTick(() => {
        this.initChart5();
      });
    },
    initChart5(val) {
      if (!val) {
        this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5);
      } else {
        this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5_dialog);
      }

      // this.chartColumn5 = echarts.init(document.querySelectorAll('#chartColumn5')[0]);
      // const chartInstance = echarts.init(
      //   document.getElementById(item.name)
      // );
      // this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5);

      this.chartColumn5.clear();

      let X = this.chart5_X;
      let Y_basic = this.chart5_Y_basic;
      let Y_show = this.chart5_Y_show;
      let red = this.chart5_red;
      let markPointData = this.chart5_markPointData;
      let DATA = this.chart5_DATA;

      let category_options_valuationTable_chart1 =
        this.category_options_valuationTable_chart1;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let arr = markPointData.map((node) => {
        return node.last;
      });

      let option = {
        grid: {
          left: this.ROOTFONTFIZE * (10 / 144),
          right: this.ROOTFONTFIZE * (20 / 144),
          bottom: this.ROOTFONTFIZE * (30 / 144),
          top: this.ROOTFONTFIZE * (20 / 144),
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: X,
          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            rotate: 20,
            show: true,
            align: "center",
            showMaxLabel: true,
            margin: this.ROOTFONTFIZE * (30 / 144),
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (12 / 144),
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            show: false,
          },
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let name = args[0].name;
            let value = DATA.bar[name];
            let str = "";
            str += `${name}`;
            str += "<br>";

            let color = "";
            if (args[1].dataIndex == Y_show.length - 1) {
              color = "#ff3300";
            } else {
              color = "#5ae8ed";
            }

            let marker =
              '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:' +
              color +
              ';"></span>';

            let max =
              category_options_valuationTable_chart1 == "div_yield_ttm"
                ? (Number(value.max) * 100).toFixed(2) + "%"
                : value.max.toFixed(2);
            str += `<div style="display:flex;justify-content:space-between;"><span>${args[1].marker}max:</span><span>${max}</span></div>`;
            let min =
              category_options_valuationTable_chart1 == "div_yield_ttm"
                ? (Number(value.min) * 100).toFixed(2) + "%"
                : value.min.toFixed(2);
            // str += ``;
            str += `<div style="display:flex;justify-content:space-between;"><span>${args[1].marker}min:</span><span>${min}</span></div>`;

            // str += "<br>";
            let last =
              category_options_valuationTable_chart1 == "div_yield_ttm"
                ? (Number(value.last) * 100).toFixed(2) + "%"
                : value.last.toFixed(2);
            str += `<div style="display:flex;justify-content:space-between;"><span>${marker}last:</span><span>${last}</span></div>`;
            // str += "<br>";
            return str;
          },
        },

        series: [
          {
            name: "Placeholder",
            type: "bar",
            stack: "Total",
            silent: true,
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent",
              },
            },
            data: Y_basic,
            label: {
              show: true,
              color: OPTIONDATA.title.textStyle.color,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              // position: "bottom",

              formatter: function (val) {
                let idx = val.dataIndex;
                return arr[idx];
              },
            },
          },
          {
            name: "property",
            type: "bar",
            stack: "Total",
            barWidth: "45px",
            label: {
              show: false,
              position: "top",
            },
            itemStyle: {
              borderRadius: 10, // 设置单个数值
              color: function (val) {
                if (val.name == red) {
                  return "#ffd8c5";
                } else {
                  return "#adb5bf";
                }
              },
            },

            data: Y_show,

            markPoint: {
              data: markPointData.map((node) => {
                return {
                  yAxis: node.yAxis,
                  xAxis: node.xAxis,
                  name: "aaa",
                  value: node.last,
                  symbol: "circle", // 标记图形
                  symbolSize: this.ROOTFONTFIZE * (24 / 144),
                  itemStyle: {
                    color: node.xAxis == red ? "#ff3300" : "#5ae8ed",
                    // 标注点颜色
                  },
                  label: {
                    show: false,
                    color: OPTIONDATA.title.textStyle.color,
                    fontSize: this.ROOTFONTFIZE * (14 / 144),
                    // position: ["0%", "0%"],
                    position: "bottom",
                    offset: [0, 50],
                  },
                };
              }),
            },

            // markPoint: {
            //   // 标记的数据，可以是最大值最小值也可以是自定义的坐标
            //   data: markPointData,
            //   symbol: "circle", // 标记图形
            //   symbolSize: 18,
            //   // symbolOffset: [0,'50%'],

            //   // 标注点的样式
            //    color: function(args){
            //       console.log(args,'args123123123');
            //     },
            //   itemStyle: {
            //    // 标注点颜色
            //     label: {
            //       show: true,
            //     },
            //   },
            // },
          },
        ],
      };

      this.chartColumn5.setOption(option);
      this.chartColumn5.resize();
    },

    //  4. 历史估值
    fnCSelect_chart2(val) {
      this.select_val_chart2 = val;
      this.fnGetValuationAnalysisReit();
      this.fnGetValuationAnalysisReitData2();
    },
    fnCTimeButtons_select_val_chart2(val) {
      this.start_date_chart2 = val;
      this.fnGetValuationAnalysisReit();
      this.fnGetValuationAnalysisReitData2();
    },
    fnCbtns_sep_chart2(val) {
      this.dButtons_separrate_current_chart2 = val;
      this.fnGetValuationAnalysisReit();
    },

    async fnGetValuationAnalysisReit() {
      this.loading2 = true;
      let code = this.CODE;

      let factor = this.select_val_chart2;
      // let str = factor.join("&factor=");
      let start_date = this.start_date_chart2;
      let show_mode =
        this.dButtons_separrate_current_chart2 == "分位数"
          ? "percentile"
          : "std";
      let n = "1M";

      const { data } = await this.$https.get(
        "/api/valuation_analysis_reit?code=" +
          code +
          "&factor=" +
          factor +
          "&start_date=" +
          start_date +
          "&show_mode=" +
          show_mode +
          "&n=" +
          n
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 下载 start
      this.json_fields_data1 = {};
      this.json_fields_pic1_lines = {};

      this.json_fields_data1_data = [];
      this.json_fields_pic1_lines_data = [];

      this.json_fields_data1_data = data.data.data1;
      this.json_fields_data1["日期"] = "tr_date";

      let factorVal = factor + "（左轴）";
      this.json_fields_data1[factorVal] = "left";
      this.json_fields_data1["指位点数（右轴）"] = "right";

      // 分位线，标准差文案
      let text1 =
        this.dButtons_separrate_current_chart2 == "标准差"
          ? "标准差（+1）"
          : "80分位数";
      let text2 =
        this.dButtons_separrate_current_chart2 == "标准差"
          ? "标准差（-1）"
          : "20分位数";

      this.json_fields_pic1_lines_data.push(data.data.pic1_lines.line);
      this.json_fields_pic1_lines[text1] = "upper";
      this.json_fields_pic1_lines["平均值"] = "avg";
      this.json_fields_pic1_lines[text2] = "lower";

      // 时间筛选
      let levelName = window.sessionStorage.getItem("levelName");
      if (levelName == "普通客户") {
        let result = fnComputeTime("3M");
        this.json_fields_data1_data = this.json_fields_data1_data.filter(
          (node) => {
            return node["tr_date"] >= result;
          }
        );
      } else {
        let result = fnComputeTime("6M");
        this.json_fields_data1_data = this.json_fields_data1_data.filter(
          (node) => {
            return node["tr_date"] >= result;
          }
        );
      }

      // 下载 end

      let DATA = data.data;

      // 直方图
      // 1. 只要把left的数据保留2位小数即可

      this.chart2_data = DATA;
      this.chart3_data = DATA;
      this.$nextTick(() => {
        this.initChart2();
        this.initChart3();
      });
    },

    initChart2(val) {
      if (!val) {
        this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);
      } else {
        this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2_dialog);
      }

      // this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);
      this.chartColumn2.clear();

      let DATA = this.chart2_data;

      let X = DATA.data1.map((node) => {
        return node.tr_date;
      });
      let YL = DATA.data1.map((node) => {
        return node.left;
      });
      let YR = DATA.data1.map((node) => {
        return node.right;
      });

      // 三条线
      let AVG = DATA.pic1_lines.line.avg;
      let UPPER = DATA.pic1_lines.line.upper;
      let LOWER = DATA.pic1_lines.line.lower;

      // ipo
      // let arr1 = dValuationAnalysisSingle_IPO.map(node=>{
      //   return node.IPO_date
      // })

      // arr1 = [...new Set(arr1)]

      // let markPointData = arr1.map(node=>{
      //   let obj ={
      //      yAxis: 0,
      //      xAxis: node,
      //   }
      //   return obj
      // })

      // let dValuationAnalysisSingle_pic1_lines=this.dValuationAnalysisSingle_pic1_lines
      let select_val_chart2 = this.select_val_chart2;

      // 分位线，标准差文案
      let text1 =
        this.dButtons_separrate_current_chart2 == "标准差"
          ? "标准差（+1）"
          : "80分位数";
      let text2 =
        this.dButtons_separrate_current_chart2 == "标准差"
          ? "标准差（-1）"
          : "20分位数";

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let option = {
        grid: {
          top: this.ROOTFONTFIZE * (70 / 144),
          bottom: this.ROOTFONTFIZE * (20 / 144),
          right: this.ROOTFONTFIZE * (50 / 144),
          left: this.ROOTFONTFIZE * (30 / 144),
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            if (select_val_chart2 == "div_yield_ttm") {
              let str = "";
              str += `${args[0].axisValueLabel}`;
              str += "<br>";
              args.forEach((node) => {
                let val = (Number(node.value) * 100).toFixed(2);
                str += `${node.marker}${node.seriesName}：${val}%`;
                str += "<br>";

                return str;
              });
              return str;
            } else {
              let str = "";
              str += `${args[0].axisValueLabel}`;
              str += "<br>";
              args.forEach((node) => {
                let val = Number(node.value).toFixed(2);
                str += `${node.marker}${node.seriesName}：${val}`;
                str += "<br>";

                return str;
              });
              return str;
            }
          },
        },

        // colors:['#5dccc8','#5654ca','#d43030'],
        legend: [
          {
            show: true,
            itemWidth: this.ROOTFONTFIZE * (15 / 144),
            itemHeight: this.ROOTFONTFIZE * (15 / 144),
            data: [
              {
                // name: "股息率",
                name:
                  this.select_val_chart2 === "div_yield_ttm"
                    ? "分红回报率（左轴）"
                    : this.select_val_chart2 + "（左轴）",
                icon: "circle",
                //  color:'rgbargba(86, 84, 202, 1)',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (20 / 144),
            y: this.ROOTFONTFIZE * (20 / 144),
          },

          {
            show: true,
            itemWidth: this.ROOTFONTFIZE * (30 / 144),
            itemHeight: this.ROOTFONTFIZE * (5 / 144),
            data: [
              {
                name: "指数点位（右轴）",
                icon: "roundRect",
                color: "rgba(56, 56, 118, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,

              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (180 / 144),
            y: this.ROOTFONTFIZE * (20 / 144),
          },

          {
            show: true,
            itemWidth: this.ROOTFONTFIZE * (15 / 144),
            itemHeight: this.ROOTFONTFIZE * (15 / 144),
            data: [
              {
                name: "IPO标志",
                icon: "triangle",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,

              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (220 / 144),
            y: this.ROOTFONTFIZE * (20 / 144),
          },

          {
            show: true,
            itemWidth: this.ROOTFONTFIZE * (30 / 144),
            itemHeight: this.ROOTFONTFIZE * (5 / 144),
            data: [
              {
                name: text1,
                lineStyle: {
                  type: "dotted",
                  width: 3,
                },
                //  color:'rgbargba(86, 84, 202, 1)',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,

              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (350 / 144),
            y: this.ROOTFONTFIZE * (20 / 144),
          },

          {
            show: true,
            itemWidth: this.ROOTFONTFIZE * (30 / 144),
            itemHeight: this.ROOTFONTFIZE * (5 / 144),
            data: [
              {
                name: "平均值",
                icon: "roundRect",
                //  color:'rgbargba(86, 84, 202, 1)',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,

              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (490 / 144),
            y: this.ROOTFONTFIZE * (20 / 144),
          },

          {
            show: true,
            itemWidth: this.ROOTFONTFIZE * (30 / 144),
            itemHeight: this.ROOTFONTFIZE * (5 / 144),
            data: [
              {
                name: text2,
                lineStyle: {
                  type: "dotted",
                  width: 3,
                },
                //  color:'rgbargba(86, 84, 202, 1)',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            left: this.ROOTFONTFIZE * (590 / 144),
            y: this.ROOTFONTFIZE * (20 / 144),
          },
        ],

        xAxis: {
          type: "category",
          // boundaryGap: true,
          boundaryGap: false,
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            margin: this.ROOTFONTFIZE * (20 / 144),
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
          // data: ['1','2','3','4'],
        },
        yAxis: [
          {
            scale: true,
            // type: 'value',
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (select_val_chart2 == "div_yield_ttm") {
                  args = args * 100;
                  let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
                  return args.toFixed(num) + "%";
                } else {
                  return args.toFixed(fnGetMuchDecimals(args));
                }

                return (Number(args) * 100).toFixed(1) + "%";
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (args == 0) {
                  return 0;
                } else {
                  return args.toFixed(fnGetMuchDecimals(args));
                }
                if (String(args).length == 3) {
                  return args + "00";
                }
                if (String(args).length !== 3) {
                  return args + ".000";
                }
                if (Number(args) == 0) {
                  return 0;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],

        series: [
          {
            name:
              this.select_val_chart2 === "div_yield_ttm"
                ? "分红回报率（左轴）"
                : this.select_val_chart2 + "（左轴）",
            data: YL,
            yAxisIndex: 0,
            type: "line",
            areaStyle: {
              color: "rgba(90, 202, 198, 1)",
            },

            itemStyle: {
              color: "rgba(90, 202, 198, 1)",
            },
            symbol: "none",
          },

          {
            name: "指数点位（右轴）",
            data: YR,
            yAxisIndex: 1,
            type: "line",

            itemStyle: {
              color: "rgba(86, 84, 202, 1)",
            },
            symbol: "none",
          },

          //  {
          //   name:'IPO标志',
          //   yAxisIndex: 1,
          //   type: "line",
          //     itemStyle: {
          //     color: "rgba(212, 48, 48, 1)",
          //   },

          //   symbol: "none",
          //    markPoint: {

          //     // 标记的数据，可以是最大值最小值也可以是自定义的坐标
          //     data: markPointData,
          //     symbol: "triangle", // 标记图形
          //     symbolSize: 18,
          //     symbolOffset: [0,'50%'],

          //     // 标注点的样式
          //     itemStyle: {
          //       color: "rgba(212, 48, 48, 1)", // 标注点颜色
          //       label: {
          //         show: true,
          //       },
          //     },
          //   },

          // },

          {
            name: "平均值",
            yAxisIndex: 0,
            // data:  AVG,
            type: "line",
            itemStyle: {
              color: theme == "dark" ? "rgba(255, 255, 255, 1)" : "#878787",
            },
            markLine: {
              symbol: ["none", "none"],

              data: [
                {
                  yAxis: AVG,
                },
              ],

              lineStyle: {
                type: "solid",
                color: theme == "dark" ? "rgba(255, 255, 255, 1)" : "#878787",
                width: 1,
              },

              label: {
                show: false,
              },
            },
          },

          {
            name: text1,
            yAxisIndex: 0,
            type: "line",
            // data:  dValuationAnalysisSingle_pic1_lines.line.upper,
            itemStyle: {
              color: "rgba(255, 174, 88, 1)",
            },

            markLine: {
              symbol: ["none", "none"],

              data: [
                {
                  yAxis: UPPER,
                },
              ],

              lineStyle: {
                type: "dashed",
                color: "rgba(255, 174, 88, 1)",
                width: 1,
              },

              label: {
                show: false,
              },
            },
          },

          {
            name: text2,
            yAxisIndex: 0,
            type: "line",
            itemStyle: {
              color: "rgba(121, 157, 255, 1)",
            },

            markLine: {
              symbol: ["none", "none"],

              data: [
                {
                  yAxis: LOWER,
                },
              ],

              lineStyle: {
                type: "dashed",
                color: "rgba(121, 157, 255, 1)",
                width: 1,
              },

              label: {
                show: false,
              },
            },
          },
        ],
      };

      this.chartColumn2.setOption(option);
      this.chartColumn2.resize();
      this.loading2 = false;
      // this.funResize()
    },

    initChart3() {
      this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      this.chartColumn3.clear();

      let DATA = this.chart3_data;

      let data = DATA.data1.map((node) => {
        return Number(node.left).toFixed(2);
      });

      let obj = data[data.length - 1];

      echarts.registerTransform(ecStat.transform.histogram);

      let source = data;
      var bins = ecStat.histogram(source, "sturges");
      let index = null;
      bins.bins.forEach((node, idx) => {
        if (node.sample.includes(obj)) {
          index = idx;
        }
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      var option = {
        title: {
          // 标题设置
          text: "频率分布直方图", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (16 / 144),
          },
          top: this.ROOTFONTFIZE * (15 / 144),
          left: "center",
        },
        grid: {
          top: this.ROOTFONTFIZE * (80 / 144),
          bottom: this.ROOTFONTFIZE * (20 / 144),
          left: this.ROOTFONTFIZE * (20 / 144),
          right: this.ROOTFONTFIZE * (20 / 144),
          containLabel: true,
        },
        legend: {
          show: true,
          orient: "horizontal",
          left: this.ROOTFONTFIZE * (20 / 144),
          top: this.ROOTFONTFIZE * (30 / 144),
          align: "left",
          // x:50,
          // x2: 150,
          icon: "roundRect",
          itemGap: this.ROOTFONTFIZE * (10 / 144),
          itemWidth: this.ROOTFONTFIZE * (24 / 144),
          itemHeight: this.ROOTFONTFIZE * (12 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
          data: [
            this.select_val_chart2 === "div_yield_ttm"
              ? "分红回报率"
              : this.select_val_chart2,
          ],
          // color: ['#c23531','#5754f7','#a84d38','#ff7432']
        },
        tooltip: {},
        xAxis: {
          type: "category",
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#dcdcdd",
            },
            onZero: false, //轴线是否在0刻度轴上
            // onZero: true, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#dcdcdd",
              },
            },
            axisTick: {
              show: false,
              //x轴刻度相关设置
              alignWithLabel: true,
            },
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              //  formatter:function(val){
              //   console.log(val,'val12qc');
              //       // val = val.toFixed(1)
              //       // if( String(val).length == 3){
              //       //       val = val +'0'
              //       //     }else{
              //       //       val =  val
              //       //     }
              //       // val = val + '%'
              //       return val
              //     },
              color: OPTIONDATA.yAxis.axisLabel.color,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },
          // {
          //     name: '核密度',
          //     scale: true,
          //     type: 'value'
          // },
        ],
        series: [
          {
            yAxisIndex: 0,
            name:
              this.select_val_chart2 === "div_yield_ttm"
                ? "分红回报率"
                : this.select_val_chart2,
            type: "bar",
            // barWidth: "10px",
            label: {
              show: false,
              position: "top",
              color: "#fff",
              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            itemStyle: {
              color: "rgba(90, 202, 198, 1)",
            },
            data: bins.data,
            markLine: {
              symbol: ["none", "none"],
              data: [
                {
                  xAxis: index,
                },
              ],
              lineStyle: {
                type: "solid",
                color: "rgba(255, 0, 0, 1)",
                width: 2,
              },
              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: false,
                  color: "red",
                  fontSize: this.ROOTFONTFIZE * (14 / 144),
                  formatter: function (val) {
                    return val.value;
                  },
                },
              },
            },
            // datasetIndex: 1
          },
          // {
          //      yAxisIndex: 1,
          // name: '核密度',
          // symbol: 'none',
          // data: arr2,
          // type: 'line',
          // }
        ],
      };

      this.chartColumn3.setOption(option);
      this.chartColumn3.resize();
      // this.funResize()
    },

    // 散点 start
    CotherButtons_select_val(val) {
      this.start_date_scatter = val;
      this.fnGetValuationAnalysisReitData2();
    },
    async fnGetValuationAnalysisReitData2() {
      let code = this.CODE;

      let factor = this.select_val_chart2;
      let start_date = this.start_date_chart2;
      let n = this.start_date_scatter;

      const { data } = await this.$https.get(
        "/api/valuation_analysis_reit_data2?code=" +
          code +
          "&factor=" +
          factor +
          "&start_date=" +
          start_date +
          "&n=" +
          n
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // let DATA = data.data;

      let last_red_line2 = data.data.last_red_line;
      let arr1 = data.data.scatter;
      let arr2 = arr1.map((item) => {
        return [item.x, item.y, item.tr_date];
      });
      arr1 = arr1.map((item) => {
        return [item.x, item.y];
      });

      this.chartColumn_scatter_last_red_line2 = last_red_line2;
      this.chartColumn_scatter_arr1 = arr1;
      this.chartColumn_scatter_arr2 = arr2;

      this.$nextTick(() => {
        this.initChart_scatter();
      });

      // start_date_scatter
      let texta = this.start_date_scatter;
      switch (texta) {
        case "1M":
          texta = "1月";
          break;
        case "3M":
          texta = "3月";
          break;
        case "6M":
          texta = "6月";
          break;
        case "1Y":
          texta = "1年";
      }
      this.scatter_future_year = texta;
    },
    initChart_scatter() {
      this.chartColumn_scatter = this.$echarts.init(
        this.$refs.chartColumn_scatter
      );
      this.chartColumn_scatter.clear();

      let last_red_line2 = this.chartColumn_scatter_last_red_line2;
      let arr1 = this.chartColumn_scatter_arr1;
      let arr2 = this.chartColumn_scatter_arr2;

      let dValuationAnalysisSingle2 = arr1;
      if (!dValuationAnalysisSingle2.length) return;

      let arr = dValuationAnalysisSingle2.map((node) => {
        return node[0];
      });

      let last_red_line = last_red_line2;

      let min = Math.min(...arr);
      let max = Math.max(...arr);
      let arr3 = [min, max, last_red_line];

      let minVal = Math.min(...arr3);
      let maxVal = Math.max(...arr3);

      echarts.registerTransform(ecStat.transform.regression);

      let factor_chart4 = this.select_val_chart2;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let option = {
        dataset: [
          {
            source: dValuationAnalysisSingle2,
          },
          {
            transform: {
              type: "ecStat:regression",
              // 'linear' by default.
              // config: { method: 'linear', formulaOn: 'end'}
            },
          },
        ],
        title: {
          // 标题设置
          text: "收\n\n益\n\n率", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
          left: this.ROOTFONTFIZE * (10 / 144),
          top: "middle",
        },
        grid: {
          top: this.ROOTFONTFIZE * (80 / 144),
          bottom: this.ROOTFONTFIZE * (30 / 144),
          left: this.ROOTFONTFIZE * (35 / 144),
          containLabel: true,
        },
        legend: {
          orient: "horizontal",
          left: this.ROOTFONTFIZE * (20 / 144),
          itemWidth: this.ROOTFONTFIZE * (20 / 144),
          itemHeight: this.ROOTFONTFIZE * (10 / 144),
          top: this.ROOTFONTFIZE * (25 / 144),
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
          data: [
            {
              name: factor_chart4,
              icon: "roundRect",
            },
          ],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              show: false,
            },
          },
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";

            args.forEach((node, idx) => {
              if (!idx) {
                let index = node.dataIndex;
                str += `日期：${arr2[index][2]}`;
                str += "<br>";
                let x = arr2[index][0];
                let num = parseInt(Math.abs(x) + "").length >= 2 ? 1 : 2;
                x = x.toFixed(num);
                str += `${node.marker}${factor_chart4}：${x}`;
                str += "<br>";
                let y = arr2[index][1] * 100;
                let num2 = parseInt(Math.abs(y) + "").length >= 2 ? 1 : 2;
                y = y.toFixed(num2);
                str += `${node.marker}一个月后回报率：${y}%`;
                return str;
              }
            });
            return str;
          },
        },
        xAxis: {
          type: "value",
          scale: true,
          splitNumber: 3,
          min: minVal.toFixed(2),
          max: maxVal.toFixed(2),
          // max: (Number(last_red_line)*1.001).toFixed(2),
          axisLine: {
            show: false,
            lineStyle: {
              color: "#dcdcdd",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
            formatter: function (val) {
              return Number(val).toFixed(2);
            }, //y轴百分比
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#dcdcdd",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            formatter: function (val) {
              val = val * 100;
              val = val.toFixed(1);

              if (val < 0) {
                if (String(val).length == 4) {
                  val = val + "0";
                } else {
                  val = val;
                }
              } else {
                if (String(val).length == 3) {
                  val = val + "0";
                } else {
                  val = val;
                }
              }

              val = val + "%";

              return val;
            },
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            name: factor_chart4,
            data: dValuationAnalysisSingle2,
            type: "scatter",
            symbolSize: this.ROOTFONTFIZE * (13 / 144),
            cursor: "pointer",
            itemStyle: {
              color: "rgba(90, 202, 198, 1)",
            },
            markLine: {
              symbol: ["none", "none"],
              data: [
                {
                  xAxis: last_red_line,
                },
              ],
              lineStyle: {
                type: "solid",
                color: "rgba(255, 0, 0, 1)",
                width: 2,
              },
              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: true,
                  color: "red",
                  fontSize: this.ROOTFONTFIZE * (14 / 144),
                  formatter: function (val) {
                    return val.value;
                  },
                },
              },
            },
          },
          {
            name: "线性回归",
            type: "line",
            datasetIndex: 1,
            symbolSize: 0.1,
            symbol: "circle",
            itemStyle: {
              type: "dashed",
              color: theme == "dark" ? "#fff" : "#878787",
            },
            label: { show: false, fontSize: 16 },
            labelLayout: { dx: -20 },
            encode: { label: 2, tooltip: 1 },
          },
        ],
      };

      this.chartColumn_scatter.setOption(option);
      this.chartColumn_scatter.resize();
      // this.funResize()
    },
    // 散点 end

    fnCSelect_chart4(val) {
      this.loading1 = true;
      this.select_val_chart4 = val;
      this.fnGetValuationAnalysisReit4();
    },
    fnCCascader_select_val_4(val) {
      this.r_type_chart4 = val;
      this.fnGetValuationAnalysisReit4();
    },

    async fnGetValuationAnalysisReit4() {
      let code = this.CODE;

      let factor = this.select_val_chart4;
      // let str = factor.join("&factor=");
      let start_date = this.start_date_chart2;
      let show_mode =
        this.dButtons_separrate_current_chart2 == "分位数"
          ? "percentile"
          : "std";
      let n = "1M";

      const { data } = await this.$https.get(
        "/api/valuation_analysis_reit?code=" +
          code +
          "&factor=" +
          factor +
          "&start_date=" +
          start_date +
          "&show_mode=" +
          show_mode +
          "&n=" +
          n
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      let DATA = data.data;

      this.chart4_data = DATA;
      this.$nextTick(() => {
        this.initChart4();
      });
    },
    initChart4(val) {
      if (!val) {
        this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      } else {
        this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4_dialog);
      }

      // this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      this.chartColumn4.clear();

      let DATA = this.chart4_data;

      let data = DATA.band;

      let factor = this.select_val_chart4;

      let X = data.map((node) => {
        return node.tr_date;
      });

      let arr1 = Object.keys(data[0]);
      arr1.shift();
      arr1.reverse();

      let Y = [];
      arr1.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };
        data.forEach((e) => {
          obj.data.push(e[node]);
        });
        Y.push(obj);
      });

      arr1 = arr1.map((node) => {
        if (node == "current") {
          return "收盘价";
        } else {
          return node;
        }
      });

      Y = Y.map((node) => {
        if (node.name == "current") {
          node.name = "收盘价";
        }
        return node;
      });

      // 漂亮 ！

      let seriesArr = Y.map((v, index) => {
        return {
          name: v.name,
          data: v.data,
          type: "line",
          smooth: false,

          symbol: "none",
        };
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let option = {
        color: [
          // "rgba(255, 255, 255, 1)",
          theme == "dark" ? "#fff" : "#878787",
          "rgba(94, 92, 230, 1)",
          "rgba(90, 202, 198, 1)",
          "rgba(96, 143, 229, 1)",
          "rgba(221, 165, 121, 1)",
          "rgba(194, 68, 146, 1)",
        ],
        legend: {
          show: true,
          orient: "vertical",
          right: this.ROOTFONTFIZE * (20 / 144),
          top: "middle",
          align: "left",
          // x:50,
          // x2: 150,
          icon: "roundRect",
          itemGap: this.ROOTFONTFIZE * (20 / 144),
          itemWidth: this.ROOTFONTFIZE * (45 / 144),
          itemHeight: this.ROOTFONTFIZE * (7 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (16 / 144),
          },
          data: arr1,
          // color: ['#c23531','#5754f7','#a84d38','#ff7432']
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            z: 0,
            lineStyle: {
              color: "#2D3443",
            },
          },
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            let a = factor == "div_yield_ttm" ? "%" : "";
            args = args.forEach((node) => {
              if (node.value) {
                let num =
                  (parseInt(Math.abs(node.value)) + "").length >= 2 ? 1 : 2;
                let val = node.value.toFixed(num);

                str += `${node.marker}${node.seriesName}：${val}${a}`;
                str += "<br>";
              }
            });
            return str;
          },
        },

        grid: {
          left: this.ROOTFONTFIZE * (30 / 144),
          top: this.ROOTFONTFIZE * (30 / 144),
          bottom: this.ROOTFONTFIZE * (20 / 144),
          right: this.ROOTFONTFIZE * (155 / 144),
          containLabel: true,
        },
        // tooltip: {
        //   // 工具提示
        //   trigger: "axis",
        //   // trigger: 'axis' , // 工具提示的类型 item代表的是每个柱本身, axis代表的是坐标轴
        //   triggerOn: "click", // 触发时机, click代表点击, mouseOver代表鼠标移过
        //   formatter: function (args) {
        //     // 文字格式化
        //     return args[0].name + "的市值是：" + args[0].data;
        //   },
        // },
        xAxis: {
          type: "category",
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            showMaxLabel: true,
            margin: this.ROOTFONTFIZE * (20 / 144),
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
        },
        yAxis: {
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
              let a = factor == "div_yield_ttm" ? "%" : "";
              args = Number(args).toFixed(num) + a;
              return args;
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: seriesArr,
        // series: [
        //   {
        //     name: "0.97x",
        //     data: dValuationBandByType_97x,
        //     type: "line",
        //     smooth: false,
        //     itemStyle: {
        //       color: "rgba(94, 92, 230, 1)",
        //     },
        //     symbol: "none",

        //     label: {
        //       show: true,
        //       position: "top",
        //     },
        //   },
        //   {
        //     name: "1.0x",
        //     data: dValuationBandByType_0x,
        //     type: "line",
        //     smooth: false,
        //     itemStyle: {
        //       color: "rgba(90, 202, 198, 1)",
        //     },
        //     symbol: "none",

        //     label: {
        //       show: true,
        //       position: "top",
        //     },
        //   },
        //   {
        //     name: "1.11x",
        //     data: dValuationBandByType_11x,
        //     type: "line",
        //     smooth: false,
        //     itemStyle: {
        //       normal: {
        //         color: "rgba(96, 143, 229, 1)",
        //       },
        //     },
        //     symbol: "none",

        //     label: {
        //       show: true,
        //       position: "top",
        //     },
        //   },
        //   {
        //     name: "1.19x",
        //     data: dValuationBandByType_19x,
        //     type: "line",
        //     itemStyle: {
        //       color: "rgba(221, 165, 121, 1)",
        //     },
        //     smooth: false,
        //     symbol: "none",

        //     label: {
        //       show: true,
        //       position: "top",
        //     },
        //   },
        //     {
        //     name: "1.23x",
        //     data: dValuationBandByType_23x,
        //     type: "line",
        //     itemStyle: {
        //       color: "rgba(194, 68, 146, 1)",
        //     },
        //     smooth: false,
        //     symbol: "none",

        //     label: {
        //       show: true,
        //       position: "top",
        //     },
        //   },
        //     {
        //     name: "收盘价",
        //     data: dValuationBandByType_current,
        //     type: "line",
        //     itemStyle: {
        //       color: "rgba(255, 255, 255, 1)",
        //     },
        //     smooth: false,
        //     symbol: "none",

        //     label: {
        //       show: true,
        //       position: "top",
        //     },
        //   },
        // ],
      };

      this.chartColumn4.setOption(option);
      this.chartColumn4.resize();
      this.loading1 = false;
      // this.funResize()
    },

    fnGetCbtns(data) {
      this.CODE = window.sessionStorage.getItem("CURRENTCODE");
      this.STOCKNAME = window.sessionStorage.getItem("CURRENTNAME");

      if (data == "估值概览") {
        this.bButtons1_card1 = true;
        this.bButtons1_card2 = false;
        this.dButtons1_current = "估值概览";
        this.fnGeturrentValuationReitCompare();
        this.fnGetCurrentValuationReit();
        this.fnGetValuationAnalysisReit4();
      }

      if (data == "历史估值") {
        this.bButtons1_card1 = false;
        this.bButtons1_card2 = true;
        this.dButtons1_current = "历史估值";
        this.fnGetValuationAnalysisReit();
        this.fnGetValuationAnalysisReitData2();
      }
    },
  },
  components: {
    CSelect,
    CSelects,
    CCascader,
    CCategoryRadio,
    CButtons,
    CTimeButtons,
    CButtonsSeparate,
    CButtonsDownload,
    CotherButtons,
    CDialog,
  },
};
</script>

<style lang="less" scoped>
.container {
}

.assistantTitle {
  font-size: calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-weight: 580;
  color: var(--chart_title);
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  display: flex;
  justify-content: space-between;
}

.CARD {
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: var(--bigger-card-bgc);
  background-color: var(--charts_bgc);
  padding: calc(var(--ROOTFONTFIZE) * (15 / 144)) 0px;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (25 / 144));
}

.CARD:last-child {
  margin-bottom: 0;
}

.CARD_tit {
  padding-left: calc(var(--ROOTFONTFIZE) * (22 / 144));
  // border-left: 5px solid rgba(21, 115, 254, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);

  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (30 / 144));
  // height: 20px;
  color: var(--chart_title);
}

.CARD_tit2 {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: rgba(255, 255, 255, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.CARD_body {
  padding: 0 calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.CARD_body_tabs {
  padding: 0px calc(var(--ROOTFONTFIZE) * (30 / 144));
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CARD_body_tabs div {
  display: flex;
  align-items: center;
}

.el-icon-download {
  margin-left: calc(var(--ROOTFONTFIZE) * (45 / 144));
}

.CARD_body_btn_sep {
  margin-top: calc(var(--ROOTFONTFIZE) * (38 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (15 / 144));
}

.marketValue {
  margin-top: calc(var(--ROOTFONTFIZE) * (35 / 144));
}

.marketValue .value span:nth-child(1) {
  font-size: calc(var(--ROOTFONTFIZE) * (36 / 144));
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);
}

.marketValue .value span:nth-child(2) {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(49, 208, 104, 1);
  margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.marketValue .time {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: calc(var(--ROOTFONTFIZE) * (16 / 144));
  color: rgba(255, 255, 255, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

.CARD_body_echart,
.CARD_body_table {
  height: calc(var(--ROOTFONTFIZE) * (245 / 144));
  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);
  border-radius: calc(var(--ROOTFONTFIZE) * (5 / 144));
}

.choose_area {
  margin-top: calc(var(--ROOTFONTFIZE) * (24 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  display: flex;
  justify-content: space-between;
}

.choose_area_right {
  display: flex;
  align-items: center;
}

.choose_area_right span:nth-child(1) {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);
}

.charts_area {
  display: flex;
  background-color: #1a1c21;
  background-color: var(--charts_bgc);
  // border: 1px solid #2d2f34;
  // border: 1px solid #44464a;
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  position: relative;
  // margin-top: 20px;
}

.charts_area3 {
  display: flex;
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.charts_area2 {
  margin-top: calc(var(--ROOTFONTFIZE) * (-20 / 144)) !important;
  border: none;
}

// .charts_area2 .left,
.charts_area_tit {
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  display: flex;
  align-items: center;
  padding-left: calc(var(--ROOTFONTFIZE) * (15 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
}

.target_style {
  margin-left: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.MB50 {
  margin-bottom: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.left {
  flex: 1;
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.right {
  flex: 1;
}

.chooseTarget {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  position: relative;
}

.contrast_area_content {
  // background-color: pink;
  display: flex;
  justify-content: center;
  padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.contrast_area_main {
  // width: 60px;
  width: calc(var(--ROOTFONTFIZE) * (120 / 144));
  margin: calc(var(--ROOTFONTFIZE) * (32 / 144)) auto
    calc(var(--ROOTFONTFIZE) * (10 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (2 / 144)) solid
    rgba(118, 118, 118, 1);
  display: flex;
  justify-content: center;
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.contrast_area_main_bar {
  width: calc(var(--ROOTFONTFIZE) * (35 / 144));
  height: calc(var(--ROOTFONTFIZE) * (145 / 144));
  background-color: rgba(247, 179, 136, 1);
  position: relative;
}

.red_area {
  width: calc(var(--ROOTFONTFIZE) * (30 / 144));
  height: calc(var(--ROOTFONTFIZE) * (30 / 144));
  border-radius: 50%;
  background-color: rgba(235, 102, 19, 1);
  border: 1px solid #fff;
  position: absolute;
  left: 50%;
  top: var(--val3);
  // top:10%;
  transform: translate(-50%, -50%);
}

.contrast_area_main_bar_info {
  position: absolute;
  top: var(--val3);
  left: 120%;
  transform: translate(0, -50%);
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(254, 254, 254, 1);
}

.contrast_bar_name {
  text-align: center;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.68px;
  line-height: calc(var(--ROOTFONTFIZE) * (19.32 / 144));
  color: rgba(255, 255, 255, 0.85);
}

.CTimeButtons_style {
  margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.REFERENCE_LINE {
  display: flex;
  align-items: center;
  margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: var(--cascader-color);
}

.CButtonsSeparate_style {
  margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-left: 20px;
}

.illustrate {
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.illustrate div {
  font-size: calc(var(--ROOTFONTFIZE) * (12 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (17.38 / 144));
  color: #737477;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (2 / 144));
}

.illustrate div:nth-child(1) {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}

.HEIGHT_396 {
  height: calc(var(--ROOTFONTFIZE) * (396 / 144)) !important;
}

.HEIGHT_664 {
  height: calc(var(--ROOTFONTFIZE) * (664 / 144)) !important;
}

.HEIGHT_370 {
  height: calc(var(--ROOTFONTFIZE) * (370 / 144)) !important;
}

.HEIGHT_471 {
  height: calc(var(--ROOTFONTFIZE) * (471 / 144)) !important;
}

.HEIGHT_396 {
  height: calc(var(--ROOTFONTFIZE) * (396 / 144)) !important;
}

.HEIGHT_293 {
  height: calc(var(--ROOTFONTFIZE) * (293 / 144)) !important;
}

.HEIGHT_434 {
  height: calc(var(--ROOTFONTFIZE) * (434 / 144)) !important;
}

.HEIGHT_402 {
  height: calc(var(--ROOTFONTFIZE) * (402 / 144)) !important;
}

.CCascader_style {
  margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.subtitle {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
  display: flex;
  justify-content: space-between;
}

.mt_30 {
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.rowStyle {
  display: flex;
  gap: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.rowStyle_left {
  flex: 3;
  // margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: #1a1c21;
  background-color: var(--charts_bgc);
  border: 1px solid #2d2f34;
  border: 1px solid var(--charts_brc);
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.rowStyle_right {
  flex: 7;

  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.rowStyle_left1 {
  flex: 3;
  display: flex;
  justify-content: center;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.CotherButtons {
  width: 80%;
}

.rowStyle_right1 {
  flex: 7;
}

.time_area {
  margin-left: calc(var(--ROOTFONTFIZE) * (50 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.contrast_area {
  position: relative;
}
</style>
