<template>
  <div class="container" id="rootchart" :style="cssVars">
    <!-- 收益分析 -->
    <div class="card">
      <div class="card_tit">
        板块收益不同时间段对比

        <div class="downloadBtns">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads1"
            @fnButtondownload="fnButtonDL"
          />

          <span style="display: none">
            <download-excel
              id="downloadExcel1"
              :data="dDiffTimeReturn"
              :fields="json_fields1"
              header="板块收益不同时间段对比"
              name="板块收益不同时间段对比.xls"
            >
            </download-excel>
          </span>
        </div>
        <!-- <span @click ="FnDownload"><i class="el-icon-download"></i></span> -->
      </div>

      <div class="card_main">
        <div class="choose_area">
          <span>
            显示类型
            <CButtonsSeparate
              @Cbtns_sep="fnCbtns_sep_chart1"
              class="CButtonsSeparate_style"
              :dButtons1="dButtons_separrate"
              :dButtons1_current="dButtons_separrate_current_chart1"
            />
          </span>

          <span v-show="dButtons_separrate_current_chart1 == '大类'">
            <CCascader
              ref="CCascader_chart1"
              :default="category_cascader_default_data"
              :checkStrictly="false"
              :multiple="true"
              :isDelete="false"
              :collapsetags="true"
              @CCascader_select_val="fnCCascader_select_val_one"
              :options="category_cascader_options"
              >选择类别</CCascader
            >
          </span>

          <span v-show="dButtons_separrate_current_chart1 !== '大类'">
            <CCascader
              ref="CCascader2_chart1"
              :checkStrictly="true"
              :multiple="true"
              :isDelete="false"
              placeholder="请选择个股"
              :wide="true"
              takeOut="takeOut"
              :collapsetags="true"
              @CCascader_select_val="fnSelect_select_val_chart1_codename"
              :options="CODENAMEDATA"
              >对比REITs</CCascader
            >
          </span>

          <span>
            <CSelectBench
              :isDelete="false"
              ref="CSelect_2"
              @CSelect_select_val="fnSelect_select_val_chart1_benchmark"
              :multiple="true"
              :limit="limit_benckmark_chart1"
              :clearable="false"
              :options="BENCHMARKDATA"
              :default="BENCHMARKDATA_chart1"
              >对比benchmark</CSelectBench
            >

            <!-- <CCascader2
              @CCascader_select_val="fnCCascader2"
              :default="BENCHMARKDATA_chart1"
              ref="CCascader_chart1"
              :multiple="true"
              :isDelete="false"
              takeOut="takeOut2"
              :collapsetags="true"
              :options="options_benchmark2"
              >对比benchmark</CCascader2
            > -->

            <!-- <CSelect
                    @CSelect_select_val="fnCSelect_chart1And2"
                    :multiple="true"
                    :limit="3"
                    :clearable="false"
                    :wide="true"
                    :collapse="true"
                    :default="select_val_benchmark"
                    :options="options_benchmark"
                    >选择benchmark</CSelect
                  > -->
          </span>

          <span class="oneyear">
            一年以上是否年化
            <el-checkbox
              @change="fnGetDiffTimeReturn"
              v-model="checked_one"
            ></el-checkbox>
          </span>
        </div>
        <div class="echarts_area CHARTS_BGC">
          <span
            @click="fnOpenDialog2"
            class="iconfont icon-fangda icon-fangda2"
          ></span>
          <div ref="chartColumn1" style="width: 100%; height: 425px"></div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card_tit">
        不同时间段各部分回报构成

        <div class="downloadBtns">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads1"
            @fnButtondownload="fnButtonDL2"
          />

          <span style="display: none">
            <download-excel
              id="downloadExcel2"
              :data="dStackingReturnData2"
              :fields="json_fields2"
              header="不同时间段各部分回报构成"
              name="不同时间段各部分回报构成.xls"
            >
            </download-excel>
          </span>
        </div>

        <!-- <span @click="FnDownload2"><i class="el-icon-download"></i></span> -->
      </div>
      <div class="card_main">
        <div class="choose_area">
          <span>
            显示类型
            <CButtonsSeparate
              @Cbtns_sep="fnCbtns_sep_chart2"
              class="CButtonsSeparate_style"
              :dButtons1="dButtons_separrate"
              :dButtons1_current="dButtons_separrate_current_chart2"
            />
          </span>

          <span v-show="dButtons_separrate_current_chart2 == '大类'">
            <CCascader
              ref="CCascader_chart2"
              :default="category_cascader_default_data"
              :checkStrictly="false"
              :multiple="true"
              :isDelete="false"
              :collapsetags="true"
              @CCascader_select_val="fnCCascader_select_val_chart2"
              :options="category_cascader_options"
              >选择类别</CCascader
            >
          </span>

          <span v-show="dButtons_separrate_current_chart2 !== '大类'">
            <CCascader
              ref="CCascader2_chart2"
              :checkStrictly="true"
              :multiple="true"
              takeOut="takeOut"
              :wide="true"
              :isDelete="false"
              placeholder="请选择个股"
              :collapsetags="true"
              @CCascader_select_val="fnCCascader_select2_val_chart2"
              :options="CODENAMEDATA"
              >对比REITs</CCascader
            >

            <!-- <CSelect
              ref="CSelect_3"
              @CSelect_select_val="fnSelect_select_val_chart2_codename"
              :multiple="true"
              :limit="10"
              :clearable="true"
              :isDelete="false"
              :options="CODENAMEDATA"
              :default="CODENAMEDATA_chart2"
              >对比REITs</CSelect
            > -->

            <!-- <CSelect
              @CSelect_select_val="fnCSelect_chart2"
              :multiple="true"
              :default="select_val_REITs_chart2"
              :options="options_REITs"
              >对比REITs</CSelect
            > -->
          </span>

          <span class="oneyear">
            一年以上是否年化
            <el-checkbox
              @change="fnGetStackingReturnData2"
              v-model="checked_two"
            ></el-checkbox>
          </span>

          <span>
            <CTimeButtons
              :default="start_date_chart2"
              @CTimeButtons_select_val="fnCTimeButtons_select_val_chart2"
            />
          </span>
        </div>

        <div class="echarts_area CHARTS_BGC">
          <span
            @click="fnOpenDialog3"
            class="iconfont icon-fangda icon-fangda2"
          ></span>

          <div
            id="chartColumn2"
            ref="chartColumn2"
            style="width: 100%; height: 425px"
          ></div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card_tit">
        <!-- {{select_val_REITs_chart3}} 按资产类别年化收益及波动率对比 -->
        按资产类别年化收益及波动率对比

        <div class="downloadBtns">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads1"
            @fnButtondownload="fnButtonDL3"
          />

          <span style="display: none">
            <download-excel
              id="downloadExcel3"
              :data="json_fields3Data"
              :fields="json_fields3"
              header="按资产类别年化收益及波动率对比"
              name="按资产类别年化收益及波动率对比.xls"
            >
            </download-excel>
          </span>
        </div>

        <!-- <span @click="FnDownload3"><i class="el-icon-download"></i></span> -->
      </div>
      <div class="card_main">
        <el-row :gutter="0">
          <el-col :xs="24" :sm="24" :md="14" :lg="14">
            <!-- <div class="choose_area2 choose_area_center">
            
            </div> -->

            <div class="echarts_area CHARTS_BGC">
              <span
                @click="fnOpenDialog"
                class="iconfont icon-fangda icon-fangda2"
              ></span>

              <div ref="chartColumn3" style="width: 100%; height: 720px"></div>
              <div class="deadline_style ddl">数据截止日期：{{ ddlVAL }}</div>
              <div class="Yield_Style">年化收益率</div>
              <div class="Volatility_Style">年化波动率</div>
            </div>
            <!-- <div class="chartColumn2_2_style_pack">
                      <div
                        class="chartColumn2_2_style"
                        ref="chartColumn2_3"
                        style="width: 100%; height: 720px"
                      ></div>
                     
                    </div> -->
            <!-- <div class="sDiffTimeReturnDDL">数据截止日期：{{dDiffTimeReturnDDL}}</div> -->
          </el-col>
          <el-col
            class="income_card2_con_right"
            :xs="24"
            :sm="24"
            :md="10"
            :lg="10"
          >
            <!-- <div
              class="choose_area2 choose_area_center"
              :style="{ visibility: 'hidden' }"
            >
              <span>
                <CTimeButtons
                  class="CTimeButtons_style"
                  :default="start_date_chart3"
                  @CTimeButtons_select_val="fnCTimeButtons_select_val_chart3"
                />
              </span>
            </div> -->

            <div class="choose_area vertical_style">
              <div class="item">
                <span>显示类型</span>
                <span>
                  <CButtonsSeparate
                    @Cbtns_sep="fnCbtns_sep_chart3"
                    class="CButtonsSeparate_style"
                    :dButtons1="dButtons_separrate"
                    :dButtons1_current="dButtons_separrate_current_chart3"
                  />
                </span>
              </div>

              <div
                class="item"
                v-show="dButtons_separrate_current_chart3 == '大类'"
              >
                <span>选择类别</span>
                <span>
                  <CCascader
                    ref="CCascader_chart3"
                    :default="category_cascader_default_data"
                    :multiple="true"
                    :wide="true"
                    :collapsetags="true"
                    :checkStrictly="false"
                    @CCascader_select_val="fnCCascader_select_val_chart3"
                    :options="category_cascader_options"
                  >
                  </CCascader>
                  <!-- :options="dButtons_separrate_current_chart3=='大类'?category_cascader_options_DALEI:category_cascader_options" -->
                </span>
              </div>

              <div
                class="item"
                v-show="dButtons_separrate_current_chart3 !== '大类'"
              >
                <span>对比REITs</span>
                <span>
                  <CCascader
                    ref="CCascader2_chart3"
                    :checkStrictly="true"
                    :multiple="true"
                    takeOut="takeOut"
                    :wide="true"
                    placeholder="请选择个股"
                    :isDelete="false"
                    :collapsetags="true"
                    @CCascader_select_val="fnCCascader_select2_val_chart3"
                    :options="CODENAMEDATA"
                  ></CCascader>

                  <!-- <CSelect
                    ref="CSelect_4"
                    @CSelect_select_val="fnSelect_select_val_chart3_codename"
                    :multiple="true"
                    :limit="limit_reits_chart3"
                    :clearable="true"
                    :isDelete="false"
                    :options="CODENAMEDATA"
                    :default="CODENAMEDATA_chart3"
                  ></CSelect> -->
                </span>
              </div>

              <div class="item">
                <span>对比benchmark</span>
                <span>
                  <CSelectBench
                    :isDelete="false"

                    ref="CSelect_5"
                    :limit="limit_benckmark_chart3"
                    :clearable="false"
                    @CSelect_select_val="fnCSelect2_chart3"
                    :multiple="true"
                    :default="select_val_Benchmark"
                    :options="BENCHMARKDATA"
                  ></CSelectBench>

                  <!-- <CCascader2
                    @CCascader_select_val="fnCCascader3"
                    :default="BENCHMARKDATA_chart3"
                    ref="CCascader_chart1"
                    :multiple="true"
                    :isDelete="false"
                    takeOut="takeOut2"
                    :collapsetags="true"
                    :options="options_benchmark3"
                  ></CCascader2> -->
                </span>
              </div>

              <div class="item">
                <span>
                  <CTimeButtons
                    class="CTimeButtons_style"
                    :default="start_date_chart3"
                    @CTimeButtons_select_val="fnCTimeButtons_select_val_chart3"
                  />
                </span>
              </div>

              <!-- <div class="item">
                <span class="oneyear">一年以上是否年化</span>
                <span>
                  <el-checkbox
                  size="medium"
                    @change="fnGetReitsDetailRiskReturnScatter"
                    v-model="checked_three"
                  ></el-checkbox>
                </span>
              </div> -->
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div :style="{ height: height_chart }">
          <div
            ref="chartColumn3_dialog"
            style="width: 100%; height: 100%"
          ></div>
          <div class="deadline_style ddl">数据截止日期：{{ ddlVAL }}</div>
          <div class="Yield_Style">年化收益率</div>
          <div class="Volatility_Style">年化波动率</div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible2"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible2"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown2"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="echarts_area CHARTS_BGC" :style="{ height: height_chart }">
          <div
            ref="chartColumn1_dialog"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible3"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible3"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown3"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            ref="chartColumn2_dialog"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CSelects from "@/components/Basic/CSelects";
import CSelect from "@/components/Basic/CSelect";
import CSelectBench from "@/components/Basic/CSelectBench";
import CCategoryRadio from "@/components/Basic/CCategoryRadio";
import CCascader from "@/components/Basic/CCascader";
import CTimeButtons from "@/components/Basic/CTimeButtons";
import CButtonsSeparate from "@/components/Basic/CButtons_separate";
import CButtonsDownload from "@/components/Basic/CButtons_download";
import CCascader2 from "@/components/Basic/CCascader2";

import hasDirectives from "@/directives/has";

import html2canvas from "html2canvas";

import CDialog from "@/components/Basic/CDialog";

import {
  fnDownloadBgc
} from "@/utils/util";

export default {
  directives: {
    has: hasDirectives,
  },
  data() {
    return {
      sCurrentTheme:this.$store.state.theme == "dark" ? true : false,
      ROOTFONTFIZE: 144,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      width_dialog: "80%",
      height_dialog: "73vh",
      height_chart: "72vh",
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: "5vh",

      // 下载 start
      dButtonsDownloads1: [
        {
          id: "241005",
          name: "下载图片",
        },
        {
          id: "241013",
          name: "下载表格",
        },
      ],

      json_fields1: {
        股票代码: "reit_code",
        今年以来: "YTD",
        "3个月以来": "3M",
        "6个月以来": "6M",
        "1年以来": "1Y",
        "3年以来": "3Y",
        上市以来: "max",
      },

      json_fields2: {
        股票代码: "reit_code",
        价格回报: "价格回报",
        分红回报: "分红回报",
        总体回报: "总体回报",
      },

      json_fields3: {},
      json_fields3Data: [],
      // 下载 end

      // category_cascader_val_chart1: ["公募REITs"],
      // category_cascader_val_chart2: ["公募REITs"],
      // category_cascader_val_chart3: ["公募REITs"],
      // category_cascader_val_chart1: [ '物流仓储'],

      category_cascader_val_chart1: JSON.parse(
        localStorage.getItem("CLASSIFY")
      ),
      category_cascader_val_chart2: JSON.parse(
        localStorage.getItem("CLASSIFY")
      ),
      category_cascader_val_chart3: JSON.parse(
        localStorage.getItem("CLASSIFY")
      ),

      // category_cascader_val_chart1: [
      //   "公募REITs",
      //   "产权类",
      //   "物流仓储",
      //   "产业园",
      //   "保障性住房",
      //   "消费",
      //   "经营权类",
      //   "市政生态",
      //   "能源",
      //   "收费公路",
      // ],
      // category_cascader_val_chart2: [
      //   "公募REITs",
      //   "产权类",
      //   "物流仓储",
      //   "产业园",
      //   "保障性住房",
      //   "消费",
      //   "经营权类",
      //   "市政生态",
      //   "能源",
      //   "收费公路",
      // ],
      // category_cascader_val_chart3: [
      //   "产权类",
      //   "物流仓储",
      //   "产业园",
      //   "保障性住房",
      //   "消费",
      //   "经营权类",
      //   "市政生态",
      //   "能源",
      //   "收费公路",
      //   "公募REITs",
      // ],
      category_cascader_default_data:
        this.$store.state.IncomeAnalysisCasCaderData,

      category_cascader_options1: [
        {
          value: "产权类",
          label: "产权类",
          children: [
            {
              value: "物流仓储",
              label: "物流仓储",
            },
            {
              value: "产业园",
              label: "产业园",
            },
            {
              value: "保障性住房",
              label: "保障性住房",
            },
            {
              value: "消费",
              label: "消费",
            },
          ],
        },
        {
          value: "经营权类",
          label: "经营权类",
          children: [
            {
              value: "市政生态",
              label: "市政生态",
            },
            {
              value: "能源",
              label: "能源",
            },
            {
              value: "收费公路",
              label: "收费公路",
            },
          ],
        },
        {
          value: "公募REITs",
          label: "公募REITs",
        },
      ],

      //       let test = localStorage.getItem('BIGCATEGORY')
      //           test = JSON.parse(test)
      //       console.log(test,'新木优子');

      category_cascader_options: JSON.parse(
        localStorage.getItem("FINALLYCATEGORY")
      ),

      category_cascader_options_gongmu: [
        {
          value: "产权类",
          label: "产权类",
          disabled: true,
          children: [
            {
              value: "物流仓储",
              label: "物流仓储",
              disabled: true,
            },
            {
              value: "产业园",
              label: "产业园",
              disabled: true,
            },
            {
              value: "保障性住房",
              label: "保障性住房",
              disabled: true,
            },
            {
              value: "消费",
              label: "消费",
              disabled: true,
            },
          ],
        },
        {
          value: "经营权类",
          label: "经营权类",
          disabled: true,
          children: [
            {
              value: "市政生态",
              label: "市政生态",
              disabled: true,
            },
            {
              value: "能源",
              label: "能源",
              disabled: true,
            },
            {
              value: "收费公路",
              label: "收费公路",
              disabled: true,
            },
          ],
        },
        {
          value: "公募REITs",
          label: "公募REITs",
        },
      ],
      category_cascader_options_nogongmu: [
        {
          value: "产权类",
          label: "产权类",

          children: [
            {
              value: "物流仓储",
              label: "物流仓储",
            },
            {
              value: "产业园",
              label: "产业园",
            },
            {
              value: "保障性住房",
              label: "保障性住房",
            },
            {
              value: "消费",
              label: "消费",
            },
          ],
        },
        {
          value: "经营权类",
          label: "经营权类",
          children: [
            {
              value: "市政生态",
              label: "市政生态",
            },
            {
              value: "能源",
              label: "能源",
            },
            {
              value: "收费公路",
              label: "收费公路",
            },
          ],
        },
        {
          value: "公募REITs",
          label: "公募REITs",
          disabled: true,
        },
      ],
      category_cascader_options_all: [
        {
          value: "产权类",
          label: "产权类",
          children: [
            {
              value: "物流仓储",
              label: "物流仓储",
            },
            {
              value: "产业园",
              label: "产业园",
            },
            {
              value: "保障性住房",
              label: "保障性住房",
            },
            {
              value: "消费",
              label: "消费",
            },
          ],
        },
        {
          value: "经营权类",
          label: "经营权类",
          children: [
            {
              value: "市政生态",
              label: "市政生态",
            },
            {
              value: "能源",
              label: "能源",
            },
            {
              value: "收费公路",
              label: "收费公路",
            },
          ],
        },
        {
          value: "公募REITs",
          label: "公募REITs",
        },
      ],

      category_cascader_options_DALEI: [
        {
          value: "产权类",
          label: "产权类",
        },
        {
          value: "经营权类",
          label: "经营权类",
        },
        {
          value: "公募REITs",
          label: "公募REITs",
        },
      ],

      select_val_Benchmark: ["公用指数"],
      // BENCHMARKDATA_chart3: [["equity", "公用指数"]],

      options_Benchmark: [
        {
          name: "沪深300",
          value: "沪深300",
        },
        {
          name: "CN10YR",
          value: "CN10YR",
        },
        {
          name: "公用指数",
          value: "公用指数",
        },
      ],

      options_REITs: [
        {
          name: "招蛇产园",
          value: "180101.SZ",
        },
        {
          name: "张江光大",
          value: "508000.SH",
        },
        {
          name: "东吴苏园",
          value: "508027.SH",
        },
      ],
      select_val_REITs_chart1: [],
      select_val_REITs_chart2: [],
      select_val_REITs_chart3: [],
      checked_one: true,
      checked_two: true,
      checked_three: true,

      dButtons_separrate: ["大类", "个股"],
      dButtons_separrate_current_chart1: "大类",
      dButtons_separrate_current_chart2: "大类",
      dButtons_separrate_current_chart3: "大类",

      start_date_chart2: "YTD",
      start_date_chart3: "YTD",

      // 对比reits
      CODENAMEDATA: [],
      CODENAMEDATA_chart1: [],
      CODENAMEDATA_chart2: [],
      CODENAMEDATA_chart3: [],

      // 对比benchmark
      BENCHMARKDATA: [],
      BENCHMARKDATA_chart1: ["沪深300"],
      // BENCHMARKDATA_chart1: [["equity", "沪深300"]],
      options_benchmark2: null,
      options_benchmark3: null,

      BENCHMARKDATA_chart2: [],
      L1NAME: [],

      chartColumn1: null,
      chartColumn1_dialog: null,
      chart1_data: [],
      dDiffTimeReturn: [],
      dDiffTimeReturn_X: [],
      dDiffTimeReturn_1Y: [],
      dDiffTimeReturn_2Y: [],
      dDiffTimeReturn_3M: [],
      dDiffTimeReturn_3Y: [],
      dDiffTimeReturn_YTD: [],
      dDiffTimeReturn_max: [],

      chartColumn2: null,
      chartColumn2_dialog: null,
      dStackingReturnData2: [],
      dStackingReturnData2_X: [],
      dStackingReturnData2_JG: [],
      dStackingReturnData2_FH: [],
      dStackingReturnData2_ZT: [],

      chartColumn3: null,
      chartColumn3_dialog: null,
      chart3_tit: "",
      dReitsDetailRiskReturnScatter: [],
      ddlVAL: "",

      // limit
      limit_reits_chart1: 10,
      limit_benckmark_chart1: 10,
      limit_reits_chart3: 10,
      limit_benckmark_chart3: 10,

      CATEGORYDATA: this.$store.state.category_cascader_options,
      benchmark: this.$store.state.benchmark,
    };
  },
  created() {
    this.FnInitBenchmark();
    // this.fnInitBenchMark2();
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener("resize", this.funResize);
  },
  methods: {
    fnGetTheme(){
      this.sCurrentTheme = this.$store.state.theme == "dark" ? true : false
    },
    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById("rootchart");
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      window.sessionStorage.setItem("ROOTFONTFIZE", this.ROOTFONTFIZE);

      var chartColumn1 = document.getElementById("chartColumn1");
      chartColumn1.style.height = this.ROOTFONTFIZE * (425 / 144) + "px";

      var chartColumn2 = document.getElementById("chartColumn2");
      chartColumn2.style.height = this.ROOTFONTFIZE * (425 / 144) + "px";

      var chartColumn3 = document.getElementById("chartColumn3");
      chartColumn3.style.height = this.ROOTFONTFIZE * (720 / 144) + "px";

      return;
    },

    // 下载 start
    fnButtonDL(val) {
      if (val == "下载图片") {
        this.FnDownload();
      } else {
        document.getElementById("downloadExcel1").click();
      }
    },

    fnButtonDL2(val) {
      if (val == "下载图片") {
        this.FnDownload2();
      } else {
        document.getElementById("downloadExcel2").click();
      }
    },

    fnButtonDL3(val) {
      if (val == "下载图片") {
        this.FnDownload3();
      } else {
        document.getElementById("downloadExcel3").click();
      }
    },

    // 下载 end

    // 下载
    FnDownload() {

      // document.getElementById('chartColumn1').style.width= '3000px'
      let h = this.$refs.chartColumn1.scrollHeight;
      let w = this.$refs.chartColumn1.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn1, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "板块收益不同时间段对比"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    // 下载
    FnDownload2() {
      let h = this.$refs.chartColumn2.scrollHeight;
      let w = this.$refs.chartColumn2.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn2, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "不同时间段各部分回报构成"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    // 下载
    FnDownload3() {
      let h = this.$refs.chartColumn3.scrollHeight;
      let w = this.$refs.chartColumn3.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn3, {
        height: h + 20,
        width: w,
        // backgroundColor: '#35373b',
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "按资产类别年化收益及波动率对比"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    // 初始化benckmark
    FnInitBenchmark() {
      this.BENCHMARKDATA = JSON.parse(localStorage.getItem("BENCHMARKNAME"));

      // 获取对比reits
      let REITsNAME = JSON.parse(localStorage.getItem("REITsNAME"));
      this.CODENAMEDATA = REITsNAME;

      // 获取L1
      let L1NAME = JSON.parse(localStorage.getItem("L1NAME"));
      this.L1NAME = L1NAME;

      let FINALLYCATEGORY = JSON.parse(localStorage.getItem("BENCHMARKNAME"));
    },
    fnInitBenchMark2() {
      return
      let arr1 = JSON.parse(localStorage.getItem("BENCHMARKNAME2"));
      let arr2 = JSON.parse(localStorage.getItem("BENCHMARKNAMEBENTYPE"));

      let arr3 = arr2.map((node) => {
        return node.benType;
      });

      arr3 = [...new Set(arr3)];

      localStorage.setItem("BENTYPE", JSON.stringify(arr3));

      let arr4 = arr3.map((node) => {
        let obj = {
          value: node,
          label: node,
          children: [],
        };

        return obj;
      });

      arr1.forEach((node) => {
        let obj = arr2.find((item) => item.benName == node.value);
        if (obj) {
          let bentype = obj.benType;
          arr4.forEach((ele) => {
            if (ele.value == bentype) {
              ele.children.push(node);
            }
          });
        }
      });

      this.options_benchmark2 = arr4;
      this.options_benchmark3 = arr4;
      this.fnNot();
      this.fnNot2();
    },

    // 禁止选择
    fnNot() {
      let value = null;
      if (typeof this.BENCHMARKDATA_chart1[0] == "string") {
        value = this.BENCHMARKDATA_chart1;
      } else {
        value = this.BENCHMARKDATA_chart1.map((node) => {
          return node[1];
        });
      }

      // 3种情况
      // 1. 等于3
      if (this.BENCHMARKDATA_chart1.length == 10) {
        this.options_benchmark2 = this.options_benchmark2.map((node) => {
          node.children.forEach((ele) => {
            let obj = value.find((item) => {
              return item == ele.value;
            });
            if (!obj) {
              ele["disabled"] = true;
            } else {
              ele["disabled"] = false;
            }
          });
          return node;
        });
      }
      // 2. 大于1小于3
      if (
        this.BENCHMARKDATA_chart1.length < 10 &&
        this.BENCHMARKDATA_chart1.length > 1
      ) {
        this.options_benchmark2 = this.options_benchmark2.map((node) => {
          node.children.forEach((ele) => {
            ele["disabled"] = false;
          });
          return node;
        });
      }
      // 3. 等于1
      if (this.BENCHMARKDATA_chart1.length == 1) {
        this.options_benchmark2 = this.options_benchmark2.map((node) => {
          node.children.forEach((ele) => {
            let obj = value.find((item) => {
              return item == ele.value;
            });
            if (obj) {
              ele["disabled"] = true;
            } else {
              ele["disabled"] = false;
            }
          });
          return node;
        });
      }
    },

    fnNot2() {
      let value = null;
      if (typeof this.BENCHMARKDATA_chart3[0] == "string") {
        value = this.BENCHMARKDATA_chart3;
      } else {
        value = this.BENCHMARKDATA_chart3.map((node) => {
          return node[1];
        });
      }

      // 3种情况
      // 1. 等于3
      if (this.BENCHMARKDATA_chart3.length == 10) {
        this.options_benchmark3 = this.options_benchmark3.map((node) => {
          node.children.forEach((ele) => {
            let obj = value.find((item) => {
              return item == ele.value;
            });
            if (!obj) {
              ele["disabled"] = true;
            } else {
              ele["disabled"] = false;
            }
          });
          return node;
        });
      }
      // 2. 大于1小于3
      if (
        this.BENCHMARKDATA_chart3.length < 10 &&
        this.BENCHMARKDATA_chart3.length > 1
      ) {
        this.options_benchmark3 = this.options_benchmark3.map((node) => {
          node.children.forEach((ele) => {
            ele["disabled"] = false;
          });
          return node;
        });
      }
      // 3. 等于1
      if (this.BENCHMARKDATA_chart3.length == 1) {
        this.options_benchmark3 = this.options_benchmark3.map((node) => {
          node.children.forEach((ele) => {
            let obj = value.find((item) => {
              return item == ele.value;
            });
            if (obj) {
              ele["disabled"] = true;
            } else {
              ele["disabled"] = false;
            }
          });
          return node;
        });
      }
    },

    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChart3(true);
      });
    },

    fnDropdown(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;

        this.$nextTick(() => {
          this.initChart3(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;

        this.$nextTick(() => {
          this.initChart3(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;

        this.$nextTick(() => {
          this.initChart3(true);
        });
      }

      // window.addEventListener("resize", this.funResize);

      // this.chartColumn4.resize();
      // this.chartColumn5.resize();
    },

    fnOpenDialog2() {
      this.dialogVisible2 = true;
      this.$nextTick(() => {
        this.initChart1(true);
      });
    },

    fnDropdown2(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      }
    },

    fnOpenDialog3() {
      this.dialogVisible3 = true;
      this.$nextTick(() => {
        this.initChart2(true);
      });
    },

    fnDropdown3(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart2(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart2(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart2(true);
        });
      }
    },

    fnCbtns_sep_chart1(val) {
      if (val == "大类") {
        this.$refs.CCascader_chart1.value =
          this.$store.state.IncomeAnalysisCasCaderData;
      }

      if (val !== this.dButtons_separrate_current_chart1) {
        this.$refs.CCascader2_chart1.value = [];
        this.select_val_REITs_chart1 = [];
        // this.BENCHMARKDATA_chart1 = ["沪深300"];
        // this.$refs.CSelect_2.value = ["沪深300"];
        // this.$refs.CSelect_2.isLast = true;
      }

      this.dButtons_separrate_current_chart1 = val;

      // if (
      //   this.dButtons_separrate_current_chart1 == "个股" &&
      //   this.category_cascader_val_chart1.includes("公募REITs")
      // ) {
      //   this.category_cascader_options = this.category_cascader_options_gongmu;
      // }

      // if (this.dButtons_separrate_current_chart1 == "大类") {
      //   this.category_cascader_options = this.category_cascader_options_all;
      // }

      this.fnGetDiffTimeReturn();
    },

    fnCCascader_select_val_one(val) {
      // if (this.dButtons_separrate_current_chart1 == "个股") {
      //   this.L1NAME.forEach((node) => {
      //     val = val.filter((e) => {
      //       return e !== node;
      //     });
      //   });
      // }

      this.category_cascader_val_chart1 = val;

      this.fnGetDiffTimeReturn();
      // this.fnGetValuationTable();
    },

    fnSelect_select_val_chart1_codename(val) {
      val = val.filter((node) => {
        return node.includes(".");
      });
      this.select_val_REITs_chart1 = val;
      // this.limit_benckmark_chart1 = 10 - this.select_val_REITs_chart1.length;
      this.fnGetDiffTimeReturn();
    },

    fnSelect_select_val_chart1_benchmark(val) {
      if (!val[0]) return this.$message.error("值不能为空");
      this.BENCHMARKDATA_chart1 = val;
      this.limit_reits_chart1 = 10 - this.BENCHMARKDATA_chart1.length;
      this.$refs.CSelect_2.value = this.BENCHMARKDATA_chart1;

      this.fnGetDiffTimeReturn();
    },

    fnCCascader2(val) {
      let arr = JSON.parse(localStorage.getItem("BENTYPE"));
      let data = val.filter((node) => {
        return !arr.includes(node);
      });
      this.BENCHMARKDATA_chart1 = data;
      this.fnNot();
      this.fnGetDiffTimeReturn(val);
    },

    async fnGetDiffTimeReturn(val) {
      let annualize = this.checked_one ? 1 : 0;
      let cal_function =
        this.dButtons_separrate_current_chart1 == "个股" ? "single" : "type";
      let codes = this.select_val_REITs_chart1;
      let str2 = codes.join("&codes=");

      let r_type_ = this.category_cascader_val_chart1;
      let str = r_type_.join("&r_type_=");

      // let ben_select = null;
      // if (!val) {
      //   // 修改一下数组 start
      //   ben_select = this.BENCHMARKDATA_chart1.map((node) => {
      //     return node[1];
      //   });
      //   // 修改一下数组 end
      // } else {
      //   ben_select = this.BENCHMARKDATA_chart1;
      // }

      let ben_select = this.BENCHMARKDATA_chart1;
      let str3 = ben_select.join("&ben_select=");

      const { data } = await this.$https.get(
        "/api/get_diff_time_return?annualize=" +
          annualize +
          "&cal_function=" +
          cal_function +
          "&codes=" +
          str2 +
          "&r_type_=" +
          str +
          "&ben_select=" +
          str3
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dDiffTimeReturn = data.data;

      // let obj =  Object.keys(this.dDiffTimeReturn[0])
      // 下载表格 start
      // this.json_fields = {
      //   股票代码: "reit_code",
      //   名称: "YTD",
      //   大类: "3M",
      //   类型: "6M",
      //   类型: "1Y",
      //   类型: "3Y",
      //   类型: "max",
      // },

      // 下载表格 end

      this.dDiffTimeReturn_X = [];
      this.dDiffTimeReturn_1Y = [];
      this.dDiffTimeReturn_2Y = [];
      this.dDiffTimeReturn_3M = [];
      this.dDiffTimeReturn_3Y = [];
      this.dDiffTimeReturn_YTD = [];
      this.dDiffTimeReturn_max = [];

      let dataTmp = data.data;

      for (let index = 0; index < dataTmp.length; index++) {
        this.dDiffTimeReturn_X.push(dataTmp[index].reit_code);
        // this.dDiffTimeReturn_YTD.push(dataTmp[index]["YTD"]);
        // this.dDiffTimeReturn_3M.push(dataTmp[index]["3M"]);
        // this.dDiffTimeReturn_1Y.push(dataTmp[index]["1Y"]);
        // this.dDiffTimeReturn_2Y.push(dataTmp[index]["2Y"]);
        // this.dDiffTimeReturn_3Y.push(dataTmp[index]["3Y"]);
        // this.dDiffTimeReturn_max.push(dataTmp[index]["max"]);
      }

      this.dDiffTimeReturnDDL =
        this.dDiffTimeReturn_X[this.dDiffTimeReturn_X.length - 1];

      this.chart1_data = dataTmp;
      this.$nextTick(()=>{
        this.initChart1();
      })
    },

    initChart1(val) {
      // this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      if (!val) {
        this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      } else {
        this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1_dialog);
      }

      this.chartColumn1.clear();

      let dataTmp = this.chart1_data;

      let NAME = Object.keys(dataTmp[0]);
      NAME.shift();
      let dDiffTimeReturn_X = this.dDiffTimeReturn_X;
      // let dDiffTimeReturn_1Y = this.dDiffTimeReturn_1Y;
      // let dDiffTimeReturn_2Y = this.dDiffTimeReturn_2Y;
      // let dDiffTimeReturn_3M = this.dDiffTimeReturn_3M;
      // let dDiffTimeReturn_3Y = this.dDiffTimeReturn_3Y;
      // let dDiffTimeReturn_YTD = this.dDiffTimeReturn_YTD;
      // let dDiffTimeReturn_max = this.dDiffTimeReturn_max;
      // let benchmark1 = this.$store.state.benchmark.map((node) => {
      //   return node.label;
      // });
      // console.log(benchmark1,'benchmark1');

      let benchmark = this.BENCHMARKDATA.map((node) => {
        return node.label;
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      // 大类
      // if (this.dButtons_separrate_current_chart1 == "大类") {
      //   // L0 L1 L2 排序
      //   let JYQL = [];
      //   let CQL = [];
      //   let BC = [];
      //   let GM = [];

      //   dDiffTimeReturn_X.forEach((node) => {
      //     this.CATEGORYDATA.forEach((e, idx) => {
      //       if (e.name == node || e.data.includes(node)) {
      //         if (!idx) {
      //           CQL.push(node);
      //         } else {
      //           JYQL.push(node);
      //         }
      //       }
      //     });

      //     this.benchmark.forEach((item) => {
      //       if (node == item.label) {
      //         BC.push(item.label);
      //       }
      //     });

      //     if (node == "公募REITs") {
      //       GM.push(node);
      //     }
      //   });

      //   let CQLTop = CQL.shift();
      //   let JYQLTop = JYQL.shift();

      //   dDiffTimeReturn_X = [];
      //   let top3 = [...GM];

      //   if (CQLTop == undefined) {
      //     top3 = [...top3, JYQLTop, ...JYQL];
      //   }
      //   if (JYQLTop == undefined) {
      //     top3 = [...top3, CQLTop, ...CQL];
      //   }

      //   if (CQLTop !== undefined && JYQLTop !== undefined) {
      //     top3 = [...top3, CQLTop, JYQLTop, ...CQL, ...JYQL];
      //   }

      //   dDiffTimeReturn_X = [...top3, ...BC];
      // }

      let dDiffTimeReturn_1Y = [];
      let dDiffTimeReturn_2Y = [];
      let dDiffTimeReturn_3M = [];
      let dDiffTimeReturn_3Y = [];
      let dDiffTimeReturn_YTD = [];
      let dDiffTimeReturn_max = [];

      dDiffTimeReturn_X.forEach((node) => {
        dataTmp.forEach((item) => {
          if (node == item.reit_code) {
            dDiffTimeReturn_1Y.push(item["1Y"]);
            dDiffTimeReturn_2Y.push(item["2Y"]);
            dDiffTimeReturn_3M.push(item["3M"]);
            dDiffTimeReturn_3Y.push(item["3Y"]);
            dDiffTimeReturn_YTD.push(item["YTD"]);
            dDiffTimeReturn_max.push(item["max"]);
          }
        });
      });

      let FINALLY = [];
      NAME.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };

        dataTmp.forEach((item) => {
          obj.data.push(item[node]);
        });

        FINALLY.push(obj);
      });

      let color = [
        "#39DDE3",
        "#55e2e7",
        "#72e7eb",
        "#8eecef",
        "#aaf0f3",
        "#c6f5f7",
        "#e3fafb",
      ];

      let benchmarkcolor = [
        "#ff3305",
        "#ff5028",
        "#ff6d4d",
        "#ff8a70",
        "#ffa894",
        "#ffc5b7",
        "#ffe2dc",
      ];

      let seriesArr = FINALLY.map((v, idx) => {
        return {
          name: v.name,
          type: "bar",
          data: v.data,
          itemStyle: {
            color: function (args) {
              if (benchmark.includes(args.name)) {
                return benchmarkcolor[idx];
              } else {
                return color[idx];
              }
            },
          },
        };
      });

      //  个股
      let option = {
        // title: {
        //   // 标题设置
        //   text: "公募REITs全部分类1年以来收益率对比", // 标题文字
        //   textStyle: {
        //     // 标题文字样式设置
        //     color: "rgba(218, 218, 218, 1)",
        //     fontSize: 16,
        //   },
        //   left: "center",
        //   top: 10,
        // },
        legend: {
          show: true,
          icon: "circle",
          orient: "horizontal",
          left: this.ROOTFONTFIZE * (30 / 144),
          top: this.ROOTFONTFIZE * (35 / 144),
          align: "left",
          itemGap: this.ROOTFONTFIZE * (10 / 144),
          itemHeight: this.ROOTFONTFIZE * (16 / 144),
          padding: [0, 0, 0, 0],
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: OPTIONDATA.legend.textStyle.fontSize,
          },
          data: NAME,
          // data: ["YTD", "3M", "1Y", "2Y", "3Y", "max"],
        },
        grid: {
          //方法 2
          left: this.ROOTFONTFIZE * (20 / 144),
          right: this.ROOTFONTFIZE * (20 / 144),
          bottom: this.ROOTFONTFIZE * (55 / 144),
          height: "70%",
          // y2: '32%',
          containLabel: true,
        },
        //   toolbox: { // 工具箱按钮
        //   right:20,
        //   top:10,
        //   itemSize:18,
        //   iconStyle:{
        //     color:'#fff',
        //     borderColor:'#fff',
        //     borderWidth:1

        //   },
        //   feature: {
        //     saveAsImage: {
        //        backgroundColor :'#35373b',

        //     }, // 导出图片

        //   }
        // },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let val = Number(node.value) * 100;
              val = val.toFixed(1);
              str += "<br>";
              str += `${node.marker}${node.seriesName}：${val}%`;

              return str;
            });
            return str;
          },
        },
        // 标记
        xAxis: {
          type: "category",
          data: dDiffTimeReturn_X,
          axisLine: {
            show: true,
            onZero: true, //轴线是否在0刻度轴上
            // onZero: true, //轴线是否在0刻度轴上
            lineStyle: {},
          },

          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            rotate: 25,
            margin: OPTIONDATA.xAxis.axisLabel.margin,
            overflow: "truncate",
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
            padding: [0, 0, 0, 0],
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
        },
        yAxis: {
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (parms) {
              let val = parms * 100;
              val = val.toFixed(1);
              val = val + "%";
              return val;
            },
          },
          splitLine: OPTIONDATA.yAxis.splitLine,
        },

        color: color,
        series: seriesArr,
        // series: [
        //   {
        //     name: "YTD",
        //     type: "bar",
        //     data: dDiffTimeReturn_YTD,
        //     // barWidth: "6px",
        //     itemStyle: {
        //       // color: "rgba(0, 47, 255,0.8)",
        //       // color:'#39DDE3'
        //       color: function (args) {
        //         if (benchmark.includes(args.name)) {
        //           return "#FF3305";
        //         } else {
        //           return "#39DDE3";
        //         }
        //       },
        //     },
        //   },

        //   {
        //     name: "3M",
        //     type: "bar",
        //     data: dDiffTimeReturn_3M,
        //     // barWidth: "6px",
        //     itemStyle: {
        //       // color: "rgba(33, 74, 255,0.8)",
        //       // color:'#5ae8ed'
        //       color: function (args) {
        //         if (benchmark.includes(args.name)) {
        //           return "#ff5229";
        //         } else {
        //           return "#5ae8ed";
        //         }
        //       },
        //     },
        //   },

        //   {
        //     name: "1Y",
        //     type: "bar",
        //     data: dDiffTimeReturn_1Y,
        //     // barWidth: "6px",
        //     itemStyle: {
        //       // color:"#365eff"
        //       // color: "rgba(54, 94, 255,0.8)",
        //       // color:'#7ef3f7'
        //       color: function (args) {
        //         if (benchmark.includes(args.name)) {
        //           return "#ff7654";
        //         } else {
        //           return "#7ef3f7";
        //         }
        //       },
        //     },
        //   },

        //   {
        //     name: "2Y",
        //     type: "bar",
        //     data: dDiffTimeReturn_2Y,
        //     // barWidth: "6px",
        //     itemStyle: {
        //       // color: "rgba(100, 137, 255,0.8)",
        //       // color:'#a7fafc'
        //       color: function (args) {
        //         if (benchmark.includes(args.name)) {
        //           return "#fe9a7f";
        //         } else {
        //           return "#a7fafc";
        //         }
        //       },
        //     },
        //   },

        //   {
        //     name: "3Y",
        //     type: "bar",
        //     data: dDiffTimeReturn_3Y,
        //     // barWidth: "6px",
        //     itemStyle: {
        //       // color: "#b6f8fa",
        //       color: function (args) {
        //         if (benchmark.includes(args.name)) {
        //           return "#fdbfaa";
        //         } else {
        //           return "#b6f8fa";
        //         }
        //       },
        //     },
        //   },

        //   {
        //     name: "max",
        //     type: "bar",
        //     data: dDiffTimeReturn_max,
        //     // barWidth: "6px",
        //     itemStyle: {
        //       // color:'#cff8fa'
        //       color: function (args) {
        //         if (benchmark.includes(args.name)) {
        //           return "#fce4d6";
        //         } else {
        //           return "#cff8fa";
        //         }
        //       },
        //     },
        //   },
        // ],

        // dataZoom: {
        //   // 开启数据平移qc
        //   type: "slider", // 滑动条单独显示
        //   show: true, // 是否显示滑动条
        //   startValue: 0, // 展示区域内容的起始数值
        //   endValue: 16, // 展示区域内容的结束数值 当前展示x坐标下标为0-16
        //   height: this.ROOTFONTFIZE * (5 / 144), // 滑动条组件高度
        //   bottom: this.ROOTFONTFIZE * (30 / 144), // 距离图表区域下边的距离
        //   showDetail: false, // 拖拽时是否显示详情
        //   showDataShadow: false,
        //   fillerColor: "#D4D4D4", // 平移条的填充颜色
        //   borderColor: "transparent", // 边框颜色
        //   zoomLock: true, // 锁定视图
        //   brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
        //   // 通过该属性可以只滑动，不显示缩放功能
        //   handleStyle: {
        //     opacity: 0,
        //   },
        //   lineStyle: {
        //     opacity: 0,
        //   },
        //   textStyle: {
        //     fontSize: 0,
        //   },
        // },
      };

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
      // this.funResize();
    },

    // chart2
    fnCbtns_sep_chart2(val) {
      if (val !== this.dButtons_separrate_current_chart2) {
        this.$refs.CCascader2_chart2.value = [];
        this.select_val_REITs_chart2 = [];
      }
      this.dButtons_separrate_current_chart2 = val;
      this.fnGetStackingReturnData2();
    },
    fnCCascader_select_val_chart2(val) {
      if (this.dButtons_separrate_current_chart2 == "个股") {
        this.L1NAME.forEach((node) => {
          val = val.filter((e) => {
            return e !== node;
          });
        });
      }

      this.category_cascader_val_chart2 = val;
      this.fnGetStackingReturnData2();
    },
    fnCCascader_select2_val_chart2(val) {
      val = val.filter((node) => {
        return node.includes(".");
      });
      this.select_val_REITs_chart2 = val;
      this.fnGetStackingReturnData2();
      // this.category_cascader_val_chart2 = val;
      // this.fnGetStackingReturnData2();
    },

    // fnSelect_select_val_chart2_codename(val) {
    //   this.select_val_REITs_chart2 = val;
    //   this.fnGetStackingReturnData2();
    // },

    fnCTimeButtons_select_val_chart2(val) {
      this.start_date_chart2 = val;
      this.fnGetStackingReturnData2();
    },

    async fnGetStackingReturnData2() {
      let annualize = this.checked_two ? 1 : 0;
      let cal_function =
        this.dButtons_separrate_current_chart2 == "个股" ? "single" : "type";
      let codes = this.select_val_REITs_chart2;
      let str0 = codes.join("&codes=");
      let r_type_ = this.category_cascader_val_chart2;
      let str = r_type_.join("&r_type_=");
      let freq_type =
        this.start_date_chart2 == "MAX" ? "max" : this.start_date_chart2;

      const { data } = await this.$https.get(
        "/api/stacking_return_data?annualize=" +
          annualize +
          "&cal_function=" +
          cal_function +
          "&codes=" +
          str0 +
          "&r_type_=" +
          str +
          "&freq_type=" +
          freq_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dStackingReturnData2 = data.data;

      this.dStackingReturnData2_X = [];
      this.dStackingReturnData2_JG = [];
      this.dStackingReturnData2_FH = [];
      this.dStackingReturnData2_ZT = [];

      let dataTmp = data.data;

      for (let index = 0; index < dataTmp.length; index++) {
        this.dStackingReturnData2_X.push(dataTmp[index].reit_code);
        this.dStackingReturnData2_JG.push(dataTmp[index]["价格回报"]);
        this.dStackingReturnData2_FH.push(dataTmp[index]["分红回报"]);
        this.dStackingReturnData2_ZT.push(dataTmp[index]["总体回报"]);
      }

      // this.dDiffTimeReturnDDL = this.dDiffTimeReturn_X[this.dDiffTimeReturn_X.length - 1]
      this.$nextTick(()=>{
        
        this.initChart2();
      })
    },

    initChart2(val) {
      if (!val) {
        this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);
      } else {
        this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2_dialog);
      }

      // this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);

      let dStackingReturnData2_X = this.dStackingReturnData2_X;
      let dStackingReturnData2_JG = this.dStackingReturnData2_JG;
      let dStackingReturnData2_FH = this.dStackingReturnData2_FH;
      let dStackingReturnData2_ZT = this.dStackingReturnData2_ZT;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme

      let texta = this.start_date_chart2;

      switch (texta) {
        case "3M":
          texta = "3个月以来";
          break;
        case "YTD":
          texta = "今年以来";
          break;

        case "6M":
          texta = "6个月以来";
          break;

        case "1Y":
          texta = "1年以来";

          break;
        case "3Y":
          texta = "3年以来";
          break;

        case "MAX":
          texta = "上市以来";
          break;
      }

      let option = {
        title: {
          // 标题设置
          text: texta + " 公募REITs各部分回报构成",
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: OPTIONDATA.title.textStyle.fontSize,
          },
          left: "center",
          top: OPTIONDATA.title.top,
        },
        legend: {
          show: true,
          orient: "horizontal",
          top: this.ROOTFONTFIZE * (38 / 144),
          left: this.ROOTFONTFIZE * (30 / 144),
          align: "left",
          icon: "roundRect",
          itemGap: this.ROOTFONTFIZE * (20 / 144),
          itemHeight: this.ROOTFONTFIZE * (10 / 144),
          itemWidth: this.ROOTFONTFIZE * (10 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: OPTIONDATA.legend.textStyle.fontSize,
          },
          data: [
            {
              name: "价格回报",
              icon: "rect",
            },
            {
              name: "分红回报",
              icon: "rect",
            },
            {
              name: "总体回报",
              icon: "circle",
            },
          ],
        },

        grid: {
          left: this.ROOTFONTFIZE * (20 / 144),
          bottom: this.ROOTFONTFIZE * (60 / 144),
          right: this.ROOTFONTFIZE * (35 / 144),
          top: this.ROOTFONTFIZE * (80 / 144),
          // height: "90%",
          // width: "95%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let val = Number(node.value) * 100;
              val = val.toFixed(1);
              str += "<br>";
              str += `${node.marker}${node.seriesName}：${val}%`;

              return str;
            });
            return str;
          },
        },

        xAxis: {
          type: "category",
          data: dStackingReturnData2_X,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
            onZero: true, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
            margin: OPTIONDATA.xAxis.axisLabel.margin,
            rotate: 25,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            // formatter: "{value}%", //y轴百分比
            formatter: function (val) {
              val = Number(val) * 100;
              let num = (parseInt(Math.abs(val)) + "").length >= 2 ? 1 : 2;
              return val.toFixed(num) + "%";
            },
          },
          splitLine: OPTIONDATA.yAxis.splitLine,
        },
        series: [
          {
            name: "价格回报",
            type: "bar",
            stack: "all",
            data: dStackingReturnData2_JG,
            // markLine: {
            //   symbol: ["none", "none"], // none为标线两端的样式为无，可更改
            //   data: [
            //     {
            //       yAxis: 0,
            //     },
            //   ],
            //   label: {
            //     show: false,
            //   },
            //   lineStyle: {
            //     type: "solid",
            //     color: "#5ae8ed",
            //   },
            // },
            // itemStyle: {
            //   color: function (params) {
            //     let name = params.name;
            //     let obj = dDailyReturnCompare.find((item) => {
            //       return item.index === name;
            //     });
            //     let obj2 = {};
            //     if (obj.index === "公募REITs") {
            //       obj2 = colors.find((item) => {
            //         return item.name === "公募REITs";
            //       });
            //     } else {
            //       obj2 = colors.find((item) => {
            //         return item.name === obj.ben_type;
            //       });
            //     }
            //     return obj2.value;
            //   },

            //   barBorderRadius: function (params) {
            //     // return [30, 30, 0, 0];
            //   },
            // },
            // barWidth: "45px",
            itemStyle: {
              color: "#5acac6",
            },
            label: {
              show: true,
              position: "insideBottom",
              fontSize:
                dStackingReturnData2_JG.length < 15
                  ? this.ROOTFONTFIZE * (14 / 144)
                  : this.ROOTFONTFIZE * (10 / 144),
              color: "#fefefe",
              formatter: function (value) {
                let val = value.value * 100;
                val = val.toFixed(1);
                if (val == 0) {
                  return "";
                } else {
                  val = val + "%";
                  return val;
                }
              },
            },
          },
          {
            name: "分红回报",
            type: "bar",
            stack: "all",
            data: dStackingReturnData2_FH,
            itemStyle: {
              color: "#5e5ce6",
            },

            // barWidth: "45px",
            label: {
              show: true,
              position: "top",
              // offset:[0,-20],
              fontSize:
                dStackingReturnData2_FH.length < 15
                  ? this.ROOTFONTFIZE * (14 / 144)
                  : this.ROOTFONTFIZE * (10 / 144),
              color: OPTIONDATA.legend.textStyle.color,
              formatter: function (value) {
                let val = value.value * 100;
                val = val.toFixed(1);
                if (val == 0) {
                  return "";
                } else {
                  val = val + "%";
                  return val;
                }
              },
            },
          },
          {
            name: "总体回报",
            type: "line",
            data: dStackingReturnData2_ZT,
            itemStyle: {
              show: false,
              width: 0,
              color: "#ff5028",
              opacity: theme == 'dark' ? 0.7 : 0.9,
            },
            lineStyle: {
              opacity: 0,
            },
            symbol: "circle",
            // symbolSize:  32 - dStackingReturnData2_X.length / 2,
            symbolSize: function (val) {
              if (val == 0) {
                return 0;
              } else {
                return 32 - dStackingReturnData2_X.length / 2;
              }
            },
            label: {
              show: true,
              position: "right",
              backgroundColor: "#ff5028",
              borderRadius: 5,
              formatter: function (value) {
                let val = value.value * 100;
                val = val.toFixed(1);
                if (val == 0) {
                  return "";
                } else {
                  val = val + "%";
                  return `{d|${val}}`;
                }
              },
              rich: {
                d: {
                  padding: 5,
                  fontSize:
                    dStackingReturnData2_ZT.length < 15
                      ? this.ROOTFONTFIZE * (14 / 144)
                      : this.ROOTFONTFIZE * (10 / 144),
                  color: "rgba(255, 255, 255)",
                },
              },
              // formatter: function (value) {
              //   let val = value.value * 100;
              //   val = val.toFixed(1);
              //   if (val == 0) {
              //     return "";
              //   } else {
              //     val = val + "%";
              //     return val;
              //   }
              // },
            },
          },
        ],
        // dataZoom: {
        //   // 开启数据平移qc
        //   type: "slider", // 滑动条单独显示
        //   show: true, // 是否显示滑动条
        //   startValue: 0, // 展示区域内容的起始数值
        //   endValue: 20, // 展示区域内容的结束数值 当前展示x坐标下标为0-16
        //   height: this.ROOTFONTFIZE * (5 / 144), // 滑动条组件高度
        //   bottom: this.ROOTFONTFIZE * (30 / 144), // 距离图表区域下边的距离
        //   showDetail: false, // 拖拽时是否显示详情
        //   showDataShadow: false,
        //   fillerColor: "#D4D4D4", // 平移条的填充颜色
        //   borderColor: "transparent", // 边框颜色
        //   zoomLock: true, // 锁定视图
        //   brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
        //   // 通过该属性可以只滑动，不显示缩放功能
        //   handleStyle: {
        //     opacity: 0,
        //   },
        //   lineStyle: {
        //     opacity: 0,
        //   },
        //   textStyle: {
        //     fontSize: 0,
        //   },
        // },
      };

      //  {
      //       name: "总体回报",
      //       type: "line",
      //       data: dStackingReturnData2_ZT,
      //       itemStyle: {
      //         show: false,
      //         width: 0,
      //         color: "#ff5028",
      //         opacity: 0.7,
      //       },
      //       lineStyle: {
      //         opacity: 0,
      //       },
      //       symbol: "circle",
      //       symbolSize:function(val){
      //           if(val == 0){
      //             return 0
      //           }else{
      //             return 32 - dStackingReturnData2_X.length / 2
      //           }

      //       },
      //       label: {
      //         show: true,
      //         position: "right",
      //         backgroundColor: "#ff5028",
      //         fontSize:
      //           dStackingReturnData2_ZT.length < 15
      //             ? this.ROOTFONTFIZE * (14 / 144)
      //             : this.ROOTFONTFIZE * (10 / 144),
      //         color: "rgba(255, 255, 255)",
      //         padding: 5,
      //         borderRadius: 5,
      //         // color:'#333',
      //         formatter: function (value) {
      //           let val = value.value * 100;
      //           val = val.toFixed(1);
      //           if (val == 0) {
      //             return "";
      //           } else {
      //             val = val + "%";
      //             return val;
      //           }
      //         },
      //       },
      //     },

      this.chartColumn2.setOption(option);
      this.chartColumn2.resize();
      // this.funResize();
    },

    fnCTimeButtons_select_val_chart3(val) {
      this.start_date_chart3 = val;
      this.fnGetReitsDetailRiskReturnScatter();
      // this.fnGetStackingReturnData2()
    },
    fnCCascader_select_val_chart3(val) {
      this.category_cascader_val_chart3 = val;
      this.fnGetReitsDetailRiskReturnScatter();
    },

    fnCCascader_select2_val_chart3(val) {
      val = val.filter((node) => {
        return node.includes(".");
      });
      this.select_val_REITs_chart3 = val;
      this.fnGetReitsDetailRiskReturnScatter();
      // this.limit_benckmark_chart3 = 10 - this.select_val_REITs_chart3.length;
    },

    // fnSelect_select_val_chart3_codename(val) {
    //   this.select_val_REITs_chart3 = val;
    //   this.limit_benckmark_chart3 = 10 - this.select_val_REITs_chart3.length;
    //   this.fnGetReitsDetailRiskReturnScatter();
    // },

    fnCSelect2_chart3(val) {
      console.log(val,'val大苏打的');

      if (!val[0]) return this.$message.error("值不能为空");
      this.select_val_Benchmark = val;
      this.limit_reits_chart3 = 10 - this.select_val_Benchmark.length;
      this.$refs.CSelect_5.value = this.select_val_Benchmark;

      this.fnGetReitsDetailRiskReturnScatter();
    },
    fnCCascader3(val) {
      let arr = JSON.parse(localStorage.getItem("BENTYPE"));
      let data = val.filter((node) => {
        return !arr.includes(node);
      });
      this.BENCHMARKDATA_chart3 = data;
      this.fnNot();
      this.fnGetReitsDetailRiskReturnScatter(val);
    },

    fnCbtns_sep_chart3(val) {
      if (val !== this.dButtons_separrate_current_chart3) {
        this.$refs.CCascader2_chart3.value = [];
        this.select_val_REITs_chart3 = [];
        this.$refs.CSelect_5.value = ["公用指数"];
        this.select_val_Benchmark = ["公用指数"];
        this.$refs.CSelect_5.isLast = true;
      }
      this.dButtons_separrate_current_chart3 = val;
      this.fnGetReitsDetailRiskReturnScatter();
    },

    async fnGetReitsDetailRiskReturnScatter(val) {
      let cal_function =
        this.dButtons_separrate_current_chart3 == "个股" ? "single" : "type";
      let codes = this.select_val_REITs_chart3;
      let str0 = codes.join("&codes=");
      let r_type_ = this.category_cascader_val_chart3;
      let str = r_type_.join("&r_type_=");
      let freq_type =
        this.start_date_chart3 == "MAX" ? "max" : this.start_date_chart3;

      // let ben_select = null;
      // if (!val) {
      //   // 修改一下数组 start
      //   ben_select = this.BENCHMARKDATA_chart3.map((node) => {
      //     return node[1];
      //   });
      //   // 修改一下数组 end
      // } else {
      //   ben_select = this.BENCHMARKDATA_chart3;
      // }

      let ben_select = this.select_val_Benchmark;
      let str1 = ben_select.join("&ben_select=");

      const { data } = await this.$https.get(
        "/api/reits_detail_risk_return_scatter?ben_select=" +
          str1 +
          "&cal_function=" +
          cal_function +
          "&codes=" +
          str0 +
          "&r_type_=" +
          str +
          "&freq_type=" +
          freq_type
      );

      // 存一个数组，包括分类名称，横，纵坐标值，name名称 （是L2数据）
      if (data.code !== 200) return this.$message.error(data.msg);

      // this.dReitsDetailRiskReturnScatter
      let dataTmp = data.data;

      this.json_fields3Data = data.data;

      if (this.dButtons_separrate_current_chart3 == "个股") {
        this.json_fields3 = {
          大类: "REITs_type_L1",
          类型: "REITs_type_L2",
          股票名称: "REITs_name",
          年化收益率: "年化收益率",
          年化波动率: "年化波动率",
          目前流通市值: "目前流通市值",
        };
      } else {
        this.json_fields3 = {
          类型: "reit_code",
          年化收益率: "年化收益率",
          年化波动率: "年化波动率",
          目前流通市值: "目前流通市值",
        };
      }

      this.ddlVAL = data.max_date;

      let arr = this.ddlVAL.split("-");
      this.ddlVAL =
        arr[0] + "年" + arr[1] + "月" + arr[2].substring(0, 2) + "日";

      this.dReitsDetailRiskReturnScatter = [];

      if (cal_function == "type") {
        for (let index = 0; index < dataTmp.length; index++) {
          let arr = [];
          let REITs_name = dataTmp[index].reit_code;
          let REITs_type_L2 = dataTmp[index].reit_code;
          let flag = this.BENCHMARKDATA.some((node) => {
            return node.value == REITs_type_L2;
          });
          if (flag) {
            REITs_type_L2 = "benchmark";
          }

          let annualizedIncome = dataTmp[index].年化波动率;
          let annualizedFluctuation = dataTmp[index].年化收益率;
          let CurrentCirculatingMarketValue =
            dataTmp[index].目前流通市值 / 100000000;

          arr.push(REITs_name); // 名称
          arr.push(REITs_type_L2);
          arr.push(annualizedIncome);
          arr.push(annualizedFluctuation);
          arr.push(CurrentCirculatingMarketValue);

          this.dReitsDetailRiskReturnScatter.push(arr);
        }
      } else {
        for (let index = 0; index < dataTmp.length; index++) {
          let arr = [];

          // if (!index) {
          //   REITs_name = dataTmp[index].REITs_name
          //     ? dataTmp[index].REITs_name
          //     : "产权类";
          //   REITs_type_L2 = dataTmp[index].REITs_type_L2
          //     ? dataTmp[index].REITs_type_L2
          //     : "产权类";
          // } else {
          //   REITs_name = dataTmp[index].REITs_name
          //     ? dataTmp[index].REITs_name
          //     : "经营权类";
          //   REITs_type_L2 = dataTmp[index].REITs_type_L2
          //     ? dataTmp[index].REITs_type_L2
          //     : "经营权类";
          // }
          let annualizedIncome = dataTmp[index].年化波动率;
          let annualizedFluctuation = dataTmp[index].年化收益率;
          let CurrentCirculatingMarketValue =
            dataTmp[index].目前流通市值 / 100000000;

          arr.push(dataTmp[index].REITs_name);
          arr.push(dataTmp[index].REITs_type_L2);
          arr.push(annualizedIncome);
          arr.push(annualizedFluctuation);
          arr.push(CurrentCirculatingMarketValue);

          this.dReitsDetailRiskReturnScatter.push(arr);
        }
      }
      this.$nextTick(()=>{
        
        this.initChart3();
      })
    },

    initChart3(val) {
      if (!val) {
        this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      } else {
        this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3_dialog);
      }

      // this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      this.chartColumn3.clear();
      let data = this.dReitsDetailRiskReturnScatter;

      let order = JSON.parse(localStorage.getItem("ORDER"));

      // 根据L1L2排序
      // let TMP = []
      // order.forEach(node=>{
      //   let obj = data.filter(item=>{
      //     return node == item[1]
      //   })
      //   if(obj.length>0){
      //     TMP.push(obj[0])
      //   }
      // })

      // data = TMP

      // 从大到小排序
      // function compare(val1, val2) {
      //   return val2[4] - val1[4];
      // }
      // data.sort(compare);

      // function compare(val1, val2) {
      //   return val2[4] - val1[4];
      // }
      // data.sort(compare);

      let NAME = data.map((node) => {
        return node[1];
      });
      NAME = [...new Set(NAME)];

      let TMP = [];
      order.forEach((node) => {
        let obj = NAME.find((item) => item == node);
        if (obj) {
          TMP.push(obj);
        }
      });
      NAME = TMP;

      let colors = this.$store.state.colors;
      let colorArr = [];
      NAME.forEach((e) => {
        let obj = colors.filter((node) => {
          return e == node.name;
        });
        if (obj.length > 0) {
          colorArr.push(obj[0].value);
        }
      });

      colorArr.push("#d5754e");

      // 设置benchmark球体大小
      let arr = [];
      data.forEach((node) => {
        if (node[1] !== "benchmark") {
          arr.push(node[4]);
        }
      });

      // 平均数
      let sum = 0;
      arr.forEach((node) => {
        sum += Number(node);
      });
      let avg = sum / arr.length;

      let isDALEI =
        this.dButtons_separrate_current_chart3 == "大类" ? true : false;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let seriesArr = data.map((v, index) => {
        return {
          name: v[1],
          data: [[v[2], [v[3]]]],
          type: "scatter",
          symbolSize:
            v[1] == "benchmark" ? avg * 1.5 : v[4] > 30 ? 30 * 1.5 : v[4] * 1.5,

          cursor: "pointer",
          itemStyle: {
            normal: {
              borderColor: "#fff",
              borderWidth: 1,
            },
          },
          label: {
            show:
              isDALEI && v[1] == "benchmark"
                ? true
                : index < 10 || v[1] == "benchmark"
                ? true
                : false,
            position: "top",
            fontSize: this.ROOTFONTFIZE * (12 / 144),
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
            },
            formatter: function (val) {
              let val1 = val.seriesIndex;
              return data[val1][0];
            },
          },
        };
      });

      // let OPTIONDATA = this.$store.state.OPTIONDATA;

      //  NAME =   NAME.map((node,idx)=>{
      //     let obj = {
      //       name:node,
      //       itemStyle:{
      //         color: colorArr[idx+1]
      //       }
      //     }
      //     return obj
      //   })

      let texta = this.start_date_chart3;

      switch (texta) {
        case "3M":
          texta = "3个月以来";
          break;
        case "YTD":
          texta = "今年以来";
          break;

        case "6M":
          texta = "6个月以来";
          break;

        case "1Y":
          texta = "1年以来";

          break;
        case "3Y":
          texta = "3年以来";
          break;

        case "MAX":
          texta = "上市以来";
          break;
      }

      let option = {
        // title: {
        //   // 标题设置
        //   text: texta + " 公募REITs按资产类别年化收益及波动率对比",
        //   textStyle: {
        //     // 标题文字样式设置
        //     color: OPTIONDATA.title.textStyle.color,
        //     fontSize: OPTIONDATA.title.textStyle.fontSize,
        //   },
        //   left: "center",
        //   top: OPTIONDATA.title.top,
        // },
        grid: {
          //方法 2
          // top: 118,
          // left: 15,
          left: this.ROOTFONTFIZE * (70 / 144),
          top: this.ROOTFONTFIZE * (80 / 144),
          right: this.ROOTFONTFIZE * (130 / 144),
          bottom: this.ROOTFONTFIZE * (70 / 144),
          containLabel: true,
        },
        color: colorArr,
        legend: {
          orient: "vertical",
          top: "middle",
          icon: "circle",
          right: this.ROOTFONTFIZE * (10 / 144),
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: OPTIONDATA.legend.textStyle.fontSize,
          },

          align: "left",
          data: NAME,
        },
        xAxis: {
          type: "value",
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#dcdcdd",
            },
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
            formatter: function (val) {
              val = val * 100;
              let num = (parseInt(Math.abs(val)) + "").length >= 2 ? 1 : 2;
              val = Number(val).toFixed(num) + "%";
              return val;
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#48494c",
            },
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#dcdcdd",
            },
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (val) {
              val = val * 100;
              let num = (parseInt(Math.abs(val)) + "").length >= 2 ? 1 : 2;
              val = Number(val).toFixed(num) + "%";
              return val;
            },
          },
          splitLine: OPTIONDATA.yAxis.splitLine,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              show: false,
              color: "#333",
              backgroundColor: "#e7e7e7",
              formatter: function (value) {
                let val = value.value;
                val = val * 100;
                let num = (parseInt(Math.abs(val)) + "").length >= 2 ? 1 : 2;
                val = Number(val).toFixed(num) + "%";
                return val;
              },
            },
          },

          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += data[args[0].seriesIndex][0];
            str += "<br>";
            let name = `收益率`;
            let value = Number(args[0].value[1]) * 100;
            let num = (parseInt(Math.abs(value)) + "").length >= 2 ? 1 : 2;
            value = Number(value).toFixed(num) + "%";
            str += `${args[0].marker}${name}：${value}`;

            str += "<br>";
            let name2 = `波动率`;
            let value2 = Number(args[0].value[0]) * 100;
            let num2 = (parseInt(Math.abs(value2)) + "").length >= 2 ? 1 : 2;
            value2 = Number(value2).toFixed(num2) + "%";
            str += `${args[0].marker}${name2}：${value2}`;

            if (data[args[0].seriesIndex][1] == "benchmark") return str;

            str += "<br>";
            let name3 = `流通市值`;
            let value3 = Number(data[args[0].seriesIndex][4]);
            let num3 = (parseInt(Math.abs(value3)) + "").length >= 2 ? 1 : 2;
            value3 = Number(value3).toFixed(num3);
            str += `${args[0].marker}${name3}：${value3}亿元`;
            return str;

            // let str = "";
            // str += `${args[0].axisValueLabel}`;
            // str += "<br>";
            // args.forEach((node) => {
            //   let val = Number(node.value[1][0]) * 100;
            //   val = val.toFixed(2);

            //   let name = data[node.seriesIndex][0];
            //   str += `${node.marker}${name}：${val}%`;

            //   return str;
            // });
            // return str;
          },
        },

        series: seriesArr,
      };

      this.chartColumn3.setOption(option);
      this.chartColumn3.resize();
      // this.funResize();
    },

    funResize() {
      // this.fnGetWidth();

      this.$nextTick(()=>{
        this.initChart1()
        this.initChart2()
        this.initChart3()

        // this.chartColumn1.resize();
        // this.chartColumn2.resize();
        // this.chartColumn3.resize();
      })

    },
  },
  components: {
    CSelects,
    CSelect,
    CCascader,
    CCascader2,
    CTimeButtons,
    CCategoryRadio,
    CButtonsSeparate,
    CButtonsDownload,
    CDialog,
    CSelectBench,
  },
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {

      this.$nextTick(() => {
        this.fnGetTheme()
        fnDownloadBgc()
        this.initChart1();
        this.initChart2();
        this.initChart3();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
}

.CButtonsSeparate_style {
  // margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  // margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-left: 20px;
}

.choose_area {
  padding: calc(var(--ROOTFONTFIZE) * (10 / 144))
    calc(var(--ROOTFONTFIZE) * (18 / 144));
}

.choose_area_center {
  display: flex;
  justify-content: center;
}

.choose_area2 {
  padding: 0px calc(var(--ROOTFONTFIZE) * (18 / 144));
}
.choose_area2 span {
  display: flex;
  align-items: center;
  color: #fff;
}

.choose_area span {
  display: flex;
  align-items: center;
  color: var(--chart_title);
  margin-right: calc(var(--ROOTFONTFIZE) * (50 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}

.chartColumn2_1_style {
}

.vertical_style {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vertical_style .item {
  display: flex;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.vertical_style div span:nth-child(1) {
  width: calc(var(--ROOTFONTFIZE) * (120 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  // font-size: 13px;
}

.el-checkbox {
}

.CTimeButtons_style {
  // margin: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-bottom: 0px;
}

.el-row {
  padding-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.ddl {
  position: absolute;
  left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: #fff;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}

.Yield_Style {
  color: var(--legndAreaItem_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  position: absolute;
  top: 50%;
  left: calc(var(--ROOTFONTFIZE) * (30 / 144));
  transform: translateY(-50%);
  letter-spacing: 0.3em;
  writing-mode: vertical-rl;
}
.Volatility_Style {
  color: var(--legndAreaItem_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  position: absolute;
  left: 50%;
  bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
  letter-spacing: 0.3em;
  transform: translateX(-50%);
}

.oneyear {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}

.card_tit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: pink;
}
</style>
